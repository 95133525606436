<template>
  <div class="w-12/12 h-full bg-black-80 h-64">
    <main-message></main-message>

    <div
      class="w-12/12 md:w-11/12 lg:10/12 xl:w-6/12 mx-auto my-8 overflow-hidden select-none bg-gradient-to-b from-black-60 via-black-30 to-black-60"
    >
      <p
        class="text-white text-1xl md:text-2xl lg:text-3xl xl:text-4xl py-2 uppercase"
      >
        {{ $t("free-training") }}
      </p>
    </div>
    <!-- FORM-->
    <div
      class="w-12/12 md:w-11/12 lg:10/12 xl:w-6/12 h-full md:h-full bg-gradient-to-b from-black-70 via-black-30 to-black-70 mx-auto my-8 overflow-hidden select-none"
    >
      <div class="text-white h-8 w-16 ml-auto bg-black-70 rounded-full m-1">
        <span class="font-semibold p-1">{{ tab_index + 1 }} / 3</span>
      </div>
      <div v-if="tab_index === 0" class="m-auto w-9/12 md:h-650 pb-10 flex">
        <p
          class="m-auto text-white tracking-wide text-xl text-center leading-20"
        >
          {{ $t("free-training-desc") }}
        </p>
      </div>
      <div
        v-if="tab_index === 1"
        class="m-auto w-full md:h-full py-5 px-5 grid grid-cols-1 md:grid-cols-2"
      >
        <div class="m-2">
          <p class="text-white m-2 uppercase text-left xl:text-xl ml-0">
            {{ $t("full-name") }}
          </p>
          <input
            v-model="form.name"
            :class="$v.form.name.$error ? 'bg-orange-50' : 'bg-black-10'"
            class="w-full h-10 text-xl xl:text-1xl px-2 rounded-sm"
            type="text"
          />
        </div>

        <div class="m-2">
          <p class="text-white m-2 uppercase text-left xl:text-xl ml-0">
            {{ $t("age") }}
          </p>
          <input
            v-model="form.age"
            :class="$v.form.age.$error ? 'bg-orange-50' : 'bg-black-10'"
            class="w-full h-10 text-xl xl:text-1xl px-2 rounded-sm"
            type="number"
          />
        </div>

        <div class="m-2">
          <p class="text-white m-2 uppercase text-left xl:text-xl ml-0">
            {{ $t("municipality") }}
          </p>
          <input
            v-model="form.municipality"
            class="w-full h-10 text-xl xl:text-1xl px-2 rounded-sm bg-black-10"
            type="text"
          />
        </div>

        <div class="m-2">
          <p class="text-white m-2 uppercase text-left xl:text-xl ml-0">
            {{ $t("place-of-residence") }}
          </p>
          <input
            v-model="form.place_of_residence"
            class="w-full h-10 text-xl xl:text-1xl px-2 rounded-sm bg-black-10"
            type="text"
          />
        </div>

        <div class="m-2">
          <p class="text-white m-2 uppercase text-left xl:text-xl ml-0">
            {{ $t("address") }}
          </p>
          <input
            v-model="form.address"
            class="w-full h-10 text-xl xl:text-1xl px-2 rounded-sm bg-black-10"
            type="text"
          />
        </div>

        <div class="m-2">
          <p class="text-white m-2 uppercase text-left xl:text-xl ml-0">
            {{ $t("po-box") }}
          </p>
          <input
            v-model="form.post_code"
            class="w-full h-10 text-xl xl:text-1xl px-2 rounded-sm bg-black-10"
            type="number"
          />
        </div>

        <div class="m-2 relative">
          <p class="text-white m-2 uppercase text-left xl:text-xl ml-0">
            {{ $t("mobile-phone") }}
          </p>
          <span
            v-if="
              ($v.form.phone_1.$error && !$v.form.phone_1.minLength) ||
              ($v.form.phone_1.$error && !$v.form.phone_1.maxLength)
            "
            class="italic text-orange-50 ml-2 text-sm absolute right-0 top-2 xl:top-3"
          >
            *{{
              !$v.form.phone_1.minLength
                ? $t("minimum-eight-digits")
                : $t("maximum-fifteen-digits")
            }}
          </span>
          <input
            v-model="form.phone_1"
            class="w-full h-10 text-xl xl:text-1xl px-2 rounded-sm"
            placeholder="06xxxxxxxx"
            :class="$v.form.phone_1.$error ? 'bg-orange-50' : 'bg-black-10'"
            type="number"
          />
        </div>

        <div class="m-2">
          <p class="text-white m-2 uppercase text-left xl:text-xl ml-0">
            {{ $t("landline") }}
          </p>
          <input
            v-model="form.phone_2"
            class="w-full h-10 text-xl xl:text-1xl px-2 rounded-sm bg-black-10"
            type="number"
          />
        </div>

        <div class="m-2 relative">
          <p class="text-white m-2 uppercase text-left xl:text-xl ml-0">
            {{ $t("email") }}
          </p>
          <span
            v-if="$v.form.email.$error && !$v.form.email.email"
            class="italic text-orange-50 ml-2 text-sm absolute right-0 top-2 xl:top-3"
          >
            *{{ $t("bad-email-format") }}
          </span>
          <input
            v-model="form.email"
            :class="$v.form.email.$error ? 'bg-orange-50' : 'bg-black-10'"
            class="w-full h-10 text-xl xl:text-1xl px-2 rounded-sm"
            type="email"
          />
        </div>
      </div>

      <div
        v-if="tab_index === 2"
        class="m-auto w-10/12 md:h-full py-5 px-5 grid grid-cols-1 md:grid-cols-2"
      >
        <p
          class="md:col-span-2 text-white m-2 uppercase text-left text-lg md:text-1xl xl:text-2xl tracking-wider"
        >
          {{ $t("i-want-to-attend-next-training-free") }} :
        </p>
        <div class="md:m-2">
          <div class="text-left m-2" v-for="(training, i) in trainings">
            <div v-if="i <= 15">
              <input
                type="radio"
                :value="training.name"
                v-model="form.wish_to_try"
              />
              <label
                v-if="$i18n.locale === 'sr'"
                class="ml-3 md:text-lg text-white uppercase tracking-wide"
                >{{ training.name }}</label
              >
              <label
                v-if="$i18n.locale === 'en'"
                class="ml-3 md:text-lg text-white uppercase tracking-wide"
                >{{ training.name_eng }}</label
              >
            </div>
          </div>
        </div>

        <div class="md:m-2">
          <div class="text-left m-2" v-for="(training, i) in trainings">
            <div v-if="i > 15">
              <input
                type="radio"
                :value="training.name"
                v-model="form.wish_to_try"
              />
              <label
                v-if="$i18n.locale === 'sr'"
                class="ml-3 md:text-lg text-white uppercase tracking-wide"
                >{{ training.name }}</label
              >
              <label
                v-if="$i18n.locale === 'en'"
                class="ml-3 md:text-lg text-white uppercase tracking-wide"
                >{{ training.name_eng }}</label
              >
            </div>
          </div>
        </div>
      </div>

      <div class="xl:w-12/12">
        <div class="float-right flex m-4">
          <div v-if="tab_index > 0" class="mx-2">
            <button
              class="bg-yellow-50 px-4 py-1 rounded text-btn"
              @click="tab_index--"
            >
              {{ $t("previous") }}
            </button>
          </div>
          <div v-if="tab_index < 2" class="mx-2">
            <button
              class="bg-yellow-50 px-4 py-1 rounded text-btn"
              @click="nextOne()"
            >
              {{ $t("next") }}
            </button>
          </div>
          <div v-if="tab_index === 2" class="mx-2">
            <button
              class="bg-yellow-50 px-4 py-1 rounded text-btn"
              @click="submitForm"
            >
              {{ $t("send") }}
            </button>
          </div>
        </div>
      </div>
      <successfully-sent-form-modal
        :modal_name="'success_info_modal'"
      ></successfully-sent-form-modal>
    </div>
  </div>
</template>

<script>
import MainMessage from "../components/MainMessage";
import SuccessfullySentFormModal from "@/components/SuccessfullySentFormModal";
import { mapGetters } from "vuex";
import {
  required,
  maxLength,
  minLength,
  email,
} from "vuelidate/lib/validators";
export default {
  name: "FreeTrainingForm",
  components: { MainMessage, SuccessfullySentFormModal },
  data() {
    return {
      tab_index: 0,
      trainings: [],
      form: {
        name: "",
        age: null,
        address: "",
        place_of_residence: "",
        city: "",
        municipality: "",
        post_code: null,
        phone_1: null,
        phone_2: null,
        email: "",
        wish_to_try: "Teretanu",
      },
    };
  },
  validationGroup: ["form"],
  validations: {
    form: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      phone_1: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(15),
      },
      age: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(2),
      },
    },
  },
  mounted() {
    this.getTrainings();
    if (this.user) {
      this.fetchMe();
    }
  },
  computed: {
    ...mapGetters("auth", {
      user: "getMe",
    }),
  },
  methods: {
    async getTrainings() {
      const { data } = await this.$http.get("/training");
      this.trainings = data;
    },
    async submitForm() {
      await this.$http
        .post("/form/free_training", this.form)
        .then((resp) => {
          this.handle_success_response("SUC_SUCCESS");
          window.dataLayer.push({ event: "formSubmit" });
          this.$modal.show("success_info_modal");
          this.tab_index = 0;
        })
        .catch((err) => {
          this.handle_error(err);
        });
    },
    nextOne() {
      if (this.tab_index === 0) {
        this.tab_index++;
      } else {
        this.$v.form.$touch();
        if (!this.$v.form.$invalid) {
          this.tab_index++;
          this.form.wish_to_try = this.trainings[0].name;
        }
      }
    },
    fetchMe() {
      this.form.name = this.user.first_name + " " + this.user.last_name;
      this.form.email = this.user.email;
      this.form.phone_1 = this.user.phone;
    },
  },
};
</script>
