<template>
    <div class="w-full">
        <div>
            <main-message></main-message>
        </div>

        <div class="bg-black-60 w-10/12 md:w-9/12 lg:w-8/12 xl:w-6/12 uppercase mx-auto grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 xl:gap-4 mt-5 py-5 md:py-20 px-5 md:px-10 xl:my-10 xl:py-12 mb-10 shadow-2xl">

            <div class="mx-4 mb-2 md:mb-4 text-sm md:text-xl xl:text-xl">
                <p class="text-black-10 mb-2 text-left">{{ $t('first-name') }}
                    <span v-if="!$v.form.first_name.required" class="text-orange-50 ml-2 xl:text-xl">*&nbsp;{{ $t('required-field') }}</span>
                </p>
                <input
                    data-test="r-first-name"
                    :class="!$v.form.first_name.required ? 'bg-orange-50' : 'bg-black-10'"
                    v-model="form.first_name"
                    :placeholder="$i18n.locale === 'sr' ? 'Ime' : 'First name'"
                    class="w-full rounded-sm focus:outline-none px-4 py-1"
                    type="text">
            </div>
            <div class="mx-4 mb-2 md:mb-4 text-sm md:text-xl xl:text-xl">
                <p class="text-black-10 mb-2 text-left">{{ $t('last-name') }}
                    <span v-if="!$v.form.last_name.required" class="text-orange-50 ml-2 font-normal xl:text-lg italic">*&nbsp;{{ $t('required-field') }}</span>
                </p>
                <input
                    data-test="r-last-name"
                    :class="!$v.form.last_name.required ? 'bg-orange-50' : 'bg-black-10'"
                    v-model="form.last_name"
                    :placeholder="$i18n.locale === 'sr' ? 'Prezime' : 'Last name'"
                    class="w-full rounded-sm focus:outline-none px-4 py-1"
                    type="text">
            </div>
            <div class="mx-4 mb-2 md:mb-4 text-sm md:text-xl xl:text-xl">
                <p class="text-black-10 mb-2 text-left">{{ $t('email') }}
                    <span v-if="!$v.form.email.required" class="text-orange-50 ml-2 xl:text-xl">*&nbsp;{{ $t('required-field') }}</span>
                    <span v-if="!$v.form.email.email" class="text-orange-50 ml-2 xl:text-xl">*&nbsp;{{ $t('bad-email-format') }}</span>
                </p>
                <input
                    data-test="r-email"
                    v-model="form.email"
                    :placeholder="$i18n.locale === 'sr' ? 'Email adresa' : 'Email address'"
                    class="w-full rounded-sm focus:outline-none px-4 py-1"
                    :class="!$v.form.email.required || !$v.form.email.email ? 'bg-orange-50' : 'bg-black-10' "
                    type="email">
            </div>
            <div class="mx-4 mb-2 md:mb-4 text-sm md:text-xl xl:text-xl">
                <p class="text-black-10 mb-2 text-left">{{ $t('phone') }}
                    <span v-if="!$v.form.phone.required" class="text-orange-50 ml-2 xl:text-xl">*&nbsp;{{ $t('required-field') }}</span>
                    <span v-if="!$v.form.phone.minLength" class="text-orange-50 ml-2 xl:text-xl">*&nbsp;{{ $t('minimum-eight-digits') }}</span>
                </p>
                <input
                    data-test="r-phone"
                    v-model="form.phone"
                    class="w-full rounded-sm focus:outline-none px-4 py-1"
                    name="phone"
                    placeholder = "06xxxxxxxx"
                    :class="!$v.form.phone.required || !$v.form.phone.minLength ? 'bg-orange-50' : 'bg-black-10' "
                    type="number">
            </div>

            <div class="mx-4 mb-2 md:mb-4 text-sm md:text-xl xl:text-xl">
                <p class="text-black-10 mb-2 text-left">{{ $t('card-id') }}</p>
                <input
                    data-test="r-card-id"
                    v-model="form.card_id"
                    :placeholder="$i18n.locale === 'sr' ? 'Broj kartice' : 'Card ID'"
                    class="w-full rounded-sm focus:outline-none px-4 py-1"
                    type="text">
            </div>
            <div class="mx-4 mb-2 md:mb-4 text-sm md:text-xl xl:text-xl">
                <p class="text-black-10 mb-2 text-left">JMBG
                    <span v-if="!$v.form.jmbg.minLength" class="text-orange-50 ml-2 xl:text-xl">*&nbsp;{{ $t('must-be-13-digits') }}</span>
                </p>
                <input
                    data-test="r-jmbg"
                    v-model="form.jmbg"
                    :placeholder="$i18n.locale === 'sr' ? 'JMBG' : 'JMBG'"
                    class="w-full rounded-sm focus:outline-none px-4 py-1"
                    :class="!$v.form.jmbg.minLength ? 'bg-orange-50' : 'bg-black-10'"
                    type="number">
            </div>
            <div class="mx-4 mb-2 md:mb-4 text-sm md:text-xl xl:text-xl">
                <p class="text-black-10 mb-2 text-left">{{ $t('password') }}
                    <span v-if="!$v.form.password.required" class="text-orange-50 ml-2 xl:text-xl">*&nbsp;{{ $t('required-field') }}</span>
                    <span v-if="!$v.form.password.minLength" class="text-orange-50 ml-2 xl:text-xl">*&nbsp;{{ $t('minimum-five-characters') }}</span>
                </p>
                <div class="grid grid-cols-5">
                    <div class="col-span-4">
                        <input
                            data-test="r-password"
                            name="pass"
                            :class="!$v.form.password.required ? 'bg-orange-50' : 'bg-black-10' "
                            v-model="form.password"
                            :placeholder="$i18n.locale === 'sr' ? 'Lozinka' : 'Password'"
                            class="w-full rounded-l-sm focus:outline-none px-4 py-1"
                            :type="passwordType"
                        >
                    </div>
                    <div class="col-span-1 w-full">
                        <div
                            @click="switchType('password')"
                            :class="!$v.form.password.required ? 'bg-orange-50' : 'bg-black-10' "
                            class="bg-black-10 w-full ml-auto rounded-r-sm cursor-pointer py-1"
                        >
                            <icon v-if="passwordType === 'password'" :icon="['far', 'eye']" class="text-black-30"></icon>
                            <icon v-if="passwordType === 'text'" :icon="['far', 'eye-slash']" class="text-black-30"></icon>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mx-4 mb-2 md:mb-4 text-sm md:text-xl xl:text-xl">
                <p class="text-black-10 mb-2 text-left tracking-tight">{{ $t('re-type-password') }}
                    <span v-if="!$v.form.re_password.required" class="text-orange-50 ml-2 xl:text-xl">*&nbsp;{{ $t('required-field') }}</span>
                </p>
                <div class="grid grid-cols-5">
                    <div class="col-span-4">
                        <input
                            data-test="r-re-password"
                            :class="!$v.form.re_password.required ? 'bg-orange-50' : '' "
                            v-model="form.re_password"
                            :placeholder="$i18n.locale === 'sr' ? 'Ponovi lozinku' : 'Repeat password'"
                            class="w-full rounded-l-sm focus:outline-none px-4 py-1"
                            :type="repasswordType">
                    </div>
                    <div class="col-span-1 w-full">
                        <div
                            @click="switchType('repassword')"
                            :class="!$v.form.re_password.required ? 'bg-orange-50' : 'bg-black-10' "
                            class="bg-black-10 w-full ml-auto rounded-r-sm cursor-pointer py-1"
                        >
                            <icon v-if="repasswordType === 'password'" :icon="['far', 'eye']" class="text-black-30"></icon>
                            <icon v-if="repasswordType === 'text'" :icon="['far', 'eye-slash']" class="text-black-30"></icon>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!$v.form.re_password.sameAsPassword" class="col-span-1 md:col-span-1 lg:col-span-2 text-orange-50 font-semibold uppercase">
                {{ $t('passwords-do-not-match') }}
            </div>

            <div class="mx-4 mb-10 pt-8 mt-2 xl:pt-6 lg:col-span-2">
                <button
                    data-test="r-button"
                    @click="submit_form"
                    class="w-full bg-green-20 uppercase md:text-xl xl:text-base hover:bg-green-30 text-white font-semibold px-4 py-1 md:py-0 xl:py-1 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                >{{ $t('register') }}
                </button>
            </div>
        </div>

        <modal
          name="confirm_email_info_modal"
          :resizable="false"
          @closed="$router.push('/login')"
          :adaptive="true">
            <div class="w-full bg-black-60 h-full border-2 border-green-20">
                <div class="text-5xl md:text-6xl lg:text-7xl mx-auto">
                    <icon :icon="['fas', 'envelope']" class="text-green-20"></icon>
                </div>
                <div class="mx-auto h-20 w-2/3 text-left md:text-lg">
                    <p class="text-white px-2">{{ $t('hi') }}</p>
                    <p class="text-white px-2">{{ $t('thank-you-for-you-registration') }}</p>
                    <p class="text-white px-2">{{ $t('please-click-the-button-below') }}</p>
                    <p class="text-white px-2">{{ $t('see-you-at-training') }}</p>
                </div>
            </div>
    </modal>

    </div>
</template>

<script>
import MainMessage from "../components/MainMessage";
import { sha512 } from 'js-sha512';
import { required, minLength, email, maxLength, sameAs } from 'vuelidate/lib/validators'
export default {
    data() {
        return {
            form: {},
            // re_password: '',
            first_name_state: false,
            last_name_state: false,
            email_state: false,
            password_state: false,
            re_password_state: false,
            phone_state: false,
            matching_password_state: null,
            form_valid: null,
            email_valid_state: true,
            phone_valid_state : true,
            jmbg_valid_state : true,
            passwordType: 'password',
            repasswordType: 'password',

        };
    },
    validations: {
        form: {
            first_name: {
                required,
            },
            last_name: {
                required,
            },
            email: {
                required,
                email,
            },
            phone: {
                required,
                minLength: minLength(8),
                maxLength: maxLength(15),
            },
            password: {
                required,
                minLength: minLength(5)
            },
            re_password: {
                required,
                sameAsPassword: sameAs('password')
            },
            jmbg: {
                minLength: minLength(13),
                maxLength: maxLength(13)
            }
        }
    },
    components: {MainMessage},
    watch: {
    //     "form.email"(val) {
    //
    //         this.email_valid_state = this.validateEmail(val);
    //
    //         if (val === "" || this.email_valid_state === false) {
    //             this.email_state = false;
    //             this.form_valid = false;
    //         } else if (val && this.email_valid_state ) {
    //             this.email_state = true;
    //             this.form_valid = true;
    //         }
    //     },
        "form.phone"(val) {
            if (val.length > 13) {
                this.form.phone = this.form.phone.substr(0, 15);
            }

            // this.phone_valid_state = this.validatePhone(val);

            if (val === "" || this.phone_valid_state === false) {
                this.phone_state = false;
                this.form_valid = false;
            } else if (val && this.phone_valid_state){
                this.phone_state = true;
                this.form_valid = true;
            }
        },
        "form.jmbg"(val) {
            if (val.length > 13) {
                this.form.jmbg = this.form.jmbg.substr(0, 13);
            }
        }
    },
    methods: {
        switchType(field) {
            if (field === 'password') {
                if (this.passwordType === 'password') {
                    this.passwordType = 'text'
                } else {
                    this.passwordType = 'password'
                }
            } else {
                if (this.repasswordType === 'password') {
                    this.repasswordType = 'text'
                } else {
                    this.repasswordType = 'password'
                }
            }
        },
        async create_user(api_data) {
            delete api_data.re_password;
            api_data.password = sha512(api_data.password);
            api_data.language = this.$i18n.locale;
            await this.$http.post("user/register", api_data)
                .then((response) => {
                    this.$modal.show('confirm_email_info_modal');
                    // this.$router.push('/login');
                })
                .catch((err) => {
                    this.handle_error(err);
                })
        },
        submit_form() {
            const data = {};

            for (const [key, value] of Object.entries(this.form)) {
                data[key] = value;
            }
            if (this.$v.$invalid) {
                console.log('invalid je true: ', this.$v.$invalid)
            } else {
                this.create_user(data);
            }
        },
    }
};
</script>