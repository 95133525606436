<template>
    <div class="w-12/12 overflow-x-hidden">
        <main-message class="xl:hidden"></main-message>
        <div class="w-12/12 h-350 md:h-400 lg:h-500 xl:h-500 sticky overflow-x-hidden pic3">
            <div class="xl:w-2/3 mx-auto">
                <p class="text-white uppercase font-bold text-2xl md:text-4xl lg:text-5xl xl:text-6xl
                          text-left mx-8 md:mx-20 lg:mx-24 xl:mx-0 pt-12 md:pt-16 lg:pt-24 xl:pt-20">
                    {{ $t('les-mills-trainings') }}
                </p>
                <p class="text-white md:text-lg lg:text-1xl text-left mx-8 md:mx-20 lg:mx-24 xl:mx-0 md:mt-1 lg:mt-2 tracking-wide">
                    {{ $t('les-mills-short-desc') }}
                </p>
                <div class="absolute bottom-0 flex w-full xl:w-2/3 xl:mx-0 h-16 bg-cyan-20 bg-opacity-50">
                    <div class="w-1/3 text-white border-r border-black-80">
                        <p class="text-base lg:text-lg mt-1 uppercase">{{ $t('increase') }}</p>
                        <p class="font-semibold text-lg lg:text-1xl tracking-widest uppercase">{{ $t('strength') }}</p>
                    </div>
                    <div class="w-1/3 text-white">
                        <p class="text-base lg:text-lg mt-1 uppercase">{{ $t('build') }}</p>
                        <p class="font-semibold text-lg lg:text-1xl tracking-widest uppercase">{{ $t('fitness') }}</p>
                    </div>
                    <div class="w-1/3 text-white border-l border-black-80">
                        <p class="text-base lg:text-lg mt-1 uppercase">{{ $t('improve') }}</p>
                        <p class="font-semibold text-lg lg:text-1xl tracking-widest uppercase">{{ $t('health') }}</p>
                    </div>
                </div>
            </div>
        </div>

        <a name="lesmills_description"></a>
        <div class="w-12/12 xl:w-6/12 xl:mx-auto h-full mx-4 md:px-4 md:mx-12 py-10 md:pb-10 md:my-8">
            <h1 class="font-semibold text-white text-left text-lg md:text-2xl lg:text-3xl xl:text-4xl uppercase">
                {{ $t('what-are-les-mills-trainings') }}
            </h1>
            <p class="text-white text-sm md:text-base lg:text-lg xl:text-xl text-left mt-1 md:mt-3 tracking-wide text-justify">{{ $t('les-mills-desc') }}</p>
        </div>

        <div class="w-12/12 h-full py-12 md:py-16 bg-blue-500">
            <div class="w-12/12 md:w-10/12 lg:w-7/12 xl:w-6/12 mx-auto flex bg-blue-500 h-full grid grid-cols-2">
                <div class="m-auto px-4">
                    <p class="font-semibold m-auto text-white text-left mt-1 uppercase md:text-lg lg:text-xl xl:text-1xl md:pr-4">{{ $t('les-mills-we-are-proud') }}</p>
                </div>
                <div class="m-auto text-white px-4 border-l grid grid-cols-1 md:grid-cols-7 border-white flex">
                    <div class="md:col-span-2 md:m-auto">
                        <warning-mobile-icon class="h-12 md:h-16 xl:mr-8"></warning-mobile-icon>
                    </div>
                    <div class="md:col-span-5">
                        <p class="text-left font-semibold md:text-base lg:text-xl">{{ $t('les-mills-warning') }} : </p>
                        <p class="text-left text-sm md:text-base lg:text-lg text-left">
                            {{ $t('les-mills-warning-desc') }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

         <div class="w-12/12 md:w-10/12 xl:w-8/12 mx-auto h-full py-12 px-4 text-white">
            <p class="font-semibold text-lg md:text-xl xl:text-2xl uppercase">{{ $t('find-your-training') }}</p>
            <p class="text-sm md:text-base lg:text-lg mt-1">{{ $t('fill-out-the-form-desc') }}</p>
            <button
                @click="$router.push('/training-selection')"
                class="bg-green-20 hover:bg-green-30 text-black-80 text-btn py-2 px-5 mt-3 uppercase">
                {{ $t('find-your-training') }}
            </button>
        </div>

         <div class="h-full xl:w-9/12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-4 mx-auto xl:mx-auto">
            <div
                v-for="training in trainings"
                v-if="training.training_type === 'lesmills'"
                @click="setTraining(training)"
                class="rounded-lg h-225 w-56 mx-auto md:p-5 transform xl:hover:scale-110 cursor-pointer transition duration-200 ease-in-out mt-16 md:mt-5">
                 <div class="py-1 text-black-20 border-black-20">
                    <div v-if="$i18n.locale === 'sr'" class="text-white text-sm md:text-base text-left font-semibold m-0 p-0 uppercase">{{ training.name }}</div>
                    <div v-if="$i18n.locale === 'en'" class="text-white text-sm md:text-base text-left font-semibold m-0 p-0 uppercase">{{ training.name_eng }}</div>
                </div>
                <app-image
                    :height="'100%'"
                    :src="`${host}/${training.photo_url}`"
                    :width="'100%'"
                />
            </div>
        </div>

        <div class="w-12/12 h-full mt-32 bg-blue relative">
            <div class="w-11/12 md:w-8/12 xl:w-6/12 mx-auto text-white">
                <h1 class="text-left text-lg md:text-xl xl:text-2xl font-semibold uppercase">
                    {{ $t('frequently-asked-questions') }}
                </h1>
                <p class="text-left text-sm md:text-base lg:text-lg text-black-30">
                    {{ $t('frequently-asked-questions-desc') }}
                </p>
            </div>
            <div class="xl:w-12/12 bg-blue-20 h-full pb-20 mt-5 text-sm md:text-base lg:text-xl tracking-wide">
                <div class="w-11/12 md:w-8/12 xl:w-6/12 mx-auto">
                    <div class="h-10 w-10">
                        <triangle-icon></triangle-icon>
                    </div>
                    <div class="grid grid-cols-8 border-b border-blue-10 py-2">
                        <div class="col-span-7 mt-5 pr-3">
                            <p
                                :class="question_1_open ? 'font-semibold transition duration-100 ease-in-out ' : 'transition duration-100 ease-in-out'"
                                class="text-left text-white my-auto text-left">{{ $t('question-1') }}
                            </p>
                            <transition name="fade" mode="out-in" appear>
                                <p v-if="question_1_open" class="text-left text-white my-auto text-left py-3 transition duration-75 linear">
                                    {{ $t('answer-1') }}
                                </p>
                            </transition>
                        </div>
                        <div class="col-span-1 flex pt-6">
                            <div @click="question_1_open = !question_1_open" class="h-12 w-12 my-auto ml-auto">
                                <faq-arrow-icon></faq-arrow-icon>
                            </div>
                        </div>
                    </div>
                    <div class="grid grid-cols-8 border-b border-blue-10 py-2">
                        <div class="col-span-7 mt-5 pr-3">
                            <p
                                :class="question_2_open ? 'font-semibold' : ''"
                                class="text-left text-white my-auto text-left">{{ $t('question-2') }}
                            </p>
                            <transition name="fade" mode="out-in" appear>
                                <p v-if="question_2_open" class="text-left text-white my-auto text-left py-3">
                                    {{ $t('answer-2') }}
                                </p>
                            </transition>
                        </div>
                        <div class="col-span-1 flex pt-6">
                            <div @click="question_2_open = !question_2_open" class="h-12 w-12 my-auto ml-auto">
                                <faq-arrow-icon></faq-arrow-icon>
                            </div>
                        </div>
                    </div>

                    <div class="grid grid-cols-8 border-b border-blue-10 py-2">
                        <div class="col-span-7 mt-5 pr-3">
                            <p
                                :class="question_3_open ? 'font-semibold' : ''"
                                class="text-left text-white my-auto text-left">{{ $t('question-3') }}
                            </p>
                            <transition name="fade" mode="out-in" appear>
                                <p v-if="question_3_open" class="text-left text-white my-auto text-left py-3">
                                    {{ $t('answer-3') }}
                                </p>
                            </transition>
                        </div>
                        <div class="col-span-1 flex pt-6">
                            <div @click="question_3_open = !question_3_open" class="h-12 w-12 my-auto ml-auto">
                                <faq-arrow-icon></faq-arrow-icon>
                            </div>
                        </div>
                    </div>

                    <div class="grid grid-cols-8 border-b border-blue-10 py-2">
                        <div class="col-span-7 mt-5 pr-3">
                            <p
                                :class="question_4_open ? 'font-semibold' : ''"
                                class="text-left text-white my-auto text-left">{{ $t('question-4') }}
                            </p>
                            <transition name="fade" mode="out-in" appear>
                                <p v-if="question_4_open" class="text-left text-white my-auto text-left py-3">
                                    {{ $t('answer-4') }}
                                    <a href="mailto:office@kocovic.rs" class="hover:underline">office@kocovic.rs</a>
                                </p>
                            </transition>
                        </div>
                        <div class="col-span-1 flex pt-6">
                            <div @click="question_4_open = !question_4_open" class="h-12 w-12 my-auto ml-auto">
                                <faq-arrow-icon></faq-arrow-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <training-info-modal
            :modal_name="'training_modal'"
            :training_data="training_data">
        </training-info-modal>
    </div>
</template>

<script>
    import MainMessage from "../components/MainMessage";
    import WarningMobileIcon from "../icons/WarningMobileIcon";
    import TriangleIcon from "../icons/TriangleIcon";
    import FaqArrowIcon from "../icons/FaqArrowIcon";
    import TrainingInfoModal from "../components/TrainingInfoModal";
    import { mapActions, mapGetters } from "vuex";
    export default {
        name: "LesMillsTraining",
        metaInfo: {
            title: 'Fitness & Gym Kočović | Teretana Kočović Beograd',
            titleTemplate: '%s ← Teretana Beograd',
            meta: [
              {name: 'description', content: 'Teretana Kočović Vam nudi sve na jednom mestu. Izaberite trening po Vašim potrebama i prijavite se odmah! | Teretana Beograd | Les Mills treninzi beograd'},
              {
                  name: 'keywords',
                  content: '' +
                      'Les mills treninzi, ' +
                      'fitnes Beograd, ' +
                      'fitnes Zemun, ' +
                      'fitnes Blokovi, ' +
                      'Fitnes Novi Beograd, ' +
                      'fitnes Vračar, ' +
                      'fitnes Banjica, ' +
                      'Fitnes Pionir, ' +
                      'Fitnes Ustanička, ' +
                      'teretana Beograd, ' +
                      'teretana Zemun, ' +
                      'teretana Blokovi, ' +
                      'teretana Vračar, ' +
                      'Teretana Pionir, ' +
                      'Teretana Ušće, ' +
                      'Teretana Banjica, ' +
                      'Les Mills Beograd, ' +
                      'grupni fitnes, ' +
                      'fitnes treninzi Beograd, ' +
                      'mršavljenje, ' +
                      'zatezanje, ' +
                      'oblikovanje tela, ' +
                      'Gubljenje kilograma/težine, ' +
                      'Celulit, ' +
                      'kardio treninzi, ' +
                      'gym belgrade'
              },
            ],
        },
        components: {MainMessage, WarningMobileIcon, TriangleIcon, FaqArrowIcon, TrainingInfoModal},
        data() {
            return {
                question_1_open: false,
                question_2_open: false,
                question_3_open: false,
                question_4_open: false,
                emptyStars: null,
                host: process.env.VUE_APP_API_URL,
                training_data: {
                    name: '',
                    name_eng: '',
                    description: '',
                    description_eng: '',
                    duration: '',
                    intensity: '',
                    calories: '',
                    photo_url: '',
                },
            }
        },
        mounted() {
            this.fetchTrainings(this.$i18n.locale);
        },
        computed: {
            ...mapGetters("trainings", {
                trainings: "getTrainings"
            })
        },
        methods: {
            ...mapActions("trainings", {
                fetchTrainings: "fetchTrainings",
                sortTrainings: "sortTrainings",
            }),
            setTraining(data) {
                this.training_data.id = data.id;
                this.training_data.title = data.name;
                this.training_data.title_eng = data.name_eng;
                this.training_data.desc = data.description;
                this.training_data.desc_eng = data.description_eng;
                this.training_data.intensity = data.intensity;
                this.training_data.duration = data.duration;
                this.training_data.calories = data.calories;
                this.training_data.photo_url = data.photo_url;
                this.training_data.empty_stars = 5 - data.intensity;
                this.$modal.show('training_modal');
            },
        },
        watch: {
            '$i18n.locale'(val) {
                this.sortTrainings(val);
            }
        }
    }
</script>