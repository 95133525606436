import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/css/tailwind.css';
import i18n from './i18n';
import Meta from 'vue-meta';
import * as VueGoogleMaps from 'vue2-google-maps';
import vmodal from 'vue-js-modal';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import http from './config/http';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import utility from "./mixins/utility";
import Image from "./components/Image";
import filters from "@/filters/filters";
import Vuelidate from 'vuelidate';
import GSignInButton from 'vue-google-signin-button';
import vueVimeoPlayer from 'vue-vimeo-player';

library.add(far, fas);
Vue.component('icon', FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBE1Z5cgjqNEfdX0HKOSjYLU5m8VqLzJDQ',
    libraries: 'places', // necessary for places input
  },
});

Vue.prototype.$http = http;


// This interceptor logout user if session not valid
http.interceptors.response.use(
    response => {
        return Promise.resolve(response);
    },
    error => {
        if (['ERR_SESSION_NOT_VALID', 'ERR_BAD_SESSION_ID'].includes(error.response.data.error)) {
            store.dispatch("auth/logout");
            router.push("/login");
        }
        return Promise.reject(error);
    }
);

Vue.component("app-image", require("./components/Image").default);

Vue.mixin(utility)
Vue.use(VueToast);
Vue.use(Meta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})
Vue.use(vmodal);
Vue.use(Vuelidate);
Vue.use(vueVimeoPlayer);
Vue.use(GSignInButton);
Vue.use(require('vue-moment'));

Vue.filter('formatCurrentDaySrb', function (value) {
  return filters.formatCurrentDaySrb(value);
});

Vue.filter('formatCurrentDayEng', function (value) {
  return filters.formatCurrentDayEng(value);
});

const root = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

