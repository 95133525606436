<template>
  <div class="h-full bg-black-50">
    <div
      class="bg-black-90 h-24 md:h-40 lg:h-48 xl:h-56 flex sm:w-screen md:w-screen mb-16"
    >
      <div class="flex mx-auto w-3/4 xl:h-56">
        <p
          v-if="$i18n.locale === 'sr'"
          class="text-white font-semibold m-auto font-thin text-2xl md:text-3xl lg:text-4xl xl:text-4xl uppercase"
        >
          {{ event.header }}
        </p>
        <p
          v-if="$i18n.locale === 'en'"
          class="text-white font-semibold m-auto font-thin text-2xl md:text-3xl lg:text-4xl xl:text-4xl uppercase"
        >
          {{ event.header_eng }}
        </p>
      </div>
    </div>
    <div
      class="text-white text-xl md:text-2xl lg:text-2xl xl:text-2xl mx-auto w-12/12 sm:w-12/12 md:w-9/12 xl:w-7/12 lg:w-7/12"
    >
      <!-- image mobile -->
      <div class="mx-4 mb-6 block sm:hidden md:hidden lg:hidden xl:hidden">
        <div class="relative" style="padding-bottom: 100%">
          <img
            class="absolute bottom-0 h-full w-full object-cover"
            :src="`${host}/${event.photo_url}`"
          />
        </div>
      </div>
      <!-- image desktop -->
      <div
        class="h-64 w-64 float-left mx-4 mt-2 hidden sm:block md:block lg:block xl:block"
      >
        <app-image
          :height="'100%'"
          :src="`${host}/${event.photo_url}`"
          :width="'100%'"
        />
      </div>

      <!-- blog text mob/desktop -->
      <p
        v-if="$i18n.locale === 'sr'"
        class="w-full text-justify px-4 break-normal"
        v-html="event.text"
      ></p>
      <p
        v-if="$i18n.locale === 'en'"
        class="w-full text-justify px-4 break-normal"
        v-html="event.text_eng"
      ></p>
      <div class="h-50 pb-12 pt-10 flex w-full">
        <div class="flex items-center h-full w-full inline-block align-middle">
          <p class="w-full text-right pr-4">
            {{ event.date_of_creation | moment("DD.MM.YYYY") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlogReadPage",
  data() {
    return {
      event: {},
      host: process.env.VUE_APP_API_URL,
      lang: "sr",
    };
  },
  mounted() {
    this.getEvent();
  },
  methods: {
    async getEvent() {
      const { data } = await this.$http.get(`/event/${this.$route.params.id}`);
      this.event = data;
    },
  },
};
</script>
