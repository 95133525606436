<template>
    <div class="w-12/12 overflow-x-hidden">
        <div
            :class="resolution > 1024 ? 'pic1' : 'pic1mobile'"
            class="w-12/12 h-350 md:h-400 lg:h-500 xl:h-500 sticky overflow-x-hidden"
        >
            <div class="xl:w-2/3 mx-auto">
                <p
                    class="text-white text-center font-bold text-2xl md:text-4xl lg:text-5xl xl:text-6xl mx-8 md:mx-20 lg:mx-24 xl:mx-0 mt-5 pt-24 md:pt-32 md:mt-0 lg:pt-40 lg:mt-0 xl:pt-40 xl:mt-0"
                >
                    VRAČAR
                </p>
                <div class="absolute bottom-0 flex w-full xl:w-2/3 xl:mx-0 h-16 bg-cyan-10 bg-opacity-50">
                    <div class="w-1/3 text-white border-r border-black-80 flex">
                        <div class="m-auto">
                            <p
                                class="text-sm md:text-base lg:text-lg uppercase"
                            >
                                {{ $t("sak-vracar-desc-1") }}
                            </p>
                            <p
                                class="font-bold text-xs md:text-lg lg:text-1xl tracking-tight uppercase"
                            >
                                {{ $t("sak-vracar-desc-1-1") }}
                            </p>
                        </div>
                    </div>
                    <div class="w-1/3 text-white flex">
                        <p class="text-sm md:text-base lg:text-lg m-auto font-bold uppercase tracking-wide">
                            {{ $t('sak-vracar-desc-6') }}
                        </p>
                    </div>
                    <div class="w-1/3 text-white border-l border-black-80 flex">
                        <div class="m-auto">
                            <p
                                class="text-sm md:text-base lg:text-lg uppercase"
                            >
                                {{ $t("sak-vracar-desc-2") }}
                            </p>
                            <p
                                class="font-bold text-xs md:text-lg lg:text-1xl uppercase"
                            >
                                {{ $t("sak-vracar-desc-2-1") }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="w-12/12 xl:w-10/12 h-24 md:h-32 xl:h-40 flex my-10 mx-auto">
            <div class="m-auto">
                <p
                    class="text-white font-bold text-lg md:text-xl xl:text-2xl text-center mx-8 tracking-wide uppercase"
                >
                    {{ $t("sak-vracar-desc-3") }}
                </p>
            </div>
        </div>

        <div class="w-12/12 h-full py-12 md:py-16 bg-cyan-10">
            <div
                class="w-12/12 md:w-10/12 lg:w-7/12 xl:w-6/12 mx-auto bg-cyan-10 h-full grid grid-cols-2"
            >
                <div class="flex">
                    <div class="m-auto">
                        <p
                            class="text-white text-base md:text-lg xl:text-xl text-center px-5 lg:px-8 xl:px-16 pt-1 uppercase"
                        >
                            {{ $t("sak-vracar-desc-4") }}
                        </p>
                        <p
                            class="text-white font-bold text-xl md:text-2xl lg:text-3xl xl:text-4xl text-center uppercase"
                        >
                            {{ $t("sak-vracar-desc-4-1") }}
                        </p>
                    </div>
                </div>
                <div class="text-white border-l border-white flex">
                    <div class="m-auto">
                        <p
                            class="text-white font-bold text-xl md:text-2xl lg:text-3xl xl:text-4xl text-center uppercase"
                        >
                            {{ $t("sak-vracar-desc-5") }}
                        </p>
                        <p
                            class="text-white text-base md:text-lg xl:text-xl text-center px-5 lg:px-8 xl:px-16 pt-1 uppercase"
                        >
                            {{ $t("sak-vracar-desc-5-1") }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="w-12/12 xl:w-8/12 grid grid-cols-1 mt-10 lg:grid-cols-3 xl:mx-auto"
        >
            <div class="m-auto pb-4">
                <club-info
                    :address="gyms.vracar.address"
                    :tel="gyms.vracar.phone"
                    :mob="gyms.vracar.phone_2"
                    :workdays="gyms.vracar.weekdays"
                    :saturday="gyms.vracar.saturday"
                    :sunday="gyms.vracar.sunday"
                ></club-info>
                <div
                    class="pt-4 md:pt-6 lg:mt-0 xl:mt-0 mx-8 md:mx-20 lg:mx-24 xl:mx-0"
                >
                    <button
                        @click="$router.push('/training-schedule/5')"
                        class=" hover:bg-cyan-20 bg-cyan-10 text-white text-btn py-2 px-5"
                    >
                        {{ $t("training-schedule") }}
                    </button>
                </div>
            </div>
            <div class="col-span-2 lg:mr-5 xl:mr-0">
                <google-map
                    :locations="locations"
                    :centerMap="centerMap"
                ></google-map>
            </div>
        </div>

        <div class="mt-16">
            <h1
                class="text-white text-2xl md:text-3xl font-semibold leading-10"
            >
                {{ $t("gallery") }}
            </h1>
        </div>
        <div
            class="h-full xl:w-2/3 grid grid-cols-3 xl:grid-cols-5 gap-1 md:gap-3 lg:gap-5 xl:gap-8 mx-auto px-1 py-16 md:mx-10 xl:mx-auto"
        >
            <div
                v-for="(image, i) in thumbnails"
                class="w-32 h-29 md:w-48 md:h-40 lg:w-48 lg:h-40 xl:h-32 xl:w-40 mx-auto mb-4 bg-black-80 m-2 transform xl:hover:scale-110 cursor-pointer transition duration-200 ease-in-out"
                @click="openViewer(i)"
            >
                <img
                    :src="`${image}`"
                    alt=""
                    style="width: 100%; height: 100%"
                />
            </div>
        </div>
        <app-image-viewer :modalName="'image_modal'"></app-image-viewer>
    </div>
</template>

<script>
import MainMessage from "../../components/MainMessage";
import GoogleMap from "../../components/GoogleMap";
import ClubInfo from "../../components/ClubInfo";
import vracar from "@/galleries/vracar";
import vracar_thumbnails from "@/galleries/vracar_thumbnails";
import AppImageViewer from "@/components/AppImageViewer";
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
    name: "Vracar",
    metaInfo: {
        title: 'Fitness & Gym Kočović | Teretana Kočović Beograd',
        titleTemplate: '%s ← Teretana Beograd',
        meta: [
            {name: 'description', content: 'Teretana Kočović Vam nudi sve na jednom mestu. Izaberite trening po Vašim potrebama i prijavite se odmah! | Teretana Beograd | Vracar'},
            {
                name: 'keywords',
                content: '' +
                    'fitnes Beograd, ' +
                    'fitnes Vračar, ' +
                    'teretana Vračar, ' +
                    'Les Mills Beograd, ' +
                    'grupni fitnes, ' +
                    'fitnes treninzi Beograd, ' +
                    'mršavljenje, ' +
                    'zatezanje, ' +
                    'oblikovanje tela, ' +
                    'Joga Beograd, ' +
                    'trening snage, ' +
                    'Kardio, ' +
                    'Fitnes centar Beograd, ' +
                    'Gluteus, ' +
                    'Zadnjica, ' +
                    'Gubljenje kilograma/težine, ' +
                    'Celulit, ' +
                    'kardio treninzi, ' +
                    'gym belgrade'
            },
        ],
    },
    data() {
        return {
            centerMap: { lat: 44.7926229, lng: 20.48204965 },
            locations: [
                {position: { lat: 44.7926229, lng: 20.4820496 }, icon:""}, // vracar
            ],
            imageViewerFlag: false,
            currentIndex: 1,
            title: 'Vracar',
            imgUrlList: vracar,
            thumbnails: vracar_thumbnails,
        }
    },
    components: {MainMessage, GoogleMap, ClubInfo, AppImageViewer},
    mounted() {
        this.setResolution();
        this.fetchAll();
        this.setImages(this.imgUrlList);
    },
    computed: {
        ...mapGetters("gyms", {
            gyms: "getAllGyms"
        }),
        ...mapGetters("shared", {
            resolution: "getResolution"
        }),
    },
    methods: {
        ...mapMutations("shared", {
            setResolution: "setResolution"
        }),
        ...mapMutations("imageViewer", {
            setImgIndex: "setImgIndex",
            setImages: "setImages"
        }),
        ...mapActions("imageViewer", {
            openImage: "openImage"
        }),
        ...mapActions("gyms", {
            fetchAll: "fetchAll"
        }),
        openViewer(i) {
            this.setImgIndex(i);
            this.openImage({imgUrlList: this.imgUrlList, index: i});
        },
    }
}
</script>