<template>
    <div class="w-full pb-20">
        <div class="bg-black-90 h-24 md:h-40 lg:h-48 xl:h-56 flex sm:w-screen md:w-screen">
            <div class="flex mx-auto w-3/4 xl:h-56">
                <p class="text-black-30 m-auto font-thin text-lg md:text-3xl lg:text-4xl xl:text-4xl">
                  FITNESS  <span class="text-white font-semibold"> {{ $t('coaches') }}</span>
                </p>
            </div>
        </div>
        <div v-if="coaches" class="w-12/12 xl:w-9/12 xl:mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-1 my-10">
            <coach-card
                    v-for="(coach, i) in coaches" :key="i"
                    :name="coach.name"
                    :photo_url="coach.photo_url"
                    class="transform xl:hover:scale-110 transition duration-200 ease-in-out cursor-pointer show-slowly"
                    @setCoach="setCoach(coach)"
            ></coach-card>
        </div>
        <modal
              name="coach_card"
              :resizable="resizable"
              :adaptive="adaptive"
              @closed="modalCoach = {}"
        >
            <div
              class="text-black-80 bg-gradient-to-r from-black-60 via-black-30 to-black-10 overflow-y-auto flex m-auto border-2 border-black-30 w-12/12"
              v-if="modalCoach"
              style="max-height: 100%; height: 100%; max-width: 100%;"
            >
                <div class="h-full w-5/12 md:w-4/12 float-left hidden md:block lg:block xl:block sticky top-0">
                    <app-image
                        :height="'100%'"
                        :src="`${host}/${modalCoach.photo_url}`"
                        :width="'100%'"
                    />
                </div>
                <div class="w-12/12 md:w-7/12 mt-2 m-auto px-10 md:px-0">
                    <div class="p-2 font-semibold text-lg pr-3 xl:pr-4">
                        <h1 class="uppercase">{{modalCoach.name}}</h1>
                    </div>
                    <div class="text-left tracking-wide lg:text-lg pr-3 xl:ml-5 xl:mt-4 xl:mb-10">
                        <p v-if="$i18n.locale === 'sr'" class="text-justify" v-html="modalCoach.description"></p>
                        <p v-if="$i18n.locale === 'en'" class="text-justify" v-html="modalCoach.description_eng"></p>
                    </div>
                </div>
            </div>
      </modal>
    </div>
</template>

<script>
    import CoachCard from "../components/CoachCard";
    import {mapActions, mapGetters} from "vuex";
    export default {
        name: "Coaches",
        metaInfo: {
            title: 'Fitness & Gym Kočović | Teretana Kočović Beograd',
            titleTemplate: '%s ← Teretana Beograd | Tim',
            meta: [
              {name: 'description', content: 'Teretana Kočović Vam nudi sve na jednom mestu. Izaberite trening po Vašim potrebama i prijavite se odmah! | Teretana Beograd | Treneri'},
              {
                  name: 'keywords',
                  content: '' +
                      'Treneri, ' +
                      'coaches, ' +
                      'team, ' +
                      'tim, ' +
                      'fitnes treneri, ' +
                      'fitnes Zemun, ' +
                      'fitnes Blokovi, ' +
                      'Fitnes Novi Beograd, ' +
                      'fitnes Vračar, ' +
                      'fitnes Banjica, ' +
                      'Les Mills Beograd, ' +
                      'grupni fitnes, ' +
                      'fitnes treninzi Beograd, ' +
                      'mršavljenje, ' +
                      'zatezanje, ' +
                      'oblikovanje tela, ' +
                      'joga zemun, ' +
                      'Joga Novi Beograd, ' +
                      'Joga Beograd, ' +
                      'boks, ' +
                      'trening snage, ' +
                      'Fitnes centar Beograd, ' +
                      'Gluteus, ' +
                      'Zadnjica, ' +
                      'Gubljenje kilograma/težine, ' +
                      'Celulit, ' +
                      'kardio treninzi, ' +
                      'gym belgrade'
              },
            ],
        },
        components: {CoachCard},
        data() {
            return {
                resizable: false,
                adaptive: true,
                host: process.env.VUE_APP_API_URL,
                modalCoach: {
                    name: '',
                    description: '',
                    description_eng: '',
                    photo_url: ''
                },
                lang: 'sr',
            }
        },
        mounted() {
            this.fetchCoaches();
        },
        computed: {
            ...mapGetters("coaches", {
                coaches: "getCoaches"
            })
        },
        methods: {
            ...mapActions("coaches", {
                fetchCoaches: "fetchCoaches"
            }),
            setCoach(data) {
                this.modalCoach = data;
                this.$modal.show('coach_card');
            },
        }
    }
</script>