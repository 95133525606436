<template>
    <div
        class="trainingCard mx-auto  xl:w-32
               hover:bg-black-40 hover:opacity-75 transform xl:hover:scale-110
               relative transition duration-200 ease-in-out rounded select-none"
        :class="[booked ? 'bg-green-20 opacity-75' : 'bg-black-60' , desktopView ? 'border xl:border-0 h-12  xl:h-32' : 'h-56 xl:h-32'] "
    >
    	<div v-if="!desktopView || width > 1279" :class="samsung_promotion ? 'bg-blue-600' : 'bg-black-50 xl:bg-black-90'"
           class="h-8 w-full flex items-center justify-between pl-2 xl:p-1 rounded-t">
            <!-- <div class="mr-auto text-sm xl:text-xs text-white flex">
                <span class="font-semibold uppercase">{{ $t('you-have-signed-up') }}</span>
            </div> -->
        <p class="w-full flex justify-end items-center mr-1 font-bold text-white text-xs">{{ trainingDate | moment('DD.MM.YYYY') }}</p>
			<icon v-if="booked" icon="check" class="mr-1 text-white"></icon>
			<img v-if="samsung_promotion" src="/samsung.png" class="w-28 ml-auto mr-2" alt="">
        </div>
		<div v-else :class="samsung_promotion ? 'bg-blue-600' : ''" style="height: 12px">
			<img v-if="samsung_promotion" src="/samsung.png" class="w-28 mx-auto pt-0.5" alt="">
		</div>
        <div v-if="!desktopView || width > 1279" class="w-12/12 mt-1 ml-1 flex" @click.prevent="$emit('setTraining')">
			 <!-- time on mobile view -->
            <span
                v-if="time"
                class="w-3/12 font-bold mr-auto text-white text-2xl md:text-3xl lg:text-4xl xl:hidden">
                <span
                    v-if="time.length === 3"
                    class="">
                    {{ time.slice(0, 1) }}:{{ time.slice(-2, time.length) }}h
                </span>
                <span v-if="time.length === 4" class="">{{ time.slice(0, 2) }}:{{ time.slice(-2, time.length) }}h</span>
            </span>
			 <!-- training names on desktop and mobile view   -->
            <span
                v-if="$i18n.locale === 'sr'"
                class="name w-9/12 ml-2 text-left font-bold text-white text-2xl md:text-3xl uppercase lg:text-4xl xl:text-sm break-words tracking-wide">
                {{name}}
            </span>
             <span
                v-if="$i18n.locale === 'en'"
                class="name w-9/12 ml-2 text-left font-bold text-white text-2xl md:text-3xl uppercase lg:text-4xl xl:text-sm break-words tracking-wide">
                {{name_eng}}
            </span>
        </div>
			<!-- training names on weekly view for mobiles -->
		<div v-if="desktopView && width < 1280" class="w-12/12 mt-1 flex flex-col" @click.prevent="$emit('setTraining')">
            <span
                v-if="$i18n.locale === 'sr'"
                class="name text-left text-white p-1 text-xxs uppercase break-words ">
                {{name}}
            </span>
             <span
                v-if="$i18n.locale === 'en'"
                class="name text-left text-white p-1 text-xxs uppercase  break-words ">
                {{name_eng}}
            </span>
        </div>


      <div v-if="page === 'promotions'" class="h-12 md:h-16 xl:h-8 px-2 tooltip-schedule-card">
        <button
         v-if="isLoggedIn && !booked"
          class="absolute inset-x-0 bottom-0 mb-2 w-11/12 mx-auto bg-cyan-10
                       hover:bg-cyan-20 text-base md:text-xl xl:text-sm text-black rounded-sm py-1 z-50"
          @click.prevent="$emit('bookTraining'); $emit('getServerTime')">{{ $t('sign-up') }}
        </button>

        <button
         v-if="!isLoggedIn"
          class="absolute inset-x-0 bottom-0 mb-2 w-11/12 mx-auto bg-cyan-10
                       hover:bg-cyan-20 text-base md:text-xl xl:text-sm text-black rounded-sm py-1 z-50"
          @click.prevent="$emit('bookTrainingModal'); $emit('getServerTime')">{{ $t('sign-up') }}
        </button>

        <button
                v-if="isLoggedIn && booked"
                @click.prevent="$emit('cancelTraining'); $emit('getServerTime')"
                class="absolute uppercase inset-x-0 bottom-0 mb-2 w-11/12 mx-auto bg-black-60
                       hover:bg-cyan-20 text-base md:text-xl xl:text-sm text-white rounded-sm py-1 z-50">
                {{ $t('cancel') }}
            </button>
      </div>

		<!-- buttons -->
        <div v-if="(!desktopView || width > 1279) && page !== 'promotions'" class="h-12 md:h-16 xl:h-8 px-2 tooltip-schedule-card">
            <!--
               ako je trening prosao
             -->
            <button
                v-if="day < currentDay"
                :disabled="day < currentDay"
                :class="day < currentDay ? 'cursor-not-allowed' : ''"
                class="absolute inset-x-0 bottom-0 mb-2 w-11/12 mx-auto bg-cyan-10
                       hover:bg-cyan-20 text-base md:text-xl xl:text-sm text-black rounded-sm py-1 z-50"
                @click.prevent="$emit('bookTraining'); $emit('getServerTime')">{{ $t('sign-up') }}
            </button>
            <!--
                ako je trening prosao a zakazan je bio a korisnik prijavljen
             -->
            <button
                v-if="day < currentDay && isLoggedIn && booked"
                :disabled="day < currentDay"
                :class="day < currentDay ? 'cursor-not-allowed' : ''"
                @click.prevent="$emit('cancelTraining'); $emit('getServerTime')"
                class="absolute uppercase inset-x-0 bottom-0 mb-2 w-11/12 mx-auto bg-black-60
                       hover:bg-cyan-20 text-base md:text-xl xl:text-sm text-white rounded-sm py-1 z-50">
                {{ $t('cancel') }}
            </button>


            <!--
                ako je dan treninga, ali je trening prosao u smislu sati
             -->
            <button
                v-if="day === currentDay && Number(currentTime) > Number(time)"
                :disabled="day === currentDay"
                :class="day === currentDay ? 'cursor-not-allowed' : ''"
                class="absolute inset-x-0 bottom-0 mb-2 w-11/12 mx-auto bg-cyan-10
                       hover:bg-cyan-20 text-base md:text-xl xl:text-sm text-black rounded-sm py-1 z-50"
                @click.prevent="$emit('bookTraining'); $emit('getServerTime')">{{ $t('sign-up') }}
            </button>

            <!--
                ako dan treninga, trening prosao i bio zakazan kao i korisnik ulogovan
             -->
            <button
                v-if="day === currentDay && Number(currentTime) > Number(time) && isLoggedIn && booked"
                :disabled="day === currentDay"
                :class="day === currentDay ? 'cursor-not-allowed' : ''"
                class="absolute uppercase inset-x-0 bottom-0 mb-2 w-11/12 mx-auto bg-black-60
                       hover:bg-cyan-20 text-base md:text-xl xl:text-sm text-white rounded-sm py-1 z-50">
                {{ $t('cancel') }}
            </button>

            <!--
                ako je dan treninga ali je vreme proslo
             -->

            <button
                v-if="Number(currentTime) > Number(time) && currentDay === day "
                :disabled="Number(currentTime) > Number(time)"
                :class="Number(currentTime) > Number(time) ? 'cursor-not-allowed' : ''"
                @click="$emit('getServerTime')"
                class="absolute inset-x-0 bottom-0 mb-2 w-11/12 mx-auto bg-cyan-10
                       hover:bg-cyan-20 text-base md:text-xl xl:text-sm text-black rounded-sm py-1 z-50 uppercase">
                {{ !booked ? $t('sign-up') : $t('cancel') }}
            </button>

            <!--
                ako je trening zakazan i nije jos uvek prosao
             -->
            <button
                v-if="booked && day <= currentDay"
                :class="day >= currentDay ? '' : ''"
                @click.prevent="$emit('cancelTraining'); $emit('getServerTime')"
                class="absolute uppercase inset-x-0 bottom-0 mb-2 w-11/12 mx-auto bg-black-60
                       hover:bg-cyan-20 text-base md:text-xl xl:text-sm text-white rounded-sm py-1 z-50">
                {{ $t('cancel') }}
            </button>


            <!--
               ako je trening u danasnjem danu nije ulogovan
             -->
            <button
                v-if="day === currentDay && !isLoggedIn && Number(currentTime) < Number(time)"
                class="absolute inset-x-0 bottom-0 mb-2 w-11/12 mx-auto bg-cyan-10
                       hover:bg-cyan-20 text-base md:text-xl xl:text-sm text-black rounded-sm py-1 z-50"
                @click.prevent="$emit('bookTrainingModal'); $emit('getServerTime')">{{ $t('sign-up') }}
            </button>

            <button
                v-if="day === currentDay && isLoggedIn && Number(currentTime) < Number(time)"
                class="absolute inset-x-0 bottom-0 mb-2 w-11/12 mx-auto bg-cyan-10
                       hover:bg-cyan-20 text-base md:text-xl xl:text-sm text-black rounded-sm py-1 z-50"
                @click.prevent="$emit('bookTraining'); $emit('getServerTime')">{{ $t('sign-up') }}
            </button>
            <button
                v-if="day === currentDay && isLoggedIn && Number(currentTime) < Number(time) && booked"
                @click.prevent="$emit('cancelTraining'); $emit('getServerTime')"
                class="absolute uppercase inset-x-0 bottom-0 mb-2 w-11/12 mx-auto bg-black-60
                       hover:bg-cyan-20 text-base md:text-xl xl:text-sm text-white rounded-sm py-1 z-50">
                {{ $t('cancel') }}
            </button>

             <!--
               ako je trening u buducnosti, nije ulogovan
             -->
            <button
                v-if="day > currentDay && !isLoggedIn && !$route.name.includes('Promotions')"
                :disabled="day > currentDay"
                :class="day > currentDay && !$route.name.includes('Promotions') ? 'cursor-not-allowed' : ''"
                class="absolute inset-x-0 bottom-0 mb-2 w-11/12 mx-auto bg-cyan-10
                       hover:bg-cyan-20 text-base md:text-xl xl:text-sm text-black rounded-sm py-1 z-50"
                @click.prevent="$emit('bookTrainingModal'); $emit('getServerTime')">{{ $t('sign-up') }}
            </button>

            <button
                v-if="day > currentDay && isLoggedIn && !$route.name.includes('Promotions')"
                :disabled="day > currentDay"
                :class="day > currentDay && !$route.name.includes('Promotions') ? 'cursor-not-allowed' : ''"
                class="absolute inset-x-0 bottom-0 mb-2 w-11/12 mx-auto bg-cyan-10
                       hover:bg-cyan-20 text-base md:text-xl xl:text-sm text-black rounded-sm py-1 z-50"
                @click.prevent="$emit('bookTraining'); $emit('getServerTime')">{{ $t('sign-up') }}
            </button>

            <!--
                  ako ukljucuje promocije
            -->

            <button
                v-if="day > currentDay && !isLoggedIn && $route.name.includes('Promotions')"
                class="absolute inset-x-0 bottom-0 mb-2 w-11/12 mx-auto bg-cyan-10
                       hover:bg-cyan-20 text-base md:text-xl xl:text-sm text-black rounded-sm py-1 z-50"
                @click.prevent="$emit('bookTrainingModal'); $emit('getServerTime')">{{ $t('sign-up') }}
            </button>

            <button
                v-if="day > currentDay && isLoggedIn && $route.name.includes('Promotions')"
                class="absolute inset-x-0 bottom-0 mb-2 w-11/12 mx-auto bg-cyan-10
                       hover:bg-cyan-20 text-base md:text-xl xl:text-sm text-black rounded-sm py-1 z-50"
                @click.prevent="$emit('bookTraining'); $emit('getServerTime')">{{ $t('sign-up') }}
            </button>

            <button
                v-if="day > currentDay && isLoggedIn && booked"
                @click.prevent="$emit('cancelTraining'); $emit('getServerTime')"
                class="absolute uppercase inset-x-0 bottom-0 mb-2 w-11/12 mx-auto bg-black-60
                       hover:bg-cyan-20 text-base md:text-xl xl:text-sm text-white rounded-sm py-1 z-50">
                {{ $t('cancel') }}
            </button>

            <button
                v-if="day <= currentDay && isLoggedIn && booked && Number(currentTime) > Number(time)"
                :disabled="day <= currentDay"
                :class="day <= currentDay ? 'cursor-not-allowed' : ''"
                @click.prevent="$emit('cancelTraining'); $emit('getServerTime')"
                class="absolute uppercase inset-x-0 bottom-0 mb-2 w-11/12 mx-auto bg-black-60
                       hover:bg-cyan-20 text-base md:text-xl xl:text-sm text-white rounded-sm py-1 z-50">
                {{ $t('cancel') }}
            </button>
        </div>
    </div>
</template>

<script>
    import CalendarPeople from "../icons/CalendarPeople";
    import CalendarMoreIcon from "../icons/CalendarMoreIcon";
    import { mapGetters } from "vuex";
    export default {
        name: "TrainingScheduleCard",
        data() {
            return {
                tooltipMessage: 'Možete se prijaviti samo za današnji dan',
                tooltipMessageEng: 'You can only sign up for todays training',
                tooltipEmptyMessage: '',
            }
        },
        props: {
            name: {
                type: String,
                required: true
            },
            name_eng: {
                type: String,
                required: true
            },
            time: {
                required: true
            },
            currentTime: {
                required: true
            },
            currentDay: {
                required: true
            },
            booked: {
                type: Boolean,
                required: false
            },
            day: {
                type: Number,
                required: true,
            },
            trainingDate: {
                required: false,
            },
            desktopView: {
              type: Boolean,
              required: false,
              default: false,
            },
            width: {
              type: Number,
              required: false,
            },
            samsung_promotion: {
              type: Boolean,
              required: false,
            },
            page: {
              type: String,
              required: true,
            }
        },
        components: {CalendarPeople, CalendarMoreIcon},
        computed: {
            ...mapGetters("auth", {
                isLoggedIn: "getIsLoggedIn"
            })
        },
    }
</script>
<style scoped>
.samsung-desktop {
	background-image: url('/samsung.png');
	background-repeat: no-repeat;
	background-size:80% 80%;
	background-position: center;
}
.samsung-mobile {
	background-image: url('/samsung.png');
	background-repeat: no-repeat;
	background-size: 250px 48px;
	background-position: center;
}
.samsung-mobile-weekly {
	background-image: url('/samsung.png');
	background-repeat: no-repeat;
	background-size: 40px 10px;
	background-position: center;
}
</style>