const zemun_thumbnails = [
  "galleries/zemun_thumbnails/1_thumb.jpg",
  "galleries/zemun_thumbnails/2_thumb.jpg",
  "galleries/zemun_thumbnails/3_thumb.jpg",
  "galleries/zemun_thumbnails/4_thumb.jpg",
  "galleries/zemun_thumbnails/5_thumb.jpg",
  "galleries/zemun_thumbnails/6_thumb.jpg",
  "galleries/zemun_thumbnails/7_thumb.jpg",
  "galleries/zemun_thumbnails/8_thumb.jpg",
  "galleries/zemun_thumbnails/9_thumb.jpg",
  "galleries/zemun_thumbnails/10_thumb.jpg",
];
export default zemun_thumbnails;
