import { create } from 'axios';
import router from '../router';

const client = create({
  baseURL: `${process.env.VUE_APP_API_URL}`,
});

const attachToken = (config) => {
  const token = localStorage.getItem('token');
  const conf = {
    ...config,
    headers: {
      session_id: `${token}` || undefined,
    },
  };
  if (conf.headers.session_id === undefined || conf.headers.session_id === null) {
    delete conf.headers.session_id;
  }
  return conf;
};

client.interceptors.request.use(attachToken);

export default client;
