<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="16" height="16" viewBox="0 0 16 16" style="enable-background:new 0 0 100 100;" xml:space="preserve">
    <g>
        <path class="st0" d="M8,3.89C5.73,3.89,3.9,5.73,3.9,8c0,2.27,1.84,4.11,4.11,4.11c2.27,0,4.11-1.84,4.11-4.11
            C12.11,5.73,10.27,3.89,8,3.89L8,3.89z M8,10.66c-1.47,0-2.66-1.19-2.66-2.66S6.53,5.33,8,5.33S10.67,6.52,10.67,8
            C10.67,9.47,9.48,10.66,8,10.66z"/>
        <path class="st0" d="M11.3,0.05c-1.47-0.07-5.12-0.07-6.59,0C3.41,0.11,2.27,0.42,1.35,1.34C-0.19,2.88,0.01,4.96,0.01,8
            c0,3.11-0.17,5.14,1.34,6.65C2.89,16.19,5,15.99,8,15.99c3.08,0,4.15,0,5.24-0.42c1.48-0.58,2.6-1.9,2.71-4.28
            c0.07-1.47,0.07-5.12,0-6.59C15.82,1.89,14.31,0.19,11.3,0.05L11.3,0.05z M13.63,13.63c-1.01,1.01-2.41,0.92-5.65,0.92
            c-3.33,0-4.67,0.05-5.65-0.93c-1.12-1.12-0.92-2.91-0.92-5.64c0-3.68-0.38-6.34,3.32-6.53c0.85-0.03,1.1-0.04,3.24-0.04L8,1.44
            c3.55,0,6.34-0.37,6.51,3.32c0.04,0.84,0.05,1.1,0.05,3.23C14.56,11.29,14.62,12.64,13.63,13.63L13.63,13.63z"/>
        <circle class="st0" cx="12.27" cy="3.73" r="0.96"/>
    </g>
</svg>

</template>

<script>
    export default {
        name: "InstaIcon"
    }
</script>

<style scoped>
	.st0{fill:#808080;}
</style>