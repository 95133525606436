<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="20" height="20" viewBox="0 0 45 45" style="enable-background:new 0 0 1000 1000;" xml:space="preserve">
        <g>
            <path class="st0" d="M22,10.7c-6.24,0-11.29,5.06-11.29,11.29c0,6.24,5.06,11.29,11.29,11.29c6.24,0,11.29-5.06,11.29-11.29
                C33.29,15.75,28.24,10.7,22,10.7L22,10.7z M22,29.32c-4.05,0-7.32-3.28-7.32-7.32s3.28-7.32,7.32-7.32s7.32,3.28,7.32,7.32
                C29.34,26.04,26.06,29.32,22,29.32z"/>
            <path class="st0" d="M31.07,0.14c-4.05-0.19-14.07-0.18-18.13,0C9.38,0.31,6.24,1.16,3.72,3.7c-4.23,4.23-3.69,9.93-3.69,18.3
                c0,8.56-0.48,14.12,3.69,18.3c4.24,4.24,10.03,3.69,18.3,3.69c8.48,0,11.4,0.01,14.4-1.15c4.07-1.58,7.16-5.23,7.45-11.77
                c0.19-4.05,0.18-14.07,0-18.13C43.49,5.21,39.35,0.52,31.07,0.14L31.07,0.14z M37.48,37.48C34.71,40.25,30.86,40,21.95,40
                c-9.16,0-12.84,0.14-15.52-2.55C3.34,34.38,3.9,29.44,3.9,21.95c0-10.13-1.04-17.42,9.12-17.94c2.34-0.08,3.02-0.11,8.9-0.11
                L22,3.97c9.77,0,17.43-1.02,17.89,9.14c0.11,2.32,0.13,3.01,0.13,8.89C40.03,31.05,40.2,34.75,37.48,37.48L37.48,37.48z"/>
            <circle class="st0" cx="33.74" cy="10.26" r="2.63"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "InstaMobileIcon"
    }
</script>

<style scoped>
    .st0{fill:#808080;}
</style>