<template>
    <div class="w-302">
        <div class="h-280 w-300 border border-black-20 m-auto mb-10 bg-gradient-to-r from-black-80 to-black-30 rounded-lg overflow-hidden">
            <p v-if="$i18n.locale === 'sr'" class="text-white text-left lg:text-base mb-1 tracking-tight uppercase px-1 py-2">
                {{ video.title.length < 43 ? video.title : video.title.substr(0, 43) + '...' }}
            </p>
            <p v-if="$i18n.locale === 'en'" class="text-white text-left lg:text-base mb-1 tracking-tight uppercase px-1 py-2">
                {{ video.title_eng.length < 43 ? video.title_eng : video.title_eng.substr(0, 43) + '...' }}
            </p>
            <div class="h-280 overflow-hidden">
                <vimeo-player
                    v-if="video.url.length > 8"
                    player-height="170"
                    :video-id="null"
                    @play="counter(video.id);"
                    class="border-b border-t border-black-20 overflow-hidden bg-black"
                    player-width="299"
                    :video-url="`https://player.vimeo.com/video/${video.url.split('com/')[1].split('/')[0]}`">
                </vimeo-player>
                <div
                    @click="$emit('openModal')"
                    v-if="$i18n.locale === 'sr'"
                    class="h-20 w-298 m-auto mb-10 bg-gradient-to-r from-black-80 to-black-30 text-left text-white px-1 pt-2 cursor-pointer rounded-lg overflow-hidden"
                >
                    {{ video.description.length < 102 ? video.description : video.description.substr(0, 102) + '...' }}
                </div>
                <div
                    @click="$emit('openModal')"
                    v-if="$i18n.locale === 'en'"
                    class="h-20 w-298 m-auto mb-10 bg-gradient-to-r from-black-80 to-black-30 text-left text-white px-1 pt-2 cursor-pointer rounded-lg overflow-hidden"
                >
                    {{ video.description_eng.length < 102 ? video.description_eng : video.description_eng.substr(0, 102) + '...' }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Video",
        props: {
            video: {
                type: Object,
                required: true
            },
            device: {
                type: String,
                required: true
            }
         },
        methods: {
            async counter(videoId) {
                await this.$http.post(`/user/video/${videoId}`, {device: this.device});
            }
        }
    }
</script>
