<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="100" height="100" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">

        <g>
            <g>
                <g>
                    <g>
                        <path class="st0" d="M37.5,0h-35C1.12,0,0,1.12,0,2.5v35C0,38.88,1.12,40,2.5,40h35c1.38,0,2.5-1.12,2.5-2.5v-35
                            C40,1.12,38.88,0,37.5,0z M37.5,37.5l-35,0v-35h35V37.5z"/>
                    </g>
                </g>
            </g>
            <polygon class="st0" points="31.97,14 20,27.97 8.03,14 	"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "FaqArrowIcon"
    }
</script>

<style scoped>
	.st0{fill:#649AE9;}
</style>