<template>
    <div class="w-12/12 overflow-x-hidden pb-10">

        <main-message></main-message>

        <div
            v-if="activeEvents.length > 0"
            :style="{ backgroundImage: 'url(' + eventImageUrl + ')' }"
            @mouseover="eventHovered = true"
            @mouseleave="eventHovered = false"
            class="w-12/12 xl:w-10/12 h-250 md:h-350 lg:h-400
                   xl:h-500 flex bg-local mt-3 md:mt-8 lg:mt-10 mx-auto background-settings">
            <div class="grid grid-cols-12 w-full h-full">
                <div
                    @click="slide('-')"
                    class="col-span-1 flex m-auto text-center text-white text-5xl
                           cursor-pointer transform md:hover:scale-150 transition duration-500 ease-in-out">
                    <span v-if="activeEvents.length > 1" class="opacity-50"> < </span>
                </div>
                <div class="col-span-10 flex">
                    <div v-if="eventHovered" class="m-auto flex h-200 w-3/4" @click="$router.push(`/events`)">
                        <button
                            class="bg-green-20 m-auto text-black hover:bg-green-30 uppercase text text-btn py-2 px-4 w-32 show-slowly"
                            @click="$router.push(`/events`)"
                        >{{ $t('more') }}
                        </button>
                    </div>
                </div>
                <div
                    @click="slide('+')"
                    class="col-span-1 flex m-auto text-center text-white text-5xl
                           cursor-pointer transform md:hover:scale-150 transition duration-500 ease-in-out">
                    <span v-if="activeEvents.length > 1" class="opacity-50"> > </span>
                </div>
            </div>
        </div>

        <div class="flex items-end w-12/12 grid grid-cols-1 xl:w-10/12 h-400 md:h-400
                    lg:h-400  xl:h-500 bg-local mt-3 md:mt-4 lg:mt-12 mx-auto pic1">
            <div class="mx-auto mt-24 w-full mb-8">
                <div class="m-auto w-full md:w-2/3 grid grid-cols-1 md:grid-cols-1">
                    <div class="text-white col-span-2 md:col-span-3 mb-16 text-lg md:text-xl xl:text-1xl ">
                        {{ $t('choose-location-and-sign-up') }}
                    </div>
                </div>
            </div>
            <div class="w-full flex items-end grid grid-cols-1 lg:text-lg">
                <div class="flex h-20">
                    <div class="flex-1 text-white border-b border-black-80 flex
                                bg-purple-50 bg-opacity-50 hover:bg-opacity-75 cursor-pointer"
                         @click="$router.push('/training-schedule/1')">
                        <div class="m-auto">
                            BANJICA
                        </div>
                    </div>
                    <div
                        @click="$router.push('/training-schedule/7')"
                        class="flex-1 text-white border-r border-l border-b border-black-80
                               flex cursor-pointer bg-purple-50 bg-opacity-50 hover:bg-opacity-75">
                        <div class="m-auto ">
                            BLOKOVI
                        </div>
                    </div>
                    <div class="flex-1 text-white border-b border-black-80 flex cursor-pointer bg-purple-50 bg-opacity-50 hover:bg-opacity-75"
                         @click="$router.push('/training-schedule/3')">
                        <div class="m-auto ">
                            ZEMUN
                        </div>
                    </div>
                </div>
                    
                <div class="flex h-20">
                    <div class="flex-1 text-white border-b  border-black-80 flex cursor-pointer bg-blue-20 bg-opacity-50 hover:bg-opacity-75"
                         @click="$router.push('/usce-sc')">
                        <div class="m-auto ">
                            UŠĆE
                        </div>
                    </div>
                    <div class="flex-1 text-white border-black-80 border-b border-l border-r flex cursor-pointer bg-blue-20 bg-opacity-50 hover:bg-opacity-75"
                         @click="$router.push('/training-schedule/8')">
                        <div class="m-auto ">
                            USTANIČKA
                        </div>
                    </div>
                    <div class="flex-1 text-white   border-black-80 flex cursor-pointer bg-blue-20 bg-opacity-50 hover:bg-opacity-75"
                         @click="$router.push('/training-schedule/5')">
                        <div class="m-auto ">
                            VRAČAR
                        </div>
                    </div>
                </div>
                <div class="flex h-20  ">
                    
                    <div class="flex-1 text-white  border-black-80 flex cursor-pointer bg-purple-50 bg-opacity-50 hover:bg-opacity-75"
                         @click="$router.push('/training-schedule/4')">
                        <div class="m-auto ">
                            PIONIR
                        </div>
                    </div>
                    <div class="flex-1 text-white border-l border-black-80 flex cursor-pointer bg-purple-50 bg-opacity-50 hover:bg-opacity-75"
                         @click="$router.push('/navigator')">
                        <div class="m-auto ">
                            NAVIGATOR
                        </div>
                    </div>
                    <div class="flex-1 text-white border-l border-black-80 flex cursor-pointer bg-purple-50 bg-opacity-50 hover:bg-opacity-75"
                         @click="$router.push('/prvomajska')">
                        <div class="m-auto ">
                            PRVOMAJSKA
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="w-12/12 xl:w-10/12 h-250 md:h-350 lg:h-400 xl:h-500 bg-local flex mt-3 md:mt-4 lg:mt-12 mx-auto pic2">
            <div class="m-auto">
                <p class="text-white font-bold text-xl md:text-2xl lg:text-3xl xl:text-4xl text-center mx-8 uppercase">
                    BLOG
                </p>
                <div class="text-center pt-3">
                    <button
                        @click="$router.push('/blog')"
                        class=" hover:bg-opacity-75 bg-yellow-50 text-btn py-2 px-4 uppercase w-32">{{ $t('more-blog') }}
                    </button>
                </div>
            </div>
        </div>
        <div class="h-500 mx-auto xl:w-10/12 xl:mx-auto grid grid-cols-1 md:grid-cols-2 mt-5 lg:mt-10">
            <div class="grid grid-cols-2">
                <div class="bg-purple-50 h-32 md:h-250 lg:h-250 flex">
                    <div class="m-auto">
                        <p class="text-white font-bold text-xl md:text-2xl lg:text-3xl xl:text-4xl text-center">7</p>
                        <p class="text-white text-base md:text-lg xl:text-xl
                                  text-center m-auto px-5 lg:px-8 xl:px-12 pt-1 uppercase">
                            {{ $t('days-in-week') }}
                        </p>
                    </div>
                </div>
                <div class="bg-blue-20 h-32 md:h-250 lg:h-250 pic5 flex">
                    <div class="m-auto">
                        <p class="text-white font-bold text-xl md:text-2xl
                                  lg:text-3xl xl:text-4xl text-center text-center">8</p>
                        <p class="text-white text-base md:text-lg xl:text-xl text-center mx-5 pt-1 uppercase">
                            {{ $t('centers') }}
                        </p>
                    </div>
                </div>
                <div class="bg-green-20 h-32 md:h-250 lg:h-250 flex">
                    <div class="m-auto">
                        <p class="text-white font-bold text-xl md:text-2xl lg:text-3xl xl:text-4xl text-center">100%</p>
                        <p class="text-white text-base md:text-lg xl:text-xl
                                  text-center px-5 lg:px-8 xl:px-12 pt-1 uppercase tracking-wider">
                            {{ $t('success') }}
                        </p>
                    </div>
                </div>
                <div class="h-32 md:h-250 lg:h-250">
                    <div class="grid grid-cols-3 px-4 md:px-3 lg:px-12 xl:px-16 h-full bg-blue-20">
                        <div class="flex">
                            <a class="m-auto" href="http://www.instagram.com/kocovicfitness" target="_blank">
                                <insta-icon-home class="h-8 w-8 md:h-10 md:w-10"></insta-icon-home>
                            </a>
                        </div>
                        <div class="flex">
                            <a class="m-auto" href="https://www.facebook.com/SAKocovic" target="_blank">
                                <facebook-icon-home class="h-8 w-8 md:h-10 md:w-10"></facebook-icon-home>
                            </a>
                        </div>
                        <div class="flex">
                            <a class="m-auto" href="https://www.youtube.com/channel/UCfYaYz3IxXuCClBhzQtmMnw" target="_blank">
                                <youtube-icon-home class="m-auto h-8 w-8 md:h-10 md:w-10"></youtube-icon-home>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="h-260 md:h-500 bg-local pic4 flex">
                <div class="m-auto">
                    <p class="text-white text-base md:text-lg xl:text-xl text-center px-3 lg:px-8 xl:px-16">
                        {{ $t('about-us-desc-1') }}
                    </p>
                    <p class="text-white text-base md:text-lg xl:text-xl text-center px-3 lg:px-8 xl:px-16 pt-2 md:pt-5">
                        {{ $t('about-us-desc-2') }}
                    </p>
                    <p class="text-white text-base md:text-lg xl:text-xl text-center px-3 lg:px-8 xl:px-16 pt-2 md:pt-5">
                        {{ $t('about-us-desc-3') }}
                    </p>
                </div>
            </div>
        </div>

        <div class="w-12/12 xl:w-10/12 h-250 md:h-350 lg:h-400 xl:h-500 flex bg-local mt-8 md:mt-8 lg:mt-10 mx-auto pic3">
            <div class="m-auto">
                <p class="text-white font-bold text-xl md:text-2xl lg:text-3xl xl:text-4xl text-center mx-8 uppercase">
                    {{ $t('free-training') }}
                </p>
                <p class="text-white text-base md:text-lg xl:text-xl text-center px-8 mt-1 md:px-12 lg:px-16 xl:px-32">
                    {{ $t('free-training-short-desc') }}
                </p>
                <div class="text-center pt-3">
                    <button
                        class="bg-green-20 hover:bg-green-30 uppercase text text-btn py-2 px-4 w-32"
                        @click="$router.push('/free-training')">
                        {{ $t('more') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MainMessage from "../components/MainMessage";
import InstaIconHome from "../icons/InstaIconHome";
import FacebookIconHome from "../icons/FacebookIconHome";
import YoutubeIconHome from "../icons/YoutubeIconHome";
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
    name: 'Home',
    metaInfo: {
        title: 'Fitness & Gym Kočović | Teretana Kočović Beograd',
        titleTemplate: '%s ← Teretana Beograd',
        meta: [
            {name: 'description', content: 'Teretana Kočović Vam nudi sve na jednom mestu. Izaberite trening po Vašim potrebama i prijavite se odmah! | Teretana Beograd | Home | Pocenta'},
            {
                name: 'keywords',
                content: '' +
                    'teretana beograd home, ' +
                    'teretana beograd pocenta, ' +
                    'fitnes Beograd, ' +
                    'fitnes Zemun, ' +
                    'fitnes Blokovi, ' +
                    'Fitnes Novi Beograd, ' +
                    'fitnes Vračar, ' +
                    'fitnes Banjica, ' +
                    'Fitnes Pionir, ' +
                    'Fitnes Ustanička, ' +
                    'teretana Beograd, ' +
                    'teretana Zemun, ' +
                    'teretana Blokovi, ' +
                    'teretana Vračar, ' +
                    'Teretana Pionir, ' +
                    'Teretana Ušće, ' +
                    'Teretana Banjica, ' +
                    'Les Mills Beograd, ' +
                    'grupni fitnes, ' +
                    'fitnes treninzi Beograd, ' +
                    'mršavljenje, ' +
                    'zatezanje, ' +
                    'oblikovanje tela, ' +
                    'joga zemun, ' +
                    'Joga Novi Beograd, ' +
                    'Joga Beograd, ' +
                    'boks, ' +
                    'trening snage, ' +
                    'Kardio, ' +
                    'Fitnes centar Beograd, ' +
                    'Gluteus, ' +
                    'Zadnjica, ' +
                    'Gubljenje kilograma/težine, ' +
                    'Celulit, ' +
                    'kardio treninzi, ' +
                    'gym belgrade'
            },
        ],
    },
    data() {
        return {
            host: process.env.VUE_APP_API_URL ,
            eventHovered: false,
        }
    },
    components: {MainMessage, FacebookIconHome, InstaIconHome, YoutubeIconHome},
    mounted() {
        this.fetchEvents(this.host);
    },
    computed: {
        ...mapGetters("events", {
            eventImageUrl: "getEventImageUrl",
            imageUrlList: "getEventsImgList",
            activeEvents: "getActiveEvents",
            imgIndex: "getImgIndex",
        })
    },
    methods: {
        ...mapActions("events", {
            fetchEvents: "fetchEvents",
            init: "initHomeSlider",
            slideShow: "slideShow"
        }),
        ...mapMutations("events", {
            slide: "slide",
            setEventImageUrl: "setEventImageUrl",
            setImgIndex: "setImgIndex",
        }),
    },
    watch: {
        imgIndex() {
            this.init();
        }
    }
}

</script>