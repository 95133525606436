import http from "@/config/http";
export default {
    namespaced: true,
    state: {
        trainings: [],
    },
    mutations: {
        setTrainings(state, loading) {
            state.trainings = loading;
        }
    },
    actions: {
        async fetchTrainings(context, language) {
            context.commit('loader/setLoading', true, { root: true });
            const {data} = await http.get('/training');
            if (data) {
                context.commit("setTrainings", data);
                context.dispatch("sortTrainings", language);
                context.commit('loader/setLoading', false, { root: true });
            }
        },
        sortTrainings(context, data){
            const trainings = context.state.trainings;
            if(data.language === "sr"){
                trainings.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ) ? 1 : -1);
            } else {
                trainings.sort((a, b) => (a.name_eng.toLowerCase() > b.name_eng.toLowerCase() ) ? 1 : -1);
            }
            context.commit("setTrainings", trainings);
        }
    },
    getters: {
        getTrainings(state) {
          return state.trainings;
        },
    }
}