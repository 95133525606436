const vracar_thumbnails = [
  "galleries/vracar_thumbnails/1_thumb.jpg",
  "galleries/vracar_thumbnails/2_thumb.jpg",
  "galleries/vracar_thumbnails/3_thumb.jpg",
  "galleries/vracar_thumbnails/4_thumb.jpg",
  "galleries/vracar_thumbnails/5_thumb.jpg",
  "galleries/vracar_thumbnails/6_thumb.jpg",
  "galleries/vracar_thumbnails/7_thumb.jpg",
  "galleries/vracar_thumbnails/8_thumb.jpg",
  "galleries/vracar_thumbnails/9_thumb.jpg",
  "galleries/vracar_thumbnails/10_thumb.jpg",
  "galleries/vracar_thumbnails/11_thumb.jpg",
  "galleries/vracar_thumbnails/12_thumb.jpg",
  "galleries/vracar_thumbnails/13_thumb.jpg",
  "galleries/vracar_thumbnails/14_thumb.jpg",
  "galleries/vracar_thumbnails/15_thumb.jpg",
  "galleries/vracar_thumbnails/16_thumb.jpg",
  "galleries/vracar_thumbnails/17_thumb.jpg",
  "galleries/vracar_thumbnails/18_thumb.jpg",
  "galleries/vracar_thumbnails/19_thumb.jpg",
  "galleries/vracar_thumbnails/20_thumb.jpg",
  "galleries/vracar_thumbnails/21_thumb.jpg",
  "galleries/vracar_thumbnails/22_thumb.jpg",
  "galleries/vracar_thumbnails/23_thumb.jpg",
  "galleries/vracar_thumbnails/24_thumb.jpg",
  "galleries/vracar_thumbnails/25_thumb.jpg",
  "galleries/vracar_thumbnails/26_thumb.jpg",
  "galleries/vracar_thumbnails/27_thumb.jpg",
];
export default vracar_thumbnails;
