const banjica = [
  "galleries/banjica/1.jpg",
  "galleries/banjica/2.jpg",
  "galleries/banjica/3.jpg",
  "galleries/banjica/4.jpg",
  "galleries/banjica/5.jpg",
  "galleries/banjica/6.jpg",
  "galleries/banjica/7.jpg",
  "galleries/banjica/8.jpg",
  "galleries/banjica/9.jpg",
  "galleries/banjica/10.jpg",
  "galleries/banjica/11.jpg",
  "galleries/banjica/12.jpg",
  "galleries/banjica/13.jpg",
  "galleries/banjica/14.jpg",
  "galleries/banjica/15.jpg",
  "galleries/banjica/16.jpg",
  "galleries/banjica/17.jpg",
  "galleries/banjica/18.jpg",
];
export default banjica;
