<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="18" height="18" viewBox="0 -3 18 18" style="enable-background:new 0 0 100 100;" xml:space="preserve">
    <g>
        <polygon class="st0" points="0,6 1,6 1,10 3,10 3,6 4,6 4,3 0,3 	"/>
        <polygon class="st0" points="2.33,2 3,1.33 3,0.67 2.33,0 1.67,0 1,0.67 1,1.33 1.67,2 	"/>
        <polygon class="st0" points="5,6 6,6 6,10 8,10 8,6 9,6 9,3 5,3 	"/>
        <polygon class="st0" points="7.33,2 8,1.33 8,0.67 7.33,0 6.67,0 6,0.67 6,1.33 6.67,2 	"/>
        <polygon class="st0" points="10,3 10,6 11,6 11,10 13,10 13,6 14,6 14,3 	"/>
        <polygon class="st0" points="12.33,2 13,1.33 13,0.67 12.33,0 11.67,0 11,0.67 11,1.33 11.67,2 	"/>
    </g>
    </svg>

</template>

<script>
    export default {
        name: "CalendarPeople.vue"
    }
</script>

<style scoped>
    .st0{fill-rule:evenodd;clip-rule:evenodd;fill:#808080;}
</style>