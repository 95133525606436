<template>
    <div class="h-screen w-12/12 bg-black-70 background404 grid grid-cols-1">
        <div class="w-12/12 h-10 flex ml-auto">
            <ul class="ml-auto text-btn">
                    <li class="cursor-pointer bg-black-90 opacity-75">
                        <select
                            v-model="$i18n.locale"
                            name="langs"
                                class="bg-black-90 text-black-30 uppercase focus:outline-none opacity-75">
                          <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ lang }}</option>
                        </select>
                    </li>
                </ul>
        </div>
        <div class="m-auto w-8/12 py-10 bg-black-80 bg-opacity-75 shadow-2xl mt-0 pt-0">
            <div class="flex mb-5 pt-10">
                <logo class="m-auto"></logo>
            </div>
            <div class="flex pb-5">
                <p class="m-auto text-white text-center text-lg md:text-lg lg:text-1xl px-10">{{ $t('404-message') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import Logo from "@/icons/Logo404";
export default {
    name: "404",
    data() {
        return {
            langs: ['sr', 'en'],
        }
    },
    components: {Logo}
}
</script>