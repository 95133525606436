<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="100" height="100" viewBox="-14 -14 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
        <circle class="st0" cx="10" cy="10" r="10"/>
    </svg>
</template>

<script>
    export default {
        name: "SliderDotActive"
    }
</script>

<style scoped>
	.st0{fill:#FFFFFF;}
</style>