const pionir_thumbnails = [
        'galleries/pionir_thumbnails/1_thumb.jpg',
        'galleries/pionir_thumbnails/2_thumb.jpg',
        'galleries/pionir_thumbnails/3_thumb.jpg',
        'galleries/pionir_thumbnails/4_thumb.jpg',
        'galleries/pionir_thumbnails/5_thumb.jpg',
        'galleries/pionir_thumbnails/6_thumb.jpg',
        'galleries/pionir_thumbnails/7_thumb.jpg',
        'galleries/pionir_thumbnails/8_thumb.jpg',
        'galleries/pionir_thumbnails/9_thumb.jpg',
        'galleries/pionir_thumbnails/10_thumb.jpg',
        'galleries/pionir_thumbnails/11_thumb.jpg',
        'galleries/pionir_thumbnails/12_thumb.jpg',
        'galleries/pionir_thumbnails/13_thumb.jpg',
        'galleries/pionir_thumbnails/14_thumb.jpg',
        'galleries/pionir_thumbnails/15_thumb.jpg',
        'galleries/pionir_thumbnails/16_thumb.jpg',
        'galleries/pionir_thumbnails/17_thumb.jpg',
    ]
export default pionir_thumbnails;