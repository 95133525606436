<template>
    <div class="w-12/12 h-full bg-black-80 h-64">
        <main-message></main-message>

        <div class="w-12/12  md:w-11/12 lg:10/12 xl:w-6/12 mx-auto mt-8
                    overflow-hidden select-none bg-gradient-to-b from-black-60 via-black-30 to-black-60">
            <p class="text-white text-1xl md:text-2xl lg:text-3xl xl:text-4xl py-2 uppercase">
                {{ $t('price-list') }}
            </p>
        </div>
        <!-- FORM-->
        <div class="w-12/12 md:w-11/12 lg:10/12 xl:w-6/12 h-full md:h-full bg-gradient-to-b
                    from-black-70 via-black-30 to-black-70 mx-auto my-8 overflow-hidden select-none shadow-2xl">
            <div class="text-white h-8 w-16 ml-auto bg-black-70 rounded-full m-1">
                <span class="font-semibold p-1">{{ tab_index + 1 }} / 3</span>
            </div>
            <div v-if="tab_index === 0" class="m-auto w-12/12 md:h-500 pb-10 px-5 grid grid-cols-1 md:grid-cols-2">
                <div class="md:col-span-2">
                    <p class="text-orange-50 m-2 text-lg md:text-xl lg:text-1xl xl:text-2xl uppercase text-left font-semibold">
                        {{ $t('please-fill-in-the-following-form') }}
                    </p>
                    <p class="text-black-10 m-2 uppercase text-left text-sm tracking-wide md:font-semibold">
                        {{ $t('after-you-fill-in-this-form-message') }}
                    </p> 
                </div>
                <div class="m-2">
                    <p class="text-white m-2 uppercase text-left xl:text-xl ml-0">{{ $t('full-name') }}</p>
                    <input
                        v-model="form.name"
                        :class="$v.form.name.$error ? 'bg-orange-50' : 'bg-black-10'"
                        class="w-full h-10 text-xl xl:text-1xl px-2 rounded-sm" type="text">
                </div>

                <div class="m-2">
                    <p class="text-white m-2 uppercase text-left xl:text-xl ml-0">{{ $t('age') }}</p>
                    <input
                        v-model="form.age"
                        :class="$v.form.age.$error ? 'bg-orange-50' : 'bg-black-10'"
                        class="w-full h-10 text-xl xl:text-1xl px-2 rounded-sm" type="number">
                </div>

                 <div class="m-2">
                    <p class="text-white m-2 uppercase text-left xl:text-xl ml-0">{{ $t('place-of-residence') }}</p>
                    <input
                        v-model="form.place_of_residence"
                        class="w-full h-10 text-xl xl:text-1xl rouned px-2 bg-black-10 rounded-sm"
                        type="text">
                </div>

                <div class="m-2">
                    <p class="text-white m-2 uppercase text-left xl:text-xl ml-0">{{ $t('address') }}</p>
                    <input
                        v-model="form.address"
                        class="w-full h-10 text-xl xl:text-1xl px-2 bg-black-10 rounded-sm"
                        type="text">
                </div>

                <div class="m-2">
                    <p class="text-white m-2 uppercase text-left xl:text-xl ml-0">{{ $t('po-box') }}</p>
                    <input
                        v-model="form.post_code"
                        class="w-full h-10 text-xl xl:text-1xl px-2 bg-black-10 rounded-sm"
                        type="number">
                </div>

                 <div class="m-2 relative">
                     <p class="text-white m-2 uppercase text-left xl:text-xl ml-0">{{ $t('mobile-phone') }}</p>
                     <span
                         v-if="$v.form.email.$error && !$v.form.phone_1.minLength || $v.form.email.$error && !$v.form.phone_1.maxLength"
                         class="italic text-orange-50 ml-2 text-sm absolute right-0 top-2 xl:top-3">
                            *{{ !$v.form.phone_1.minLength ? $t('minimum-eight-digits') : $t('maximum-fifteen-digits') }}
                     </span>
                     <input
                        v-model="form.phone_1"
                        class="w-full h-10 text-xl xl:text-1xl px-2 rounded-sm"
                        placeholder = "06xxxxxxxx"
                        :class="$v.form.phone_1.$error ? 'bg-orange-50' : 'bg-black-10'"
                        type="number">
                </div>

                <div class="m-2">
                    <p class="text-white m-2 uppercase text-left xl:text-xl ml-0">{{ $t('landline') }}</p>
                    <input v-model="form.phone_2" class="w-full h-10 text-xl xl:text-1xl px-2 bg-black-10 rounded-sm" type="number">
                </div>

                <div class="m-2 relative">
                    <p class="text-white m-2 uppercase text-left xl:text-xl ml-0">{{ $t('email') }}</p>
                     <span
                         v-if="$v.form.email.$error && !$v.form.email.email"
                         class="italic text-orange-50 ml-2 text-sm absolute right-0 top-2 xl:top-3">
                            *{{ $t('bad-email-format') }}
                     </span>
                    <input
                        v-model="form.email"
                        :class="$v.form.email.$error ? 'bg-orange-50' : 'bg-black-10'"
                        class="w-full h-10 text-xl xl:text-1xl px-2 rounded-sm"  type="email"  >
                </div>
            </div>



            <div v-if="tab_index === 1" class="m-auto w-full md:h-full py-5 px-5 mb-8 grid grid-cols-1 md:grid-cols-3">
                <div class="m-2">
                    <p class="text-white m-2 uppercase text-left xl:text-xl">{{ $t('wish-to-go-to') }} :</p>
                    <div class="text-left m-2">
                        <input type="radio" value="Teretanu" v-model="form.wish_to_go">
                        <label class="ml-3 text-lg text-white">{{ $t('gym') }} / {{ $t('individually') }}</label>
                    </div>
                    <div class="text-left m-2">
                        <input type="radio" value="Grupne programe" v-model="form.wish_to_go">
                        <label class="ml-3 text-lg text-white">{{ $t('group-programs') }}</label>
                    </div>
                    <div class="text-left m-2">
                        <input type="radio" value="Teretanu i grupne programe" v-model="form.wish_to_go">
                        <label class="ml-3 text-lg text-white">{{ $t('gym-and-group-programs') }}</label>
                    </div>
                    <div class="text-left m-2">
                        <input type="radio" value="Personalne treninge" v-model="form.wish_to_go">
                        <label class="ml-3 text-lg text-white">{{ $t('personal-trainings') }}</label>
                    </div>
                </div>

                <div class="m-2">
                    <p class="text-white m-2 uppercase text-left xl:text-xl">{{ $t('interested-for-membership-period') }} :</p>
                    <div class="text-left m-2">
                        <input type="radio" value="Jedan mesec" v-model="form.membership_period">
                        <label class="ml-3 text-lg text-white">{{ $t('one-month') }}</label>
                    </div>
                     <div class="text-left m-2">
                        <input type="radio" value="Dva meseca" v-model="form.membership_period">
                        <label class="ml-3 text-lg text-white">{{ $t('two-months') }}</label>
                    </div> <div class="text-left m-2">
                        <input type="radio" value="Tri meseca" v-model="form.membership_period">
                        <label class="ml-3 text-lg text-white">{{ $t('three-months') }}</label>
                    </div>
                </div>

                 <div class="m-2">
                    <p class="text-white m-2 uppercase text-left xl:text-xl">{{ $t('with-training-wants-to') }}:</p>
                    <div class="text-left m-2">
                        <input type="radio" value="Smršam" v-model="form.primary_wish">
                        <label class="ml-3 text-lg text-white">{{ $t('lose-weight') }}</label>
                    </div>
                     <div class="text-left m-2">
                        <input type="radio" value="Poboljšam kondiciju" v-model="form.primary_wish">
                        <label class="ml-3 text-lg text-white">{{ $t('improve-my-fitness') }}</label>
                     </div>
                     <div class="text-left m-2">
                        <input type="radio" value="Oblikujem telo" v-model="form.primary_wish">
                        <label class="ml-3 text-lg text-white">{{ $t('shape-my-body') }}</label>
                    </div>
                </div>

                <div class="m-2">
                    <p class="text-white m-2 uppercase text-left xl:text-xl">{{ $t('i-can-go-to-my-training') }}:</p>
                    <div class="text-left m-2">
                        <input type="radio" value="Tri dana nedeljno" v-model="form.can_visit">
                        <label class="ml-3 text-lg text-white">{{ $t('three-times-a-week') }}</label>
                    </div>
                     <div class="text-left m-2">
                        <input type="radio" value="Četiri dana nedeljno" v-model="form.can_visit">
                        <label class="ml-3 text-lg text-white">{{ $t('four-times-a-week') }}</label>
                     </div>
                     <div class="text-left m-2">
                        <input type="radio" value="Pet dana nedeljno" v-model="form.can_visit">
                        <label class="ml-3 text-lg text-white">{{ $t('five-times-a-week') }}</label>
                    </div>
                    <div class="text-left m-2">
                        <input type="radio" value="Svakog dana" v-model="form.can_visit">
                        <label class="ml-3 text-lg text-white">{{ $t('every-day') }}</label>
                    </div>
                    <div class="text-left m-2">
                        <input type="radio" value="Samo vikendom" v-model="form.can_visit">
                        <label class="ml-3 text-lg text-white">{{ $t('only-on-weekends') }}</label>
                    </div>
                </div>

                    <div class="m-2 md:col-span-2">
                    <p class="text-white m-2 uppercase text-left xl:text-xl">
                        {{ $t('it-would-suit-me-to-go-to-my-training-in-the-center') }} :
                    </p>
                    <div class="text-left m-2">
                        <input type="radio" value="Banjica" v-model="form.training_place">
                        <label class="ml-3 text-lg text-white">Banjica</label>
                    </div>
                    <div class="text-left m-2">
                        <input type="radio" value="Pionir" v-model="form.training_place">
                        <label class="ml-3 text-lg text-white">Pionir</label>
                    </div>
                    <div class="text-left m-2">
                        <input type="radio" value="Zemun" v-model="form.training_place">
                        <label class="ml-3 text-lg text-white">Zemun</label>
                    </div>
                    <div class="text-left m-2">
                        <input type="radio" value="Novi Beograd" v-model="form.training_place">
                        <label class="ml-3 text-lg text-white">Novi Beograd</label>
                    </div>
                    <div class="text-left m-2">
                        <input type="radio" value="Ušće" v-model="form.training_place">
                        <label class="ml-3 text-lg text-white">SC Ušće</label>
                    </div>
                    <div class="text-left m-2">
                        <input type="radio" value="Ustanička" v-model="form.training_place">
                        <label class="ml-3 text-lg text-white">Ustanička</label>
                    </div>
                    <div class="text-left m-2">
                        <input type="radio" value="Vračar" class="border border-red-50" v-model="form.training_place">
                        <label class="ml-3 text-lg text-white">Vračar</label>
                    </div>
                    <div class="text-left m-2">
                        <input type="radio" value="Navigator" class="border border-red-50" v-model="form.training_place">
                        <label class="ml-3 text-lg text-white">Navigator</label>
                    </div>
                    </div>
                </div>

            <div v-if="tab_index === 2" class="m-auto w-full h-500 py-5 px-5">
                <p class="text-black-10 m-2 uppercase text-left font-semibold ml-0 mb-5">
                    {{ $t('if-you-have-any-further-questions-or-comments') }} :
                </p>
                <textarea
                    name="additional"
                    class="w-full bg-gray-300 px-3 rounded-sm"
                    v-model="form.additional"
                    rows="10">
                </textarea>
            </div>

            <div class="xl:w-12/12">
                <div class="float-right flex m-4">
                    <div v-if="tab_index > 0" class="mx-2">
                        <button class="bg-yellow-50 px-4 py-1 rounded text-btn" @click="tab_index--">
                            {{ $t('previous') }}
                        </button>
                    </div>
                    <div v-if="tab_index < 2" class="mx-2">
                        <button class="bg-yellow-50 px-4 py-1 rounded text-btn" @click="nextOne()">
                            {{ $t('next') }}
                        </button>
                    </div>
                    <div v-if="tab_index === 2" class="mx-2">
                        <button class="bg-yellow-50 px-4 py-1 rounded text-btn" @click="submitForm()">
                            {{ $t('send') }}
                        </button>
                    </div>
                </div>
            </div>
            <successfully-sent-form-modal :modal_name="'success_info_modal'"></successfully-sent-form-modal>
        </div>
    </div>
</template>

<script>
    import MainMessage from "../components/MainMessage";
    import SuccessfullySentFormModal from "@/components/SuccessfullySentFormModal";
    import { mapGetters } from "vuex";
    import { required, maxLength, minLength, email } from 'vuelidate/lib/validators';
    export default {
        name: "PriceListForm",
        components: {MainMessage, SuccessfullySentFormModal},
        data() {
            return {
                tab_index: 0,
                form: {
                    name: '',
                    age: '',
                    address: '',
                    place_of_residence: '',
                    post_code: '',
                    phone_1: '',
                    phone_2: '',
                    email: '',
                    wish_to_go: 'Teretanu',
                    membership_period: 'Jedan mesec',
                    primary_wish: 'Smršam',
                    can_visit: 'Tri dana nedeljno',
                    training_place: 'Banjica',
                    additional: '',
                },
            }
        },
        validationGroup: ['form'],
        validations: {
            form: {
                name: {
                    required,
                },
                email: {
                    required,
                    email
                },
                phone_1: {
                    required,
                    minLength: minLength(8),
                    maxLength: maxLength(15),
                },
                age: {
                    required,
                    minLength: minLength(2),
                    maxLength: maxLength(2),
                },
            },
        },
        mounted() {
            if (this.user) {
                this.fetchMe();
            }
        },
        computed: {
            ...mapGetters("auth", {
                user: "getMe"
            })
        },
        methods: {
            nextOne(){
                this.$v.form.$touch();
                if (!this.$v.form.$invalid) {
                    this.tab_index++;
                }
            },
            async submitForm() {
                await this.$http.post('/form/price', this.form)
                    .then((resp) => {
                        this.handle_success_response('SUC_SUCCESS');
                        window.dataLayer.push({event: 'UpitCenovnik'});
                        this.$modal.show('success_info_modal');
                        this.tab_index = 0;
                    })
                    .catch((err) => {
                        this.handle_error(err);
                    });
            },
            fetchMe() {
                this.form.name = this.user.first_name + ' ' + this.user.last_name;
                this.form.email = this.user.email;
                this.form.phone_1 = this.user.phone;
            },
        }
    }
</script>