<template>
    <div @click="$emit('setCoach')" class="rounded h-305 w-56 bg-gradient-to-r from-black-80 to-black-30 text-black-20 border border-black-20 m-auto my-5">
        <div class="h-250">
            <app-image
                :height="'100%'"
                :src="`${host}/${photo_url}`"
                :width="'100%'"
            />
        </div>
        <div class="py-1 bg-gradient-to-r from-black-80 to-black-30 text-black-20 border-t border-black-20">
            <div class="text-white text-lg md:text-xl xl:text-base mb-1 mt-2 uppercase tracking-tight">{{ name }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CoachCard",
        data() {
            return {
                resizable: true,
                adaptive: true,
                host: process.env.VUE_APP_API_URL,
            }
        },
        props: {
            name: {
                type: String,
                required: true
            },
            photo_url: {
                 type: String,
                 required: true
             },
         }
    }
</script>