import BanjicaImg from "./../../../assets/landingLokacije/banjica.png";
import BlokoviImg from "./../../../assets/landingLokacije/blokovi.png";
import NavigatorImg from "./../../../assets/landingLokacije/navigator.png";
import PionirImg from "./../../../assets/landingLokacije/pionir.png";
import PrvomajskaImg from "./../../../assets/landingLokacije/prvomajska.png";
import UsceImg from "./../../../assets/landingLokacije/usce.png";
import UstanickaImg from "./../../../assets/landingLokacije/ustanicka.png";
import VracarImg from "./../../../assets/landingLokacije/vracar.png";
import ZemunImg from "./../../../assets/landingLokacije/zemun.png";

const lokacije = [
  {
    img: ZemunImg,
    value: "Zemun",
    name: "ZEMUN",
    types: ["teretana", "fitnes", "kardio-zona"],
    properties: [
      "landing-zemun-desc-1-sq",
      "landing-zemun-desc-2",
      "landing-zemun-desc-3",
      "landing-zemun-desc-4",
      "landing-zemun-desc-5",
      "landing-zemun-desc-6",
    ],
    programs: ["massage", "lesMills"],
    rating: 4.7,
    address: "ALEKSANDRA DUBČEKA 20",
    phoneNumber: "064 87 15 111",
    cardColor: "yellow-300",
  },
  {
    img: BlokoviImg,
    name: "BLOKOVI",
    value: "Blokovi",
    types: ["teretana", "fitnes", "kardio-zona"],
    properties: [
      "landing-blokovi-desc-1-sq",
      "landing-blokovi-desc-2",
      "landing-blokovi-desc-3",
      "landing-blokovi-desc-4",
      "landing-blokovi-desc-5",
      "landing-blokovi-desc-6",
    ],
    programs: ["lesMills"],
    rating: 4.6,
    address: "JURIJA GAGARINA 153",
    phoneNumber: "065 22 81 133",
    cardColor: "blue-300",
  },
  {
    img: BanjicaImg,
    name: "BANJICA",
    value: "Banjica",
    types: ["teretana", "fitnes"],
    properties: [
      "landing-banjica-desc-1-sq",
      "landing-banjica-desc-2",
      "landing-banjica-desc-3",
      "landing-banjica-desc-4",
      "landing-banjica-desc-5",
      "landing-banjica-desc-6",
    ],
    programs: [],
    rating: 4.7,
    address: "CRNOTRAVSKA 4",
    phoneNumber: "065 99 60 999",
    cardColor: "red-300",
  },
  {
    img: VracarImg,
    name: "VRAČAR",
    value: "Vracar",
    types: ["teretana", "fitnes", "kardio-zona"],
    properties: [
      "landing-vracar-desc-1-sq",
      "landing-vracar-desc-2",
      "landing-vracar-desc-3",
      "landing-vracar-desc-4",
      "landing-vracar-desc-5",
    ],
    programs: ["massage"],
    rating: 4.6,
    address: "VLADIMIRA KARIĆA 5",
    phoneNumber: "064 87 77 077",
    cardColor: "green-400",
  },
  {
    img: PionirImg,
    name: "PIONIR",
    value: "Pionir",
    types: ["fitnes"],
    properties: [
      "landing-pionir-desc-1",
      "landing-pionir-desc-2",
      "landing-pionir-desc-3",
    ],
    programs: [],
    rating: 4.7,
    address: "ČARLIJA ČAPLINA 39",
    phoneNumber: "064 87 15 113",
    cardColor: "yellow-600",
  },
  {
    img: UstanickaImg,
    name: "USTANIČKA",
    value: "Ustanicka",
    types: ["fitnes"],
    properties: [
      "landing-ustanicka-desc-1-sq",
      "landing-ustanicka-desc-2",
      "landing-ustanicka-desc-3",
    ],
    programs: [],
    rating: 4,
    address: "USTANIČKA 194",
    phoneNumber: "066 34 04 40",
    cardColor: "purple-400",
  },
  {
    img: UsceImg,
    name: "UŠĆE",
    value: "Usce",
    types: ["teretana", "kardio-zona"],
    properties: [
      "landing-usce-desc-1-sq",
      "landing-usce-desc-2",
      "landing-usce-desc-3",
      "landing-usce-desc-4",
      "landing-usce-desc-5",
    ],
    programs: ["massage"],
    rating: 4.8,
    address: "BULEVAR MIHAJLA PUPINA 4",
    phoneNumber: "066 35 80 00",
    cardColor: "green-300",
  },
  {
    img: NavigatorImg,
    name: "NAVIGATOR",
    value: "Navigator",
    types: ["teretana", "kardio-zona"],
    properties: [
      "landing-navigator-desc-1",
      "landing-navigator-desc-2",
      "landing-navigator-desc-3",
      "landing-navigator-desc-4",
      "landing-navigator-desc-5",
    ],
    programs: ["massage", "lesMills"],
    rating: 4.5,
    address: "BULEVAR MILUTINA MILANKOVIĆA 1I",
    phoneNumber: "064 87 77 064",
    cardColor: "red-500",
  },
  {
    img: PrvomajskaImg,
    name: "PRVOMAJSKA",
    value: "Prvomajska",
    types: ["teretana", "kardio-zona"],
    properties: [
      "landing-prvomajska-desc-1-sq",
      "landing-prvomajska-desc-2",
      "landing-prvomajska-desc-3",
      "landing-prvomajska-desc-4",
      "landing-prvomajska-desc-5",
      "landing-prvomajska-desc-6",
    ],
    programs: ["massage", "lesMills"],
    rating: 4.6,
    address: "PRVOMAJSKA 13",
    phoneNumber: "065 93 00 000",
    cardColor: "gray-400",
  },
];

export default lokacije;
