import Vue from 'vue'

export default {
    data() {
        return {
            error: '',
            msg: '',
            errorMessagesEng: {
                ERR_DUPLICATED_EMAIL: 'ERR_DUPLICATED_EMAIL',
                ERR_BAD_EMAIL: 'ERR_BAD_EMAIL',
                ERR_DUPLICATED_PHONE: 'ERR_DUPLICATED_PHONE',
                ERR_DUPLICATED_JMBG: 'ERR_DUPLICATED_JMBG',
                ERR_DUPLICATED_FACEBOOK: 'ERR_DUPLICATED_FACEBOOK',
                ERR_DUPLICATED_GOOGLE: 'ERR_DUPLICATED_GOOGLE',
                ERR_BAD_SESSION_ID: 'ERR_BAD_SESSION_ID',
                ERR_ALREADY_BOOKED: 'ERR_YOU_ALREADY_BOOKED_YOUR_TRAINING_SESSION_FOR_MORE_INFORMATIONS_CONTACT_FRONT_DESK',
                ERR_BAD_ROLE: 'ERR_BAD_ROLE',
                ERR_BAD_CREDENTIALS: 'ERR_BAD_CREDENTIALS',
                ERR_TRAINING_FILLED: 'ERR_TRAINING_FILLED_OR_CANCELED',
                ERR_USER_NOT_VALIDATED: 'ERR_USER_NOT_VALIDATED',
                ERR_YOU_CAN_NOT_SIGN_UP_FOR_TRAINING_THAT_HAS_PASSED: 'ERR_YOU_CAN_NOT_SIGN_UP_FOR_TRAINING_THAT_HAS_PASSED',
                ERR_SOMETHING_WENT_WRONG: 'ERR_SORRY,_SOMETHING_WENT_WRONG',
                ERR_ALL_FIELDS_REQUIRED: 'ERR_YOU_MUST_COMPLETE_ALL_FIELDS',
                ERR_SESSION_NOT_VALID: "ERR_SESSION_NOT_VALID",
                ERR_BAD_GROUP_CODE: "ERR_BAD_GROUP_CODE",
                ERR_USER_ALREADY_IN_GROUP: "ERR_YOU_HAVE_ALREADY_BEEN_ADDED_TO_THE_GROUP",
                ERR_GROUP_CODE_EXPIRED: "ERR_GROUP_CODE_HAS_BEEN_EXPIRED_OR_NOT_VALID_YET",
                ERR_GOOGLE: "ERR_BAD_RESPONSE_FROM_GOOGLE",

            },
            errorMessagesSrb: {
                ERR_DUPLICATED_EMAIL: 'ERR_MEJL_VEĆ_POSTOJI',
                ERR_BAD_EMAIL: 'ERR_NETAČNA_MEJL_ADRESA',
                ERR_DUPLICATED_PHONE: 'ERR_BROJ_VEĆ_POSTOJI',
                ERR_DUPLICATED_JMBG: 'ERR_JMBG_VEĆ_POSTOJI',
                ERR_DUPLICATED_FACEBOOK: 'ERR_OVAJ_FACEBOOK_NALOG_VEĆ_POSTOJI',
                ERR_DUPLICATED_GOOGLE: 'ERR_OVAJ_GOOGLE_NALOG_VEĆ_POSTOJI',
                ERR_BAD_SESSION_ID: 'ERR_LOŠ_ID_SESIJE',
                ERR_ALREADY_BOOKED: 'ERR_VEĆ_STE_PRIJAVLJENI_ZA_OVAJ_TRENING_ZA_VIŠE_INFORMACIJA_POZOVITE_INFO_PULT',
                ERR_BAD_ROLE: 'ERR_LOŠA_ROLA_KORISNIKA',
                ERR_BAD_CREDENTIALS: 'ERR_UNELI_STE_POGREŠNE_KREDENCIJALE',
                ERR_TRAINING_FILLED: 'ERR_TRENING_JE_POPUNJEN ILI OTKAZAN',
                ERR_USER_NOT_VALIDATED: 'ERR_KORISNIČKI_NALOG_NIJE_VALIDIRAN',
                ERR_YOU_CAN_NOT_SIGN_UP_FOR_TRAINING_THAT_HAS_PASSED: 'ERR_NE_MOŽETE_SE_PRIJAVITI_ZA_TRENING_KOJI_JE_PROSAO',
                ERR_SOMETHING_WENT_WRONG: 'ERR_DOŠLO_JE_DO_GREŠKE_NA_SERVERU',
                ERR_ALL_FIELDS_REQUIRED: 'ERR_MORATE_POPUNITI_SVA_POLJA',
                ERR_SESSION_NOT_VALID: "ERR_SESIJA_NIJE_VALIDNA",
                ERR_BAD_GROUP_CODE: "ERR_UNELI_STE_POGREŠAN_KOD",
                ERR_USER_ALREADY_IN_GROUP: "ERR_VEĆ_STE_DODATI_U_GRUPU",
                ERR_GROUP_CODE_EXPIRED: "ERR_KOD_JE_ISTEKAO_ILI_JOŠ_UVEK_NIJE_VALIDAN",
                ERR_GOOGLE: "ERR_LOŠ_ODGOVOR_SA_GUGLA",
            },
            successMessagesSrb: {
                SUC_SUCCESSFULLY_BOOKED_TRAINING: "USPEŠNO STE ZAKAZALI TRENING",
                SUC_SUCCESSFULLY_CANCELED_TRAINING: "USPEŠNO STE OTKAZALI TRENING",
                SUC_SUCCESSFULLY_REGISTERED: "USPEŠNO STE SE REGISTROVALI",
                SUC_SUCCESSFULLY_LOGGED_IN: "USPEŠNO STE SE ULOGOVALI",
                SUC_YOUR_CODE_IS_CORRECT: "VAŠ KOD JE ISPRAVAN",
                SUC_SUCCESS: "USPEŠNO",
            },
            successMessagesEng: {
                SUC_SUCCESSFULLY_BOOKED_TRAINING: "SUCCESSFULLY BOOKED TRAINING",
                SUC_SUCCESSFULLY_CANCELED_TRAINING: "SUCCESSFULLY CANCELED TRAINING",
                SUC_SUCCESSFULLY_REGISTERED: "SUCCESSFULLY REGISTERED",
                SUC_SUCCESSFULLY_LOGGED_IN: "SUCCESSFULLY LOGGED IN",
                SUC_YOUR_CODE_IS_CORRECT: "YOUR CODE IS CORRECT",
                SUC_SUCCESS: "SUCCESS",
            }
        }
    },
    methods: {
        handle_error(err) {
            let count = 0;
            Object.keys(this.errorMessagesEng).forEach((key) => {
                if (key === err.response.data.error) {
                    count++;
                }
            });

            if (this.$i18n.locale === 'sr') {
                Object.keys(this.errorMessagesSrb).forEach((key) => {
                    if (key === err.response.data.error) {
                        this.error = this.errorMessagesSrb[key];
                    }
                    if (count < 1) {
                        this.error = this.errorMessagesSrb.ERR_SOMETHING_WENT_WRONG;
                    }
                });
            } else {
                Object.keys(this.errorMessagesEng).forEach((key) => {
                    if (key === err.response.data.error) {
                        this.error = this.errorMessagesEng[key];
                    }
                    if (count < 1) {
                        this.error = this.errorMessagesEng.ERR_SOMETHING_WENT_WRONG;
                    }
                });
            }
            let message = this.error
                .replace(/_/g, " ")
                .substr(4, this.error.length)
                .toUpperCase();

            this.$toast.open({
                message: message,
                type: 'error',
                duration: 5000,
            });
        },
        handle_success_response(data) {
            if (this.$i18n.locale === 'sr') {
                Object.keys(this.successMessagesSrb).forEach((key) => {
                    if (key === data) {
                        this.msg = this.successMessagesSrb[key]
                    }
                });
            } else {
                Object.keys(this.successMessagesEng).forEach((key) => {
                    if (key === data) {
                        this.msg = this.successMessagesEng[key]
                    }
                });
            }
            this.$toast.open({
                message: this.msg.toUpperCase(),
                type: 'success',
                duration: 5000,
            });
        },
    }
};
