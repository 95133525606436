<template>
    <div class="loader-wrapper">
        <logo class="loader-logo zoom-logo-animation"></logo>
    </div>
</template>

<script>
import Logo from "@/icons/LoaderLogo";
export default {
    name: "AppLoader",
    components: {Logo}
}
</script>

<style scoped>
/*.loader-spin {*/
/*    margin: auto;*/
/*    height: 90px;*/
/*    width: 90px;*/
/*}*/
</style>