import { encode } from './dataEncoder';

export const mimeTypes = {
  aac: 'audio/aac',
  abw: 'application/x-abiword',
  arc: 'application/x-freearc',
  avi: 'video/x-msvideo',
  azw: 'application/vnd.amazon.ebook',
  bin: 'application/octet-stream',
  bmp: 'image/bmp',
  bz: 'application/x-bzip',
  bz2: 'application/x-bzip2',
  csh: 'application/x-csh',
  css: 'text/css',
  csv: 'text/csv',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  eot: 'application/vnd.ms-fontobject',
  epub: 'application/epub+zip',
  gz: 'application/gzip',
  gif: 'image/gif',
  htm: 'text/html',
  html: 'text/html',
  ico: 'image/vnd.microsoft.icon',
  ics: 'text/calendar',
  jar: 'application/java-archive',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  js: 'text/javascript',
  json: 'application/json',
  jsonld: 'application/ld+json',
  mid: '.midi',
  mjs: 'text/javascript',
  mp3: 'audio/mpeg',
  mpeg: 'video/mpeg',
  mpkg: 'application/vnd.apple.installer+xml',
  odp: 'application/vnd.oasis.opendocument.presentation',
  ods: 'application/vnd.oasis.opendocument.spreadsheet',
  odt: 'application/vnd.oasis.opendocument.text',
  oga: 'audio/ogg',
  ogv: 'video/ogg',
  ogx: 'application/ogg',
  opus: 'audio/opus',
  otf: 'font/otf',
  png: 'image/png',
  pdf: 'application/pdf',
  php: 'application/php',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  rar: 'application/vnd.rar',
  rtf: 'application/rtf',
  sh: 'application/x-sh',
  svg: 'image/svg+xml',
  swf: 'application/x-shockwave-flash',
  tar: 'application/x-tar',
  tif: 'image/tiff',
  tiff: 'image/tiff',
  ts: 'video/mp2t',
  ttf: 'font/ttf',
  txt: 'text/plain',
  vsd: 'application/vnd.visio',
  wav: 'audio/wav',
  weba: 'audio/webm',
  webm: 'video/webm',
  webp: 'image/webp',
  woff: 'font/woff',
  woff2: 'font/woff2',
  xhtml: 'application/xhtml+xml',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xml: 'XML',
  xul: 'application/vnd.mozilla.xul+xml',
  zip: 'application/zip',
  apk: 'application/vnd.android.package-archive',
  '3gp': 'video/3gpp',
  '3g2': 'video/3gpp2',
  '7z': 'application/x-7z-compressed',
};

export const base64ToUint8Array = (base64) => {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

export const arrayBufferToBase64 = (buffer) => {
  if (!buffer) return;
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export const getPhotoUrls = (imgUrls) => {
  try {
    const urls = imgUrls.map((data) => {
      const bytes = base64ToUint8Array(data);
      return `data:application/octet-stream;base64,${encode(bytes)}`;
    });
    return Promise.resolve(urls);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const prepareForUplaod = (myFile) => {
  const reader = new FileReader();
  const fileByteArray = [];
  reader.readAsArrayBuffer(myFile);
  reader.onloadend = (evt) => {
    if (!evt.target?.result || !(evt.target.readyState === FileReader.DONE))
      return;
    const arrayBuffer = evt.target.result;
    const array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < array.length; i++) {
      fileByteArray.push(array[i]);
    }
  };
  return fileByteArray;
};

export const handleLinkDownload = (blob, fileName) => {
  const link = document.createElement('a');
  document.body.appendChild(link);
  const url = window.URL.createObjectURL(blob);
  link.href = url;
  link.download = fileName;
  link.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
};

export const downloadFile = (data, saveName, type) => {
  if (!data) return;
  const bytes = base64ToUint8Array(data);
  const blob = new Blob([bytes], { type: MimeTypes[type] });

  // some version can work with msSaveBlob,
  // even though ts interface doesn't include it
  // we have to alter global interface
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, saveName);
  } else {
    handleLinkDownload(blob, saveName);
  }
};

const reverseString = (str) => {
  if (str === '') return '';
  return reverseString(str.slice(1)) + str.charAt(0);
};

export const getFileType = (fileName) => {
  const reversedName = reverseString(fileName);
  const dotIndex = reversedName.indexOf('.');
  if (!dotIndex) return '';
  return reverseString(reversedName.slice(0, dotIndex));
};
