import http from "@/config/http";
export default {
    namespaced: true,
    state: {
        errorMessage: null,
        successMessage: null,
        showModal: false,
        modalTitle: '',
        isOpenNav: false,
        resolution: false,
        currentDay: 1,
        currentTime: null,
    },
    mutations: {
        setErrorMessage(state, data) {
          state.errorMessage = data;
        },
        setSuccessMessage(state, message) {
            state.successMessage = message;
        },
        setModal(state, data) {
            state.modalTitle = data.name;
            state.showModal = data.show;
        },
        toggleNavigation(state) {
            state.isOpenNav = !state.isOpenNav;
        },
        setResolution(state) {
            var resolution = window.innerWidth;
            state.resolution = resolution;
        },
        setCurrentTime(state, data) {
            state.currentTime = data;
        },
        setCurrentDay(state, data) {
            state.currentDay = data;
        },
    },
    actions: {
        async getServerTime(context) {
            const { data } = await http.get('/time');
            context.commit("setCurrentDay", data.day);
            var hours = data.time.split(':')[0];
            var minutes = data.time.split(':')[1];
            minutes = parseInt(minutes) + parseInt(2);
            if (minutes < 10) {
                minutes = '0' + minutes;
            }
            var time = String(hours) + String(minutes);
            time = parseInt(time);
            context.commit("setCurrentTime", time);
        }
    },
    getters: {
        getErrorMessage(state) {
          return state.errorMessage;
        },
        getSuccessMessage(state) {
          return state.successMessage;
        },
        getModalTitle(state) {
            return state.modalTitle;
        },
        getModalStatus(state) {
            return state.showModal;
        },
        getIsOpenNav(state) {
            return state.isOpenNav;
        },
        getResolution(state) {
            return state.resolution;
        },
        getCurrentTime(state) {
            return state.currentTime;
        },
        getCurrentDay(state) {
            return state.currentDay;
        }
    }
}