export default {
    namespaced: true,
    state: {
        imgIndex: 0,
        imageUrl: '',
        images: [],
    },
    mutations: {
        setImgIndex(state, data) {
            state.imgIndex = data;
        },
        setImgUrl(state, data) {
            state.imageUrl = data;
        },
        setImages(state, data) {
            state.images = data;
        },
        slide(state, operator) {
            if (operator === '-' && state.imgIndex > 0) {
                state.imgIndex--;
            } else if (operator === '+' && state.images.length - 1 > state.imgIndex) {
                state.imgIndex++;
            } else {
                return;
            }
        }
    },
    actions: {
        openImage(context, data) {
            data.imgUrlList.forEach((item, i) => {
                if (data.index === i) {
                    context.commit("setImgUrl", item);
                }
            });
            context.commit('shared/setModal', {name: 'image_modal', show: true}, { root: true });
        },
        initComponent(context, data) {
            context.state.images.forEach((item, i) => {
                if (context.state.imgIndex === i) {
                    context.commit("setImgUrl", item);
                }
            })
        },
    },
    getters: {
        getImgIndex(state) {
          return state.imgIndex;
        },
        getImgUrl(state) {
          return state.imageUrl;
        },
    }
}