<template>
    <div :style="style"></div>
</template>

<script>
export default {
    props: {
        src: {
            type: String,
            default: ''
        },
        width: {
            type: String
        },
        height: {
            type: String
        }
    },
    computed: {
        style() {
            return {
                backgroundImage: `url(${this.src})`.replace(/ /g, "%20"),
                backgroundRepeat: this.repeat,
                backgroundPosition: "center",
                backgroundColor: "#ccc",
                repeat: 'no-repeat',
                position: 'sticky',
                top: '0',
                borderTopRightRadius: '2px',
                borderTopLeftRadius: '2px',
                borderBottomRightRadius: '2px',
                backgroundSize: "cover",
                alt: "Image not available",
                width: this.width,
                height: this.height,
            };
        }
    }
};
</script>
