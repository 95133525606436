const blokovi = [
  "galleries/blokovi/1.jpg",
  "galleries/blokovi/2.jpg",
  "galleries/blokovi/3.jpg",
  "galleries/blokovi/4.jpg",
  "galleries/blokovi/5.jpg",
  "galleries/blokovi/6.jpg",
  "galleries/blokovi/7.jpg",
  "galleries/blokovi/8.jpg",
  "galleries/blokovi/9.jpg",
  "galleries/blokovi/10.jpg",
  "galleries/blokovi/11.jpg",
  "galleries/blokovi/12.jpg",
  "galleries/blokovi/13.jpg",
  "galleries/blokovi/15.jpg",
  "galleries/blokovi/16.jpg",
];
export default blokovi;
