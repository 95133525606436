import http from "../config/http";
import router from "@/router";
export default {
    namespaced: true,
    state: {
        me: null,
        token: '' || localStorage.getItem("token"),
        cookie: '' || localStorage.getItem("cookie"),
        user: null,
        isLoggedIn: false || localStorage.getItem("token"),
        rememberMe: true,
        googleConnected: false,
        loginType: '',
        googleData: {},
        facebookData: {},
        accessToken: '',
        first_name: '',
        last_name: '',
        email: '',
    },
    mutations: {
        setToken(state, token) {
            localStorage.setItem("token", token);
            state.token = token;
        },
        setGoogleConected(state, data) {
            state.googleConnected = data;
        },
        setIsLoggedIn(state, data) {
            state.isLoggedIn = data;
        },
        setUser(state, user) {
            state.user = user;
        },
        setMe(state, data) {
            state.user = data;
            state.me = data;
        },
        setGoogleData(state, data) {
            state.googleData = data;
        },
        setFacebookData(state, data) {
            state.facebookData = data;
        },
        setEmail(state, data) {
            state.email = data;
        },
        setUsername(state, data) {
            state.first_name = data.first_name;
            state.last_name = data.last_name;
        },
        setCookie(state, payload) {
            var expires = "";
            if (payload.days) {
                var date = new Date();
                date.setTime(date.getTime() + (payload.days * 24 * 60 * 60 * 1000));
                expires = "; expires=" + date.toUTCString();
            }
            document.cookie = payload.name + "=" + (payload.value || "")  + expires;
        },
        eraseCookie(state, payload) {
            document.cookie = payload.name +'=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        },
        setStateCookie(state, payload) {
            if (localStorage.getItem('token')) {
                state.token = localStorage.getItem('token');
            }

            if (payload) {
                state.token = payload
            }

            if (localStorage.getItem('token') === null ||
                localStorage.getItem('token') === undefined &&
                payload === undefined || payload === ''
            ) {
                state.token = '';
            }
        },
        setLoginType(state, data) {
            state.loginType = data;
        },
        setAccessToken(state, data) {
            state.accessToken = data;
        },
    },
    actions: {
        async fetchMe(context) {
            const { data } = await http.get('/user');
            context.commit("setMe", data);
        },
        async login(context, data) {
            context.commit('loader/setLoading', true, { root: true });
            await http.post('user/login', data)
                .then((resp) => {
                    context.commit('shared/setSuccessMessage', 'SUC_SUCCESSFULLY_LOGGED_IN', { root: true });
                    context.commit("setToken", resp.data.session_id);
                    context.commit("setUser", resp.data);
                    context.commit("setMe", resp.data);
                    context.commit("setIsLoggedIn", true);
                    if (context.state.rememberMe === true) {
                        context.commit('setCookie', {name: 'token', value: resp.data.session_id, days: 365});
                    }
                    http.defaults.headers.common['session_id'] = `${resp.data.session_id}`;
                    const { redirect = false } = router.currentRoute.query;
                    const path = redirect ? decodeURI(redirect) : '/';
                    router.push({path});
                    context.commit('loader/setLoading', false, { root: true });

                })
                .catch((err) => {
                    context.commit('loader/setLoading', false, { root: true });
                    if (err.response.data.error === 'ERR_USER_NOT_VALIDATED') {
                        context.commit('shared/setErrorMessage', err, { root: true });
                        context.commit('shared/setModal', {name: 'resend_confirmaiton_email', show: true}, { root: true });
                    }
                    if (context.state.loginType === 'default') {
                        context.commit('shared/setErrorMessage', err, { root: true });
                    } else if (context.state.loginType === 'google') {
                        if (err.response.data.error === 'ERR_BAD_CREDENTIALS') {
                            context.commit('shared/setModal', {name: 'enter_phone_modal_google', show: true}, { root: true });
                        } else {
                            context.commit('shared/setErrorMessage', err, { root: true });
                        }
                    } else if (context.state.loginType === 'facebook') {
                        if (err.response.data.error === 'ERR_BAD_CREDENTIALS') {
                            context.commit('shared/setModal', {name: 'enter_phone_modal_facebook', show: true}, { root: true });
                        } else {
                            context.commit('shared/setErrorMessage', err, { root: true });
                            window.FB.logout();
                            window.FB.Event.clear;
                        }
                    }
                    context.dispatch("logout");
                });
        },
        logout(context) {
            context.commit('loader/setLoading', false, { root: true });
            context.commit("setToken", null);
            localStorage.removeItem("token");
            localStorage.removeItem("role");
            context.commit("setIsLoggedIn", false);
            context.commit("setUser", null);
            context.commit("setMe", null);
            context.commit("eraseCookie", {name: 'token'});
            context.commit("setGoogleConected", false);
            delete http.defaults.headers.common['session_id'];
            router.push('/login');
            if (context.state.accessToken) {
                window.FB.logout();
            }
        },
        fetchCookie(context) {
            var cookie = decodeURIComponent(document.cookie);
            cookie = cookie.substr(6, cookie.length);
            context.commit('setStateCookie', cookie);
        },
        async resendVerificationEmail(context, data) {
            const apiData = {
                email: data.email,
                language: data.lang
            };
            await http.post('/user/resend-verification', apiData)
                .then((resp) => {
                    context.commit('shared/setSuccessMessage', 'SUC_SUCCESS', { root: true });
                    context.commit('shared/setModal', {name: 'resend_confirmaiton_email', show: false}, { root: true });
                    window.FB.logout();
                    window.FB.Event.clear;
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    window.FB.logout();
                    window.FB.Event.clear;
                });
        },
        async forgotPassword(context, data) {
            const apiData = {
                email: data.forgotEmail,
                language: data.lang
            };
            await http.post('/user/forgot-password', apiData)
                .then((resp) => {
                    context.commit('shared/setSuccessMessage', 'SUC_SUCCESS', { root: true });
                    context.commit('shared/setModal', {name: 'forgot_password_modal', show: false}, { root: true });
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                });
        },
        // GOOGLE LOGIN - START
        async onSignInSuccess(context, user) {
            // `googleUser` is the GoogleUser object that represents the just-signed-in user.
            // See https://developers.google.com/identity/sign-in/web/reference#users
            const profile = user.getBasicProfile();
            const last_name = profile.getFamilyName() ? profile.getFamilyName() : profile.getGivenName();
            const userInfo = {
                id: profile.getId(),
                first_name: profile.getGivenName(),
                last_name: last_name,
                email: profile.getEmail(),
            }
            var id_token = user.getAuthResponse().id_token;
            userInfo.id_token = id_token;
            context.commit("setGoogleData", userInfo);
            if (userInfo) {
                context.dispatch("login", {google: id_token})
            }
        },
        onSignInError(context, error) {
            // `error` contains any error occurred.
            // console.log('OH NOES', error)
            var err = {response: {data: {error: 'ERR_GOOGLE'}}};
            context.commit('shared/setErrorMessage', err, { root: true });
        },
        async createUserFromGoogle(context, data) {
            // email: googleData.email, google: googleData.id_token, first_name: googleData.first_name, last_name: googleData.last_name, phone: phoneGoogle}
            const apiData = {
                email: data.googleData.email,
                google: data.googleData.id_token,
                first_name: data.googleData.first_name,
                last_name: data.googleData.last_name,
                phone: data.phone,
                language: data.lang
            }
            await http.post("user/register", apiData)
                .then((response) => {
                    context.commit('shared/setModal', {name: 'enter_phone_modal_google', show: false}, { root: true });
                    context.commit('shared/setModal', {name: 'confirm_email_info_modal', show: true}, { root: true });
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                })
        }, // GOOGLE LOGIN - END

        // FACEBOOK LOGIN - START
        initFB(context) {
            window.fbAsyncInit = function () {
                window.FB.init({
                    appId: '2830828717188684',  // pravi
                    // appId: '375811340243054',  // testni
                    cookie: true,
                    xfbml: true,
                    version: 'v9.0'
                });
            };

            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        },
        loginWithFacebook (context, data) {
            window.FB.login(response => {
                if (response.status === "connected") {
                    context.commit("setAccessToken", response.authResponse.accessToken);
                    window.FB.api('/me?fields=email,first_name,last_name', res => {
                        context.commit("setFacebookData", res);
                        context.commit("setUsername", {
                            first_name: res.first_name,
                            last_name: res.last_name,
                        });
                        context.commit("setEmail", res.email);
                    });
                    context.dispatch("login", {facebook: response.authResponse.accessToken})
                }
            })
        },
        async createUserFromFacebook(context, data) {
            const accessToken = context.state.accessToken;
            const apiData = {
                email: data.facebookData.email || data.email,
                first_name: data.facebookData.first_name,
                last_name: data.facebookData.last_name,
                facebook: accessToken,
                phone: data.phone,
                language: data.lang
            }
            if (!apiData.phone || !apiData.email) {
                const err = {response: {data: {error: 'ERR_ALL_FIELDS_REQUIRED'}}};
                context.commit('shared/setErrorMessage', err, { root: true });
            } else {
                await http.post("user/register", apiData)
                    .then((response) => {
                        context.commit('shared/setModal', {name: 'enter_phone_modal_facebook', show: false}, { root: true });
                        context.commit('shared/setModal', {name: 'confirm_email_info_modal', show: true}, { root: true });
                        window.FB.logout();
                        window.FB.Event.clear;
                    })
                    .catch((err) => {

                        context.commit('shared/setErrorMessage', err, { root: true });
                        context.commit('shared/setModal', {name: 'enter_phone_modal_facebook', show: false}, { root: true });
                        window.FB.logout();
                        window.FB.Event.clear;
                    })
            }
        },
        // FACEBOOK LOGIN - END
    },
    getters: {
        getError(state) {
          return state.error;
        },
        getIsLoggedIn(state) {
          return state.isLoggedIn;
        },
        getMe(state) {
            return state.me;
        },
        getLoginType(state) {
            return state.loginType;
        },
        getGoogleData(state) {
            return state.googleData;
        },
        getFacebookData(state) {
            return state.facebookData;
        },
        getAccessToken(state) {
            return state.accessToken;
        },
        getFirstName(state) {
            return state.first_name;
        },
        getLastName(state) {
            return state.last_name;
        },
        getEmail(state) {
            return state.email;
        }
    }
}