<template>
  <section class="py-5 grid md:grid-cols-2 xl:grid-cols-3 bg-black">
    <div
      v-for="(lokacija, index) in lokacije"
      :key="index"
      class="relative w-3/4 sm:w-1/2 md:w-8/12 my-4 mx-auto rounded-b-xl text-md font-semibold text-black bg-white"
    >
      <img
        :src="lokacija.img"
        :alt="lokacija.name + '.img'"
        class="w-full h-52"
      />
      <div class="relative">
        <div
          class="w-11/10 rounded-xl absoluteCentered"
          :class="'bg-' + lokacija.cardColor"
        >
          <h1 class="text-3xl">{{ lokacija.name }}</h1>
          <span
            v-for="(type, index) in lokacija.types"
            :key="index"
            class="text-lg"
          >
            {{ $t(type) }}{{ index !== lokacija.types.length - 1 ? " |" : "" }}
          </span>
        </div>
      </div>
      <div class="h-48 mt-12 mb-2 px-5 text-left text-sm bg-white">
        <ul>
          <li v-for="(property, index) in lokacija.properties" :key="index">
            <p>
              {{ "- " + $t(property)
              }}<sup v-if="property.includes('sq')">2</sup>
            </p>
          </li>
        </ul>
      </div>
      <div class="relative h-12">
        <div class="w-11/10 h-10 flex justify-between absoluteCentered">
          <div
            :class="
              lokacija.programs.includes('massage')
                ? 'bg-' + lokacija.cardColor
                : 'bg-transparent'
            "
            class="w-1/2 h-full py-1 px-5 text-center lokacijaProgramRibbonLeft"
          >
            <div v-if="lokacija.programs.includes('massage')">
              {{ $t("masaza") }}
            </div>
          </div>
          <div
            :class="
              lokacija.programs.includes('lesMills')
                ? 'bg-' + lokacija.cardColor
                : 'bg-transparent'
            "
            class="w-1/2 h-full py-1 px-5 text-center lokacijaProgramRibbonRight"
          >
            <div v-if="lokacija.programs.includes('lesMills')">
              <p>LES MILLS</p>
            </div>
          </div>
        </div>
      </div>
      <div class="relative h-12">
        <div
          class="w-11/10 h-12 py-1 px-5 flex justify-between bg-gray-600 text-white absoluteCentered"
        >
          <span class="mt-2"
            >RATING:
            <span :class="'text-' + lokacija.cardColor">{{
              lokacija.rating
            }}</span></span
          >
          <star-rating
            class="-mt-px"
            v-bind:increment="0.1"
            v-bind:rating="lokacija.rating"
            v-bind:max-rating="5"
            v-bind:read-only="true"
            v-bind:star-size="24"
            v-bind:show-rating="false"
            v-bind:inline="true"
            inactive-color="#fff"
          ></star-rating>
        </div>
      </div>
      <div class="py-2 px-7 text-left bg-white">
        <p>{{ lokacija.address }}</p>
        <a :href="`tel:${lokacija.phoneNumber}`">{{
          "TEL: " + lokacija.phoneNumber
        }}</a>
      </div>
      <div class="flex w-full justify-evenly">
        <button
          class="w-2/5 p-2 my-4 rounded-xl text-sm text-middle font-semibold text-black zakaziButtonBoxShadow"
          :class="'bg-' + lokacija.cardColor"
          @click="emitSelectGym(lokacija.value)"
        >
          <p>{{ $t("cta-zakazi") }}</p>
          <p>{{ $t("cta-probni") }}</p>
        </button>
        <button
          class="w-2/5 p-2 my-4 rounded-xl text-sm text-middle font-semibold text-black zakaziButtonBoxShadow uppercase"
          :class="'bg-' + lokacija.cardColor"
          @click="$router.push('/price-list')"
        >
          <p>{{ $t("price-list") }}</p>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import StarRating from "../../../../node_modules/vue-star-rating";
import lokacije from "./lokacije.texts.js";

export default {
  name: "Lokacije",
  data() {
    return {
      lokacije: lokacije,
      width: null,
    };
  },
  props: ["handleSelectGym"],
  components: { StarRating },
  methods: {
    emitSelectGym(gymId) {
      this.$emit("select-gym", gymId);
    },
  },
  mounted() {
    this.width = window.innerWidth;
    window.onresize = () => {
      this.width = window.innerWidth;
    };
  },
};
</script>
