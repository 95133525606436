<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="18" height="18" viewBox="0 -5 18 18" style="enable-background:new 0 0 100 100;" xml:space="preserve">
    <g>
        <circle class="st0" cx="14" cy="2" r="2"/>
        <circle class="st0" cx="8" cy="2" r="2"/>
        <circle class="st0" cx="2" cy="2" r="2"/>
    </g>
    </svg>

</template>

<script>
    export default {
        name: "CalendarMoreIcon.vue"
    }
</script>

<style scoped>
    .st0{fill:#808080;}
</style>