<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="30" height="30" viewBox="0 0 30 30" style="enable-background:new 0 0 100 100;" xml:space="preserve">
    <path class="st0" d="M17.61,15.49c1.18-1.61,1.89-3.59,1.89-5.74C19.5,4.37,15.13,0,9.75,0S0,4.37,0,9.75s4.37,9.75,9.75,9.75
        c2.15,0,4.13-0.71,5.74-1.89L21.88,24L24,21.88C24,21.88,17.61,15.49,17.61,15.49L17.61,15.49z M9.75,16.5C6.03,16.5,3,13.47,3,9.75
        S6.03,3,9.75,3s6.75,3.03,6.75,6.75S13.47,16.5,9.75,16.5L9.75,16.5z"/>
    </svg>

</template>

<script>
    export default {
        name: "SearchIcon"
    }
</script>

<style scoped>
    .st0{fill:#808080;}
</style>