<template>
    <div>
        <div class="bg-black-90 h-24 md:h-40 lg:h-48 xl:h-56 flex sm:w-screen md:w-screen">
            <div class="flex mx-auto w-3/4 xl:h-56">
                <p class="text-black-30 m-auto font-thin text-lg md:text-3xl lg:text-4xl xl:text-4xl font-light select-none uppercase">
                  {{ $t('you-have-no-excuse') }}. <span class="text-white font-semibold"> {{ $t('train') }}</span>
                </p>
            </div>
        </div>

        <div class="flex sm:w-screen md:w-screen mx-auto h-12 md:h-12 block lg:hidden lg:h-12 xl:h-12 bg-green-20">
            <button v-if="!isLoggedIn" @click="$router.push('/login')" class="uppercase text-white m-auto text-sm px-4 bg-green-30 h-full md:text-xl lg:text-2xl block lg:hidden">
                {{ $t('log-in') }}
            </button>
             <button v-if="isLoggedIn" @click="logout" class="uppercase text-white m-auto text-sm px-4 bg-green-30 h-full md:text-xl lg:text-2xl block lg:hidden">
                {{ $t('log-out') }}
            </button>
        </div>
    </div>
</template>

<script>
    import SliderDot from "../icons/SliderDot";
    import SliderDotActive from "../icons/SliderDotActive";
    import {mapActions, mapGetters} from "vuex";
    export default {
        name: "MainMessage",
        components: {SliderDot, SliderDotActive},
        computed: {
            ...mapGetters("auth", {
                isLoggedIn: "getIsLoggedIn"
            }),
        },
        methods: {
            ...mapActions("auth", {
                logout: "logout"
            }),
        }
    }
</script>