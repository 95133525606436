<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0,0,256,256"
    width="32px"
    height="32px"
  >
    <g
      fill="none"
      fill-rule="nonzero"
      stroke="none"
      stroke-width="1"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal"
    >
      <g transform="scale(5.33333,5.33333)">
        <circle cx="24" cy="24" r="20" fill="#18ba48"></circle>
        <path
          d="M22.5,33c-0.226,0 -0.446,-0.076 -0.625,-0.219l-7.5,-6c-0.431,-0.345 -0.501,-0.974 -0.156,-1.405c0.347,-0.431 0.975,-0.501 1.406,-0.156l6.667,5.334l9.889,-14.126c0.316,-0.454 0.94,-0.562 1.393,-0.246c0.453,0.317 0.562,0.94 0.246,1.393l-10.5,15c-0.158,0.227 -0.403,0.377 -0.677,0.417c-0.048,0.005 -0.096,0.008 -0.143,0.008z"
          fill="#0c0e11"
        ></path>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "CheckIcon",
};
</script>

<style scoped type="text/css"></style>
