const usce = [
  "galleries/usce/1.jpg",
  "galleries/usce/2.jpg",
  "galleries/usce/3.jpg",
  "galleries/usce/4.jpg",
  "galleries/usce/5.jpg",
  "galleries/usce/6.jpg",
  "galleries/usce/7.jpg",
  "galleries/usce/8.jpg",
  "galleries/usce/9.jpg",
  "galleries/usce/10.jpg",
  "galleries/usce/11.jpg",
  "galleries/usce/12.jpg",
  "galleries/usce/13.jpg",
  "galleries/usce/14.jpg",
  "galleries/usce/15.jpg",
];
export default usce;
