<template>
    <div class="m-auto">
        <div class="text-white m-1 xl:m-0 pb-2 xl:w-12/12">
            <div class="xl:flex xl:justify-center">
                <p class="font-semibold md:text-xl lg:text-xl mt-1 xl:mt-0">
                    {{ $t("address") }} :
                </p>
                <p class="md:text-lg lg:text-xl xl:ml-3 text-black-20">
                    {{ address }}
                </p>
            </div>
            <div v-if="tel" class="mt-3 xl:mt-5 xl:flex xl:justify-center">
                <p class="font-semibold md:text-xl xl:text-xl mt-1 xl:mt-0">
                    Tel :
                </p>
                <p class="md:text-lg lg:text-xl xl:ml-3 text-black-20">
                    <a :href="`tel:${tel}`">{{ tel }}</a>

                </p>
            </div>
            <div v-if="mob" class="mt-3 xl:mt-5 xl:flex xl:justify-center">
                <p class="font-semibold md:text-xl xl:text-xl mt-1 xl:mt-0">
                    Mob :
                </p>
                <p class="md:text-lg lg:text-xl xl:ml-3 text-black-20">
                    <a :href="`tel:${mob}`">{{ mob }}</a>
                </p>
            </div>
            <div class="mt-3 xl:mt-5 xl:text-center">
                <p class="font-semibold md:text-xl xl:text-xl mt-1">
                    {{ $t("work-hour") }} :
                </p>
                <p
                    class="md:text-lg lg:text-xl xl:ml-3 mt-1 xl:mt-2 text-black-20"
                >
                    {{ $t("weekdays") }}: {{ workdays }}
                </p>
                <p
                    class="md:text-lg lg:text-xl xl:ml-3 mt-1 xl:mt-2 text-black-20"
                >
                    {{ $t("saturday") }}: {{ saturday }}
                </p>
                <p
                    class="md:text-lg lg:text-xl xl:ml-3 mt-1 xl:mt-2 text-black-20"
                >
                    {{ $t("sunday") }}: {{ sunday }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ClubInfo",
    data() {
        return {
            hovered: false,
        }
        
    },
    props: {
        address: {
            type: String,
            required: true,
        },
        tel: {
            type: String,
            required: false,
        },
        mob: {
            type: String,
            required: false,
        },
        workdays: {
            type: String,
            required: false,
        },
        saturday: {
            type: String,
            required: false,
        },
        sunday: {
            type: String,
            required: false,
        },
    }
};
</script>