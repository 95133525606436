<template>
    <div class="w-12/12 overflow-x-hidden mb-12">
        <div
            :class="resolution > 1023 ? 'pic11' : 'job-final'"
            class="w-12/12 h-350 md:h-400 lg:h-500 xl:h-500 sticky overflow-x-hidden flex"
         >
            <div class="xl:w-2/3 mx-auto flex m-auto">
                <p class="text-white m-auto font-bold text-2xl md:text-4xl lg:text-5xl xl:text-6xl uppercase">{{ $t('become-a-team-member') }}</p>
            </div>
        </div>
        <div class="mt-8 lg:mt-10 w-11/12 xl:w-8/12 mx-auto grid grid-cols-1 md:grid-cols-3 py-6">
            <div class="job-final hidden md:block lg:block xl:block"></div>
            <div class="md:col-span-2">
                <p class="md:text-lg text-black-20 lg:text-xl text-left px-8 mt-1 px-2 md:px-6 lg:px-8 xl:px-10">{{ $t('become-a-team-member-desc-1') }}</p>
                <p class="md:text-lg text-black-20 lg:text-xl text-left px-8 mt-5 px-2 md:px-6 lg:px-8 xl:px-10">{{ $t('become-a-team-member-desc-2') }}</p>
                <p class="md:text-lg text-black-20 lg:text-xl text-left px-8 mt-5 px-2 md:px-6 lg:px-8 xl:px-10">{{ $t('become-a-team-member-desc-3') }}</p>
                <p class="md:text-lg text-black-20 lg:text-xl text-left px-8 mt-5 px-2 md:px-6 lg:px-8 xl:px-10">{{ $t('become-a-team-member-desc-4') }}
                    <a href="mailto:office@kocovic.rs" class="hover:underline">office@kocovic.rs</a>
                </p>
            </div>

        </div>
    </div>
</template>

<script>
    import { mapGetters, mapMutations } from "vuex";
    export default {
        name: "BecomeTeamMember",
        metaInfo: {
            // Children can override the title.
            title: 'Fitness & Gym Kočović | Teretana Kočović Beograd',
            // Result: My Page Title ← My Site
            // If a child changes the title to "My Other Page Title",
            // it will become: My Other Page Title ← My Site
            titleTemplate: '%s ← Teretana Beograd',
            meta: [
              // {"http-equiv": 'Content-Type', content: 'text/html; charset=utf-8'},
              // {name: 'viewport', content: 'width=device-width, initial-scale=1'},
              {name: 'description', content: 'Teretana Beograd | Postani deo tima | Prijavite se za posao kod nas. Teretana Beograd. Fitness & Gym Kocovic'},
              {
                  name: 'keywords',
                  content: '' +
                      'fitnes Beograd, ' +
                      'fitnes Zemun, ' +
                      'fitnes Blokovi, ' +
                      'Fitnes Novi Beograd, ' +
                      'fitnes Vračar, ' +
                      'fitnes Banjica, ' +
                      'Fitnes Pionir, ' +
                      'Fitnes Ustanička, ' +
                      'teretana Beograd, ' +
                      'teretana Zemun, ' +
                      'teretana Blokovi, ' +
                      'teretana Vračar, ' +
                      'Teretana Pionir, ' +
                      'Teretana Ušće, ' +
                      'Teretana Banjica, ' +
                      'Les Mills Beograd, ' +
                      'grupni fitnes, ' +
                      'fitnes treninzi Beograd, ' +
                      'mršavljenje, ' +
                      'zatezanje, ' +
                      'oblikovanje tela, ' +
                      'joga zemun, ' +
                      'Joga Novi Beograd, ' +
                      'Joga Beograd, ' +
                      'boks, ' +
                      'trening snage, ' +
                      'Kardio, ' +
                      'Fitnes centar Beograd, ' +
                      'Gluteus, ' +
                      'Zadnjica, ' +
                      'Gubljenje kilograma/težine, ' +
                      'Celulit, ' +
                      'kardio treninzi, ' +
                      'gym belgrade'
              },
            ],
        },
        mounted() {
            this.setResolution();
        },
        computed: {
            ...mapGetters("shared", {
                resolution: "getResolution"
            })
        },
        methods: {
            ...mapMutations("shared", {
                setResolution: "setResolution"
            })
        }
    }
</script>