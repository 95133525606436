<template>
    <div class="w-full fixed bottom-0 bg-gradient-to-b from-black-50 to-black-90 shadow-2xl slideToTop flex justify-center items-center">
        <div class="w-11/12 lg:w-9/12 mx-auto h-full py-8 text-white">
            <p class="break-words">{{ $t('accept-cookies-1') }}</p>
            <p class="break-words">{{ $t('accept-cookies-2') }}</p>
            <button @click="$emit('acceptCookies')" class="bg-yellow-500 px-4 mt-3 cursor-pointer">OK</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "CookiesBanner"
}
</script>

<style scoped>

</style>