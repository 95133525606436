<template>
  <main>
    <h1
      class="text-4xl custom-green-text font-semibold text-white mx-2 md:mx-20 my-10 text-center md:text-center uppercase lg:text-6xl py-4"
    >
      {{ $t("questions-and-answers") }}
    </h1>
    <section
      class="flex flex-wrap justify-center md:justify-between lg:justify-between xl:justify-between gap-2 lg:gap-10 px-10 md:px-20"
    >
      <div
        v-for="(textQA, index) in textQA"
        :key="index"
        class="messageWrapper bg-white sm:w-2/3 md:w-2/5 lg:w-2/5 min-height xl:w-1/4"
      >
        <h2
          class="flex items-center p-4 custom-green-bg text-left font-semibold text-white uppercase heading-min-height lg:xl-heading-min-height"
        >
          {{ $t(textQA.heading) }}
        </h2>
        <p class="text-left font-semibold p-4">
          {{ $t(textQA.beforeSpanDescription) }}

          <span class="custom-green-text font-semibold uppercase">{{
            $t(textQA.greenText)
          }}</span>
          {{ $t(textQA.afterSpanDescription) }}
        </p>
      </div>
    </section>
  </main>
</template>

<script>
import textQA from "./questionsAndAnswers.texts";

export default {
  name: "QuestionsAndAnswers",
  data() {
    return {
      textQA: textQA,
      width: null,
    };
  },
  mounted() {
    this.width = window.innerWidth;
    window.onresize = () => {
      this.width = window.innerWidth;
    };
  },
};
</script>

<style>
.messageWrapper {
  clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 75%,
    80% 75%,
    100% 91%,
    50% 75%,
    0% 75%
  );
}
.min-height {
  min-height: 27rem;
}
.heading-min-height {
  min-height: 6rem;
}
.custom-green-bg {
  background-color: #00c54f;
}
.custom-green-text {
  color: #00c54f !important;
}
</style>
