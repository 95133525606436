<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="112" height="110" viewBox="0 0 230 230" style="enable-background:new 0 0 1000 1000;" xml:space="preserve">
    <g>
        <polygon class="st0" points="31.68,49.28 24.11,72.23 32.4,100.12 18.74,100.12 13.4,78.33 13.4,100.12 0.17,100.12 0.17,49.28
            13.4,49.28 13.4,69.04 19.27,49.28 	"/>
        <path class="st0" d="M50.52,62.35c0-2.37-0.13-3.88-0.4-4.55c-0.26-0.65-0.79-0.99-1.61-0.99c-0.7,0-1.22,0.27-1.59,0.8
            c-0.38,0.53-0.56,2.1-0.56,4.73v23.73c0,2.96,0.13,4.78,0.36,5.47c0.24,0.7,0.8,1.04,1.68,1.04c0.9,0,1.47-0.4,1.73-1.19
            c0.25-0.8,0.38-2.69,0.38-5.69V62.35z M63.74,79.05c0,5.11-0.12,8.73-0.35,10.85c-0.25,2.13-1,4.07-2.26,5.83
            c-1.26,1.76-2.98,3.11-5.13,4.05c-2.15,0.94-4.67,1.42-7.54,1.42c-2.72,0-5.17-0.45-7.33-1.34c-2.18-0.89-3.91-2.22-5.23-4
            c-1.32-1.78-2.1-3.72-2.36-5.81c-0.25-2.1-0.38-5.77-0.38-11v-8.69c0-5.11,0.12-8.73,0.36-10.86c0.24-2.12,0.99-4.06,2.26-5.82
            c1.26-1.76,2.97-3.11,5.13-4.05c2.15-0.94,4.66-1.42,7.54-1.42c2.72,0,5.17,0.45,7.33,1.34c2.17,0.89,3.91,2.22,5.23,4
            c1.32,1.78,2.1,3.72,2.35,5.81c0.26,2.1,0.38,5.76,0.38,11V79.05z"/>
        <polygon class="st0" points="84.18,41.03 87.68,36.92 96.19,36.92 88.61,45.78 79.76,45.78 71.68,36.92 80.48,36.92 	"/>
        <path class="st0" d="M99.54,71.45H86.33v-8.85c0-2.57-0.15-4.18-0.43-4.82c-0.28-0.63-0.9-0.95-1.87-0.95
            c-1.09,0-1.78,0.39-2.08,1.16c-0.29,0.78-0.44,2.45-0.44,5.03v23.62c0,2.48,0.15,4.08,0.44,4.83c0.3,0.76,0.95,1.14,1.98,1.14
            c0.99,0,1.63-0.37,1.93-1.14c0.3-0.75,0.46-2.52,0.46-5.31v-6.37h13.22v1.97c0,5.25-0.37,8.98-1.11,11.18
            c-0.74,2.2-2.39,4.13-4.93,5.78c-2.54,1.65-5.68,2.49-9.4,2.49c-3.88,0-7.07-0.71-9.58-2.11c-2.51-1.4-4.18-3.34-4.99-5.82
            c-0.81-2.49-1.22-6.22-1.22-11.2V67.21c0-3.67,0.13-6.41,0.37-8.24c0.26-1.83,1-3.6,2.25-5.3c1.24-1.69,2.97-3.03,5.18-4.01
            c2.21-0.96,4.75-1.46,7.61-1.46c3.9,0,7.12,0.76,9.65,2.26c2.53,1.51,4.19,3.39,5,5.64c0.79,2.25,1.19,5.76,1.19,10.51V71.45z"/>
        <path class="st0" d="M121.27,62.35c0-2.37-0.13-3.88-0.4-4.55c-0.26-0.65-0.79-0.99-1.61-0.99c-0.7,0-1.22,0.27-1.59,0.8
            c-0.38,0.53-0.56,2.1-0.56,4.73v23.73c0,2.96,0.13,4.78,0.36,5.47c0.24,0.7,0.8,1.04,1.68,1.04c0.9,0,1.47-0.4,1.73-1.19
            c0.25-0.8,0.38-2.69,0.38-5.69V62.35z M134.49,79.05c0,5.11-0.12,8.73-0.35,10.85c-0.25,2.13-1,4.07-2.26,5.83
            c-1.26,1.76-2.98,3.11-5.13,4.05c-2.15,0.94-4.67,1.42-7.54,1.42c-2.72,0-5.17-0.45-7.33-1.34c-2.18-0.89-3.91-2.22-5.23-4
            c-1.32-1.78-2.1-3.72-2.36-5.81c-0.25-2.1-0.38-5.77-0.38-11v-8.69c0-5.11,0.12-8.73,0.36-10.86c0.24-2.12,0.99-4.06,2.26-5.82
            c1.26-1.76,2.97-3.11,5.13-4.05c2.15-0.94,4.66-1.42,7.54-1.42c2.72,0,5.17,0.45,7.33,1.34c2.17,0.89,3.91,2.22,5.23,4
            c1.32,1.78,2.1,3.72,2.35,5.81c0.26,2.1,0.38,5.76,0.38,11V79.05z"/>
        <path class="st0" d="M170.83,49.28l-6.72,50.84h-20.07l-7.63-50.84h13.95c1.61,14,2.75,25.84,3.45,35.51
            c0.69-9.78,1.42-18.46,2.17-26.06l0.91-9.45H170.83z"/>
        <path class="st0" d="M222.42,0.96v30.93h-7.03V11l-2.81,20.88h-4.99l-2.96-20.4v20.4h-7.03V0.96h10.41
            c0.31,1.86,0.63,4.05,0.97,6.57l1.11,7.87l1.83-14.44H222.42z M196.22,0.96l-5.86,19.73v11.2h-7.45v-11.2l-5.65-19.73h7.4
            c1.15,6.03,1.81,10.08,1.94,12.17c0.45-3.3,1.19-7.36,2.24-12.17H196.22z M175.97,12.34h-8.04V9.53c0-1.77-0.08-2.88-0.23-3.33
            c-0.16-0.44-0.52-0.67-1.09-0.67c-0.5,0-0.83,0.19-1.02,0.57c-0.18,0.38-0.27,1.36-0.27,2.94V23.9c0,1.39,0.09,2.3,0.27,2.75
            c0.18,0.44,0.53,0.66,1.07,0.66c0.59,0,0.98-0.25,1.2-0.74c0.21-0.5,0.31-1.47,0.31-2.91v-3.66h-1.62v-4.7h9.42v16.6h-5.06
            l-0.74-2.22c-0.55,0.96-1.24,1.67-2.07,2.15c-0.83,0.48-1.82,0.72-2.95,0.72c-1.35,0-2.62-0.33-3.79-0.98
            c-1.18-0.66-2.07-1.47-2.69-2.44c-0.61-0.97-0.99-1.98-1.15-3.05c-0.15-1.06-0.23-2.66-0.23-4.79v-9.19c0-2.95,0.16-5.1,0.48-6.44
            c0.32-1.34,1.23-2.57,2.75-3.68c1.51-1.12,3.46-1.68,5.86-1.68c2.35,0,4.31,0.48,5.86,1.45c1.55,0.97,2.57,2.12,3.04,3.45
            c0.47,1.33,0.7,3.26,0.7,5.8V12.34z M119.82,10.31h-7.47V8.02c0-1.07-0.1-1.75-0.29-2.05c-0.19-0.29-0.51-0.44-0.96-0.44
            c-0.48,0-0.85,0.2-1.1,0.59c-0.25,0.39-0.37,0.99-0.37,1.79c0,1.03,0.14,1.81,0.42,2.33c0.27,0.52,1.03,1.15,2.28,1.89
            c3.58,2.13,5.83,3.87,6.76,5.23c0.93,1.36,1.4,3.56,1.4,6.59c0,2.2-0.26,3.83-0.78,4.87c-0.52,1.04-1.51,1.92-2.99,2.63
            c-1.48,0.7-3.2,1.06-5.16,1.06c-2.15,0-3.99-0.41-5.51-1.23c-1.53-0.81-2.52-1.85-2.99-3.11c-0.47-1.27-0.7-3.05-0.7-5.37v-2.02
            h7.47v3.76c0,1.16,0.1,1.9,0.31,2.24c0.22,0.33,0.59,0.5,1.12,0.5c0.53,0,0.93-0.21,1.19-0.63c0.26-0.42,0.39-1.04,0.39-1.87
            c0-1.82-0.25-3.01-0.74-3.57c-0.51-0.56-1.77-1.49-3.76-2.8c-2-1.33-3.33-2.29-3.98-2.89c-0.65-0.6-1.19-1.43-1.61-2.48
            c-0.43-1.06-0.64-2.41-0.64-4.05c0-2.37,0.3-4.1,0.91-5.2c0.6-1.1,1.58-1.95,2.93-2.57c1.35-0.61,2.98-0.93,4.89-0.93
            c2.09,0,3.87,0.34,5.34,1.01c1.47,0.68,2.45,1.53,2.93,2.56c0.48,1.02,0.71,2.77,0.71,5.22V10.31z M99.59,10.31h-7.47V8.02
            c0-1.07-0.1-1.75-0.29-2.05c-0.19-0.29-0.51-0.44-0.96-0.44c-0.48,0-0.85,0.2-1.1,0.59c-0.25,0.39-0.37,0.99-0.37,1.79
            c0,1.03,0.14,1.81,0.42,2.33c0.27,0.52,1.03,1.15,2.28,1.89c3.58,2.13,5.83,3.87,6.76,5.23c0.93,1.36,1.4,3.56,1.4,6.59
            c0,2.2-0.26,3.83-0.78,4.87c-0.52,1.04-1.51,1.92-2.99,2.63c-1.48,0.7-3.2,1.06-5.16,1.06c-2.15,0-3.99-0.41-5.51-1.23
            c-1.53-0.81-2.52-1.85-2.99-3.11c-0.47-1.27-0.7-3.05-0.7-5.37v-2.02h7.47v3.76c0,1.16,0.1,1.9,0.31,2.24
            c0.22,0.33,0.59,0.5,1.12,0.5s0.93-0.21,1.19-0.63c0.26-0.42,0.39-1.04,0.39-1.87c0-1.82-0.25-3.01-0.74-3.57
            c-0.51-0.56-1.77-1.49-3.76-2.8c-2-1.33-3.33-2.29-3.98-2.89c-0.65-0.6-1.19-1.43-1.61-2.48c-0.43-1.06-0.64-2.41-0.64-4.05
            c0-2.37,0.3-4.1,0.91-5.2c0.6-1.1,1.58-1.95,2.93-2.57c1.35-0.61,2.98-0.93,4.89-0.93c2.09,0,3.87,0.34,5.34,1.01
            c1.47,0.68,2.45,1.53,2.93,2.56c0.48,1.02,0.71,2.77,0.71,5.22V10.31z M66.29,0.96H79.7v6.19h-5.37v5.86h5.02v5.89h-5.02v6.8h5.9
            v6.19H66.29V0.96z M63.06,0.96v30.93h-7.05l-4.19-14.06v14.06H45.1V0.96h6.72l4.51,13.93V0.96H63.06z M43.26,0.96v6.19h-4.77v24.74
            h-8.04V7.14h-4.76V0.96H43.26z M23.84,0.96v30.93h-8.05V0.96H23.84z M0.22,0.96h13.62v6.19H8.26v5.86h4.96v5.89H8.26v12.99H0.22
            V0.96z"/>
        <path class="st0" d="M3.36,43.9c0,0.55-0.15,0.97-0.43,1.24c-0.31,0.29-0.74,0.46-1.23,0.46c-0.49,0-0.92-0.17-1.23-0.46
            c-0.28-0.27-0.43-0.81-0.43-1.36v-0.51c0-0.11,0.1-0.21,0.48-0.21c0.38,0,0.48,0.1,0.48,0.21v0.79c0,0.34,0.21,0.73,0.71,0.73
            c0.52,0,0.71-0.39,0.71-0.73V43.3c0-0.7-0.35-1.14-0.62-1.47L0.5,40.22C0.07,39.68,0,39.17,0,38.8v-0.27
            c0-0.55,0.15-0.94,0.43-1.21c0.31-0.29,0.74-0.47,1.23-0.47c0.49,0,0.92,0.17,1.23,0.47c0.28,0.27,0.43,0.68,0.43,1.23v0.34
            c0,0.11-0.1,0.21-0.48,0.21c-0.38,0-0.47-0.08-0.47-0.19v-0.57c0-0.34-0.21-0.73-0.71-0.73c-0.52,0-0.71,0.39-0.71,0.73v0.53
            c0,0.37,0.14,0.68,0.6,1.26l1.29,1.59c0.28,0.34,0.52,0.89,0.52,1.59V43.9z"/>
        <path class="st0" d="M7.65,40.39c0,0.27-0.14,0.82-0.63,1.16c-0.31,0.21-0.77,0.39-1.37,0.39H5.5c-0.15,0-0.24,0.11-0.24,0.22
            l0.04,3.15c0,0.11-0.14,0.21-0.5,0.21c-0.36,0-0.46-0.1-0.46-0.21v-8.16c0-0.11,0.1-0.2,0.22-0.2h0.96c2.04,0,2.13,1.5,2.13,1.77
            V40.39z M6.73,38.64c0-0.73-0.84-0.87-0.97-0.87H5.48c-0.12,0-0.22,0.09-0.22,0.19v3c0,0.11,0.1,0.2,0.22,0.2h0.18
            c0.92,0,1.06-0.71,1.06-0.85V38.64z"/>
        <path class="st0" d="M11.83,43.9c0,0.55-0.15,0.97-0.43,1.24c-0.31,0.29-0.74,0.46-1.23,0.46c-0.49,0-0.94-0.17-1.25-0.46
            c-0.28-0.27-0.43-0.69-0.43-1.24v-5.34c0-0.55,0.15-0.97,0.43-1.23c0.31-0.29,0.76-0.47,1.25-0.47c0.49,0,0.92,0.17,1.23,0.47
            c0.28,0.27,0.43,0.68,0.43,1.23V43.9z M10.85,38.33c0-0.34-0.18-0.73-0.68-0.73c-0.52,0-0.7,0.39-0.7,0.73v5.72
            c0,0.34,0.18,0.74,0.7,0.74c0.5,0,0.68-0.39,0.68-0.74V38.33z"/>
        <path class="st0" d="M16.21,45.31c0,0.14-0.1,0.21-0.5,0.21c-0.39,0-0.48-0.1-0.48-0.21v-3.01c0-0.13,0.04-0.84-0.88-0.84h-0.32
            c-0.13,0-0.22,0.09-0.22,0.2v3.66c0,0.14-0.1,0.22-0.48,0.21c-0.35-0.01-0.45-0.1-0.45-0.21v-8.09c0-0.18,0.11-0.26,0.29-0.26h0.98
            c1.18,0,1.99,0.57,1.99,1.66v1.22c0,0.58-0.15,0.81-0.73,1.08c-0.11,0.05-0.14,0.12,0,0.16c0.6,0.15,0.73,0.66,0.74,1.09
            L16.21,45.31z M15.19,38.62c0-0.13,0-0.84-0.92-0.84h-0.24c-0.13,0-0.22,0.09-0.22,0.19v2.58c0,0.11,0.1,0.2,0.22,0.2h0.2
            c0.13,0,0.96-0.14,0.96-0.87V38.62z"/>
        <path class="st0" d="M19.62,37.74h-0.74c-0.13,0-0.22,0.09-0.22,0.19v7.39c0,0.11-0.1,0.21-0.49,0.21c-0.39,0-0.49-0.1-0.49-0.21
            v-7.39c0-0.11-0.1-0.19-0.22-0.19h-0.74c-0.13,0-0.24-0.09-0.24-0.38c0-0.31,0.11-0.39,0.24-0.39h2.91c0.13,0,0.25,0.09,0.24,0.4
            C19.84,37.65,19.75,37.74,19.62,37.74"/>
        <path class="st0" d="M23.48,43.9c0,0.55-0.15,0.97-0.43,1.24c-0.31,0.29-0.74,0.46-1.23,0.46c-0.49,0-0.92-0.17-1.23-0.46
            c-0.28-0.27-0.43-0.81-0.43-1.36v-0.51c0-0.11,0.1-0.21,0.48-0.21c0.38,0,0.48,0.1,0.48,0.21v0.79c0,0.34,0.21,0.73,0.71,0.73
            c0.52,0,0.71-0.39,0.71-0.73V43.3c0-0.7-0.35-1.14-0.62-1.47l-1.29-1.61c-0.43-0.54-0.5-1.05-0.5-1.42v-0.27
            c0-0.55,0.15-0.94,0.43-1.21c0.31-0.29,0.74-0.47,1.23-0.47c0.49,0,0.92,0.17,1.23,0.47c0.28,0.27,0.43,0.68,0.43,1.23v0.34
            c0,0.11-0.1,0.21-0.48,0.21c-0.38,0-0.48-0.08-0.48-0.19v-0.57c0-0.34-0.21-0.73-0.71-0.73c-0.52,0-0.71,0.39-0.71,0.73v0.53
            c0,0.37,0.14,0.68,0.6,1.26l1.29,1.59c0.28,0.34,0.52,0.89,0.52,1.59V43.9z"/>
        <path class="st0" d="M27.79,45.32c0,0.13-0.1,0.21-0.47,0.21c-0.42,0-0.5-0.1-0.5-0.21v-3.14c0-0.14,0.04-0.84-0.88-0.84H25.6
            c-0.13,0-0.22,0.09-0.22,0.19v3.79c0,0.13-0.1,0.21-0.46,0.21c-0.36,0-0.46-0.1-0.46-0.21v-8.2c0-0.11,0.1-0.2,0.45-0.21
            c0.38-0.01,0.48,0.1,0.48,0.21v3.29c0,0.11,0.1,0.2,0.22,0.2h0.15c0.18,0,0.81-0.11,0.81-0.87v-2.62c0-0.11,0.1-0.22,0.49-0.21
            c0.36,0.01,0.46,0.1,0.46,0.21v2.59c0,0.57-0.03,0.81-0.57,1.06c-0.11,0.05-0.1,0.12,0.04,0.16c0.6,0.15,0.73,0.67,0.74,1.1
            L27.79,45.32z"/>
        <path class="st0" d="M31.98,45.32c0.01,0.11-0.1,0.21-0.52,0.21c-0.45,0-0.53-0.1-0.55-0.21l-0.18-1.78
            c-0.01-0.21-0.12-0.2-0.22-0.2h-0.46c-0.13,0-0.22,0.06-0.24,0.18l-0.18,1.8c-0.01,0.11-0.1,0.21-0.53,0.21
            c-0.43,0-0.55-0.1-0.53-0.21l1.26-8.2c0.01-0.11,0.11-0.21,0.45-0.21c0.34,0,0.43,0.1,0.45,0.21L31.98,45.32z M30.36,39.73
            c-0.01-0.11,0-0.17-0.08-0.17c-0.07,0-0.08,0.06-0.1,0.17l-0.25,2.69c-0.01,0.11,0.07,0.17,0.2,0.17h0.28
            c0.13,0,0.21-0.06,0.2-0.17L30.36,39.73z"/>
        <path class="st0" d="M37.59,45.32c0.01,0.11-0.1,0.21-0.52,0.21c-0.45,0-0.53-0.1-0.55-0.21l-0.18-1.78
            c-0.01-0.21-0.12-0.2-0.22-0.2h-0.46c-0.13,0-0.22,0.06-0.24,0.18l-0.18,1.8c-0.01,0.11-0.1,0.21-0.53,0.21
            c-0.43,0-0.54-0.1-0.53-0.21l1.26-8.2c0.01-0.11,0.11-0.21,0.45-0.21c0.34,0,0.43,0.1,0.45,0.21L37.59,45.32z M35.97,39.73
            c-0.01-0.11,0-0.17-0.08-0.17c-0.07,0-0.08,0.06-0.1,0.17l-0.25,2.69c-0.01,0.11,0.07,0.17,0.2,0.17h0.28
            c0.13,0,0.21-0.06,0.2-0.17L35.97,39.73z"/>
        <path class="st0" d="M41.63,45.32c0,0.13-0.1,0.21-0.48,0.21c-0.42,0-0.5-0.1-0.5-0.21v-3.14c0-0.14,0.04-0.84-0.88-0.84h-0.32
            c-0.13,0-0.22,0.09-0.22,0.19v3.79c0,0.13-0.1,0.21-0.46,0.21c-0.36,0-0.46-0.1-0.46-0.21v-8.2c0-0.11,0.1-0.2,0.45-0.21
            c0.38-0.01,0.48,0.1,0.48,0.21v3.29c0,0.11,0.1,0.2,0.22,0.2h0.15c0.18,0,0.81-0.11,0.81-0.87v-2.62c0-0.11,0.1-0.22,0.49-0.21
            c0.36,0.01,0.46,0.1,0.46,0.21v2.59c0,0.57-0.03,0.81-0.57,1.06c-0.11,0.05-0.1,0.12,0.04,0.16c0.6,0.15,0.73,0.67,0.74,1.1
            L41.63,45.32z"/>
        <path class="st0" d="M45.82,45.32c0.01,0.11-0.1,0.21-0.52,0.21c-0.45,0-0.53-0.1-0.55-0.21l-0.18-1.78
            c-0.01-0.21-0.12-0.2-0.22-0.2H43.9c-0.13,0-0.22,0.06-0.24,0.18l-0.18,1.8c-0.01,0.11-0.1,0.21-0.53,0.21
            c-0.43,0-0.55-0.1-0.53-0.21l1.26-8.2c0.01-0.11,0.11-0.21,0.45-0.21c0.33,0,0.43,0.1,0.45,0.21L45.82,45.32z M44.2,39.73
            c-0.01-0.11,0-0.17-0.08-0.17c-0.07,0-0.08,0.06-0.1,0.17l-0.25,2.69c-0.01,0.11,0.07,0.17,0.2,0.17h0.28
            c0.12,0,0.21-0.06,0.2-0.17L44.2,39.73z"/>
        <path class="st0" d="M49.85,43.7c0,0.27-0.08,1.77-2.12,1.77h-0.96c-0.13,0-0.22-0.09-0.22-0.2v-8.12c0-0.11,0.1-0.2,0.22-0.2h1.08
            c1.18,0,2.01,0.57,2.01,1.66V43.7z M48.9,38.62c0-0.13-0.03-0.84-0.95-0.84h-0.28c-0.13,0-0.22,0.09-0.22,0.19v6.52
            c0,0.11,0.1,0.2,0.22,0.2h0.29c0.13,0,0.94-0.13,0.94-0.87V38.62z"/>
        <path class="st0" d="M53.67,45.48h-2.55c-0.13,0-0.22-0.09-0.22-0.2v-8.12c0-0.11,0.1-0.2,0.22-0.2h2.55
            c0.13,0,0.24,0.08,0.24,0.38c0,0.27-0.11,0.35-0.24,0.35H52.1c-0.17,0-0.22,0.09-0.22,0.19v2.53c0,0.11,0.06,0.19,0.22,0.19h0.85
            c0.13,0,0.24,0.09,0.24,0.38c0,0.27-0.11,0.35-0.24,0.35H52.1c-0.17,0-0.22,0.08-0.22,0.2v2.99c0,0.11,0.06,0.19,0.22,0.19h1.57
            c0.13,0,0.25,0.09,0.24,0.38C53.89,45.39,53.8,45.48,53.67,45.48"/>
        <path class="st0" d="M58.48,45.32c0,0.11-0.1,0.21-0.5,0.21c-0.41,0-0.5-0.1-0.5-0.21l0.06-5.18c0-0.12-0.06-0.18-0.13-0.18
            c-0.06,0-0.13,0.06-0.17,0.18l-0.46,1.33c-0.08,0.23-0.14,0.26-0.24,0.26c-0.1,0-0.17-0.06-0.24-0.27l-0.45-1.32
            c-0.04-0.12-0.11-0.17-0.17-0.17c-0.06,0-0.11,0.06-0.11,0.17l0.06,5.18c0,0.11-0.1,0.21-0.53,0.21c-0.41,0-0.5-0.1-0.5-0.21v-8.2
            c0-0.11,0.1-0.21,0.4-0.21c0.34,0,0.42,0.11,0.46,0.21l0.85,2.25c0.09,0.23,0.14,0.33,0.27,0.33c0.11,0,0.13-0.06,0.22-0.33
            l0.84-2.25c0.04-0.1,0.1-0.21,0.42-0.21c0.32,0,0.42,0.1,0.42,0.21V45.32z"/>
        <path class="st0" d="M60.69,45.32c0,0.11-0.1,0.22-0.5,0.21c-0.35-0.01-0.45-0.1-0.45-0.21v-8.2c0-0.11,0.1-0.21,0.47-0.21
            c0.38,0,0.48,0.1,0.48,0.21V45.32z"/>
        <path class="st0" d="M64.74,43.9c0,0.55-0.14,0.98-0.42,1.25c-0.31,0.29-0.71,0.45-1.2,0.45c-0.49,0-0.88-0.17-1.19-0.46
            c-0.28-0.27-0.4-0.68-0.4-1.24v-0.63c0-0.11,0.1-0.21,0.49-0.21c0.38,0,0.48,0.1,0.48,0.21v0.79c0,0.34,0.13,0.73,0.63,0.73
            c0.52,0,0.67-0.39,0.67-0.73v-6.94c0-0.11,0.1-0.21,0.47-0.21c0.38,0,0.48,0.1,0.48,0.21V43.9z"/>
        <path class="st0" d="M68.66,45.32c0.01,0.11-0.1,0.21-0.52,0.21c-0.45,0-0.53-0.1-0.55-0.21l-0.18-1.78
            c-0.01-0.21-0.13-0.2-0.22-0.2h-0.46c-0.13,0-0.22,0.06-0.24,0.18l-0.18,1.8c-0.01,0.11-0.1,0.21-0.53,0.21
            c-0.44,0-0.55-0.1-0.53-0.21l1.26-8.2c0.01-0.11,0.11-0.21,0.45-0.21c0.34,0,0.43,0.1,0.45,0.21L68.66,45.32z M67.04,39.73
            c-0.01-0.11,0-0.17-0.08-0.17c-0.07,0-0.08,0.06-0.1,0.17l-0.25,2.69c-0.01,0.11,0.07,0.17,0.2,0.17h0.28
            c0.13,0,0.21-0.06,0.2-0.17L67.04,39.73z"/>
        <path class="st0" d="M139.1,25.95l-2.49-5.11c-0.96,1.06-1.43,2.21-1.43,3.44c0,0.9,0.15,1.56,0.45,2.01
            c0.3,0.44,0.74,0.66,1.32,0.66C137.58,26.96,138.29,26.63,139.1,25.95 M137.38,11.28c0.86-1.39,1.29-2.72,1.29-3.99
            c0-0.54-0.12-1.05-0.38-1.5c-0.25-0.46-0.6-0.68-1.07-0.68c-0.42,0-0.73,0.17-0.95,0.51c-0.21,0.34-0.32,0.87-0.32,1.57
            C135.95,8.67,136.42,10.03,137.38,11.28z M149.24,17.93v6.4l-2.17,1.69l2.7,6.33h-7.11l-0.93-2.11c-2.39,1.94-4.65,2.91-6.78,2.91
            c-2.24,0-3.96-0.92-5.16-2.74c-1.21-1.83-1.81-4.03-1.81-6.61c0-2.06,0.36-3.68,1.07-4.86c0.71-1.17,1.77-2.12,3.2-2.86
            c-2.03-1.8-3.05-4.23-3.05-7.29c0-2.64,0.72-4.77,2.17-6.38c1.45-1.61,3.44-2.42,5.98-2.42c2.28,0,4.08,0.79,5.4,2.37
            c1.32,1.58,1.98,3.59,1.98,6.03c0,2.56-0.8,4.83-2.4,6.82l2.72,6.05L149.24,17.93z"/>
        <rect x="173.09" y="49.28" class="st0" width="13.23" height="50.84"/>
        <polygon class="st0" points="217.6,37.27 207.26,45.78 200.89,45.78 206.42,37.27 	"/>
        <path class="st0" d="M222.5,71.45h-13.22v-8.85c0-2.57-0.15-4.18-0.43-4.82c-0.28-0.63-0.9-0.95-1.87-0.95
            c-1.09,0-1.78,0.39-2.08,1.16c-0.29,0.78-0.44,2.45-0.44,5.03v23.62c0,2.48,0.15,4.08,0.44,4.83c0.3,0.76,0.95,1.14,1.98,1.14
            c0.99,0,1.63-0.37,1.93-1.14c0.3-0.75,0.46-2.52,0.46-5.31v-6.37h13.22v1.97c0,5.25-0.37,8.98-1.11,11.18
            c-0.74,2.2-2.39,4.13-4.93,5.78c-2.54,1.65-5.68,2.49-9.4,2.49c-3.88,0-7.07-0.71-9.58-2.11c-2.51-1.4-4.18-3.34-4.99-5.82
            c-0.81-2.49-1.22-6.22-1.22-11.2V67.21c0-3.67,0.13-6.41,0.37-8.24c0.26-1.83,1-3.6,2.25-5.3c1.24-1.69,2.97-3.03,5.18-4.01
            c2.21-0.96,4.75-1.46,7.61-1.46c3.9,0,7.12,0.76,9.65,2.26c2.53,1.51,4.19,3.39,5,5.64c0.79,2.25,1.19,5.76,1.19,10.51V71.45z"/>
    </g>
    </svg>
</template>

<script>
    export default {
        name: "LogoMobileGray"
    }
</script>

<style scoped>
	.st0{fill:#808080;}
</style>