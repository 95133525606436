import http from "@/config/http";
export default {
    namespaced: true,
    state: {
        blogs: [],
    },
    mutations: {
        setBlogs(state, data) {
            state.blogs = data;
        }
    },
    actions: {
        async fetchBlogs(context, host) {
            context.commit('loader/setLoading', true, { root: true });
            const { data } = await http.get('/blog');
            data.forEach((element) => {
                element.photo_url = host + "/" + element.photo_url;
            });
            data.sort((a, b) => (a.date_of_creation < b.date_of_creation ) ? 1 : -1);
            context.commit("setBlogs", data);
            context.commit('loader/setLoading', false, { root: true });
        },
    },
    getters: {
        getBlogs(state) {
          return state.blogs;
        },
    }
}