const navigator = [
  "galleries/navigator/1.jpg",
  "galleries/navigator/2.jpg",
  "galleries/navigator/3.jpg",
  "galleries/navigator/4.jpg",
  "galleries/navigator/5.jpg",
  "galleries/navigator/6.jpg",
  "galleries/navigator/7.jpg",
  "galleries/navigator/8.jpg",
  "galleries/navigator/9.jpg",
  "galleries/navigator/10.jpg",
  "galleries/navigator/11.jpg",
];
export default navigator;
