import http from "@/config/http";
export default {
    namespaced: true,
    state: {
        events: [],
        activeEvents: [],
        eventImageUrl: '',
        eventsImgList: [],
        imgIndex: 0,
        interval: null,
    },
    mutations: {
        setEvents(state, data) {
            state.events = data;
        },
        setEventImageUrl(state, data) {
            state.eventImageUrl = data;
        },
        setEventsImgList(state, data) {
            state.eventsImgList = data;
        },
        setActiveEvents(state, data) {
            state.activeEvents = data;
        },
        slide(state, operator) {
            if (operator === '-' && state.imgIndex > 0) {
                state.imgIndex--;
            } else if (operator === '+' && state.eventsImgList.length - 1 > state.imgIndex) {
                state.imgIndex++;
            } else if (state.imgIndex === 0 && operator === '-') {
                state.imgIndex = state.eventsImgList.length - 1;
            } else if (state.imgIndex === state.eventsImgList.length - 1) {
                state.imgIndex = 0;
            } else {
                return;
            }
        },
        setImgIndex(state, data) {
            state.imgIndex = data;
        },
        setInterval(state, data) {
            state.interval = data;
        }
    },
    actions: {
        async fetchEvents(context, host) {
            context.commit('loader/setLoading', true, { root: true });
            const { data } = await http.get('/event/home');
            data.forEach((element) => {
                element.photo_url = host + "/" + element.photo_url;
            });
            data.sort((a, b) => (a.date_of_creation < b.date_of_creation ) ? 1 : -1);
            context.commit("setEvents", data);
            context.dispatch("fetchActiveEvents", {data: data, host: host});
            context.commit('loader/setLoading', false, { root: true });
        },
        fetchActiveEvents(context, data) {
            const activeEvents = data.data;
            context.commit("setActiveEvents", activeEvents);
            let url;
            if (activeEvents.length === 1) {
                url = data.host + '/' + activeEvents[0].home_photo_url;
                context.commit("setEventImageUrl", url);
            } else if (activeEvents.length > 1) {
                url = data.host + '/' + activeEvents[0].home_photo_url;
                context.commit("setEventImageUrl", url);
                const eventsImgList = [];
                activeEvents.forEach((item, i) => {
                    eventsImgList.push(data.host + '/' + item.home_photo_url);
                });
                context.commit("setEventsImgList", eventsImgList);
                context.dispatch("slideShow");
            }
        },
        initHomeSlider(context) {
            context.state.eventsImgList.forEach((item, i) => {
                if (context.state.imgIndex === i) {
                    context.commit("setEventImageUrl", item);
                }
            })
        },
        slideShow(context) {
            context.commit("setInterval", null);
            context.state.interval = setInterval(() => {
                if (context.state.activeEvents.length - 1 === context.state.imgIndex) {
                    context.commit("setImgIndex", 0);
                } else {
                    context.commit("slide", "+");
                }
            }, 10000);
        },
    },
    getters: {
        getEvents(state) {
            return state.events;
        },
        getEventImageUrl(state) {
            return state.eventImageUrl;
        },
        getEventsImgList(state) {
            return state.eventsImgList;
        },
        getActiveEvents(state) {
            return state.activeEvents;
        },
        getImgIndex(state) {
            return state.imgIndex;
        }
    }
}