<template>
     <modal
            :name="modal_name"
            :resizable="false"
            :minHeight="320"
            :adaptive="true"
            :shiftY="0.4"
        >
             <div
                class="text-black-60 bg-black-10 overflow-auto flex m-auto border-4"
                v-if="training_data"
                style="max-height: 100%; height: 100%; max-width: 100%;"
            >
                <div class="h-full w-5/12 md:w-4/12 float-left hidden md:block lg:block xl:block sticky top-0">
                    <app-image
                        :height="'100%'"
                        :src="`${host}/${training_data.photo_url}`"
                        :width="'auto'"
                    />
                </div>
                <div class="w-12/12 md:w-7/12 mt-2 m-auto px-10 md:px-0 overflow-auto pb-16 xl:pb-6">
                    <div class="p-2 font-semibold text-lg pr-3 xl:pr-4">
                        <h1 v-if="$i18n.locale === 'sr'" class="uppercase">{{training_data.title}}</h1>
                        <h1 v-if="$i18n.locale === 'en'" class="uppercase">{{training_data.title_eng}}</h1>
                    </div>
                    <div class="text-left pr-3 xl:ml-5 xl:mt-4 xl:mb-10">
                        <p v-if="$i18n.locale === 'sr'" class="text-justify" v-html="training_data.desc"></p>
                        <p v-if="$i18n.locale === 'en'" class="text-justify" v-html="training_data.desc_eng"></p>
                    </div>


                    <div @mouseover="showDescription = true;" @mouseleave="showDescription = false;" class="w-full md:w-7/12 grid grid-cols-3 flex ml-auto absolute bottom-0 left-0 right-0 bg-black-10 text-cyan-10 h-12 mb-1 pl-5 md:pl-0 mr-5 md:mr-8">
                        <div class="grid grid-cols-1 relative">
                            <transition name="fade" mode="out-in" appear>
                                <span v-if="showDescription" class="">{{ $t('duration') }}</span>
                            </transition>
                            <span class="absolute inset-x-0 bottom-0">
                                <icon icon="clock" class="mr-1"></icon>
                            {{ training_data.duration }} min
                            </span>
                        </div>
                        <div class="grid grid-cols-1 relative">
                            <transition name="fade" mode="out-in" appear>
                                <span v-if="showDescription" class="ml-1">{{ $t('calories-up-to') }}</span>
                            </transition>
                            <span class="absolute inset-x-0 bottom-0">
                                <icon icon="tint-slash" class="mr-1"></icon>
                                {{ training_data.calories }} kcal
                            </span>
                        </div>
                        <div class="grid grid-cols-1 relative">
                            <transition name="fade" mode="out-in" appear>
                                <span v-if="showDescription" class="mr-1">{{ $t('intensity') }}</span>
                            </transition>
                            <span class="absolute inset-x-0 bottom-0">
                                <icon :icon="['fas', 'tint']" v-for="(num, i) in training_data.intensity" class="mr-1" :key="`star${i}`"></icon>
                                <icon :icon="['fas', 'tint']" v-for="(num, i) in training_data.empty_stars" class="text-black-20 mr-1" :key="`emptyStar${i}`"></icon>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
      </modal>
</template>

<script>
export default {
    name: "ScheduleTrainingModal",
    props: {
        training_data: {
            type: Object,
            required: true
        },
        modal_name: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            host: process.env.VUE_APP_API_URL,
            showDescription: false,
        }
    }
}
</script>