<template>
  <div class="w-12/12 h-full bg-black-80 h-64">
    <main-message></main-message>
    <div
      class="w-12/12 md:w-11/12 lg:10/12 xl:w-6/12 h-full md:h-full bg-gradient-to-b from-black-70 via-black-30 to-black-70 mx-auto my-8 overflow-hidden select-none"
    >
      <div class="m-auto w-9/12 md:h-650 pb-5 flex">
        <p
          class="m-auto text-white tracking-wide text-xl text-center leading-20"
        >
          <button
            @click="downloadAPK"
            class="bg-green-20 hover:bg-green-30 text-black-80 text-btn py-2 px-5 mt-3 uppercase"
          >
            {{ $t("download-mobile-app") }}
          </button>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import MainMessage from "../components/MainMessage";
import SuccessfullySentFormModal from "@/components/SuccessfullySentFormModal";
import { downloadFile, handleLinkDownload } from "../utils/fileDownload";
export default {
  name: "ApplicationPage",
  components: { MainMessage, SuccessfullySentFormModal },
  data() {
    return {
      tab_index: 0,
      trainings: [],
    };
  },

  mounted() {},

  methods: {
    async downloadAPK() {
      const { data } = await this.$http.get(`/app/apk/download`, {
        responseType: "blob",
      });
      handleLinkDownload(data, "kocovic-app");
    },
  },
};
</script>