<template>
    <div class="w-12/12 overflow-x-hidden mb-20">
        <div
            :class="resolution > 1023 ? 'pic6' : 'pic6mobile'"
            class="w-12/12 h-350 md:h-400 lg:h-500 xl:h-500 sticky overflow-x-hidden flex"
         >
            <div class="xl:w-2/3 mx-auto flex m-auto">
                <p class="text-white m-auto font-bold text-2xl md:text-4xl lg:text-5xl xl:text-6xl uppercase">
                    {{ $t('gym-1') }}
                </p>
            </div>
        </div>
        <span id="personal"></span>
        <div class="w-12/12 xl:w-8/12 xl:mx-auto grid grid-cols-4 mt-12 md:mt-16 lg:mt-20">
            <div class="pic20 hidden md:hidden lg:hidden xl:block"></div>
            <div class="col-span-4 xl:col-span-3 flex">
                <div class="mx-5 md:mx-8 lg:mx-10 xl:mx-auto text-black-20 p-3 pb-10 border-b border-black-20 xl:border-none">
                    <h1 class="font-bold text-lg md:text-xl xl:text-2xl text-center mx-8 tracking-wide uppercase">
                        {{ $t('personal-training') }}
                    </h1>
                    <p class=" md:text-base lg:text-sxl text-center px-8 mt-1 px-2 md:px-6 lg:px-8 xl:px-10 mt-10">
                        {{ $t('personal-training-desc-1') }}
                    </p>
                    <p class=" md:text-base lg:text-sxl text-center px-8 mt-1 px-2 md:px-6 lg:px-8 xl:px-10 mt-6">
                        {{ $t('personal-training-desc-2') }}
                    </p>
                    <p class=" md:text-base lg:text-sxl text-center px-8 mt-1 px-2 md:px-6 lg:px-8 xl:px-10 mt-6">
                        {{ $t('personal-training-desc-3') }}
                    </p>
                </div>
            </div>
        </div>
        <span id="individual"></span> <!--  placeholder for anchor-->
        <div class="w-12/12 xl:w-8/12 xl:mx-auto grid grid-cols-4 mt-12 md:mt-16 lg:mt-20">
            <div class="pic21 hidden md:hidden lg:hidden xl:block"></div>
            <div class="col-span-4 xl:col-span-3 flex">
                <div class="mx-5 md:mx-8 lg:mx-10 xl:mx-auto text-black-20 p-3 pb-10 border-b border-black-20 xl:border-none">
                    <h1 class="font-bold text-lg md:text-xl xl:text-2xl text-center mx-8 tracking-wide uppercase">
                        {{ $t('individual-training') }}
                    </h1>
                    <p class=" md:text-base lg:text-sxl text-center px-8 mt-1 px-2 md:px-6 lg:px-8 xl:px-10 mt-10">
                        {{ $t('individual-training-desc-1') }}
                    </p>
                    <p class=" md:text-base lg:text-sxl text-center px-8 mt-1 px-2 md:px-6 lg:px-8 xl:px-10 mt-6">
                        {{ $t('individual-training-desc-2') }}
                    </p>
                    <p class=" md:text-base lg:text-sxl text-center px-8 mt-1 px-2 md:px-6 lg:px-8 xl:px-10 mt-6">
                        {{ $t('individual-training-desc-3') }}
                    </p>
                </div>
            </div>
        </div>
        <span id="small"></span> <!--  placeholder for anchor-->
        <div class="w-12/12 xl:w-8/12 xl:mx-auto grid grid-cols-4 mt-12 md:mt-16 lg:mt-20">
            <div class="pic22 hidden md:hidden lg:hidden xl:block"></div>
            <div class="col-span-4 xl:col-span-3 flex">
                <div class="mx-5 md:mx-8 lg:mx-10 xl:mx-auto text-black-20 p-3 pb-10">
                    <h1 class="font-bold text-lg md:text-xl xl:text-2xl text-center mx-8 tracking-wide uppercase">
                        {{ $t('small-group-training') }}
                    </h1>
                    <p class=" md:text-base lg:text-sxl text-center px-8 mt-1 px-2 md:px-6 lg:px-8 xl:px-10 mt-10">
                        {{ $t('small-group-training-desc-1') }}
                    </p>
                    <p class=" md:text-base lg:text-sxl text-center px-8 mt-1 px-2 md:px-6 lg:px-8 xl:px-10 mt-6">
                        {{ $t('small-group-training-desc-2') }}
                    </p>
                    <p class=" md:text-base lg:text-sxl text-center px-8 mt-1 px-2 md:px-6 lg:px-8 xl:px-10 mt-6">
                        {{ $t('small-group-training-desc-3') }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapMutations} from "vuex";
    export default {
        name: "GymServices",
        metaInfo: {
            // Children can override the title.
            title: 'Fitness & Gym Kočović | Teretana Kočović Beograd',
            titleTemplate: '%s ← Teretana Beograd',
            meta: [
              {name: 'description', content: 'Teretana Kočović Vam nudi sve na jednom mestu. Izaberite trening po Vašim potrebama i prijavite se odmah! | Teretana Beograd | Usluge'},
              {
                  name: 'keywords',
                  content: '' +
                      'Usluge, ' +
                      'fitnes Beograd, ' +
                      'fitnes Zemun, ' +
                      'fitnes Blokovi, ' +
                      'Fitnes Novi Beograd, ' +
                      'fitnes Vračar, ' +
                      'fitnes Banjica, ' +
                      'Fitnes Pionir, ' +
                      'Fitnes Ustanička, ' +
                      'teretana Beograd, ' +
                      'teretana Zemun, ' +
                      'Teretana Ušće, ' +
                      'Teretana Banjica, ' +
                      'Les Mills Beograd, ' +
                      'grupni fitnes, ' +
                      'fitnes treninzi Beograd, ' +
                      'mršavljenje, ' +
                      'zatezanje, ' +
                      'trening snage, ' +
                      'Kardio, ' +
                      'Fitnes centar Beograd, ' +
                      'Gluteus, ' +
                      'Zadnjica, ' +
                      'Gubljenje kilograma/težine, ' +
                      'Celulit, ' +
                      'kardio treninzi, ' +
                      'gym belgrade'
              },
            ],
        },
        data() {
            return {
                anchor: null,
            }
        },
        mounted() {
            this.setResolution();
            this.setAnchor(this.$route.hash);
        },
        computed: {
            ...mapGetters("shared", {
                resolution: "getResolution"
            })
        },
        methods: {
            ...mapMutations("shared", {
                setResolution: "setResolution"
            }),
            setAnchor(hash) {
                this.anchor = hash;
                this.$nextTick(() => {
                    if (this.anchor && document.querySelector(this.anchor)) {
                        location.href = this.anchor;
                    }
                });
            },
        },
        watch: {
            '$route.hash'(val) {
                this.setAnchor(val);
            }
        }
    }
</script>