<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="50" height="60" viewBox="0 0 100 130" style="enable-background:new 0 0 1000 1000;" xml:space="preserve">

        <g>
            <g>
                <g>
                    <path class="st0" d="M98.41,72.08L60.18,5.88C58.06,2.2,54.25,0,50,0c-4.25,0-8.06,2.2-10.18,5.88L1.59,72.08
                        c-2.13,3.68-2.13,8.08,0,11.76c2.13,3.68,5.93,5.88,10.18,5.88h76.44c4.25,0,8.06-2.2,10.18-5.88
                        C100.53,80.16,100.53,75.76,98.41,72.08z M93.33,80.91c-1.07,1.85-2.98,2.95-5.11,2.95H11.78c-2.13,0-4.04-1.1-5.11-2.95
                        c-1.07-1.85-1.07-4.05,0-5.9l38.22-66.2c1.07-1.85,2.98-2.95,5.11-2.95c2.13,0,4.04,1.1,5.11,2.95l38.22,66.2
                        C94.4,76.86,94.4,79.06,93.33,80.91z"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="47.07" y="29.24" class="st0" width="5.86" height="29.29"/>
                </g>
            </g>
            <g>
                <g>
                    <path class="st0" d="M50,64.39c-2.15,0-3.91,1.75-3.91,3.91S47.85,72.2,50,72.2c2.15,0,3.91-1.75,3.91-3.91S52.15,64.39,50,64.39
                        z"/>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "WarningMobileIcon"
    }
</script>

<style scoped>
	.st0{fill:#FFFFFF;}
</style>