import http from "@/config/http";

export default {
    namespaced: true,
    state: {
        videos: [],
    },
    mutations: {
        setVideos(state, data) {
            state.videos = data;
        }
    },
    actions: {
        async fetchVideos(context) {
            context.commit('loader/setLoading', true, { root: true });
            await http.get('/user/group-code')
                .then((resp) => {
                    if (resp.data && resp.data.length < 1) {
                        context.commit('shared/setModal', {name: 'enter_code_modal', show: true}, { root: true });
                    }
                    context.commit("setVideos", resp.data);
                    context.commit('loader/setLoading', false, { root: true });
                })
                .catch((err) => {
                    context.commit('loader/setLoading', false, { root: true });
                    context.commit('shared/setErrorMessage', err, { root: true });
                });
        },
        async setCode(context, code) {
            context.commit('loader/setLoading', true, { root: true });
            await http.post('/user/group-code', {code: code})
                .then((resp) => {
                    context.commit('shared/setSuccessMessage', 'SUC_YOUR_CODE_IS_CORRECT', { root: true });
                    context.commit('shared/setModal', {name: 'enter_code_modal', show: false}, { root: true });
                    context.dispatch("fetchVideos");
                    context.commit('loader/setLoading', false, { root: true });
                })
                .catch((err) => {
                    context.commit('loader/setLoading', false, { root: true });
                    if (err.response.data.error === 'ERR_USER_ALREADY_IN_GROUP') {
                        context.commit('shared/setModal', {name: 'enter_code_modal', show: false}, { root: true });
                    } else if (err.response.data.error === 'ERR_BAD_GROUP_CODE') {
                        context.commit('shared/setModal', {name: 'enter_code_modal', show: true}, { root: true });
                    }
                    context.commit('shared/setErrorMessage', err, { root: true });
                });
        },
        async setTermsAndConditions(context, data) {
            context.commit('loader/setLoading', true, { root: true });
            await http.patch('/user', {terms_and_conditions: data})
                .then((resp) => {
                    context.commit('loader/setLoading', false, { root: true });
                    context.dispatch("auth/fetchMe", null, {root: true});
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                });
        },
    },
    getters: {
        getVideos(state) {
            return state.videos;
        },
    }
}
