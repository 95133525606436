<template>
    <div class="h-full mb-8">

        <div class="bg-black-90 h-24 md:h-40 lg:h-48 xl:h-56 flex sm:w-screen md:w-screen">
            <div class="flex mx-auto w-3/4 xl:h-56">
                <p class="text-white font-semibold m-auto font-thin text-2xl md:text-3xl lg:text-4xl xl:text-4xl">
                    {{ $t('events') }}
                </p>
            </div>
        </div>

        <!-- mobile -->
        <div
            class="block md:hidden text-white  text-xl md:text-2xl lg:text-2xl xl:text-2xl "
        >
            <div
                class="h-475 rounded flex show-slowly"
                v-for="event in events"
                :key="event.id"
                @click="$router.push(`/events/${event.id}`)"
            >
                <div class="mx-auto flex flex-col border rounded w-101 mt-8">
                    <div class="h-100 flex items-center bg-gradient-to-r from-black-90 via-black-70 to-black-50">
                        <p  v-if="$i18n.locale === 'sr'"
                            class="w-full text-center rounded text-black-20 text-lg md:text-xl xl:xl uppercase tracking-tight">
                            {{ event.header}}</p>
                        <p  v-if="$i18n.locale === 'en'"
                            class="w-full text-center rounded p-2 text-black-20 text-lg md:text-xl xl:xl mb-1 uppercase tracking-tight">
                            {{ event.header_eng}}</p>
                    </div>
                    <div class="rounded h-325">
                        <app-image
                            :height="'100%'"
                            :src="event.photo_url"
                            :width="'100%'"
                        />
                    </div>
                    <div class="h-50 bg-gradient-to-r from-black-90 via-black-70 to-black-50">
                        <div class="flex items-center  h-full w-full text-black-20 " >
                            <p class="w-full text-right pr-8 text-lg md:text-xl lg:text-xl xl:text-xl">{{ event.date_of_creation | moment('DD.MM.YYYY') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- desktop -->
        <div
            class="hidden md:block text-white my-16 text-xl md:text-2xl lg:text-2xl xl:text-2xl mx-auto w-12/12 sm:w-12/12 md:w-12/12 xl:w-9/12 lg:w-10/12"
        >
            <div
                class=" h-330 text-black-70  rounded border border-black-30 flex my-10
				cursor-pointer transform xl:hover:scale-105 transition duration-200 ease-in-out show-slowly"
                v-for="event in events"
                :key="event.id"
                @click="$router.push(`/events/${event.id}`)"
            >
                <!-- image -->
                <div class="flex-none rounded-lg h-full w-101 hidden md:block lg:block xl:block">
                    <app-image
                        :height="'100%'"
                        :src="event.photo_url"
                        :width="'100%'"
                    />
                </div>
                <!-- blog text -->
                <div  class="text-xl flex-1 h-full shadow-2xl">
                    <div class="text-xl flex flex-col h-full w-full bg-gradient-to-b from-black via-black-30 to-black-30 text-black-10">
                        <div class=" h-50  flex flex-row w-full font-bold">
                            <p  v-if="$i18n.locale === 'sr'"
                                class="w-full text-center rounded-r p-2 text-lg md:text-xl xl:xl mb-1 uppercase tracking-tight">
                                {{ event.header}}</p>
                            <p  v-if="$i18n.locale === 'en'"
                                class="w-full text-center rounded-r p-2 text-lg md:text-xl xl:xl mb-1 uppercase tracking-tight">
                                {{ event.header_eng}}</p>
                        </div>
                        <div class="flex-1 w-full p-4 pb-0 md:pb-0 xl:pb-2 text-lg md:text-xl lg:text-lg xl:text-lg">
                            <div v-if="$i18n.locale === 'sr'" class="h-full text-justify overflow-hidden" v-html="extractContent(event.text)" >
                            </div>
                            <div v-if="$i18n.locale === 'en'" class="h-full text-justify overflow-hidden" v-html="extractContent(event.text_eng)">
                            </div>
                        </div>
                        <div class="h-50 flex flex-row w-full">
                            <div class="flex items-center  h-full w-full inline-block align-middle " >
                                <p class="w-full text-right pr-8 text-lg md:text-xl lg:text-xl xl:text-xl">{{ event.date_of_creation | moment('DD.MM.YYYY') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    name: "Events",
    metaInfo: {
        // Children can override the title.
        title: 'Fitness & Gym Kočović | Teretana Kočović Beograd',
        titleTemplate: '%s ← Teretana Beograd',
        meta: [
            {name: 'description', content: 'Teretana Kočović Vam nudi sve na jednom mestu. Izaberite trening po Vašim potrebama i prijavite se odmah! | Teretana Beograd | Aktuelnosti | Novosti'},
            {
                name: 'keywords',
                content: '' +
                    'aktuelnosti, ' +
                    'sport aktuelnosti, ' +
                    'teretana aktuelnosti, ' +
                    'kocovic aktuelnosti, ' +
                    'fitnes Beograd, ' +
                    'fitnes Zemun, ' +
                    'fitnes Blokovi, ' +
                    'Fitnes Novi Beograd, ' +
                    'fitnes Vračar, ' +
                    'fitnes Banjica, ' +
                    'Fitnes Pionir, ' +
                    'Fitnes Ustanička, ' +
                    'teretana Beograd, ' +
                    'teretana Zemun, ' +
                    'teretana Blokovi, ' +
                    'teretana Vračar, ' +
                    'Teretana Pionir, ' +
                    'Teretana Ušće, ' +
                    'Teretana Banjica, ' +
                    'Les Mills Beograd, ' +
                    'grupni fitnes, ' +
                    'fitnes treninzi Beograd, ' +
                    'mršavljenje, ' +
                    'zatezanje, ' +
                    'oblikovanje tela, ' +
                    'joga zemun, ' +
                    'Joga Novi Beograd, ' +
                    'Joga Beograd, ' +
                    'boks, ' +
                    'trening snage, ' +
                    'Kardio, ' +
                    'Fitnes centar Beograd, ' +
                    'Gluteus, ' +
                    'Zadnjica, ' +
                    'Gubljenje kilograma/težine, ' +
                    'Celulit, ' +
                    'kardio treninzi, ' +
                    'gym belgrade'
            },
        ],
    },
    data() {
        return {
            host: process.env.VUE_APP_API_URL,
        }
    },
    mounted() {
        this.fetchEvents(this.host);
    },
    computed: {
        ...mapGetters("events", {
            events: "getEvents"
        }),
    },
    methods: {
        ...mapActions("events", {
            fetchEvents: "fetchEvents"
        }),
        extractContent(s) {
            s = s.substring(0,350);
            return s + ".....";
        },

    }
};
</script>