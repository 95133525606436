const usce_thumbnails = [
  "galleries/usce_thumbnails/1_thumb.jpg",
  "galleries/usce_thumbnails/2_thumb.jpg",
  "galleries/usce_thumbnails/3_thumb.jpg",
  "galleries/usce_thumbnails/4_thumb.jpg",
  "galleries/usce_thumbnails/5_thumb.jpg",
  "galleries/usce_thumbnails/6_thumb.jpg",
  "galleries/usce_thumbnails/7_thumb.jpg",
  "galleries/usce_thumbnails/8_thumb.jpg",
  "galleries/usce_thumbnails/9_thumb.jpg",
  "galleries/usce_thumbnails/10_thumb.jpg",
  "galleries/usce_thumbnails/11_thumb.jpg",
  "galleries/usce_thumbnails/12_thumb.jpg",
  "galleries/usce_thumbnails/13_thumb.jpg",
  "galleries/usce_thumbnails/14_thumb.jpg",
  "galleries/usce_thumbnails/15_thumb.jpg",
];
export default usce_thumbnails;
