const textQA = [
  {
    heading: "trial-training-free",
    beforeSpanDescription: "trial-training-before",
    greenText: "trial-training-green",
    afterSpanDescription: "trial-training-after",
  },
  {
    heading: "are-we-obligated-to-pay-heading",
    beforeSpanDescription: "are-we-obligated-to-pay-before",
    greenText: "are-we-obligated-to-pay-green",
    afterSpanDescription: "are-we-obligated-to-pay-after",
  },
  {
    heading: "required-equipment-heading",
    beforeSpanDescription: "required-equipment-before",
    greenText: "",
    afterSpanDescription: "",
  },
  {
    heading: "group-terms-heading",
    beforeSpanDescription: "group-terms-before",
    greenText: "group-terms-green",
    afterSpanDescription: "group-terms-after",
  },
  {
    heading: "never-practice-before-heading",
    beforeSpanDescription: "never-practice-before-before",
    greenText: "never-practice-before-green",
    afterSpanDescription: "never-practice-before-after",
  },
  {
    heading: "changing-rooms-heading",
    beforeSpanDescription: "changing-rooms-before",
    greenText: "",
    afterSpanDescription: "",
  },
  {
    heading: "do-you-have-parking-heading",
    beforeSpanDescription: "do-you-have-parking-before",
    greenText: "",
    afterSpanDescription: "",
  },
];

export default textQA;
