<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="120" height="120" viewBox="8 0 70 70" style="enable-background:new 0 0 100 100;" xml:space="preserve">
    <g>
        <polygon class="st0" points="11.45,17.81 8.71,26.11 11.71,36.19 6.77,36.19 4.84,28.31 4.84,36.19 0.06,36.19 0.06,17.81
            4.84,17.81 4.84,24.95 6.96,17.81 	"/>
        <path class="st0" d="M18.26,22.54c0-0.86-0.05-1.4-0.14-1.64c-0.09-0.24-0.29-0.36-0.58-0.36c-0.25,0-0.44,0.1-0.57,0.29
            c-0.14,0.19-0.2,0.76-0.2,1.71v8.58c0,1.07,0.05,1.73,0.13,1.98c0.09,0.25,0.29,0.38,0.61,0.38c0.33,0,0.53-0.14,0.62-0.43
            c0.09-0.29,0.14-0.97,0.14-2.06V22.54z M23.04,28.57c0,1.85-0.04,3.15-0.13,3.92c-0.09,0.77-0.36,1.47-0.82,2.11
            c-0.46,0.64-1.08,1.12-1.86,1.46c-0.78,0.34-1.69,0.51-2.72,0.51c-0.98,0-1.87-0.16-2.65-0.48c-0.79-0.32-1.41-0.8-1.89-1.45
            c-0.48-0.64-0.76-1.34-0.85-2.1c-0.09-0.76-0.14-2.08-0.14-3.98v-3.14c0-1.85,0.04-3.15,0.13-3.92c0.09-0.77,0.36-1.47,0.82-2.1
            c0.46-0.64,1.07-1.12,1.86-1.46c0.78-0.34,1.69-0.51,2.72-0.51c0.98,0,1.87,0.16,2.65,0.48c0.78,0.32,1.41,0.8,1.89,1.45
            c0.48,0.64,0.76,1.34,0.85,2.1c0.09,0.76,0.14,2.08,0.14,3.98V28.57z"/>
        <polygon class="st0" points="30.43,14.83 31.69,13.34 34.77,13.34 32.03,16.55 28.83,16.55 25.91,13.34 29.09,13.34 	"/>
        <path class="st0" d="M35.98,25.82H31.2v-3.2c0-0.93-0.05-1.51-0.16-1.74c-0.1-0.23-0.33-0.34-0.67-0.34c-0.4,0-0.64,0.14-0.75,0.42
            c-0.1,0.28-0.16,0.89-0.16,1.82v8.54c0,0.9,0.05,1.48,0.16,1.75c0.11,0.28,0.34,0.41,0.72,0.41c0.36,0,0.59-0.14,0.7-0.41
            c0.11-0.27,0.17-0.91,0.17-1.92v-2.3h4.78v0.71c0,1.9-0.14,3.25-0.4,4.04c-0.27,0.79-0.86,1.49-1.78,2.09
            c-0.92,0.6-2.05,0.9-3.4,0.9c-1.4,0-2.56-0.26-3.46-0.76c-0.91-0.51-1.51-1.21-1.81-2.1c-0.29-0.9-0.44-2.25-0.44-4.05v-5.37
            c0-1.33,0.05-2.32,0.14-2.98c0.09-0.66,0.36-1.3,0.81-1.91c0.45-0.61,1.07-1.1,1.87-1.45c0.8-0.35,1.72-0.53,2.75-0.53
            c1.41,0,2.57,0.28,3.49,0.82c0.91,0.55,1.51,1.22,1.81,2.04c0.29,0.81,0.43,2.08,0.43,3.8V25.82z"/>
        <path class="st0" d="M43.83,22.54c0-0.86-0.05-1.4-0.14-1.64c-0.09-0.24-0.29-0.36-0.58-0.36c-0.25,0-0.44,0.1-0.57,0.29
            c-0.14,0.19-0.2,0.76-0.2,1.71v8.58c0,1.07,0.05,1.73,0.13,1.98c0.09,0.25,0.29,0.38,0.61,0.38c0.33,0,0.53-0.14,0.62-0.43
            c0.09-0.29,0.14-0.97,0.14-2.06V22.54z M48.61,28.57c0,1.85-0.04,3.15-0.13,3.92c-0.09,0.77-0.36,1.47-0.82,2.11
            c-0.46,0.64-1.08,1.12-1.86,1.46c-0.78,0.34-1.69,0.51-2.72,0.51c-0.98,0-1.87-0.16-2.65-0.48c-0.79-0.32-1.41-0.8-1.89-1.45
            c-0.48-0.64-0.76-1.34-0.85-2.1c-0.09-0.76-0.14-2.08-0.14-3.98v-3.14c0-1.85,0.04-3.15,0.13-3.92c0.09-0.77,0.36-1.47,0.82-2.1
            c0.46-0.64,1.07-1.12,1.86-1.46c0.78-0.34,1.69-0.51,2.72-0.51c0.98,0,1.87,0.16,2.65,0.48c0.78,0.32,1.41,0.8,1.89,1.45
            c0.48,0.64,0.76,1.34,0.85,2.1c0.09,0.76,0.14,2.08,0.14,3.98V28.57z"/>
        <path class="st0" d="M61.74,17.81l-2.43,18.38h-7.25L49.3,17.81h5.04c0.58,5.06,1,9.34,1.25,12.84c0.25-3.53,0.51-6.67,0.78-9.42
            l0.33-3.42H61.74z"/>
        <path class="st1" d="M80.39,0.35v11.18h-2.54V3.98l-1.02,7.55h-1.8l-1.07-7.38v7.38h-2.54V0.35h3.76c0.11,0.67,0.23,1.46,0.35,2.38
            l0.4,2.84l0.66-5.22H80.39z M70.92,0.35L68.8,7.48v4.05h-2.69V7.48l-2.04-7.13h2.67c0.42,2.18,0.65,3.64,0.7,4.4
            c0.16-1.19,0.43-2.66,0.81-4.4H70.92z M63.6,4.46H60.7V3.44c0-0.64-0.03-1.04-0.08-1.2C60.56,2.08,60.43,2,60.22,2
            c-0.18,0-0.3,0.07-0.37,0.21c-0.06,0.14-0.1,0.49-0.1,1.06v5.36c0,0.5,0.03,0.83,0.1,0.99c0.07,0.16,0.19,0.24,0.39,0.24
            c0.21,0,0.36-0.09,0.43-0.27c0.08-0.18,0.11-0.53,0.11-1.05V7.22H60.2v-1.7h3.4v6h-1.83l-0.27-0.8c-0.2,0.35-0.45,0.6-0.75,0.78
            c-0.3,0.17-0.66,0.26-1.07,0.26c-0.49,0-0.95-0.12-1.37-0.36c-0.43-0.24-0.75-0.53-0.97-0.88c-0.22-0.35-0.36-0.72-0.41-1.1
            c-0.05-0.38-0.08-0.96-0.08-1.73V4.37c0-1.07,0.06-1.84,0.17-2.33c0.12-0.49,0.45-0.93,0.99-1.33c0.54-0.4,1.25-0.61,2.12-0.61
            c0.85,0,1.56,0.17,2.12,0.53c0.56,0.35,0.93,0.77,1.1,1.25c0.17,0.48,0.25,1.18,0.25,2.1V4.46z M43.31,3.73h-2.7V2.9
            c0-0.39-0.04-0.63-0.1-0.74C40.44,2.05,40.32,2,40.16,2c-0.17,0-0.31,0.07-0.4,0.21c-0.09,0.14-0.13,0.36-0.13,0.65
            c0,0.37,0.05,0.66,0.15,0.84c0.1,0.19,0.37,0.41,0.82,0.68c1.29,0.77,2.11,1.4,2.44,1.89c0.34,0.49,0.5,1.29,0.5,2.38
            c0,0.8-0.09,1.38-0.28,1.76c-0.19,0.38-0.55,0.7-1.08,0.95c-0.53,0.25-1.15,0.38-1.86,0.38c-0.78,0-1.44-0.15-1.99-0.44
            c-0.55-0.29-0.91-0.67-1.08-1.12C37.08,9.74,37,9.09,37,8.25V7.52h2.7v1.36c0,0.42,0.04,0.69,0.11,0.81
            c0.08,0.12,0.21,0.18,0.41,0.18c0.19,0,0.34-0.08,0.43-0.23c0.09-0.15,0.14-0.38,0.14-0.68c0-0.66-0.09-1.09-0.27-1.29
            c-0.18-0.2-0.64-0.54-1.36-1.01c-0.72-0.48-1.2-0.83-1.44-1.04c-0.23-0.22-0.43-0.52-0.58-0.9c-0.16-0.38-0.23-0.87-0.23-1.46
            c0-0.86,0.11-1.48,0.33-1.88c0.22-0.4,0.57-0.7,1.06-0.93c0.49-0.22,1.08-0.33,1.77-0.33c0.76,0,1.4,0.12,1.93,0.37
            c0.53,0.25,0.88,0.55,1.06,0.92c0.17,0.37,0.26,1,0.26,1.89V3.73z M35.99,3.73h-2.7V2.9c0-0.39-0.04-0.63-0.1-0.74
            C33.12,2.05,33.01,2,32.85,2c-0.17,0-0.31,0.07-0.4,0.21c-0.09,0.14-0.13,0.36-0.13,0.65c0,0.37,0.05,0.66,0.15,0.84
            c0.1,0.19,0.37,0.41,0.82,0.68c1.29,0.77,2.11,1.4,2.44,1.89c0.34,0.49,0.5,1.29,0.5,2.38c0,0.8-0.09,1.38-0.28,1.76
            c-0.19,0.38-0.55,0.7-1.08,0.95c-0.54,0.25-1.15,0.38-1.86,0.38c-0.78,0-1.44-0.15-1.99-0.44c-0.55-0.29-0.91-0.67-1.08-1.12
            c-0.17-0.46-0.25-1.1-0.25-1.94V7.52h2.7v1.36c0,0.42,0.04,0.69,0.11,0.81c0.08,0.12,0.21,0.18,0.41,0.18
            c0.19,0,0.34-0.08,0.43-0.23c0.09-0.15,0.14-0.38,0.14-0.68c0-0.66-0.09-1.09-0.27-1.29c-0.18-0.2-0.64-0.54-1.36-1.01
            c-0.72-0.48-1.2-0.83-1.44-1.04c-0.23-0.22-0.43-0.52-0.58-0.9c-0.16-0.38-0.23-0.87-0.23-1.46c0-0.86,0.11-1.48,0.33-1.88
            c0.22-0.4,0.57-0.7,1.06-0.93c0.49-0.22,1.08-0.33,1.77-0.33c0.76,0,1.4,0.12,1.93,0.37c0.53,0.25,0.88,0.55,1.06,0.92
            c0.17,0.37,0.26,1,0.26,1.89V3.73z M23.96,0.35h4.85v2.24h-1.94V4.7h1.81v2.13h-1.81v2.46H29v2.24h-5.04V0.35z M22.79,0.35v11.18
            h-2.55l-1.51-5.08v5.08H16.3V0.35h2.43l1.63,5.03V0.35H22.79z M15.64,0.35v2.24h-1.73v8.94h-2.91V2.58H9.29V0.35H15.64z M8.62,0.35
            v11.18H5.71V0.35H8.62z M0.08,0.35H5v2.24H2.99V4.7h1.79v2.13H2.99v4.7H0.08V0.35z"/>
        <path class="st0" d="M1.21,15.87c0,0.2-0.06,0.35-0.16,0.45c-0.11,0.11-0.27,0.17-0.44,0.17c-0.18,0-0.33-0.06-0.44-0.17
            c-0.1-0.1-0.16-0.29-0.16-0.49v-0.18c0-0.04,0.04-0.08,0.17-0.08c0.14,0,0.17,0.03,0.17,0.08v0.29c0,0.12,0.08,0.27,0.26,0.27
            c0.19,0,0.26-0.14,0.26-0.27v-0.27c0-0.25-0.13-0.41-0.22-0.53l-0.46-0.58C0.03,14.34,0,14.16,0,14.02v-0.1
            c0-0.2,0.06-0.34,0.16-0.44c0.11-0.11,0.27-0.17,0.44-0.17c0.18,0,0.33,0.06,0.44,0.17c0.1,0.1,0.16,0.25,0.16,0.45v0.12
            c0,0.04-0.04,0.07-0.17,0.07c-0.14,0-0.17-0.03-0.17-0.07v-0.21c0-0.12-0.08-0.27-0.26-0.27c-0.19,0-0.26,0.14-0.26,0.27v0.19
            c0,0.13,0.05,0.25,0.22,0.45l0.47,0.57c0.1,0.12,0.19,0.32,0.19,0.58V15.87z"/>
        <path class="st0" d="M2.76,14.6c0,0.1-0.05,0.3-0.23,0.42c-0.11,0.07-0.28,0.14-0.5,0.14H1.99c-0.06,0-0.09,0.04-0.09,0.08
            l0.01,1.14c0,0.04-0.05,0.08-0.18,0.08c-0.13,0-0.17-0.04-0.17-0.08v-2.95c0-0.04,0.04-0.07,0.08-0.07H2
            c0.74,0,0.77,0.54,0.77,0.64V14.6z M2.43,13.97c0-0.27-0.3-0.31-0.35-0.31h-0.1c-0.05,0-0.08,0.03-0.08,0.07v1.08
            c0,0.04,0.04,0.07,0.08,0.07h0.07c0.33,0,0.38-0.26,0.38-0.31V13.97z"/>
        <path class="st0" d="M4.28,15.87c0,0.2-0.06,0.35-0.16,0.45c-0.11,0.11-0.27,0.17-0.44,0.17c-0.18,0-0.34-0.06-0.45-0.17
            c-0.1-0.1-0.16-0.25-0.16-0.45v-1.93c0-0.2,0.06-0.35,0.16-0.45c0.11-0.11,0.27-0.17,0.45-0.17c0.18,0,0.33,0.06,0.44,0.17
            c0.1,0.1,0.16,0.25,0.16,0.45V15.87z M3.92,13.86c0-0.12-0.07-0.27-0.25-0.27c-0.19,0-0.25,0.14-0.25,0.27v2.07
            c0,0.12,0.07,0.27,0.25,0.27c0.18,0,0.25-0.14,0.25-0.27V13.86z"/>
        <path class="st0" d="M5.86,16.38c0,0.05-0.04,0.08-0.18,0.08c-0.14,0-0.17-0.04-0.17-0.08v-1.09c0-0.05,0.02-0.3-0.32-0.3H5.07
            c-0.05,0-0.08,0.03-0.08,0.07v1.32c0,0.05-0.04,0.08-0.17,0.08c-0.13,0-0.16-0.04-0.16-0.08v-2.93c0-0.07,0.04-0.09,0.11-0.09h0.35
            c0.42,0,0.72,0.21,0.72,0.6v0.44c0,0.21-0.06,0.29-0.26,0.39c-0.04,0.02-0.05,0.04,0,0.06c0.22,0.05,0.26,0.24,0.27,0.39
            L5.86,16.38z M5.49,13.96c0-0.05,0-0.3-0.33-0.3H5.07c-0.05,0-0.08,0.03-0.08,0.07v0.93c0,0.04,0.04,0.07,0.08,0.07h0.07
            c0.05,0,0.35-0.05,0.35-0.31V13.96z"/>
        <path class="st0" d="M7.09,13.64H6.82c-0.05,0-0.08,0.03-0.08,0.07v2.67c0,0.04-0.04,0.07-0.18,0.07c-0.14,0-0.18-0.04-0.18-0.07
            v-2.67c0-0.04-0.04-0.07-0.08-0.07H6.04c-0.05,0-0.09-0.03-0.09-0.14c0-0.11,0.04-0.14,0.09-0.14h1.05c0.05,0,0.09,0.03,0.09,0.15
            C7.17,13.61,7.14,13.64,7.09,13.64"/>
        <path class="st0" d="M8.49,15.87c0,0.2-0.06,0.35-0.16,0.45c-0.11,0.11-0.27,0.17-0.44,0.17c-0.18,0-0.33-0.06-0.45-0.17
            c-0.1-0.1-0.16-0.29-0.16-0.49v-0.18c0-0.04,0.04-0.08,0.17-0.08c0.14,0,0.17,0.03,0.17,0.08v0.29c0,0.12,0.08,0.27,0.26,0.27
            c0.19,0,0.26-0.14,0.26-0.27v-0.27c0-0.25-0.13-0.41-0.22-0.53l-0.47-0.58c-0.16-0.19-0.18-0.38-0.18-0.51v-0.1
            c0-0.2,0.06-0.34,0.16-0.44c0.11-0.11,0.27-0.17,0.44-0.17c0.18,0,0.33,0.06,0.44,0.17c0.1,0.1,0.16,0.25,0.16,0.45v0.12
            c0,0.04-0.04,0.07-0.17,0.07c-0.14,0-0.17-0.03-0.17-0.07v-0.21c0-0.12-0.08-0.27-0.26-0.27c-0.19,0-0.26,0.14-0.26,0.27v0.19
            c0,0.13,0.05,0.25,0.22,0.45l0.46,0.57c0.1,0.12,0.19,0.32,0.19,0.58V15.87z"/>
        <path class="st0" d="M10.04,16.38c0,0.05-0.04,0.08-0.17,0.08c-0.15,0-0.18-0.04-0.18-0.08v-1.14c0-0.05,0.01-0.3-0.32-0.3H9.25
            c-0.05,0-0.08,0.03-0.08,0.07v1.37c0,0.05-0.04,0.08-0.17,0.08c-0.13,0-0.17-0.04-0.17-0.08v-2.97c0-0.04,0.03-0.07,0.16-0.08
            c0.14,0,0.17,0.04,0.17,0.08v1.19c0,0.04,0.04,0.07,0.08,0.07h0.06c0.07,0,0.29-0.04,0.29-0.31v-0.95c0-0.04,0.04-0.08,0.18-0.08
            c0.13,0,0.17,0.04,0.17,0.08v0.94c0,0.21-0.01,0.29-0.21,0.38c-0.04,0.02-0.04,0.04,0.02,0.06c0.22,0.05,0.26,0.24,0.27,0.4
            L10.04,16.38z"/>
        <path class="st0" d="M11.56,16.38c0,0.04-0.04,0.08-0.19,0.08c-0.16,0-0.19-0.04-0.2-0.08l-0.07-0.64c0-0.08-0.05-0.07-0.08-0.07
            h-0.17c-0.05,0-0.08,0.02-0.09,0.07l-0.07,0.65c-0.01,0.04-0.04,0.08-0.19,0.08c-0.16,0-0.2-0.04-0.19-0.08l0.46-2.97
            c0-0.04,0.04-0.08,0.16-0.08c0.12,0,0.16,0.04,0.16,0.08L11.56,16.38z M10.97,14.36c-0.01-0.04,0-0.06-0.03-0.06
            c-0.03,0-0.03,0.02-0.04,0.06l-0.09,0.97c0,0.04,0.03,0.06,0.07,0.06h0.1c0.05,0,0.08-0.02,0.07-0.06L10.97,14.36z"/>
        <path class="st0" d="M13.59,16.38c0.01,0.04-0.04,0.08-0.19,0.08c-0.16,0-0.19-0.04-0.2-0.08l-0.07-0.64
            c0-0.08-0.05-0.07-0.08-0.07h-0.17c-0.05,0-0.08,0.02-0.09,0.07l-0.07,0.65c0,0.04-0.04,0.08-0.19,0.08c-0.16,0-0.2-0.04-0.19-0.08
            l0.46-2.97c0-0.04,0.04-0.08,0.16-0.08c0.12,0,0.16,0.04,0.16,0.08L13.59,16.38z M13,14.36c-0.01-0.04,0-0.06-0.03-0.06
            c-0.02,0-0.03,0.02-0.04,0.06l-0.09,0.97c-0.01,0.04,0.02,0.06,0.07,0.06h0.1c0.05,0,0.08-0.02,0.07-0.06L13,14.36z"/>
        <path class="st0" d="M15.05,16.38c0,0.05-0.04,0.08-0.17,0.08c-0.15,0-0.18-0.04-0.18-0.08v-1.14c0-0.05,0.01-0.3-0.32-0.3h-0.12
            c-0.05,0-0.08,0.03-0.08,0.07v1.37c0,0.05-0.04,0.08-0.17,0.08c-0.13,0-0.17-0.04-0.17-0.08v-2.97c0-0.04,0.04-0.07,0.16-0.08
            c0.14,0,0.17,0.04,0.17,0.08v1.19c0,0.04,0.04,0.07,0.08,0.07h0.06c0.07,0,0.29-0.04,0.29-0.31v-0.95c0-0.04,0.04-0.08,0.18-0.08
            c0.13,0,0.17,0.04,0.17,0.08v0.94c0,0.21-0.01,0.29-0.21,0.38c-0.04,0.02-0.04,0.04,0.01,0.06c0.22,0.05,0.26,0.24,0.27,0.4
            L15.05,16.38z"/>
        <path class="st0" d="M16.56,16.38c0.01,0.04-0.04,0.08-0.19,0.08c-0.16,0-0.19-0.04-0.2-0.08l-0.07-0.64
            c0-0.08-0.05-0.07-0.08-0.07h-0.17c-0.05,0-0.08,0.02-0.09,0.07l-0.07,0.65c-0.01,0.04-0.04,0.08-0.19,0.08
            c-0.16,0-0.2-0.04-0.19-0.08l0.46-2.97c0-0.04,0.04-0.08,0.16-0.08c0.12,0,0.16,0.04,0.16,0.08L16.56,16.38z M15.98,14.36
            c-0.01-0.04,0-0.06-0.03-0.06c-0.03,0-0.03,0.02-0.04,0.06l-0.09,0.97c0,0.04,0.03,0.06,0.07,0.06h0.1c0.04,0,0.08-0.02,0.07-0.06
            L15.98,14.36z"/>
        <path class="st0" d="M18.02,15.8c0,0.1-0.03,0.64-0.77,0.64H16.9c-0.05,0-0.08-0.03-0.08-0.07v-2.93c0-0.04,0.04-0.07,0.08-0.07
            h0.39c0.42,0,0.73,0.21,0.73,0.6V15.8z M17.67,13.96c0-0.05-0.01-0.3-0.34-0.3h-0.1c-0.05,0-0.08,0.03-0.08,0.07v2.36
            c0,0.04,0.04,0.07,0.08,0.07h0.11c0.05,0,0.34-0.05,0.34-0.31V13.96z"/>
        <path class="st0" d="M19.4,16.44h-0.92c-0.05,0-0.08-0.03-0.08-0.07v-2.93c0-0.04,0.04-0.07,0.08-0.07h0.92
            c0.05,0,0.09,0.03,0.09,0.14c0,0.1-0.04,0.13-0.09,0.13h-0.57c-0.06,0-0.08,0.03-0.08,0.07v0.92c0,0.04,0.02,0.07,0.08,0.07h0.31
            c0.05,0,0.09,0.03,0.09,0.14c0,0.1-0.04,0.13-0.09,0.13h-0.31c-0.06,0-0.08,0.03-0.08,0.07v1.08c0,0.04,0.02,0.07,0.08,0.07h0.57
            c0.05,0,0.09,0.03,0.09,0.14C19.48,16.41,19.44,16.44,19.4,16.44"/>
        <path class="st0" d="M21.14,16.38c0,0.04-0.04,0.08-0.18,0.08c-0.15,0-0.18-0.04-0.18-0.08l0.02-1.87c0-0.04-0.02-0.07-0.05-0.07
            c-0.02,0-0.05,0.02-0.06,0.07l-0.17,0.48c-0.03,0.08-0.05,0.09-0.09,0.09c-0.03,0-0.06-0.02-0.09-0.1l-0.16-0.48
            c-0.02-0.04-0.04-0.06-0.06-0.06c-0.02,0-0.04,0.02-0.04,0.06l0.02,1.87c0,0.04-0.04,0.08-0.19,0.08c-0.15,0-0.18-0.04-0.18-0.08
            v-2.97c0-0.04,0.04-0.08,0.15-0.08c0.12,0,0.15,0.04,0.17,0.08l0.31,0.81c0.03,0.08,0.05,0.12,0.1,0.12c0.04,0,0.05-0.02,0.08-0.12
            l0.3-0.81c0.02-0.04,0.04-0.08,0.15-0.08c0.12,0,0.15,0.04,0.15,0.08V16.38z"/>
        <path class="st0" d="M21.94,16.38c0,0.04-0.04,0.08-0.18,0.08c-0.13,0-0.16-0.04-0.16-0.08v-2.97c0-0.04,0.04-0.08,0.17-0.08
            c0.14,0,0.17,0.04,0.17,0.08V16.38z"/>
        <path class="st0" d="M23.4,15.87c0,0.2-0.05,0.35-0.15,0.45c-0.11,0.11-0.26,0.16-0.43,0.16c-0.18,0-0.32-0.06-0.43-0.17
            c-0.1-0.1-0.15-0.25-0.15-0.45v-0.23c0-0.04,0.03-0.08,0.18-0.08c0.14,0,0.17,0.03,0.17,0.08v0.29c0,0.12,0.05,0.27,0.23,0.27
            c0.19,0,0.24-0.14,0.24-0.27v-2.51c0-0.04,0.04-0.08,0.17-0.08s0.17,0.04,0.17,0.08V15.87z"/>
        <path class="st0" d="M24.82,16.38c0.01,0.04-0.04,0.08-0.19,0.08c-0.16,0-0.19-0.04-0.2-0.08l-0.07-0.64
            c-0.01-0.08-0.05-0.07-0.08-0.07h-0.17c-0.05,0-0.08,0.02-0.09,0.07l-0.07,0.65c0,0.04-0.04,0.08-0.19,0.08
            c-0.16,0-0.2-0.04-0.19-0.08l0.45-2.97c0-0.04,0.04-0.08,0.16-0.08c0.12,0,0.16,0.04,0.16,0.08L24.82,16.38z M24.23,14.36
            c-0.01-0.04,0-0.06-0.03-0.06c-0.03,0-0.03,0.02-0.04,0.06l-0.09,0.97c0,0.04,0.03,0.06,0.07,0.06h0.1c0.05,0,0.08-0.02,0.07-0.06
            L24.23,14.36z"/>
        <path class="st1" d="M50.28,9.38l-0.9-1.85c-0.35,0.38-0.52,0.8-0.52,1.24c0,0.32,0.05,0.56,0.16,0.73
            c0.11,0.16,0.27,0.24,0.48,0.24C49.73,9.74,49.98,9.62,50.28,9.38 M49.65,4.08c0.31-0.5,0.47-0.98,0.47-1.44
            c0-0.2-0.04-0.38-0.14-0.54c-0.09-0.16-0.22-0.25-0.39-0.25c-0.15,0-0.27,0.06-0.34,0.19c-0.08,0.12-0.12,0.32-0.12,0.57
            C49.14,3.13,49.31,3.62,49.65,4.08z M53.94,6.48V8.8l-0.78,0.61l0.98,2.29h-2.57l-0.34-0.76c-0.86,0.7-1.68,1.05-2.45,1.05
            c-0.81,0-1.43-0.33-1.87-0.99c-0.44-0.66-0.66-1.46-0.66-2.39c0-0.75,0.13-1.33,0.39-1.76c0.26-0.42,0.64-0.77,1.16-1.03
            c-0.74-0.65-1.1-1.53-1.1-2.64c0-0.95,0.26-1.72,0.78-2.31C48.01,0.29,48.73,0,49.65,0c0.82,0,1.48,0.29,1.95,0.86
            c0.48,0.57,0.71,1.3,0.71,2.18c0,0.93-0.29,1.75-0.87,2.46l0.98,2.19L53.94,6.48z"/>
        <rect x="62.56" y="17.81" class="st0" width="4.78" height="18.38"/>
        <polygon class="st0" points="78.65,13.47 74.91,16.55 72.61,16.55 74.61,13.47 	"/>
        <path class="st0" d="M80.42,25.82h-4.78v-3.2c0-0.93-0.05-1.51-0.16-1.74c-0.1-0.23-0.33-0.34-0.67-0.34
            c-0.4,0-0.64,0.14-0.75,0.42c-0.1,0.28-0.16,0.89-0.16,1.82v8.54c0,0.9,0.05,1.48,0.16,1.75c0.11,0.28,0.34,0.41,0.72,0.41
            c0.36,0,0.59-0.14,0.7-0.41c0.11-0.27,0.17-0.91,0.17-1.92v-2.3h4.78v0.71c0,1.9-0.14,3.25-0.4,4.04
            c-0.27,0.79-0.86,1.49-1.78,2.09c-0.92,0.6-2.05,0.9-3.4,0.9c-1.4,0-2.56-0.26-3.46-0.76c-0.91-0.51-1.51-1.21-1.81-2.1
            c-0.29-0.9-0.44-2.25-0.44-4.05v-5.37c0-1.33,0.05-2.32,0.14-2.98c0.09-0.66,0.36-1.3,0.81-1.91c0.45-0.61,1.07-1.1,1.87-1.45
            c0.8-0.35,1.72-0.53,2.75-0.53c1.41,0,2.57,0.28,3.49,0.82c0.91,0.55,1.52,1.22,1.81,2.04c0.29,0.81,0.43,2.08,0.43,3.8V25.82z"/>
    </g>
    </svg>
</template>

<script>
    export default {
        name: "LogoWebNav"
    }
</script>

<style scoped>
    .st0{fill:#ED1C24;}
    .st1{fill:#231F20;}
</style>