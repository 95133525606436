<template>
    <schedule :page="'promotions'"></schedule>
</template>

<script>
import Schedule from "@/components/Schedule";
export default {
    name: "Promotions",
    components: {Schedule}

}
</script>