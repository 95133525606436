<template>
  <div class="page h-screen">
    <div class="hidden-button"></div>
  </div>
</template>

<script>
export default {
  name: "LiveChat",
  mounted() {
    window.Tawk_API.onLoad = function(){
      window.Tawk_API.maximize();
    };

    window.Tawk_API.customStyle = {
      zIndex : '5 !important'
    };
  },
}
</script>

<style scoped>
.page {
  position: relative;
  z-index: 1000 !important;
  height: 0;
}

.hidden-button {
  position: absolute;
  right: 0;
  top: 0;
  height: 60px;
  width: 60px;
  z-index: 100000000 !important;
  background-color: #99cc29;
}
</style>