const formatCurrentDaySrb = (val) => {
        switch (val) {
            case 1:
                return 'Ponedeljak';
            case 2:
                return 'Utorak';
            case 3:
                return 'Sreda';
            case 4:
                return 'Četvrtak';
            case 5:
                return 'Petak';
            case 6:
                return 'Subota';
            case 7:
                return 'Nedelja';
        }
}

const formatCurrentDayEng = (val) => {
        switch (val) {
            case 1:
                return 'Monday';
            case 2:
                return 'Tuesday';
            case 3:
                return 'Wednesday';
            case 4:
                return 'Thursday';
            case 5:
                return 'Friday';
            case 6:
                return 'Saturday';
            case 7:
                return 'Sunday';
        }
}


const filters = {formatCurrentDaySrb: formatCurrentDaySrb, formatCurrentDayEng: formatCurrentDayEng};
export default filters;