<template>
     <modal
            :name="modalName"
            :resizable="true"
            width="100%"
            height="100%"
            :adaptive="true"
        >
             <div
                class="bg-gradient-to-b from-black-90 via-black-30 to-black-90 overflow-auto flex m-auto flex text-black-70"
                style="max-height: 100%; height: 100%; max-width: 100%;"
            >
                 <div class="absolute right-0 top-0 text-2xl ml-auto mb-auto pb-5 mr-5 mt-2 text-black-20">
                     <button class="hover:bg-glack-20" @click="$modal.hide(modalName)">
                         <icon class="hover:text-red-50" :icon="['far', 'window-close']" size="lg"></icon>
                     </button>
                 </div>
                 <div class="w-full h-full grid grid-cols-12">
                    <div
                        class="col-span-1 flex xl:hover:bg-black-30 cursor-pointer transition duration-300 ease-in-out"
                        @click="slide('-')">
                        <icon class="m-auto text-3xl" :icon="['fas', 'chevron-circle-left']" size="lg"></icon>
                    </div>
                    <div class="col-span-10 w-full h-full mx-auto">
                        <div class="image-box mx-auto" v-bind:style="{ 'background-image': 'url(' + imageUrl + ')' }"></div>
                    </div>
                     <div
                         class="col-span-1 flex xl:hover:bg-black-30 cursor-pointer transition duration-300 ease-in-out"
                         @click="slide('+')">
                         <icon class="m-auto text-3xl" :icon="['fas', 'chevron-circle-right']" size="lg"></icon>
                     </div>
                 </div>
             </div>
      </modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
    name: "AppImageViewer",
    data() {
        return {
            // imageUrl: null,
        }
    },
    props: {
        // image: {
        //     type: String,
        //     required: true
        // },
        // images: {
        //     type: Array,
        //     required: true
        // },
        modalName: {
            type: String,
            required: false
        },
    },
    mounted() {
        // this.setImageUrl(this.image)
        // this.imageUrl = this.image;
        this.init();
    },
    computed: {
        ...mapGetters("imageViewer", {
            imageUrl: "getImgUrl",
            imageIndex: "getImgIndex",
        })
    },
    methods: {
        ...mapActions("imageViewer", {
            init: "initComponent",
        }),
        ...mapMutations("imageViewer", {
            slide: "slide",
            setImageUrl: "setImgUrl",
        }),
        // init() {
        //     this.images.forEach((item, i) => {
        //         if (this.$store.state.imgIndex === i) {
        //             this.imageUrl = item;
        //         }
        //     })
        // },
        // slide(operator) {
        //     if (operator === '-' && this.$store.state.imgIndex > 0) {
        //         this.$store.state.imgIndex--;
        //     } else if (operator === '+' && this.images.length - 1 > this.$store.state.imgIndex) {
        //         this.$store.state.imgIndex++;
        //     } else {
        //         return
        //     }
        // }
    },
    watch: {
        imageIndex() {
            this.init();
        }
    }
}
</script>

<style scoped>

</style>