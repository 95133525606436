<template>
  <section class="relative">
    <div
      class="min-h-screen xl:max-h-full px-10 pt-5 pb-10 xl:overflow-y-none overflow-x-hidden"
      :class="width > 768 ? 'cta' : 'ctaMobile'"
    >
      <h1
        class="mx-2 md:mx-20 my-10 text-4xl font-semibold text-center: md:text-left text-white"
      >
        {{ $t("nemas-izgovor") }}.
        <span class="text-red-50">{{ $t("treniraj") }}.</span>
      </h1>
      <ul
        class="w-full md:w-7/12 mx-2 md:mx-20"
        :class="width > 768 ? '' : 'bg-black bg-opacity-50 p-2'"
      >
        <li v-for="(item, index) in optionList" :key="index">
          <div
            class="my-7 flex align-middle font-semibold text-2xl text-left md:text-justify lg:text-left text-white"
          >
            <span class="mr-5"><check-icon-mobile></check-icon-mobile></span>
            <span class="leading-none">
              {{ $t(item) }}
            </span>
          </div>
        </li>
      </ul>
      <div class="w-fit mx-auto mt-20 mb-10 md:mt-30">
        <button
          class="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-5 rounded-xl text-lg lg:text-2xl text-middle font-semibold bg-yellow-400 text-black zakaziButtonBoxShadow"
          @click="emitScrollToForm"
        >
          <p>{{ $t("cta-zakazi") }}</p>
          <p>{{ $t("cta-probni") }}</p>
        </button>
      </div>
      <div class="absolute bottom-4 right-6">
        <logo class="h-12 md:h-16 w-48 md:w-full"></logo>
      </div>
    </div>
  </section>
</template>

<script>
import CheckIcon from "./../../icons/CheckIcon";
import CheckIconMobile from "./../../icons/CheckIconMobile";
import Logo from "./../../icons/Logo";

export default {
  name: "CallToAction",
  // computed: { console: () => console },
  data() {
    return {
      optionList: [
        "cta-9-lokacija",
        "cta-grupni-fitnes-treninzi",
        "cta-profesionalni-treneri",
        "cta-prostrani-udobni",
        "cta-probaj-besplatno",
      ],
      width: null,
    };
  },
  components: {
    CheckIcon,
    CheckIconMobile,
    Logo,
  },
  methods: {
    emitScrollToForm() {
      this.$emit("scroll-to-form");
    },
  },
  mounted() {
    this.width = window.innerWidth;

    window.addEventListener("resize", () => {
      this.width = window.innerWidth;
    });
  },
};
</script>
