<template>

    <svg version="1.1" id="Laer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         width="150" height="45" viewBox="40 0 110 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
        <g>
	<polygon class="st0" points="31.3,48.7 23.83,71.38 32.01,98.94 18.52,98.94 13.24,77.41 13.24,98.94 0.17,98.94 0.17,48.7
		13.24,48.7 13.24,68.22 19.04,48.7 	"/>
	<path class="st0" d="M49.93,61.61c0-2.34-0.13-3.83-0.39-4.49c-0.25-0.65-0.78-0.97-1.59-0.97c-0.69,0-1.21,0.26-1.57,0.79
		c-0.37,0.52-0.55,2.08-0.55,4.67v23.45c0,2.92,0.13,4.72,0.36,5.4c0.23,0.69,0.79,1.03,1.66,1.03c0.89,0,1.45-0.39,1.71-1.18
		c0.24-0.79,0.37-2.66,0.37-5.62V61.61z M62.99,78.12c0,5.05-0.12,8.62-0.35,10.72c-0.24,2.11-0.99,4.03-2.24,5.76
		c-1.25,1.74-2.94,3.07-5.07,4c-2.13,0.93-4.62,1.4-7.45,1.4c-2.69,0-5.11-0.44-7.25-1.32c-2.15-0.88-3.87-2.19-5.17-3.95
		c-1.3-1.76-2.08-3.68-2.33-5.74c-0.24-2.08-0.37-5.7-0.37-10.87v-8.59c0-5.05,0.12-8.62,0.36-10.73c0.23-2.1,0.97-4.01,2.24-5.75
		c1.25-1.74,2.93-3.07,5.07-4c2.13-0.93,4.61-1.4,7.45-1.4c2.69,0,5.11,0.44,7.25,1.32c2.14,0.88,3.87,2.19,5.17,3.95
		c1.3,1.76,2.08,3.68,2.32,5.74c0.25,2.08,0.37,5.69,0.37,10.87V78.12z"/>
	<polygon class="st0" points="83.19,40.55 86.64,36.48 95.05,36.48 87.56,45.24 78.81,45.24 70.84,36.48 79.53,36.48 	"/>
	<path class="st0" d="M98.37,70.6H85.31v-8.75c0-2.54-0.15-4.13-0.42-4.77c-0.28-0.63-0.89-0.94-1.84-0.94
		c-1.08,0-1.76,0.38-2.05,1.14c-0.29,0.77-0.43,2.43-0.43,4.97v23.34c0,2.45,0.15,4.04,0.43,4.78c0.3,0.75,0.94,1.12,1.96,1.12
		c0.97,0,1.61-0.37,1.91-1.12c0.3-0.74,0.46-2.49,0.46-5.24v-6.29h13.06v1.95c0,5.19-0.37,8.88-1.1,11.05
		c-0.73,2.17-2.36,4.08-4.87,5.71c-2.51,1.63-5.61,2.46-9.29,2.46c-3.83,0-6.99-0.7-9.47-2.09c-2.48-1.39-4.13-3.31-4.94-5.75
		c-0.81-2.46-1.21-6.14-1.21-11.07V66.42c0-3.62,0.13-6.33,0.37-8.15c0.25-1.81,0.99-3.56,2.22-5.23c1.23-1.67,2.93-3,5.12-3.96
		c2.18-0.95,4.69-1.44,7.52-1.44c3.86,0,7.03,0.75,9.53,2.23c2.5,1.49,4.14,3.35,4.94,5.57c0.78,2.22,1.18,5.69,1.18,10.38V70.6z"/>
	<path class="st0" d="M119.84,61.61c0-2.34-0.13-3.83-0.39-4.49c-0.25-0.65-0.78-0.97-1.59-0.97c-0.69,0-1.21,0.26-1.57,0.79
		c-0.37,0.52-0.55,2.08-0.55,4.67v23.45c0,2.92,0.13,4.72,0.36,5.4c0.23,0.69,0.79,1.03,1.66,1.03c0.89,0,1.45-0.39,1.71-1.18
		c0.24-0.79,0.37-2.66,0.37-5.62V61.61z M132.9,78.12c0,5.05-0.12,8.62-0.35,10.72c-0.24,2.11-0.99,4.03-2.23,5.76
		c-1.25,1.74-2.94,3.07-5.07,4c-2.13,0.93-4.62,1.4-7.45,1.4c-2.69,0-5.11-0.44-7.25-1.32c-2.15-0.88-3.87-2.19-5.17-3.95
		c-1.3-1.76-2.08-3.68-2.33-5.74c-0.24-2.08-0.37-5.7-0.37-10.87v-8.59c0-5.05,0.12-8.62,0.36-10.73c0.23-2.1,0.97-4.01,2.23-5.75
		c1.25-1.74,2.93-3.07,5.07-4c2.13-0.93,4.61-1.4,7.45-1.4c2.69,0,5.11,0.44,7.25,1.32c2.14,0.88,3.87,2.19,5.17,3.95
		c1.3,1.76,2.08,3.68,2.32,5.74c0.25,2.08,0.37,5.69,0.37,10.87V78.12z"/>
	<path class="st0" d="M168.81,48.7l-6.64,50.24h-19.83L134.8,48.7h13.78c1.59,13.83,2.72,25.54,3.41,35.09
		c0.68-9.66,1.4-18.24,2.14-25.75l0.9-9.34H168.81z"/>
	<path class="st1" d="M219.79,0.94v30.56h-6.95V10.87l-2.78,20.63h-4.93l-2.93-20.16v20.16h-6.95V0.94h10.29
		c0.3,1.84,0.62,4,0.96,6.5l1.1,7.78l1.81-14.27H219.79z M193.9,0.94l-5.79,19.5v11.06h-7.37V20.44l-5.59-19.5h7.31
		c1.14,5.95,1.78,9.96,1.92,12.02c0.44-3.26,1.18-7.27,2.21-12.02H193.9z M173.89,12.2h-7.95V9.42c0-1.75-0.08-2.84-0.23-3.29
		c-0.15-0.44-0.51-0.66-1.08-0.66c-0.5,0-0.82,0.19-1.01,0.57c-0.17,0.37-0.26,1.35-0.26,2.91v14.67c0,1.37,0.09,2.27,0.26,2.71
		c0.18,0.43,0.53,0.65,1.06,0.65c0.58,0,0.97-0.24,1.19-0.73c0.21-0.5,0.31-1.45,0.31-2.87v-3.62h-1.6V15.1h9.31v16.41h-5
		l-0.73-2.19c-0.54,0.95-1.22,1.65-2.05,2.13c-0.82,0.47-1.8,0.71-2.92,0.71c-1.33,0-2.58-0.33-3.74-0.97
		c-1.17-0.65-2.05-1.45-2.65-2.41c-0.61-0.95-0.98-1.96-1.13-3.01c-0.15-1.05-0.23-2.63-0.23-4.73v-9.08c0-2.92,0.15-5.04,0.47-6.36
		c0.32-1.33,1.22-2.54,2.71-3.63c1.49-1.1,3.42-1.66,5.79-1.66c2.33,0,4.26,0.48,5.79,1.44c1.53,0.95,2.54,2.09,3,3.41
		c0.46,1.31,0.7,3.22,0.7,5.73V12.2z M118.41,10.19h-7.38V7.93c0-1.06-0.1-1.73-0.28-2.02c-0.19-0.29-0.5-0.43-0.95-0.43
		c-0.48,0-0.84,0.19-1.08,0.59c-0.24,0.39-0.37,0.98-0.37,1.77c0,1.02,0.14,1.79,0.41,2.31c0.26,0.52,1.02,1.13,2.25,1.87
		c3.54,2.1,5.76,3.82,6.68,5.17c0.92,1.35,1.38,3.52,1.38,6.52c0,2.18-0.26,3.78-0.77,4.81c-0.51,1.03-1.49,1.9-2.95,2.6
		c-1.46,0.7-3.16,1.05-5.1,1.05c-2.13,0-3.94-0.41-5.45-1.21c-1.51-0.81-2.49-1.83-2.96-3.07c-0.46-1.25-0.7-3.02-0.7-5.31v-2h7.38
		v3.72c0,1.15,0.1,1.88,0.31,2.21c0.21,0.32,0.58,0.49,1.11,0.49c0.53,0,0.92-0.21,1.18-0.62c0.26-0.41,0.39-1.03,0.39-1.85
		c0-1.8-0.24-2.98-0.73-3.53c-0.5-0.55-1.75-1.48-3.72-2.77c-1.98-1.31-3.29-2.26-3.93-2.85c-0.64-0.59-1.17-1.41-1.59-2.45
		c-0.43-1.04-0.63-2.38-0.63-4c0-2.34,0.3-4.05,0.9-5.14c0.59-1.08,1.56-1.93,2.89-2.54c1.33-0.61,2.94-0.91,4.83-0.91
		c2.07,0,3.83,0.33,5.28,1c1.46,0.67,2.42,1.51,2.89,2.53c0.47,1.01,0.7,2.73,0.7,5.16V10.19z M98.41,10.19h-7.38V7.93
		c0-1.06-0.1-1.73-0.28-2.02c-0.19-0.29-0.5-0.43-0.95-0.43c-0.48,0-0.84,0.19-1.08,0.59c-0.24,0.39-0.37,0.98-0.37,1.77
		c0,1.02,0.14,1.79,0.41,2.31c0.26,0.52,1.02,1.13,2.25,1.87c3.54,2.1,5.76,3.82,6.68,5.17c0.92,1.35,1.38,3.52,1.38,6.52
		c0,2.18-0.26,3.78-0.77,4.81c-0.51,1.03-1.5,1.9-2.95,2.6c-1.46,0.7-3.16,1.05-5.1,1.05c-2.13,0-3.94-0.41-5.45-1.21
		c-1.51-0.81-2.49-1.83-2.96-3.07c-0.46-1.25-0.7-3.02-0.7-5.31v-2h7.38v3.72c0,1.15,0.1,1.88,0.31,2.21
		c0.21,0.32,0.58,0.49,1.11,0.49c0.53,0,0.92-0.21,1.18-0.62c0.26-0.41,0.39-1.03,0.39-1.85c0-1.8-0.24-2.98-0.73-3.53
		c-0.5-0.55-1.75-1.48-3.72-2.77c-1.98-1.31-3.29-2.26-3.93-2.85c-0.64-0.59-1.17-1.41-1.59-2.45c-0.43-1.04-0.63-2.38-0.63-4
		c0-2.34,0.3-4.05,0.9-5.14c0.59-1.08,1.56-1.93,2.89-2.54c1.33-0.61,2.94-0.91,4.83-0.91c2.07,0,3.83,0.33,5.28,1
		c1.46,0.67,2.42,1.51,2.89,2.53c0.47,1.01,0.7,2.73,0.7,5.16V10.19z M65.51,0.94h13.26v6.12h-5.3v5.79h4.96v5.82h-4.96v6.72h5.83
		v6.12H65.51V0.94z M62.32,0.94v30.56h-6.97l-4.14-13.89v13.89h-6.64V0.94h6.64l4.46,13.76V0.94H62.32z M42.75,0.94v6.12h-4.72
		v24.45h-7.95V7.06h-4.7V0.94H42.75z M23.56,0.94v30.56H15.6V0.94H23.56z M0.22,0.94h13.46v6.12H8.17v5.79h4.9v5.82h-4.9v12.84H0.22
		V0.94z"/>
	<path class="st0" d="M3.32,43.38c0,0.54-0.15,0.96-0.43,1.22c-0.3,0.29-0.73,0.46-1.22,0.46c-0.48,0-0.91-0.17-1.22-0.46
		c-0.27-0.27-0.43-0.8-0.43-1.34v-0.51c0-0.11,0.1-0.21,0.47-0.21c0.37,0,0.47,0.1,0.47,0.21v0.78c0,0.34,0.21,0.73,0.7,0.73
		c0.51,0,0.71-0.39,0.71-0.73v-0.75c0-0.69-0.35-1.12-0.61-1.45L0.5,39.74C0.07,39.21,0,38.7,0,38.34v-0.27
		c0-0.54,0.15-0.93,0.43-1.2c0.3-0.29,0.73-0.46,1.22-0.46c0.48,0,0.91,0.17,1.22,0.46c0.28,0.27,0.43,0.68,0.43,1.22v0.34
		c0,0.11-0.1,0.2-0.47,0.2c-0.37,0-0.47-0.08-0.47-0.19v-0.57c0-0.34-0.21-0.72-0.71-0.72c-0.51,0-0.7,0.39-0.7,0.72v0.52
		c0,0.36,0.14,0.68,0.59,1.24l1.27,1.57c0.28,0.34,0.51,0.88,0.51,1.57V43.38z"/>
	<path class="st0" d="M7.56,39.91c0,0.27-0.14,0.81-0.62,1.15c-0.3,0.2-0.76,0.38-1.35,0.38H5.43c-0.15,0-0.23,0.11-0.23,0.22
		l0.04,3.12c0,0.11-0.14,0.21-0.5,0.21c-0.36,0-0.46-0.1-0.46-0.21v-8.06c0-0.11,0.1-0.19,0.22-0.19h0.95c2.02,0,2.1,1.49,2.1,1.75
		V39.91z M6.65,38.18c0-0.73-0.83-0.86-0.95-0.86H5.42c-0.12,0-0.22,0.08-0.22,0.19v2.96c0,0.11,0.1,0.19,0.22,0.19H5.6
		c0.91,0,1.05-0.7,1.05-0.84V38.18z"/>
	<path class="st0" d="M11.69,43.38c0,0.54-0.15,0.96-0.43,1.22c-0.3,0.29-0.73,0.46-1.21,0.46c-0.48,0-0.93-0.17-1.23-0.46
		c-0.28-0.27-0.43-0.68-0.43-1.22V38.1c0-0.54,0.15-0.95,0.43-1.22c0.3-0.29,0.75-0.46,1.23-0.46c0.48,0,0.91,0.17,1.21,0.46
		c0.28,0.27,0.43,0.68,0.43,1.22V43.38z M10.72,37.88c0-0.34-0.18-0.72-0.68-0.72c-0.51,0-0.69,0.39-0.69,0.72v5.65
		c0,0.34,0.18,0.73,0.69,0.73c0.5,0,0.68-0.39,0.68-0.73V37.88z"/>
	<path class="st0" d="M16.02,44.78c0,0.13-0.1,0.21-0.5,0.21c-0.39,0-0.47-0.1-0.47-0.21v-2.97c0-0.13,0.04-0.83-0.87-0.83h-0.32
		c-0.12,0-0.22,0.09-0.22,0.19v3.61c0,0.13-0.1,0.22-0.47,0.21c-0.35-0.01-0.44-0.1-0.44-0.21v-8c0-0.18,0.11-0.25,0.29-0.25h0.97
		c1.16,0,1.96,0.57,1.96,1.64v1.21c0,0.57-0.15,0.8-0.72,1.06c-0.11,0.05-0.14,0.12,0,0.16c0.59,0.15,0.72,0.65,0.73,1.08
		L16.02,44.78z M15.01,38.16c0-0.13,0-0.83-0.91-0.83h-0.24c-0.12,0-0.22,0.08-0.22,0.19v2.55c0,0.11,0.1,0.19,0.22,0.19h0.19
		c0.12,0,0.95-0.13,0.95-0.86V38.16z"/>
	<path class="st0" d="M19.39,37.29h-0.73c-0.13,0-0.22,0.08-0.22,0.19v7.3c0,0.11-0.1,0.2-0.48,0.2c-0.39,0-0.48-0.1-0.48-0.2v-7.3
		c0-0.11-0.1-0.19-0.22-0.19h-0.73c-0.12,0-0.23-0.08-0.23-0.37c0-0.3,0.11-0.39,0.23-0.39h2.88c0.12,0,0.25,0.08,0.23,0.4
		C19.61,37.2,19.51,37.29,19.39,37.29"/>
	<path class="st0" d="M23.2,43.38c0,0.54-0.15,0.96-0.43,1.22c-0.31,0.29-0.73,0.46-1.22,0.46c-0.49,0-0.91-0.17-1.22-0.46
		c-0.28-0.27-0.43-0.8-0.43-1.34v-0.51c0-0.11,0.1-0.21,0.47-0.21c0.37,0,0.47,0.1,0.47,0.21v0.78c0,0.34,0.21,0.73,0.71,0.73
		c0.51,0,0.7-0.39,0.7-0.73v-0.75c0-0.69-0.35-1.12-0.61-1.45l-1.27-1.59c-0.43-0.53-0.5-1.04-0.5-1.4v-0.27
		c0-0.54,0.15-0.93,0.43-1.2c0.3-0.29,0.73-0.46,1.22-0.46c0.48,0,0.91,0.17,1.22,0.46c0.28,0.27,0.43,0.68,0.43,1.22v0.34
		c0,0.11-0.1,0.2-0.47,0.2c-0.37,0-0.47-0.08-0.47-0.19v-0.57c0-0.34-0.21-0.72-0.7-0.72c-0.51,0-0.71,0.39-0.71,0.72v0.52
		c0,0.36,0.14,0.68,0.59,1.24l1.27,1.57c0.28,0.34,0.51,0.88,0.51,1.57V43.38z"/>
	<path class="st0" d="M27.46,44.78c0,0.13-0.1,0.21-0.47,0.21c-0.42,0-0.5-0.1-0.5-0.21v-3.1c0-0.13,0.04-0.83-0.87-0.83H25.3
		c-0.12,0-0.22,0.08-0.22,0.19v3.75c0,0.13-0.1,0.21-0.46,0.21c-0.36,0-0.46-0.1-0.46-0.21v-8.11c0-0.11,0.1-0.19,0.44-0.21
		c0.37-0.01,0.47,0.1,0.47,0.21v3.25c0,0.11,0.1,0.19,0.22,0.19h0.15c0.18,0,0.8-0.11,0.8-0.86v-2.59c0-0.11,0.1-0.22,0.48-0.21
		c0.36,0.01,0.46,0.1,0.46,0.21v2.56c0,0.57-0.03,0.8-0.57,1.05c-0.11,0.05-0.1,0.12,0.04,0.16c0.59,0.14,0.72,0.66,0.73,1.09
		L27.46,44.78z"/>
	<path class="st0" d="M31.6,44.78c0.01,0.11-0.1,0.21-0.51,0.21c-0.44,0-0.53-0.1-0.54-0.21l-0.18-1.76
		c-0.01-0.21-0.12-0.19-0.22-0.19H29.7c-0.12,0-0.22,0.06-0.23,0.18l-0.18,1.78c-0.01,0.11-0.1,0.21-0.53,0.21
		c-0.43,0-0.54-0.1-0.53-0.21l1.24-8.11c0.01-0.11,0.11-0.21,0.44-0.21c0.33,0,0.43,0.1,0.44,0.21L31.6,44.78z M30,39.26
		c-0.01-0.11,0-0.17-0.08-0.17c-0.07,0-0.08,0.06-0.1,0.17l-0.25,2.66c-0.01,0.11,0.07,0.17,0.19,0.17h0.28
		c0.13,0,0.21-0.06,0.19-0.17L30,39.26z"/>
	<path class="st0" d="M37.14,44.78c0.01,0.11-0.1,0.21-0.51,0.21c-0.44,0-0.53-0.1-0.54-0.21l-0.18-1.76
		c-0.01-0.21-0.12-0.19-0.22-0.19h-0.46c-0.12,0-0.22,0.06-0.24,0.18l-0.18,1.78c-0.01,0.11-0.1,0.21-0.53,0.21
		c-0.43,0-0.54-0.1-0.52-0.21l1.24-8.11c0.01-0.11,0.11-0.21,0.44-0.21c0.33,0,0.43,0.1,0.44,0.21L37.14,44.78z M35.54,39.26
		c-0.01-0.11,0-0.17-0.08-0.17c-0.07,0-0.08,0.06-0.1,0.17l-0.25,2.66c-0.01,0.11,0.07,0.17,0.19,0.17h0.28
		c0.13,0,0.21-0.06,0.19-0.17L35.54,39.26z"/>
	<path class="st0" d="M41.14,44.78c0,0.13-0.1,0.21-0.47,0.21c-0.41,0-0.5-0.1-0.5-0.21v-3.1c0-0.13,0.04-0.83-0.87-0.83h-0.32
		c-0.12,0-0.22,0.08-0.22,0.19v3.75c0,0.13-0.1,0.21-0.46,0.21c-0.36,0-0.46-0.1-0.46-0.21v-8.11c0-0.11,0.1-0.19,0.44-0.21
		c0.37-0.01,0.47,0.1,0.47,0.21v3.25c0,0.11,0.1,0.19,0.22,0.19h0.15c0.18,0,0.8-0.11,0.8-0.86v-2.59c0-0.11,0.1-0.22,0.48-0.21
		c0.36,0.01,0.46,0.1,0.46,0.21v2.56c0,0.57-0.03,0.8-0.57,1.05c-0.11,0.05-0.1,0.12,0.04,0.16c0.6,0.14,0.72,0.66,0.73,1.09
		L41.14,44.78z"/>
	<path class="st0" d="M45.28,44.78c0.01,0.11-0.1,0.21-0.51,0.21c-0.44,0-0.53-0.1-0.54-0.21l-0.18-1.76
		c-0.01-0.21-0.12-0.19-0.22-0.19h-0.46c-0.13,0-0.22,0.06-0.24,0.18l-0.18,1.78c-0.01,0.11-0.1,0.21-0.52,0.21
		c-0.43,0-0.54-0.1-0.53-0.21l1.24-8.11c0.01-0.11,0.11-0.21,0.44-0.21c0.33,0,0.43,0.1,0.44,0.21L45.28,44.78z M43.68,39.26
		c-0.01-0.11,0-0.17-0.08-0.17c-0.07,0-0.08,0.06-0.1,0.17l-0.25,2.66c-0.01,0.11,0.07,0.17,0.19,0.17h0.28
		c0.12,0,0.21-0.06,0.19-0.17L43.68,39.26z"/>
	<path class="st0" d="M49.26,43.19c0,0.27-0.08,1.75-2.1,1.75h-0.95c-0.12,0-0.22-0.09-0.22-0.19v-8.02c0-0.11,0.1-0.19,0.22-0.19
		h1.06c1.16,0,1.99,0.57,1.99,1.64V43.19z M48.32,38.16c0-0.13-0.03-0.83-0.94-0.83h-0.28c-0.12,0-0.22,0.08-0.22,0.19v6.44
		c0,0.11,0.1,0.19,0.22,0.19h0.29c0.12,0,0.93-0.13,0.93-0.86V38.16z"/>
	<path class="st0" d="M53.04,44.94h-2.52c-0.13,0-0.22-0.09-0.22-0.19v-8.02c0-0.11,0.1-0.19,0.22-0.19h2.52
		c0.12,0,0.23,0.08,0.23,0.37c0,0.27-0.11,0.35-0.23,0.35h-1.55c-0.17,0-0.22,0.08-0.22,0.19v2.5c0,0.11,0.05,0.19,0.22,0.19h0.84
		c0.12,0,0.24,0.09,0.24,0.38c0,0.27-0.11,0.35-0.24,0.35h-0.84c-0.17,0-0.22,0.08-0.22,0.19v2.96c0,0.11,0.05,0.19,0.22,0.19h1.55
		c0.12,0,0.25,0.09,0.23,0.38C53.26,44.85,53.16,44.94,53.04,44.94"/>
	<path class="st0" d="M57.79,44.78c0,0.11-0.1,0.21-0.5,0.21c-0.4,0-0.5-0.1-0.5-0.21l0.06-5.12c0-0.12-0.06-0.18-0.12-0.18
		c-0.06,0-0.12,0.06-0.17,0.18l-0.46,1.32c-0.08,0.23-0.14,0.25-0.24,0.25c-0.1,0-0.17-0.06-0.23-0.27l-0.44-1.3
		c-0.04-0.12-0.11-0.17-0.17-0.17c-0.06,0-0.11,0.06-0.11,0.17l0.05,5.12c0,0.11-0.1,0.21-0.52,0.21c-0.4,0-0.5-0.1-0.5-0.21v-8.11
		c0-0.11,0.1-0.21,0.4-0.21c0.33,0,0.42,0.11,0.46,0.21l0.84,2.22c0.09,0.23,0.14,0.33,0.26,0.33c0.11,0,0.12-0.06,0.22-0.33
		l0.83-2.22c0.04-0.1,0.1-0.21,0.41-0.21c0.32,0,0.42,0.1,0.42,0.21V44.78z"/>
	<path class="st0" d="M59.97,44.78c0,0.11-0.1,0.22-0.5,0.21c-0.35-0.01-0.44-0.1-0.44-0.21v-8.11c0-0.11,0.1-0.21,0.47-0.21
		c0.37,0,0.47,0.1,0.47,0.21V44.78z"/>
	<path class="st0" d="M63.98,43.38c0,0.54-0.14,0.97-0.41,1.23c-0.3,0.29-0.7,0.45-1.19,0.45c-0.48,0-0.87-0.17-1.18-0.46
		c-0.28-0.27-0.4-0.68-0.4-1.22v-0.63c0-0.11,0.1-0.21,0.48-0.21c0.37,0,0.47,0.1,0.47,0.21v0.78c0,0.34,0.12,0.73,0.62,0.73
		c0.51,0,0.66-0.39,0.66-0.73v-6.86c0-0.11,0.1-0.21,0.47-0.21c0.37,0,0.47,0.1,0.47,0.21V43.38z"/>
	<path class="st0" d="M67.85,44.78c0.01,0.11-0.1,0.21-0.51,0.21c-0.44,0-0.53-0.1-0.54-0.21l-0.18-1.76
		c-0.01-0.21-0.12-0.19-0.22-0.19h-0.46c-0.13,0-0.22,0.06-0.24,0.18l-0.18,1.78c-0.01,0.11-0.1,0.21-0.52,0.21
		c-0.43,0-0.54-0.1-0.53-0.21l1.24-8.11c0.01-0.11,0.11-0.21,0.44-0.21c0.33,0,0.43,0.1,0.44,0.21L67.85,44.78z M66.25,39.26
		c-0.01-0.11,0-0.17-0.08-0.17c-0.07,0-0.08,0.06-0.1,0.17l-0.25,2.66c-0.01,0.11,0.07,0.17,0.19,0.17h0.28
		c0.13,0,0.21-0.06,0.19-0.17L66.25,39.26z"/>
	<path class="st1" d="M137.46,25.65L135,20.6c-0.94,1.04-1.41,2.18-1.41,3.4c0,0.89,0.15,1.54,0.44,1.98
		c0.3,0.43,0.73,0.66,1.3,0.66C135.95,26.64,136.66,26.31,137.46,25.65 M135.75,11.14c0.85-1.38,1.28-2.69,1.28-3.94
		c0-0.54-0.12-1.04-0.38-1.48c-0.25-0.45-0.6-0.67-1.05-0.67c-0.41,0-0.73,0.17-0.94,0.51c-0.21,0.34-0.32,0.86-0.32,1.55
		C134.34,8.56,134.81,9.91,135.75,11.14z M147.48,17.72v6.33l-2.15,1.67l2.67,6.26h-7.02l-0.92-2.09c-2.36,1.92-4.59,2.88-6.7,2.88
		c-2.21,0-3.91-0.91-5.1-2.71c-1.19-1.81-1.79-3.99-1.79-6.53c0-2.04,0.35-3.64,1.06-4.8c0.7-1.15,1.75-2.1,3.16-2.82
		c-2.01-1.78-3.01-4.18-3.01-7.2c0-2.61,0.71-4.71,2.15-6.3c1.43-1.59,3.4-2.39,5.91-2.39c2.25,0,4.03,0.78,5.33,2.34
		c1.3,1.56,1.95,3.54,1.95,5.95c0,2.53-0.79,4.78-2.37,6.74l2.69,5.98L147.48,17.72z"/>
	<rect x="171.04" y="48.7" class="st0" width="13.07" height="50.24"/>
	<polygon class="st0" points="215.03,36.83 204.81,45.24 198.51,45.24 203.98,36.83 	"/>
	<path class="st0" d="M219.87,70.6h-13.06v-8.75c0-2.54-0.15-4.13-0.42-4.77c-0.28-0.63-0.89-0.94-1.84-0.94
		c-1.08,0-1.76,0.38-2.05,1.14c-0.29,0.77-0.43,2.43-0.43,4.97v23.34c0,2.45,0.15,4.04,0.43,4.78c0.3,0.75,0.94,1.12,1.96,1.12
		c0.98,0,1.61-0.37,1.91-1.12c0.3-0.74,0.46-2.49,0.46-5.24v-6.29h13.06v1.95c0,5.19-0.37,8.88-1.1,11.05
		c-0.73,2.17-2.36,4.08-4.87,5.71c-2.51,1.63-5.61,2.46-9.29,2.46c-3.83,0-6.99-0.7-9.47-2.09c-2.48-1.39-4.13-3.31-4.94-5.75
		c-0.8-2.46-1.21-6.14-1.21-11.07V66.42c0-3.62,0.13-6.33,0.37-8.15c0.25-1.81,0.99-3.56,2.22-5.23c1.23-1.67,2.93-3,5.12-3.96
		c2.18-0.95,4.69-1.44,7.52-1.44c3.86,0,7.03,0.75,9.53,2.23c2.5,1.49,4.14,3.35,4.94,5.57c0.78,2.22,1.18,5.69,1.18,10.38V70.6z"/>
</g>
</svg>

</template>

<script>
export default {
name: "LoginLogo"
}
</script>

<style scoped>
    .st0{fill:#ED1C24;}
	.st1{fill:#FFFFFF;}
</style>