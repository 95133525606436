<template>
    <div class="w-12/12 overflow-x-hidden mb-12">
        <div
            :class="resolution > 1023 ? 'pic10' : 'pic10mobile'"
            class="w-12/12 h-350 md:h-400 lg:h-500 xl:h-500 sticky overflow-x-hidden flex"
         >
            <div class="xl:w-2/3 mx-auto flex m-auto">
                <p class="text-white m-auto font-bold text-2xl md:text-4xl lg:text-5xl xl:text-6xl uppercase">
                    {{ $t('other-services') }}
                </p>
            </div>
        </div>
        <div id="solarium" class="mt-8 lg:mt-10 w-11/12 xl:w-8/12 mx-auto grid grid-cols-1">
            <div class="py-8 md:py-12 lg:py-20 border-b border-black-30 text-orange-50">
                <h1 class="font-bold text-lg md:text-xl xl:text-2xl text-center mx-8 tracking-wide uppercase">
                    {{ $t('solarium') }}
                </h1>
                <p class="md:text-lg lg:text-xl text-center px-8 mt-1 px-2 md:px-6 lg:px-8 xl:px-10 mt-5">
                    {{ $t('solarium-desc') }}
                </p>
            </div>

            <div id="massage" class="py-8 md:py-12 lg:py-20 border-b border-black-30 text-blue-10">
                <h1 class="font-bold text-lg md:text-xl xl:text-2xl text-center mx-8 tracking-wide uppercase">
                    {{ $t('massage') }}
                </h1>
                <p class="md:text-lg lg:text-xl text-center px-8 mt-1 px-2 md:px-6 lg:px-8 xl:px-10 mt-5">
                    {{ $t('massage-desc') }}
                </p>
            </div>
            <div id="health-bar" class="py-8 md:py-12 lg:py-20 text-green-20">
                <h1 class="font-bold text-lg md:text-xl xl:text-2xl text-center mx-8 tracking-wide uppercase">
                    HEALTH BAR
                </h1>
                <p class="md:text-lg lg:text-xl text-center px-8 mt-1 px-2 md:px-6 lg:px-8 xl:px-10 mt-5">
                    {{ $t('health-bar-desc') }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapMutations} from "vuex";
    export default {
        name: "OtherServices",
        metaInfo: {
             title: 'Fitness & Gym Kočović | Teretana Kočović Beograd',
             titleTemplate: '%s ← Fitness & Gym Kočović',
        },
        data() {
            return {
                anchor: null,
            }
        },
        mounted() {
            this.setResolution();
            this.setAnchor(this.$route.hash);
        },
        computed: {
            ...mapGetters("shared", {
                resolution: "getResolution"
            })
        },
        methods: {
            ...mapMutations("shared", {
                setResolution: "setResolution"
            }),
            setAnchor(hash) {
                this.anchor = hash;
                this.$nextTick(() => {
                    if (this.anchor && document.querySelector(this.anchor)) {
                        location.href = this.anchor;
                    }
                });
            },
        },
        watch: {
            '$route.hash'(val) {
                this.setAnchor(val);
            }
        }
    }
</script>