<template>
  <div>
    <gmap-map
      :center="center"
      :zoom="12"
      class="w-full h-350 md:h-400 lg:h-500"
    >
      <gmap-marker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        @click="center=m.position"
      ></gmap-marker>
    </gmap-map>
  </div>
</template>

<script>
export default {
  name: "GoogleMap",
  props: {
    locations: {
      required: true,
      type: Array
    },
    centerMap: {
        required: true,
        type: Object
    }
  },
  data() {
    return {
      // default to Montreal to keep it simple
      // change this to whatever makes sense
      center:  this.centerMap,
      markers: [],
        // {position: { lat: 44.763566, lng: 20.472464 }, icon:""}, // banjica
        // {position: { lat: 44.7926229, lng: 20.4820496 }, icon:""}, // vracar
        // {position: { lat: 44.80334579, lng: 20.3831138 }, icon:""}, // blokovi
        // {position: { lat: 44.8357549, lng: 20.4074158 }, icon:""}, // zemun
        // {position: { lat: 44.8156726, lng: 20.482527 }, icon:""}, // pionir
        // {position: { lat: 44.7827354, lng: 20.5067655 }, icon:""}, // ustanicka
        // {position: { lat: 44.8158837, lng: 20.434235 }, icon:""}, // usce
      places: [],
      currentPlace: null
    };
  },

  mounted() {
    this.geolocate();
    this.markers = this.locations;
  },

  methods: {
    // receives a place object via the autocomplete component
    setPlace(place) {
      this.currentPlace = place;
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng()
        };
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
      }
    },
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
      });
    }
  }
};
</script>