import http from "../config/http";

export default {
    namespaced: true,
    state: {
        days: null,
        hours: [],
        defaultDay: 1,
    },
    mutations: {
        setDays(state, days) {
            state.days = days;
        },
        setHours(state, hours) {
            state.hours = hours;
        },
        setDefaultDay(state, day) {
            state.defaultDay = +day;
        },
    },
    actions: {
        async book(context, data) {
            data.currentTime = parseInt(data.currentTime) + parseInt(2);
            // booking if user have account
            var isLoggedIn = context.rootGetters["auth/getIsLoggedIn"];
            context.commit('loader/setLoading', true, { root: true });
            if (isLoggedIn) {
                const apiData = {};
                let url;
                if (data.page !== 'promotions' && data.currentTime <= data.period.time) {
                    url = `/period/${data.period.id}/booking`;
                } else if (data.page === 'promotions') {
                    apiData.training_date = data.period.training_date;
                    url = `/promotion/period/${data.period.id}/booking`;
                } else {
                    const err = {response: {data: {error: "ERR_YOU_CAN_NOT_SIGN_UP_FOR_TRAINING_THAT_HAS_PASSED"}}};
                    context.commit('shared/setErrorMessage', err, { root: true });
                }
                await http.post(url, apiData)
                    .then((resp) => {
                        context.commit('shared/setSuccessMessage', 'SUC_SUCCESSFULLY_BOOKED_TRAINING', { root: true });
                        context.dispatch("fetchPeriodsForRoom", {roomId: data.period.gym_room_id, page: data.page});
                    })
                    .catch((err) => {
                        context.commit('shared/setErrorMessage', err, { root: true });
                    });
            } else {
                // booking if user don't have account
                const apiData = {
                    first_name: data.bookingForm.firstName,
                    last_name: data.bookingForm.lastName,
                    phone: data.bookingForm.phone
                };
                let url;
                if (data.page !== 'promotions' && data.currentTime <= data.period.time) {
                    url = `/period/${data.period.id}/booking/guest`;
                } else if (data.page === 'promotions') {
                    apiData.training_date = data.period.training_date;
                    url = `/promotion/period/${data.period.id}/booking/guest`;
                } else {
                    const err = {response: {data: {error: "ERR_YOU_CAN_NOT_SIGN_UP_FOR_TRAINING_THAT_HAS_PASSED"}}};
                    context.commit('shared/setErrorMessage', err, { root: true });
                }
                await http.post(url, apiData)
                    .then((resp) => {
                        context.commit('shared/setSuccessMessage', 'SUC_SUCCESSFULLY_BOOKED_TRAINING', { root: true });
                        context.commit('shared/setModal', {name: 'book_training_modal', show: false}, { root: true });
                        context.dispatch("fetchPeriodsForRoom", {roomId: data.period.gym_room_id, page: data.page});
                        // this.getPeriodsForRoom(period.gym_room_id);
                    })
                    .catch((err) => {
                        context.commit('shared/setErrorMessage', err, { root: true });
                    });
            }
            context.commit('loader/setLoading', false, { root: true });
        },
        async fetchPeriodsForRoom(context, data) {
            var days = {};
            var hours = {};
            let url;
            if (data.page === 'promotions') {
                url = `/promotion/period/gym_room/${data.roomId}`;
            } else {
                url = `/period/gym_room/${data.roomId}`;
            }
            context.commit('loader/setLoading', true, { root: true });
            const resp = await http.get(url);
            resp.data.forEach((item, i) => {
                if (!days[item.day]) {
                    days[item.day] = [];
                    days[item.day].push(item);
                } else {
                    days[item.day].push(item);
                }
            });
            Object.keys(days).forEach((key) => {
                days[key].sort((a, b) => a.time - b.time);
            });
            context.commit('setDays', days);
            resp.data.forEach((item, i) => {
                if (!hours[item.time]) {
                    hours[item.time] = [];
                    hours[item.time].push(item);
                } else {
                    hours[item.time].push(item);
                }
            });
            Object.keys(hours).forEach((key) => {
                hours[key].sort((a, b) => a.day - b.day);
            });

            await context.dispatch('fetchDefaultDay', days);

            context.commit('setHours', hours);
            context.commit('loader/setLoading', false, { root: true });
        },

        fetchDefaultDay(context, days) {
            var currentDay = new Date().getDay();
            if (currentDay === 0) currentDay = 7;
            var defaultDay = null;
            Object.keys(days).forEach((key) => {
                if (currentDay < +key && defaultDay < currentDay) defaultDay = +key;
                else if (currentDay > +key && defaultDay < currentDay) defaultDay = +key;
                else if (currentDay === +key && defaultDay < currentDay) defaultDay = +key;
            })
            context.commit("setDefaultDay", defaultDay);
        },

        // this method canceling training for users with account
        async cancelTraining(context, data) {
            let url;
            if (data.page !== 'promotions') {
                url = `/period/${data.period.id}/booking`;
            } else {
                url = `/promotion/period/${data.period.id}/booking`;
            }
            context.commit('loader/setLoading', true, { root: true });
            await http.delete(url)
                .then((resp) => {
                    context.commit('shared/setSuccessMessage', 'SUC_SUCCESSFULLY_CANCELED_TRAINING', { root: true });
                    context.dispatch("fetchPeriodsForRoom", {roomId: data.period.gym_room_id, page: data.page});
                    context.commit('loader/setLoading', false, { root: true });
                })
                .catch((err) => {
                    context.commit('shared/setErrorMessage', err, { root: true });
                    context.commit('loader/setLoading', false, { root: true });
                });
        },

        daySwitcher(context, operator) {
            if (operator === "+" && context.state.defaultDay < 7) context.commit("setDefaultDay", context.state.defaultDay + 1);
            else if (operator === "+" && context.state.defaultDay === 7) context.commit("setDefaultDay", 1);
            else if (operator === "-" && context.state.defaultDay > 1) context.commit("setDefaultDay", context.state.defaultDay - 1);
            else if (operator === "-" && context.state.defaultDay === 1) context.commit("setDefaultDay", 7);
        },
    },
    getters: {
        getDays(state) {
            return state.days;
        },
        getHours(state) {
            return state.hours;
        },
        getDefaultDay(state) {
            return state.defaultDay;
        },
    }
}