const prvomajskaImagePaths = [
  "galleries/prvomajska/prvomajska-1.jpg",
  "galleries/prvomajska/prvomajska-2.jpg",
  "galleries/prvomajska/prvomajska-3.jpg",
  "galleries/prvomajska/prvomajska-4.jpg",
  "galleries/prvomajska/prvomajska-5.jpg",
  "galleries/prvomajska/prvomajska-6.jpg",
  "galleries/prvomajska/prvomajska-7.jpg",
  "galleries/prvomajska/prvomajska-8.jpg",
  "galleries/prvomajska/prvomajska-9.jpg",
  "galleries/prvomajska/prvomajska-10.jpg",
  "galleries/prvomajska/prvomajska-11.jpg",
  "galleries/prvomajska/prvomajska-12.jpg",
  "galleries/prvomajska/prvomajska-13.jpg",
  "galleries/prvomajska/prvomajska-14.jpg",
  "galleries/prvomajska/prvomajska-15.jpg",
  "galleries/prvomajska/prvomajska-16.jpg",
];
export default prvomajskaImagePaths;
