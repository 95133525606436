import Vue from 'vue';
import Vuex from 'vuex';
import auth from "@/store/auth";
import loader from "@/store/loader";
import shared from "@/store/shared";
import gyms from "@/store/gyms";
import imageViewer from "@/store/imageViewer";
import schedule from "@/store/schedule";
import trainings from "@/store/trainings";
import coaches from "@/store/coaches";
import onlineTrainings from "@/store/onlineTrainings";
import events from "@/store/events";
import blog from "@/store/blog";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        // googleConnected: false,
        // facebookConnected: false,
    },
    mutations: {
    },
    actions: {
    },
    modules: {auth, schedule, gyms, loader, shared, coaches, trainings, onlineTrainings, events, blog, imageViewer},
    plugins: [
        createPersistedState({
            paths: [
                'auth',
                'coaches',
            ],
        }),
    ],
});
