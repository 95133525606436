const pionir = [
        'galleries/pionir/1.jpg',
        'galleries/pionir/2.jpg',
        'galleries/pionir/3.jpg',
        'galleries/pionir/4.jpg',
        'galleries/pionir/5.jpg',
        'galleries/pionir/6.jpg',
        'galleries/pionir/7.jpg',
        'galleries/pionir/8.jpg',
        'galleries/pionir/9.jpg',
        'galleries/pionir/10.jpg',
        'galleries/pionir/11.jpg',
        'galleries/pionir/12.jpg',
        'galleries/pionir/13.jpg',
        'galleries/pionir/14.jpg',
        'galleries/pionir/15.jpg',
        'galleries/pionir/16.jpg',
        'galleries/pionir/17.jpg'
    ]
export default pionir;