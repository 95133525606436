<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="20" height="20" viewBox="0 0 45 45" style="enable-background:new 0 0 1000 1000;" xml:space="preserve">
        <path class="st0" d="M40.83,0H3.17C1.42,0,0,1.42,0,3.17v37.65C0,42.58,1.42,44,3.17,44H23.5V26.98h-5.71v-6.66h5.71v-4.9
            c0-5.68,3.47-8.78,8.54-8.78c2.43,0,4.52,0.18,5.12,0.26v5.94h-3.5c-2.76,0-3.29,1.31-3.29,3.23v4.24h6.6l-0.86,6.66h-5.74V44h10.45
            c1.75,0,3.17-1.42,3.17-3.17V3.17C44,1.42,42.58,0,40.83,0z"/>
    </svg>
</template>

<script>
    export default {
        name: "FacebookMobileIcon"
    }
</script>

<style scoped>
	.st0{fill:#808080;}
</style>