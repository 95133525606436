<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="30" height="40" viewBox="0 0 75 75" style="enable-background:new 0 0 1000 1000;" xml:space="preserve">
    <polygon class="st0" points="10.29,86.17 0,80 22.15,43.09 0,6.17 10.29,0 36.14,43.09 "/>
    </svg>
</template>

<script>
    export default {
        name: "CalendarArrowRightIcon"
    }
</script>

<style scoped>
    .st0{fill:#006961;}
</style>
