<template>
  <section class="w-full mx-auto my-4 flex flex-col items-center md:items-end">
    <div
      class="m-auto w-full md:h-full py-5 px-5 grid grid-cols-1 md:grid-cols-2"
    >
      <div class="m-2">
        <p class="text-white m-2 uppercase text-left xl:text-xl ml-0">
          {{ $t("full-name") }}
        </p>
        <input
          v-model="form.name"
          :class="$v.form.name.$error ? 'bg-orange-50' : 'bg-black-10'"
          class="w-full h-10 text-xl xl:text-1xl px-2 rounded-sm"
          type="text"
        />
      </div>

      <div class="m-2">
        <p class="text-white m-2 uppercase text-left xl:text-xl ml-0">
          {{ $t("municipality-address") }}
        </p>
        <input
          v-model="form.address"
          class="w-full h-10 text-xl xl:text-1xl px-2 rounded-sm bg-black-10"
          type="text"
        />
      </div>

      <div class="m-2 relative">
        <p class="text-white m-2 uppercase text-left xl:text-xl ml-0">
          {{ $t("mobile-phone") }}
        </p>
        <span
          v-if="
            ($v.form.phone_1.$error && !$v.form.phone_1.minLength) ||
            ($v.form.phone_1.$error && !$v.form.phone_1.maxLength)
          "
          class="italic text-orange-50 ml-2 text-sm absolute right-0 top-2 xl:top-3"
        >
          *{{
            !$v.form.phone_1.minLength
              ? $t("minimum-eight-digits")
              : $t("maximum-fifteen-digits")
          }}
        </span>
        <input
          v-model="form.phone_1"
          class="w-full h-10 text-xl xl:text-1xl px-2 rounded-sm"
          placeholder="06xxxxxxxx"
          :class="$v.form.phone_1.$error ? 'bg-orange-50' : 'bg-black-10'"
          type="number"
        />
      </div>

      <div class="m-2 relative">
        <p class="text-white m-2 uppercase text-left xl:text-xl ml-0">
          {{ $t("email") }}
        </p>
        <span
          v-if="$v.form.email.$error && !$v.form.email.email"
          class="italic text-orange-50 ml-2 text-sm absolute right-0 top-2 xl:top-3"
        >
          *{{ $t("bad-email-format") }}
        </span>
        <input
          v-model="form.email"
          :class="$v.form.email.$error ? 'bg-orange-50' : 'bg-black-10'"
          class="w-full h-10 text-xl xl:text-1xl px-2 rounded-sm"
          type="email"
        />
      </div>
      <div class="m-2 relative">
        <p class="text-white m-2 uppercase text-left xl:text-xl ml-0">
          {{ $t("training-type") }}
        </p>

        <select
          v-model="form.wish_to_try"
          :class="$v.form.wish_to_try.$error ? 'bg-orange-50' : 'bg-black-10'"
          class="w-full h-10 px-2 text-xl xl:text-1xl rounded-sm"
        >
          <option :value="null" disabled hidden>
            {{ $t("choose-training") }}
          </option>
          <option
            v-for="training in trainings"
            :value="training.value"
            :key="training.name"
          >
            {{ $t(training.name) }}
          </option>
        </select>
      </div>
      <div class="m-2 relative">
        <p class="text-white m-2 uppercase text-left xl:text-xl ml-0">
          {{ $t("gym-location") }}
        </p>
        <select
          v-model="form.gym_location"
          :class="$v.form.gym_location.$error ? 'bg-orange-50' : 'bg-black-10'"
          class="w-full h-10 px-2 text-xl xl:text-1xl rounded-sm"
        >
          <option :value="null" disabled hidden>{{ $t("choose-gym") }}</option>
          <option v-for="gym in gyms" :value="gym.value" :key="gym.value">
            {{ gym.label }}
          </option>
        </select>
      </div>
    </div>
    <div class="xl:w-12/12">
      <div class="flex m-4">
        <div class="mx-2">
          <button
            class="bg-yellow-50 px-4 py-1 rounded text-btn"
            @click="submitForm"
          >
            {{ $t("send") }}
          </button>
        </div>
      </div>
    </div>
    <successfully-sent-form-modal
      :modal_name="'success_info_modal'"
    ></successfully-sent-form-modal>
  </section>
</template>

<script>
import SuccessfullySentFormModal from "../../components/SuccessfullySentFormModal.vue";

import {
  required,
  maxLength,
  minLength,
  email,
} from "vuelidate/lib/validators";

export default {
  name: "LandingForm",
  components: { SuccessfullySentFormModal },

  data() {
    return {
      trainings: [
        { value: "samostalni_trening", name: "individual-training" },
        { value: "personalni_trening", name: "personal-training" },
        { value: "grupni_fitnes", name: "group-fitness" },
      ],
      gyms: [
        { value: "Banjica", label: "Banjica" },
        { value: "Navigator", label: "Navigator" },
        { value: "Zemun", label: "Zemun" },
        { value: "Pionir", label: "Pionir" },
        { value: "Vracar", label: "Vračar" },
        { value: "Usce", label: "Ušće" },
        { value: "Blokovi", label: "Blokovi" },
        { value: "Ustanicka", label: "Ustanička" },
        { value: "Prvomajska", label: "Prvomajska" },
      ],
    };
  },
  props: ["form"],
  validationGroup: ["form"],
  validations: {
    form: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      phone_1: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(15),
      },
      wish_to_try: {
        required,
      },
      gym_location: {
        required,
      },
    },
  },
  methods: {
    async submitForm() {
      console.log(window.dataLayer);
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;
      await this.$http
        .post("/form/lending_page", this.form)
        .then((resp) => {
          this.handle_success_response("SUC_SUCCESS");
          window.dataLayer.push({ event: "formSubmit" });
          this.$modal.show("success_info_modal");
          this.tab_index = 0;
        })
        .catch((err) => {
          this.handle_error(err);
        });
    },
    async getTrainings() {
      const { data } = await this.$http.get("/training");
      this.trainings = data;
    },
  },
};
</script>
