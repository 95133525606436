import http from "../config/http";
export default {
    namespaced: true,
    state: {
        banjica: [],
        blokovi: [],
        pionir: [],
        ustanicka: [],
        usce: [],
        prvomajska: [],
        zemun: [],
        vracar: [],
        navigator: [],
        gyms: [],
        rooms: [],
        selectedRoom: null,
    },
    mutations: {
        setAllGyms(state, data) {
            data.forEach((item) => {
                if (item.name.toLowerCase().includes('banjica')) {
                    state.banjica = item;
                } else if (item.name.toLowerCase().includes('blokovi')) {
                    state.blokovi = item;
                } else if (item.name.toLowerCase().includes('pionir')) {
                    state.pionir = item;
                } else if (item.name.toLowerCase().includes('ustanička')) {
                    state.ustanicka = item;
                } else if (item.name.toLowerCase().includes('ušće')) {
                    state.usce = item;
                } else if (item.name.toLowerCase().includes('zemun')) {
                    state.zemun = item;
                } else if (item.name.toLowerCase().includes('vračar')) {
                    state.vracar = item;
                } else if (item.name.toLowerCase().includes('navigator')) {
                    state.navigator = item;
                } else if (item.name.toLowerCase().includes('prvomajska')) {
                    state.prvomajska = item;
                }
            });
        },
        setGyms(state, data) {
            state.gyms = data;
        },
        setRooms(state, data) {
            state.rooms = data;
        },
        setSelectedRoom(state, data) {
            state.selectedRoom = data;
        }
    },
    actions: {
        async fetchAll(context) {
            context.commit('loader/setLoading', true, { root: true });
            const {data} = await http.get('/gym');
            if (data) {
                context.commit("setAllGyms", data);
                context.commit("setGyms", data);
                context.commit('loader/setLoading', false, { root: true });
            }
        },
        async fetchRooms(context, gymId) {
            context.commit('loader/setLoading', true, { root: true });
            const { data } = await http.get(`/gym/${gymId}/gym_room`);
            if (data) {
                context.commit("setRooms", data);
                context.commit("setSelectedRoom", data[0].id);
                context.commit('loader/setLoading', false, { root: true });
            }
        },
    },
    getters: {
        getAllGyms(state) {
            const gyms = {
                banjica: state.banjica,
                blokovi: state.blokovi,
                pionir: state.pionir,
                ustanicka: state.ustanicka,
                usce: state.usce,
                zemun: state.zemun,
                vracar: state.vracar,
                navigator: state.navigator,
                prvomajska: state.prvomajska,
            };
          return gyms;
        },
        getGyms(state) {
            return state.gyms;
        },
        getRooms(state) {
            return state.rooms;
        },
        getSelectedRoom(state) {
            return state.selectedRoom;
        }
    }
}