<template>
  <div class="w-12/12 overflow-x-hidden">
    <div
      :class="resolution > 1023 ? 'pic1' : 'pic1mobile'"
      class="w-12/12 h-350 md:h-400 lg:h-500 xl:h-500 sticky overflow-x-hidden"
    >
      <div class="xl:w-2/3 mx-auto">
        <p
          class="text-white font-bold text-2xl md:text-4xl lg:text-5xl xl:text-6xl mx-8 md:mx-20 lg:mx-24 xl:mx-0 mt-5 pt-24 md:pt-32 md:mt-0 lg:pt-40 lg:mt-0 xl:pt-40 xl:mt-0"
        >
          {{ $t("sc-banjica") }}
        </p>
        <div
          class="absolute bottom-0 flex w-full xl:w-2/3 xl:mx-0 h-16 bg-purple-50 bg-opacity-50"
        >
          <div class="w-1/3 text-white border-r border-black-80 flex">
            <div class="m-auto">
              <p class="text-sm md:text-base lg:text-lg uppercase">
                {{ $t("sak-banjica-desc-3") }}
              </p>
              <p
                class="font-bold text-xs md:text-lg lg:text-1xl tracking-tight uppercase"
              >
                {{ $t("sak-banjica-desc-3-1") }}
              </p>
            </div>
          </div>
          <div class="w-1/3 text-white flex">
            <p
              class="text-sm md:text-base lg:text-lg m-auto font-bold uppercase tracking-wide"
            >
              {{ $t("sak-banjica-desc-4") }}
            </p>
          </div>
          <div class="w-1/3 text-white border-l border-black-80 flex">
            <div class="m-auto">
              <p class="text-sm md:text-base lg:text-lg uppercase">
                {{ $t("sak-banjica-desc-2") }}
              </p>
              <p class="font-bold text-xs md:text-lg lg:text-1xl uppercase">
                {{ $t("sak-banjica-desc-2-1") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-12/12 h-24 md:h-32 xl:h-40 flex my-10 mx-auto bg-purple-50">
      <div class="m-auto">
        <p
          class="text-white font-bold text-lg md:text-xl xl:text-2xl text-center mx-8 tracking-wide uppercase"
        >
          {{ $t("sak-banjica-desc-1") }}
        </p>
      </div>
    </div>

    <div class="w-12/12 xl:w-8/12 grid grid-cols-1 lg:grid-cols-3 xl:mx-auto">
      <div class="m-auto pb-4">
        <club-info
          :address="gyms.banjica.address"
          :tel="gyms.banjica.phone"
          :mob="gyms.banjica.phone_2"
          :workdays="gyms.banjica.weekdays"
          :saturday="gyms.banjica.saturday"
          :sunday="gyms.banjica.sunday"
        ></club-info>
      </div>

      <div class="col-span-2 lg:mr-5 xl:mr-0">
        <google-map :locations="locations" :centerMap="centerMap"></google-map>
      </div>
    </div>

    <div class="mt-16">
      <h1 class="text-white text-2xl md:text-3xl font-semibold leading-10">
        {{ $t("gallery") }}
      </h1>
    </div>
    <div
      class="h-full xl:w-2/3 grid grid-cols-3 xl:grid-cols-5 gap-1 md:gap-3 lg:gap-5 xl:gap-8 mx-auto px-1 py-16 md:mx-10 xl:mx-auto"
    >
      <div
        v-for="(image, i) in thumbnails"
        :key="i"
        class="w-32 h-29 md:w-48 md:h-40 lg:w-48 lg:h-40 xl:h-32 xl:w-40 mx-auto mb-4 bg-black-80 m-2 transform xl:hover:scale-110 cursor-pointer transition duration-200 ease-in-out"
        @click="openViewer(i)"
      >
        <img :src="`${image}`" alt="" style="width: 100%; height: 100%" />
      </div>
    </div>
    <app-image-viewer :modalName="'image_modal'"></app-image-viewer>
  </div>
</template>

<script>
import MainMessage from "../../components/MainMessage";
import GoogleMap from "../../components/GoogleMap";
import ClubInfo from "../../components/ClubInfo";
import LogoMobileGray from "../../icons/LogoMobileGray";
import banjica_thumbnails from "../../galleries/banjica_thumbnails";
import banjica from "../../galleries/banjica";
import AppImageViewer from "@/components/AppImageViewer";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "Banjica",
  metaInfo: {
    // Children can override the title.
    title: "Fitness & Gym Kočović | Teretana Kočović Beograd",
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    titleTemplate: "%s ← Teretana Beograd",
    // htmlAttrs: {
    //     lang: 'en',
    //     amp: true
    // },
    // Define meta tags here.
    meta: [
      // {"http-equiv": 'Content-Type', content: 'text/html; charset=utf-8'},
      // {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      {
        name: "description",
        content:
          "Teretana Kočović Vam nudi sve na jednom mestu. Izaberite trening po Vašim potrebama i prijavite se odmah! | Teretana Beograd | Banjica",
      },
      {
        name: "keywords",
        content:
          "" +
          "fitnes Beograd, " +
          "fitnes Banjica, " +
          "Teretana Banjica, " +
          "Les Mills Beograd, " +
          "grupni fitnes, " +
          "fitnes treninzi Beograd, " +
          "mršavljenje, " +
          "zatezanje, " +
          "oblikovanje tela, " +
          "trening snage, " +
          "Kardio, " +
          "Fitnes centar Beograd, " +
          "Gluteus, " +
          "Zadnjica, " +
          "Gubljenje kilograma/težine, " +
          "Celulit, " +
          "kardio treninzi, " +
          "gym belgrade",
      },
    ],
  },
  data() {
    return {
      centerMap: { lat: 44.763566, lng: 20.472464 },
      locations: [
        { position: { lat: 44.763566, lng: 20.472464 }, icon: "" }, // banjica
      ],
      imageViewerFlag: false,
      currentIndex: 1,
      title: "FOTO",
      imgUrlList: banjica,
      thumbnails: banjica_thumbnails,
    };
  },
  components: {
    MainMessage,
    GoogleMap,
    ClubInfo,
    LogoMobileGray,
    AppImageViewer,
  },
  mounted() {
    this.setResolution();
    this.fetchAll();
    this.setImages(this.imgUrlList);
  },
  computed: {
    ...mapGetters("gyms", {
      gyms: "getAllGyms",
    }),
    ...mapGetters("shared", {
      resolution: "getResolution",
    }),
  },
  methods: {
    ...mapMutations("shared", {
      setResolution: "setResolution",
    }),
    ...mapMutations("imageViewer", {
      setImgIndex: "setImgIndex",
      setImages: "setImages",
    }),
    ...mapActions("imageViewer", {
      openImage: "openImage",
    }),
    ...mapActions("gyms", {
      fetchAll: "fetchAll",
    }),
    openViewer(i) {
      this.setImgIndex(i);
      this.openImage({ imgUrlList: this.imgUrlList, index: i });
    },
  },
};
</script>
