<template>
    <div class="w-12/12 h-full text-black-20 bg-black-90">
        <div class="w-12/12 xl:w-10/12 xl:mx-auto grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-6
                    text-sm md:text-base mx-4 md:mx-32 lg:mx-40 lg:px-10 xl:flex xl:justify-around uppercase">
            <div class="py-10 border-b border-white xl:border-none xl:mx-auto hidden xl:block">
                <ul class="w-48 mx-auto pl-2 text-left">
                    <li class="mb-5 font-semibold text-white">
                        <a href="http://www.instagram.com/kocovicfitness" target="_blank">
                            <insta-mobile-icon class="float-left mr-5"></insta-mobile-icon>
                        </a>
                        <a href="https://www.facebook.com/SAKocovic" target="_blank">
                            <facebook-mobile-icon class="mr-4 float-left"></facebook-mobile-icon>
                        </a>
                        <a href="https://www.youtube.com/channel/UCfYaYz3IxXuCClBhzQtmMnw" target="_blank">
                            <youtube-mobile-icon class=""></youtube-mobile-icon>
                        </a>
                    </li>
                    <li class="mb-5 underline cursor-pointer" @click="$router.push('/')"><logo-mobile-gray class="h-40"></logo-mobile-gray></li>
                </ul>
            </div>
            <div class="py-10 border-b border-white xl:border-none xl:mx-auto">
                <ul class="text-left font-semibold">
                    <li class="mb-5 font-semibold text-white" @click="$router.push('/clubs')">{{ $t('locations') }}</li>
                    <li class="pb-1 hover:underline cursor-pointer" @click="$router.push('/banjica')">BANJICA</li>
                    <li class="pb-1 hover:underline cursor-pointer" @click="$router.push('/blokovi')">{{ $t('blokovi') }}</li>
                    <li class="pb-1 hover:underline cursor-pointer" @click="$router.push('/zemun')">ZEMUN</li>
                    <li class="pb-1 hover:underline cursor-pointer" @click="$router.push('/pionir')">{{ $t('pionir-hall') }}</li>
                    <li class="pb-1 hidden xl:block hover:underline cursor-pointer" @click="$router.push('/ustanicka')">USTANIČKA</li>
                    <li class="pb-1 hidden xl:block hover:underline cursor-pointer" @click="$router.push('/usce-sc')">SC UŠĆE</li>
                    <li class="pb-1 hidden xl:block hover:underline cursor-pointer" @click="$router.push('/vracar')">VRAČAR</li>
                    <li class="pb-1 hidden xl:block hover:underline cursor-pointer" @click="$router.push('/navigator')">NAVIGATOR</li>
                    <li class="pb-1  xl:block hover:underline cursor-pointer" @click="$router.push('/prvomajska')">PRVOMAJSKA</li>
                 </ul>
            </div>

             <div class="py-10 border-b border-white xl:border-none xl:mx-auto xl:hidden">
                <ul class="text-left font-semibold">
                    <li class="mb-5 font-semibold text-black-90 xl:hidden">.</li>
                    <li class="pb-1" @click="$router.push('/ustanicka')">USTANIČKA</li>
                    <li class="pb-1" @click="$router.push('/usce-sc')">SC UŠCE</li>
                    <li class="pb-1" @click="$router.push('/vracar')">VRAČAR</li>
                    <li class="pb-1" @click="$router.push('/navigator')">NAVIGATOR</li>
                </ul>
            </div>

            <div class="py-10 border-b border-white xl:border-none xl:mx-auto">
                <ul class="text-left font-semibold">
                    <li class="mb-5 font-semibold text-white">{{ $t('services') }}</li>
                    <li class="pb-1 hover:underline cursor-pointer hidden md:hidden lg:hidden xl:block" @click="$router.push('/gym-services')">
                       {{ $t('gym-1') }}
                    </li>
                    <li class="pb-1 hover:underline cursor-pointer hidden md:hidden lg:hidden xl:block" @click="$router.push('/gym-services')">
                        {{ $t('personal-training') }}
                    </li>
                    <li class="pb-5 hover:underline cursor-pointer hidden md:hidden lg:hidden xl:block" @click="$router.push('/gym-services')">
                        {{ $t('small-group-training') }}
                    </li>
                    <li class="pb-1 text-black xl:hidden hover:underline cursor-pointer hidden md:hidden lg:hidden" @click="$router.push('/freestyle-trainings')">
                       .
                    </li>

                      <li class="pb-1 hover:underline cursor-pointer xl:hidden" @click="$router.push('/gym-services')">
                       {{ $t('personal-training') }}
                    </li>
                    <li class="pb-1 hover:underline cursor-pointer xl:hidden" @click="$router.push('/gym-services')">
                        {{ $t('individual-training') }}
                    </li>
                    <li class="pb-5 hover:underline cursor-pointer xl:hidden" @click="$router.push('/gym-services')">
                        {{ $t('small-group-training') }}
                    </li>


                    <li class="pb-1 hover:underline cursor-pointer" @click="$router.push('/other-services')">
                        {{ $t('solarium') }}
                    </li>
                    <li class="pb-1 hover:underline cursor-pointer" @click="$router.push('/other-services')">
                        {{ $t('massage') }}
                    </li>
                    <li class="pb-1 hover:underline cursor-pointer" @click="$router.push('/other-services')">
                        HEALTH BAR
                    </li>
                    <li class="pb-1 pt-5 hover:underline cursor-pointer xl:hidden" @click="$router.push('/freestyle-trainings')">
                        {{ $t('freestyle-trainings') }}
                    </li>
                    <li class="pb-1 hover:underline cursor-pointer xl:hidden" @click="$router.push('/les-mills-trainings')">
                        {{ $t('les-mills-trainings') }}
                    </li>
                 </ul>
            </div>

             <div class="py-10 border-b border-white xl:border-none xl:mx-auto">
                <ul class="text-left font-semibold">
                     <li class="mb-5 text-black">
                        .
                    </li>
                    <li class="pb-1 hover:underline cursor-pointer hidden md:hidden lg:hidden xl:block" @click="$router.push('/freestyle-trainings')">
                        {{ $t('freestyle-trainings') }}
                    </li>
                    <li class="pb-1 hover:underline cursor-pointer hidden md:hidden lg:hidden xl:block" @click="$router.push('/les-mills-trainings')">
                        {{ $t('les-mills-trainings') }}
                    </li>
                    <ul class="mx-auto text-left font-semibold xl:hidden">
<!--                        <li class="mb-5 font-semibold text-white" @click="$router.push('/training-schedule')">{{ $t('training-schedule') }}</li>-->
                        <li class="mb-5 font-semibold text-white" @click="$router.push('/coaches')">{{ $t('team') }}</li>
                        <li class="mb-5 text-white">{{ $t('news') }}</li>
                        <li class="pb-1 hover:underline cursor-pointer" @click="$router.push('/events')">{{ $t('events') }}</li>
                        <li class="pb-1 hover:underline cursor-pointer" @click="$router.push('/blog')">BLOG</li>
                    </ul>
                </ul>
            </div>

            <div class="py-10 border-b border-white xl:border-none xl:mx-auto hidden md:hidden lg:hidden xl:block">
                <ul class="text-left font-semibold">
                     <li class="mb-5 text-white">
                        {{ $t('news') }}
                    </li>
                    <li class="pb-1 hover:underline cursor-pointer" @click="$router.push('/events')">
                       {{ $t('events') }}
                    </li>
                    <li class="pb-1 hover:underline cursor-pointer" @click="$router.push('/blog')">
                        BLOG
                    </li>
                </ul>
            </div>

            <div class="py-10 border-b border-white xl:border-none xl:mx-auto">
                <ul class="mx-auto text-left font-semibold">
                    <li class="mb-5 font-semibold text-white">{{ $t('contact') }}</li>
                    <li class="pb-1 hover:underline cursor-pointer" @click="$router.push('/price-list')">{{ $t('price-list') }}</li>
                    <li class="pb-1 hover:underline cursor-pointer" @click="$router.push('/free-training')">{{ $t('free-training') }}</li>
                    <li class="pb-1 hover:underline cursor-pointer" @click="$router.push('/training-selection')">{{ $t('find-your-training') }}</li>
                    <li class="pb-1 hover:underline cursor-pointer" @click="$router.push('/become-a-team-member')">{{ $t('become-a-team-member') }}</li>
                </ul>
            </div>

            <div class="py-5 mt-1 border-b border-white xl:border-none xl:mx-auto">
                <ul class="mx-auto pl-2 text-left block xl:hidden">
                    <li class="mb-5 font-semibold text-white">
                        <a href="http://www.instagram.com/kocovicfitness" target="_blank">
                            <insta-mobile-icon class="float-left mr-5"></insta-mobile-icon>
                        </a>
                    </li>
                    <a href="https://www.facebook.com/SAKocovic" target="_blank">
                        <facebook-mobile-icon class="mr-4 float-left"></facebook-mobile-icon>
                    </a>
                    <li>
                        <a href="https://www.youtube.com/channel/UCfYaYz3IxXuCClBhzQtmMnw" target="_blank">
                            <youtube-mobile-icon class=""></youtube-mobile-icon>
                        </a>
                    </li>
                    <li class="mb-5 underline" @click="$router.push('/')"><logo-mobile-gray class="h-40"></logo-mobile-gray></li>
                </ul>
            </div>
        </div>
    </div>
</template>



<script>
    import InstaMobileIcon from "../../icons/InstaMobileIcon";
    import FacebookMobileIcon from "../../icons/FacebookMobileIcon";
    import YoutubeMobileIcon from "../../icons/YoutubeMobileIcon";
    import LogoMobileGray from "../../icons/LogoMobileGray";
    export default {
        name: "Footer",
        data() {
            return {
                clubs: [],
            }
        },
        components: {
            InstaMobileIcon,
            FacebookMobileIcon,
            YoutubeMobileIcon,
            LogoMobileGray
        },
        mounted() {
            this.getClubs();
        },
        methods: {
            async getClubs() {
                const {data} = await this.$http.get('/gym');
                this.clubs = data;
            },
        }
    }
</script>