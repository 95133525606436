<template>
  <div class="w-12/12 overflow-x-hidden">
    <div
      class="w-12/12 h-350 md:h-400 lg:h-500 xl:h-500 sticky overflow-x-hidden"
      :class="resolution > 1024 ? 'pic8' : 'pic8mobile'"
    >
      <div class="xl:w-2/3 mx-auto">
        <p
          class="text-white text-center font-bold text-2xl md:text-4xl lg:text-5xl xl:text-6xl mx-8 md:mx-20 lg:mx-24 xl:mx-0 mt-5 pt-24 md:pt-32 md:mt-0 lg:pt-40 lg:mt-0 xl:pt-40 xl:mt-0"
        >
          PRVOMAJSKA
        </p>
        <div
          class="absolute bottom-0 flex w-full xl:w-2/3 xl:mx-0 h-16 bg-yellow-50 bg-opacity-50"
        >
          <div class="w-1/2 text-black-80 border-black-80 flex">
            <div class="m-auto">
              <p class="text-sm md:text-base lg:text-lg uppercase">
                {{ $t("sak-prvomajska-desc-1") }}
              </p>
              <p
                class="font-bold text-xs md:text-lg lg:text-1xl tracking-tight uppercase"
              >
                {{ $t("sak-prvomajska-desc-1-1") }}
              </p>
            </div>
          </div>
          <div class="w-1/2 text-black-80 border-black-80 border-l flex">
            <div class="m-auto">
              <!-- <p class="text-sm md:text-base lg:text-lg uppercase">
                {{ $t("sak-prvomajska-desc-1") }}
              </p> -->
              <p
                class="font-bold text-xs md:text-lg lg:text-1xl tracking-tight uppercase"
              >
                {{ $t("sak-prvomajska-desc-2") }}
              </p>
            </div>
          </div>
          <div class="w-1/2 text-black-80 border-l border-black-80 flex">
            <div class="m-auto">
              <p class="text-sm md:text-base lg:text-lg uppercase">
                {{ $t("sak-prvomajska-desc-3") }}
              </p>
              <p
                class="font-bold text-xs md:text-lg lg:text-1xl uppercase tracking-tight"
              >
                {{ $t("sak-prvomajska-desc-3-1") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="w-12/12 h-full py-12 md:py-16 bg-yellow-50 mt-12 md:mt-16 lg:mt-20"
    >
      <div
        class="w-12/12 md:w-10/12 lg:w-7/12 xl:w-6/12 mx-auto bg-yellow-50 h-full grid grid-cols-2"
      >
        <div class="flex">
          <p
            class="font-semibold text-black-70 px-6 text-center uppercase md:text-lg lg:text-xl xl:text-1xl"
          >
            {{ $t("sak-prvomajska-desc-4") }}
          </p>
        </div>
        <div class="text-white border-l border-black-70 flex">
          <p
            class="font-semibold text-black-70 text-center m-auto px-5 uppercase md:text-lg lg:text-xl xl:text-1xl"
          >
            {{ $t("sak-prvomajska-desc-5") }}
          </p>
        </div>
      </div>
    </div>

    <div
      class="w-12/12 xl:w-8/12 grid grid-cols-1 mt-10 lg:grid-cols-3 xl:mx-auto"
    >
      <div class="m-auto pb-4">
        <club-info
          :address="gyms.prvomajska.address"
          :tel="gyms.prvomajska.phone"
          :mob="gyms.prvomajska.phone_2"
          :workdays="gyms.prvomajska.weekdays"
          :saturday="gyms.prvomajska.saturday"
          :sunday="gyms.prvomajska.sunday"
        ></club-info>
      </div>
      <div class="col-span-2 lg:mr-5 xl:mr-0">
        <google-map :locations="locations" :centerMap="centerMap"></google-map>
      </div>
    </div>

    <div class="mt-16">
      <h1 class="text-white text-2xl md:text-3xl font-semibold leading-10">
        {{ $t("gallery") }}
      </h1>
    </div>
    <div
      class="h-full xl:w-2/3 grid grid-cols-3 xl:grid-cols-5 gap-1 md:gap-3 lg:gap-5 xl:gap-8 mx-auto px-1 py-16 md:mx-10 xl:mx-auto"
    >
      <div
        v-for="(image, i) in thumbnails"
        :key="i"
        class="w-32 h-29 md:w-48 md:h-40 lg:w-48 lg:h-40 xl:h-32 xl:w-40 mx-auto mb-4 bg-black-80 m-2 transform xl:hover:scale-110 cursor-pointer transition duration-200 ease-in-out"
        @click="openViewer(i)"
      >
        <img :src="`${image}`" alt="" style="width: 100%; height: 100%" />
      </div>
    </div>
    <app-image-viewer :modalName="'image_modal'"></app-image-viewer>
  </div>
</template>

<script>
import MainMessage from "../../components/MainMessage";
import GoogleMap from "../../components/GoogleMap";
import ClubInfo from "../../components/ClubInfo";
import facebookIcon from "../../icons/FacebookIcon";
import AppImageViewer from "@/components/AppImageViewer";
import prvomajskaImagePaths from "../../galleries/prvomajska";

import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "Prvomajska",
  metaInfo: {
    title: "Fitness & Gym Kočović | Teretana Kočović Beograd",
    titleTemplate: "%s ← Teretana Beograd",
    meta: [
      {
        name: "description",
        content:
          "Teretana Kočović Vam nudi sve na jednom mestu. Izaberite trening po Vašim potrebama i prijavite se odmah! | Teretana Beograd | Prvomajska",
      },
      {
        name: "keywords",
        content:
          "" +
          "fitnes Beograd, " +
          "fitnes Prvomajska, " +
          "teretana Beograd, " +
          "teretana Prvomajska, " +
          "Les Mills Beograd, " +
          "grupni fitnes, " +
          "fitnes treninzi Beograd, " +
          "mršavljenje, " +
          "zatezanje, " +
          "oblikovanje tela, " +
          "Joga Prvomajska, " +
          "Joga Beograd, " +
          "trening snage, " +
          "Kardio, " +
          "Fitnes centar Beograd, " +
          "Gluteus, " +
          "Zadnjica, " +
          "Gubljenje kilograma/težine, " +
          "Celulit, " +
          "kardio treninzi, " +
          "gym belgrade",
      },
    ],
  },
  data() {
    return {
      centerMap: { lat: 44.807412, lng: 20.4194301 },
      locations: [
        { position: { lat: 44.807412, lng: 20.4194301 }, icon: "" }, // navigator
      ],
      imageViewerFlag: false,
      currentIndex: 1,
      imgUrlList: prvomajskaImagePaths,
      thumbnails: prvomajskaImagePaths,
    };
  },
  components: {
    MainMessage,
    GoogleMap,
    ClubInfo,
    facebookIcon,
    AppImageViewer,
  },
  mounted() {
    this.setResolution();
    this.fetchAll();
    this.setImages(this.imgUrlList);
  },
  computed: {
    ...mapGetters("gyms", {
      gyms: "getAllGyms",
    }),
    ...mapGetters("shared", {
      resolution: "getResolution",
    }),
  },
  methods: {
    ...mapMutations("shared", {
      setResolution: "setResolution",
    }),
    ...mapActions("gyms", {
      fetchAll: "fetchAll",
    }),
    ...mapMutations("imageViewer", {
      setImgIndex: "setImgIndex",
      setImages: "setImages",
    }),
    ...mapActions("imageViewer", {
      openImage: "openImage",
    }),
    openViewer(i) {
      this.setImgIndex(i);
      this.openImage({ imgUrlList: this.imgUrlList, index: i });
    },
  },
};
</script>
