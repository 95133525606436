const ustanicka_thumbnails = [
        'galleries/ustanicka_thumbnails/1_thumb.jpg',
        'galleries/ustanicka_thumbnails/2_thumb.jpg',
        'galleries/ustanicka_thumbnails/3_thumb.jpg',
        'galleries/ustanicka_thumbnails/4_thumb.jpg',
        'galleries/ustanicka_thumbnails/5_thumb.jpg',
        'galleries/ustanicka_thumbnails/6_thumb.jpg',
        'galleries/ustanicka_thumbnails/7_thumb.jpg',
        'galleries/ustanicka_thumbnails/8_thumb.jpg',
        'galleries/ustanicka_thumbnails/9_thumb.jpg',
        'galleries/ustanicka_thumbnails/10_thumb.jpg',
        'galleries/ustanicka_thumbnails/11_thumb.jpg',
        'galleries/ustanicka_thumbnails/12_thumb.jpg',
        'galleries/ustanicka_thumbnails/13_thumb.jpg',
        'galleries/ustanicka_thumbnails/14_thumb.jpg',
        'galleries/ustanicka_thumbnails/15_thumb.jpg',
        'galleries/ustanicka_thumbnails/16_thumb.jpg',
        'galleries/ustanicka_thumbnails/17_thumb.jpg',
        'galleries/ustanicka_thumbnails/18_thumb.jpg',
        'galleries/ustanicka_thumbnails/19_thumb.jpg',
        'galleries/ustanicka_thumbnails/20_thumb.jpg',
        'galleries/ustanicka_thumbnails/21_thumb.jpg',
        'galleries/ustanicka_thumbnails/22_thumb.jpg',
        'galleries/ustanicka_thumbnails/23_thumb.jpg',
        'galleries/ustanicka_thumbnails/24_thumb.jpg',
        'galleries/ustanicka_thumbnails/25_thumb.jpg',
        'galleries/ustanicka_thumbnails/26_thumb.jpg',
        'galleries/ustanicka_thumbnails/27_thumb.jpg',
        'galleries/ustanicka_thumbnails/28_thumb.jpg',
        'galleries/ustanicka_thumbnails/29_thumb.jpg',
        'galleries/ustanicka_thumbnails/30_thumb.jpg',
    ]
export default ustanicka_thumbnails;