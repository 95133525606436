<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	  width="40" height="32" viewBox="0 0 100 100" style="enable-background:new 0 0 110 100;" xml:space="preserve">
    <g>
        <rect class="st0" width="110" height="8.11"/>
        <rect y="20.95" class="st0" width="110" height="8.11"/>
        <rect y="41.89" class="st0" width="110" height="8.11"/>
    </g>
</svg>

</template>

<script>
    export default {
        name: "MenuIcon"
    }
</script>

<style scoped>
	.st0{fill:#4D4D4D;}
</style>