<template>
  <main class="relative">
    <h2
      class="text-4xl font-semibold text-white mx-2 md:mx-20 my-10 text-center md:text-center uppercase"
    >
      {{ $t("why-is-kocovic-right-choice") }}
    </h2>
    <section
      class="flex flex-wrap sm:flex-col lg:flex-row justify-center md:justify-around items-center lg:items-start md:px-28 lg:px-40 lg:justify-around gap-4"
    >
      <div
        v-for="(option, index) in optionList"
        :key="index"
        class="w-full md:w-full lg:w-2/6 xl:w-1/5 flex flex-col items-center mx-2 md:mx-0 my-6"
      >
        <div class="flex flex-col items-center justify-end">
          <div :is="getOptionComponent(option)" />
          <p
            class="mt-6 text-center text-white text-xl uppercase max-w-xs font-semibold"
          >
            {{ $t(option) }}
          </p>
        </div>
      </div>
    </section>

    <h2
      class="text-4xl font-semibold light-blue mx-2 md:mx-20 my-10 text-center md:text-center uppercase"
    >
      {{ $t("ideal-training-for-you") }}
    </h2>
    <section
      class="flex flex-wrap justify-center md:justify-center lg:justify-center lg:flex-nowrap 2xl:justify-center 2xl:flex-nowrap gap-4 px-10 xl:px-40"
    >
      <div :class="containerClass">
        <figure class="flex sm:block sm:w-auto">
          <personal-icon></personal-icon>
        </figure>
        <h2 :class="containerHeadingClass">
          {{ $t("individual-training-why") }}
        </h2>
        <p :class="containerParagraphClass">
          {{ $t("question-about-practice") }}
        </p>
      </div>
      <div :class="containerClass">
        <figure class="flex sm:block sm:w-auto">
          <training-icon></training-icon>
        </figure>
        <h2 :class="containerHeadingClass">{{ $t("personal-training") }}</h2>
        <p :class="containerParagraphClass">{{ $t("plan-and-support") }}</p>
      </div>
      <div :class="containerClass">
        <figure class="flex sm:block sm:w-auto">
          <group-training-icon></group-training-icon>
        </figure>
        <h2 :class="containerHeadingClass">{{ $t("group-fitness") }}</h2>
        <p :class="containerParagraphClass">
          {{ $t("cardio-strength-and-relax") }}
        </p>
      </div>
    </section>
  </main>
</template>

<script>
import DecadesOfSuccessfulBussinesIcon from "../../icons/DecadesOfSuccessfulBussinesIcon.vue";
import EducatedTrainersIcon from "../../icons/EducatedTrainersIcon.vue";
import PersonalTrainingsIcon from "../../icons/PersonalTrainingsIcon.vue";
import SuberblyEquippedGymIcon from "../../icons/SuperblyEquippedGymIcon.vue";
import ModernCentersIcon from "../../icons/ModernCentersIcon.vue";
import FreeTrainingIcon from "../../icons/FreeTrainingIcon.vue";
import GreatAtmosphereIcon from "../../icons/GreatAtmosphereIcon.vue";
import FitnessTrainingsIcon from "../../icons/FitnessTrainingsIcon.vue";
import TrainingIcon from "../../icons/TrainingIcon.vue";
import PersonalIcon from "../../icons/PersonalIcon.vue";
import GroupTrainingIcon from "../../icons/GroupTrainingIcon.vue";

export default {
  name: "LandingPage",
  data() {
    return {
      optionList: [
        "decades-of-succces",
        "educated-trainers",
        "personal-trainers",
        "equipped-gym",
        "fitness-training",
        "modern-centers",
        "free-trial-training",
        "great-atmosphere",
      ],

      width: null,
      containerClass: "flex flex-col items-center pb-10 lg:w-3/6 2xl:w-2/3	",
      containerHeadingClass:
        "pt-4 pb-4 uppercase text-3xl font-semibold text-white",
      containerParagraphClass:
        "light-blue text-xl uppercase font-semibold w-2/3 py-1 font-size:xl",
    };
  },

  components: {
    DecadesOfSuccessfulBussinesIcon,
    EducatedTrainersIcon,
    PersonalTrainingsIcon,
    SuberblyEquippedGymIcon,
    FitnessTrainingsIcon,
    ModernCentersIcon,
    FreeTrainingIcon,
    GreatAtmosphereIcon,
    PersonalIcon,
    TrainingIcon,
    GroupTrainingIcon,
  },

  methods: {
    getOptionComponent(option) {
      switch (option) {
        case "decades-of-succces":
          return "DecadesOfSuccessfulBussinesIcon";
        case "educated-trainers":
          return "EducatedTrainersIcon";
        case "personal-trainers":
          return "PersonalTrainingsIcon";
        case "equipped-gym":
          return "SuberblyEquippedGymIcon";
        case "free-trial-training":
          return "FreeTrainingIcon";
        case "fitness-training":
          return "FitnessTrainingsIcon";
        case "modern-centers":
          return "ModernCentersIcon";
        case "great-atmosphere":
          return "GreatAtmosphereIcon";
        default:
          return "";
      }
    },
  },
};
</script>

<style>
.light-blue {
  color: #00fff0;
}
</style>
