<template>
    <div class="w-12/12 overflow-x-hidden select-none">
        <div
            class="w-12/12 h-350 md:h-400 lg:h-500 xl:h-500 sticky overflow-x-hidden pic9"
            :class="resolution > 767 ? 'pic9' : 'pic9mobile'"
        >
            <div class="xl:w-2/3 mx-auto">
                <p
                    class="text-white text-center font-bold text-2xl md:text-4xl lg:text-5xl xl:text-6xl mx-8 md:mx-20 lg:mx-24 xl:mx-0 mt-5 pt-24 md:pt-32 md:mt-0 lg:pt-40 lg:mt-0 xl:pt-40 xl:mt-0"
                >
                    {{ $t("pionir-hall") }}
                </p>

                <div
                    class="absolute bottom-0 flex w-full justify-center xl:w-2/3 xl:mx-0 h-16 "
                >
					<div class="w-1/3 text-white border-r border-black-80 bg-blue-20 bg-opacity-50 flex">
                        <div class="m-auto">
                            <p
                                class="text-sm md:text-base lg:text-lg uppercase"
                            >
                                {{ $t("sak-pionir-desc-1") }}
                            </p>
                            <p
                                class="font-bold text-xs md:text-lg lg:text-1xl tracking-tight uppercase"
                            >
                                {{ $t("sak-pionir-desc-1-1") }}
                            </p>
                        </div>
                    </div>
                    <div class="w-1/3 text-white border-black-80 bg-blue-20 bg-opacity-50 flex">
                        <div class="m-auto">
                            <p
                                class="text-sm md:text-base lg:text-lg uppercase"
                            >
                                {{ $t("sak-pionir-desc-2") }}
                            </p>
                            <p
                                class="font-bold text-xs md:text-lg lg:text-1xl uppercase tracking-tight"
                            >
                                {{ $t("sak-pionir-desc-2-1") }}
                            </p>
                        </div>
					</div>
                </div>
            </div>
        </div>

        <div
            class="w-12/12 h-full py-12 md:py-16 bg-blue-20 mt-12 md:mt-16 lg:mt-20"
        >
            <div
                class="w-12/12 md:w-10/12 lg:w-7/12 xl:w-6/12 mx-auto bg-blue-20 h-full grid grid-cols-2"
            >
                <div class="flex">
                    <p
                        class="font-semibold text-white px-6 text-center uppercase md:text-lg lg:text-xl xl:text-1xl"
                    >
                        {{ $t("sak-pionir-desc-4") }}
                    </p>
                </div>
                <div class="text-white border-l border-white flex">
                    <p
                        class="font-semibold text-white text-center m-auto px-5 uppercase md:text-lg lg:text-xl xl:text-1xl"
                    >
                        {{ $t("sak-pionir-desc-3") }}
                    </p>
                </div>
            </div>
        </div>

        <div
            class="w-12/12 xl:w-8/12 grid grid-cols-1 mt-10 lg:grid-cols-3 xl:mx-auto"
        >
            <div class="m-auto pb-4">
                <club-info
                    :address="gyms.pionir.address"
                    :tel="gyms.pionir.phone"
                    :mob="gyms.pionir.phone_2"
                    :workdays="gyms.pionir.weekdays"
                    :saturday="gyms.pionir.saturday"
                    :sunday="gyms.pionir.sunday"
                ></club-info>

                <div
                    class="pt-4 md:pt-6 lg:mt-0 xl:mt-0 mx-8 md:mx-20 lg:mx-24 xl:mx-0"
                >
                    <button
                        @click="$router.push('/training-schedule/4')"
                        class="hover:bg-blue-600 bg-blue-20 text-white text-btn py-2 px-5"
                    >
                        {{ $t("training-schedule") }}
                    </button>
                </div>

            </div>
            <div class="col-span-2 lg:mr-5 xl:mr-0">
                <google-map
                    :locations="locations"
                    :centerMap="centerMap"
                ></google-map>
            </div>
        </div>

        <div class="mt-16">
            <h1
                class="text-white text-2xl md:text-3xl font-semibold leading-10"
            >
                {{ $t("gallery") }}
            </h1>
        </div>
        <div
            class="h-full xl:w-2/3 grid grid-cols-3 xl:grid-cols-5 gap-1 md:gap-3 lg:gap-5 xl:gap-8 mx-auto px-1 py-16 md:mx-10 xl:mx-auto"
        >
            <div
                v-for="(image, i) in thumbnails"
                class="w-32 h-29 md:w-48 md:h-40 lg:w-48 lg:h-40 xl:h-32 xl:w-40 mx-auto mb-4 bg-black-80 m-2 transform xl:hover:scale-110 cursor-pointer transition duration-200 ease-in-out"
                @click="openViewer(i)"
            >
                <img
                    :src="`${image}`"
                    alt=""
                    style="width: 100%; height: 100%"
                />
            </div>
        </div>
        <app-image-viewer :modalName="'image_modal'"></app-image-viewer>
    </div>
</template>

<script>
import MainMessage from "../../components/MainMessage";
import GoogleMap from "../../components/GoogleMap";
import ClubInfo from "../../components/ClubInfo";
import facebookIcon from "../../icons/FacebookIcon";
import pionir from '../../galleries/pionir';
import pionir_thumbnails from '../../galleries/pionir_thumbnails';
import AppImageViewer from "@/components/AppImageViewer";
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
    name: "Pionir",
    metaInfo: {
        // Children can override the title.
        title: 'Fitness & Gym Kočović | Teretana Kočović Beograd',
        // Result: My Page Title ← My Site
        // If a child changes the title to "My Other Page Title",
        // it will become: My Other Page Title ← My Site
        titleTemplate: '%s ← Teretana Beograd',
        // htmlAttrs: {
        //     lang: 'en',
        //     amp: true
        // },
        // Define meta tags here.
        meta: [
            // {"http-equiv": 'Content-Type', content: 'text/html; charset=utf-8'},
            // {name: 'viewport', content: 'width=device-width, initial-scale=1'},
            {name: 'description', content: 'Teretana Kočović Vam nudi sve na jednom mestu. Izaberite trening po Vašim potrebama i prijavite se odmah! | Teretana Beograd | Pionir'},
            {
                name: 'keywords',
                content: '' +
                    'fitnes Beograd, ' +
                    'Fitnes Pionir, ' +
                    'Teretana Pionir, ' +
                    'Les Mills Beograd, ' +
                    'grupni fitnes, ' +
                    'fitnes treninzi Beograd, ' +
                    'mršavljenje, ' +
                    'zatezanje, ' +
                    'oblikovanje tela, ' +
                    'boks, ' +
                    'trening snage, ' +
                    'Kardio, ' +
                    'Fitnes centar Beograd, ' +
                    'Gluteus, ' +
                    'Zadnjica, ' +
                    'Gubljenje kilograma/težine, ' +
                    'Celulit, ' +
                    'kardio treninzi, ' +
                    'gym belgrade'
            },
        ],
    },
    data() {
        return {
            centerMap: { lat: 44.8156726, lng: 20.482527 },
            locations: [
                {position: { lat: 44.8156726, lng: 20.482527 }, icon:""}, // pionir
            ],
            imageViewerFlag: false,
            currentIndex: 1,
            title: 'Pionir',
            imgUrlList: pionir,
            thumbnails: pionir_thumbnails,
        }
    },
    components: {MainMessage, GoogleMap, ClubInfo, facebookIcon, AppImageViewer},
    mounted() {
        this.setResolution();
        this.fetchAll();
        this.setImages(this.imgUrlList);
    },
    computed: {
        ...mapGetters("gyms", {
            gyms: "getAllGyms"
        }),
        ...mapGetters("shared", {
            resolution: "getResolution"
        }),
    },
    methods: {
        ...mapMutations("shared", {
            setResolution: "setResolution"
        }),
        ...mapMutations("imageViewer", {
            setImgIndex: "setImgIndex",
            setImages: "setImages"
        }),
        ...mapActions("imageViewer", {
            openImage: "openImage"
        }),
        ...mapActions("gyms", {
            fetchAll: "fetchAll"
        }),
        openViewer(i) {
            this.setImgIndex(i);
            this.openImage({imgUrlList: this.imgUrlList, index: i});
        },
    }
}
</script>