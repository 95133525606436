<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="50" height="20" viewBox="0 0 85 45" style="enable-background:new 0 0 1000 1000;" xml:space="preserve">
<path class="st0" d="M61.7,6.9c-0.73-2.7-2.85-4.83-5.55-5.55C51.22,0,31.5,0,31.5,0S11.78,0,6.85,1.3C4.2,2.02,2.02,4.2,1.3,6.9
	C0,11.83,0,22.06,0,22.06s0,10.27,1.3,15.15c0.73,2.7,2.85,4.83,5.55,5.55c4.98,1.35,24.65,1.35,24.65,1.35s19.72,0,24.65-1.3
	c2.7-0.73,4.83-2.85,5.55-5.55C63,32.33,63,22.11,63,22.11S63.05,11.83,61.7,6.9z M25.22,31.5V12.61l16.4,9.44L25.22,31.5z"/>
</svg>
</template>

<script>
    export default {
        name: "YoutubeMobileIcon"
    }
</script>

<style scoped>
	.st0{fill:#808080;}
</style>