<template>
    <div class="w-12/12 bg-black-80">
        <div
            class="w-full flex justify-center h-250 md:h-300 lg:h-400"
            :class="resolution < 768 ? 'pic1mobile' : 'pic1'"
        >
            <p class="text-white uppercase text-center font-bold text-2xl md:text-4xl lg:text-5xl xl:text-6xl mx-8
                       md:mx-20 lg:mx-24 xl:mx-0 mt-5 pt-24 md:pt-32 md:mt-0 lg:pt-40 lg:mt-0 xl:pt-40 xl:mt-0">
                {{ $t('clubs') }}
            </p>
        </div>

        <div class="w-full md:w-11/12 xl:w-8/12 h-full grid grid-cols-1
                    md:grid-cols-4 gap-5 xl:gap-12 md:mx-auto xl:mx-auto my-10 xl:my-20">

            <div class="h-280 md:h-335 text-white bg-cyan-20 mx-1  my-2 cursor-pointer transform
                         xl:hover:scale-110 transition duration-500 ease-in-out flex">
                <div class="m-auto flex justify-center">
                    <div class="absolute top-0 pt-5 px-1" @click="$router.push('/banjica')">
                        <h1 class="text-1xl md:text-1xl lg:text-2xl font-bold uppercase">{{ gyms.banjica.name }}</h1>
                        <p class="font-semibold  md:text-lg lg:text-xl mt-1">{{ $t('address') }} :</p>
                        <p class="md:text-lg xl:text-xl tracking-wide">{{ gyms.banjica.address }}</p>
                        <p v-if="gyms.banjica.phone" class="font-semibold  md:text-lg lg:text-xl mt-1">Tel :</p>
                        <p v-if="gyms.banjica.phone" class="md:text-lg xl:text-xl tracking-wide">{{ gyms.banjica.phone }}</p>
                        <p v-if="gyms.banjica.phone_2" class="font-semibold  md:text-lg lg:text-xl mt-1">Mob :</p>
                        <p v-if="gyms.banjica.phone_2" class="md:text-lg xl:text-xl tracking-wide">{{ gyms.banjica.phone_2 }}</p>
                    </div>
                    <div class="absolute bottom-0 pb-5">
                        <div class="tooltip-clubs">
                            <icon v-if="gyms.banjica.gym" :icon="['fas', 'dumbbell']" class="mx-2 text-2xl"></icon>
                            <icon v-if="gyms.banjica.fitness" :icon="['fas', 'users']" class="mx-2 text-2xl"></icon>
                            <span v-if="gyms.banjica.gym && gyms.banjica.fitness && $i18n.locale === 'sr'" class="tooltip-text-clubs">{{ fitnessAndGymSrb }}</span>
                            <span v-if="gyms.banjica.gym && gyms.banjica.fitness && $i18n.locale === 'en'" class="tooltip-text-clubs">{{ fitnessAndGymEng }}</span>
                            <span v-if="gyms.banjica.gym && !gyms.banjica.fitness && $i18n.locale === 'sr'" class="tooltip-text-clubs">{{ gymSrb }}</span>
                            <span v-if="gyms.banjica.gym && !gyms.banjica.fitness && $i18n.locale === 'en'" class="tooltip-text-clubs">{{ gymEng }}</span>
                            <span v-if="!gyms.banjica.gym && gyms.banjica.fitness && $i18n.locale === 'sr'" class="tooltip-text-clubs">{{ fitnessSrb }}</span>
                            <span v-if="!gyms.banjica.gym && gyms.banjica.fitness && $i18n.locale === 'en'" class="tooltip-text-clubs">{{ fitnessEng }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="h-280 md:h-335 text-white bg-cyan-20 mx-1 my-2 cursor-pointer transform
                         xl:hover:scale-110 transition duration-500 ease-in-out flex">
                <div class="m-auto flex justify-center">
                    <div class="absolute top-0 pt-5 px-1" @click="$router.push('/blokovi')">
                        <h1 class="text-1xl md:text-1xl lg:text-2xl font-bold uppercase">{{ gyms.blokovi.name }}</h1>
                        <p class="font-semibold  md:text-lg lg:text-xl mt-1">{{ $t('address') }} :</p>
                        <p class="md:text-lg xl:text-xl tracking-wide">{{ gyms.blokovi.address }}</p>
                        <p v-if="gyms.blokovi.phone" class="font-semibold  md:text-lg lg:text-xl mt-1">Tel :</p>
                        <p v-if="gyms.blokovi.phone" class="md:text-lg xl:text-xl tracking-wide">{{ gyms.blokovi.phone }}</p>
                        <p v-if="gyms.blokovi.phone_2" class="font-semibold  md:text-lg lg:text-xl mt-1">Mob :</p>
                        <p v-if="gyms.blokovi.phone_2" class="md:text-lg xl:text-xl tracking-wide">{{ gyms.blokovi.phone_2 }}</p>
                    </div>
                    <div class="absolute bottom-0 pb-5">
                        <div class="tooltip-clubs">
                            <icon v-if="gyms.blokovi.gym" :icon="['fas', 'dumbbell']" class="mx-2 text-2xl"></icon>
                            <icon v-if="gyms.blokovi.fitness" :icon="['fas', 'users']" class="mx-2 text-2xl"></icon>
                            <span v-if="gyms.blokovi.gym && gyms.blokovi.fitness && $i18n.locale === 'sr'" class="tooltip-text-clubs">{{ fitnessAndGymSrb }}</span>
                            <span v-if="gyms.blokovi.gym && gyms.blokovi.fitness && $i18n.locale === 'en'" class="tooltip-text-clubs">{{ fitnessAndGymEng }}</span>
                            <span v-if="gyms.blokovi.gym && !gyms.blokovi.fitness && $i18n.locale === 'sr'" class="tooltip-text-clubs">{{ gymSrb }}</span>
                            <span v-if="gyms.blokovi.gym && !gyms.blokovi.fitness && $i18n.locale === 'en'" class="tooltip-text-clubs">{{ gymEng }}</span>
                            <span v-if="!gyms.blokovi.gym && gyms.blokovi.fitness && $i18n.locale === 'sr'" class="tooltip-text-clubs">{{ fitnessSrb }}</span>
                            <span v-if="!gyms.blokovi.gym && gyms.blokovi.fitness && $i18n.locale === 'en'" class="tooltip-text-clubs">{{ fitnessEng }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="h-280 md:h-335 text-white bg-cyan-20 mx-1 mt-2 cursor-pointer transform
                         xl:hover:scale-110 transition duration-500 ease-in-out flex">
                <div class="m-auto flex justify-center ">
                    <div class="absolute top-0 pt-5 px-1" @click="$router.push('/pionir')">
                        <h1 class="text-1xl md:text-1xl lg:text-2xl font-bold uppercase">{{ gyms.pionir.name }}</h1>
                        <p class="font-semibold  md:text-lg lg:text-xl mt-1">{{ $t('address') }} :</p>
                        <p class="md:text-lg xl:text-xl tracking-wide">{{ gyms.pionir.address }}</p>
                        <p v-if="gyms.pionir.phone" class="font-semibold  md:text-lg lg:text-xl mt-1">Tel :</p>
                        <p v-if="gyms.pionir.phone" class="md:text-lg xl:text-xl tracking-wide">{{ gyms.pionir.phone }}</p>
                        <p v-if="gyms.pionir.phone_2" class="font-semibold  md:text-lg lg:text-xl mt-1">Mob :</p>
                        <p v-if="gyms.pionir.phone_2" class="md:text-lg xl:text-xl tracking-wide">{{ gyms.pionir.phone_2 }}</p>
                    </div>
                    <div class="absolute bottom-0 pb-5">
                        <div class="tooltip-clubs">
                            <icon v-if="gyms.pionir.gym" :icon="['fas', 'dumbbell']" class="mx-2 text-2xl"></icon>
                            <icon v-if="gyms.pionir.fitness" :icon="['fas', 'users']" class="mx-2 text-2xl"></icon>
                            <span v-if="gyms.pionir.gym && gyms.pionir.fitness && $i18n.locale === 'sr'" class="tooltip-text-clubs">{{ fitnessAndGymSrb }}</span>
                            <span v-if="gyms.pionir.gym && gyms.pionir.fitness && $i18n.locale === 'en'" class="tooltip-text-clubs">{{ fitnessAndGymEng }}</span>
                            <span v-if="gyms.pionir.gym && !gyms.pionir.fitness && $i18n.locale === 'sr'" class="tooltip-text-clubs">{{ gymSrb }}</span>
                            <span v-if="gyms.pionir.gym && !gyms.pionir.fitness && $i18n.locale === 'en'" class="tooltip-text-clubs">{{ gymEng }}</span>
                            <span v-if="!gyms.pionir.gym && gyms.pionir.fitness && $i18n.locale === 'sr'" class="tooltip-text-clubs">{{ fitnessSrb }}</span>
                            <span v-if="!gyms.pionir.gym && gyms.pionir.fitness && $i18n.locale === 'en'" class="tooltip-text-clubs">{{ fitnessEng }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="h-280 md:h-335 text-white bg-cyan-20 mx-1 mt-2 cursor-pointer transform
                         xl:hover:scale-110 transition duration-500 ease-in-out flex">
                <div class="m-auto flex justify-center ">
                    <div class="absolute top-0 pt-5 px-1" @click="$router.push('/ustanicka')">
                        <h1 class="text-1xl md:text-1xl lg:text-2xl font-bold uppercase">{{ gyms.ustanicka.name }}</h1>
                        <p class="font-semibold  md:text-lg lg:text-xl mt-1">{{ $t('address') }} :</p>
                        <p class="md:text-lg xl:text-xl tracking-wide">{{ gyms.ustanicka.address }}</p>
                        <p v-if="gyms.ustanicka.phone" class="font-semibold  md:text-lg lg:text-xl mt-1">Tel :</p>
                        <p v-if="gyms.ustanicka.phone" class="md:text-lg xl:text-xl tracking-wide">{{ gyms.ustanicka.phone }}</p>
                        <p v-if="gyms.ustanicka.phone_2" class="font-semibold  md:text-lg lg:text-xl mt-1">Mob :</p>
                        <p v-if="gyms.ustanicka.phone_2" class="md:text-lg xl:text-xl tracking-wide">{{ gyms.ustanicka.phone_2 }}</p>
                    </div>
                    <div class="absolute bottom-0 pb-5">
                        <div class="tooltip-clubs">
                            <icon v-if="gyms.ustanicka.gym" :icon="['fas', 'dumbbell']" class="mx-2 text-2xl"></icon>
                            <icon v-if="gyms.ustanicka.fitness" :icon="['fas', 'users']" class="mx-2 text-2xl"></icon>
                            <span v-if="gyms.ustanicka.gym && gyms.ustanicka.fitness && $i18n.locale === 'sr'" class="tooltip-text-clubs">{{ fitnessAndGymSrb }}</span>
                            <span v-if="gyms.ustanicka.gym && gyms.ustanicka.fitness && $i18n.locale === 'en'" class="tooltip-text-clubs">{{ fitnessAndGymEng }}</span>
                            <span v-if="gyms.ustanicka.gym && !gyms.ustanicka.fitness && $i18n.locale === 'sr'" class="tooltip-text-clubs">{{ gymSrb }}</span>
                            <span v-if="gyms.ustanicka.gym && !gyms.ustanicka.fitness && $i18n.locale === 'en'" class="tooltip-text-clubs">{{ gymEng }}</span>
                            <span v-if="!gyms.ustanicka.gym && gyms.ustanicka.fitness && $i18n.locale === 'sr'" class="tooltip-text-clubs">{{ fitnessSrb }}</span>
                            <span v-if="!gyms.ustanicka.gym && gyms.ustanicka.fitness && $i18n.locale === 'en'" class="tooltip-text-clubs">{{ fitnessEng }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="h-280 md:h-335 text-white bg-cyan-20 mx-1 mt-2 cursor-pointer transform
                         xl:hover:scale-110 transition duration-500 ease-in-out flex">
                <div class="m-auto flex justify-center">
                    <div class="absolute top-0 pt-5 px-1" @click="$router.push('/usce-sc')">
                        <h1 class="text-1xl md:text-1xl lg:text-2xl font-bold uppercase">{{ gyms.usce.name }}</h1>
                        <p class="font-semibold  md:text-lg lg:text-xl mt-1">{{ $t('address') }} :</p>
                        <p class="md:text-lg xl:text-xl tracking-wide">{{ gyms.usce.address }}</p>
                        <p v-if="gyms.usce.phone" class="font-semibold  md:text-lg lg:text-xl mt-1">Tel :</p>
                        <p v-if="gyms.usce.phone" class="md:text-lg xl:text-xl tracking-wide">{{ gyms.usce.phone }}</p>
                        <p v-if="gyms.usce.phone_2" class="font-semibold  md:text-lg lg:text-xl mt-1">Mob :</p>
                        <p v-if="gyms.usce.phone_2" class="md:text-lg xl:text-xl tracking-wide">{{ gyms.usce.phone_2 }}</p>
                    </div>
                    <div class="absolute bottom-0 pb-5">
                        <div class="tooltip-clubs">
                            <icon v-if="gyms.usce.gym" :icon="['fas', 'dumbbell']" class="mx-2 text-2xl"></icon>
                            <icon v-if="gyms.usce.fitness" :icon="['fas', 'users']" class="mx-2 text-2xl"></icon>
                            <span v-if="gyms.usce.gym && gyms.usce.fitness && $i18n.locale === 'sr'" class="tooltip-text-clubs">{{ fitnessAndGymSrb }}</span>
                            <span v-if="gyms.usce.gym && gyms.usce.fitness && $i18n.locale === 'en'" class="tooltip-text-clubs">{{ fitnessAndGymEng }}</span>
                            <span v-if="gyms.usce.gym && !gyms.usce.fitness && $i18n.locale === 'sr'" class="tooltip-text-clubs">{{ gymSrb }}</span>
                            <span v-if="gyms.usce.gym && !gyms.usce.fitness && $i18n.locale === 'en'" class="tooltip-text-clubs">{{ gymEng }}</span>
                            <span v-if="!gyms.usce.gym && gyms.usce.fitness && $i18n.locale === 'sr'" class="tooltip-text-clubs">{{ fitnessSrb }}</span>
                            <span v-if="!gyms.usce.gym && gyms.usce.fitness && $i18n.locale === 'en'" class="tooltip-text-clubs">{{ fitnessEng }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="h-280 md:h-335 text-white bg-cyan-20 mx-1 mt-2 cursor-pointer transform
                         xl:hover:scale-110 transition duration-500 ease-in-out flex">
                <div class="m-auto flex justify-center ">
                    <div class="absolute top-0 pt-5 px-1" @click="$router.push('/zemun')">
                        <h1 class="text-1xl md:text-1xl lg:text-2xl font-bold uppercase">{{ gyms.zemun.name }}</h1>
                        <p class="font-semibold  md:text-lg lg:text-xl mt-1">{{ $t('address') }} :</p>
                        <p class="md:text-lg xl:text-xl tracking-wide">{{ gyms.zemun.address }}</p>
                        <p v-if="gyms.zemun.phone" class="font-semibold  md:text-lg lg:text-xl mt-1">Tel :</p>
                        <p v-if="gyms.zemun.phone" class="md:text-lg xl:text-xl tracking-wide">{{ gyms.zemun.phone }}</p>
                        <p v-if="gyms.zemun.phone_2" class="font-semibold  md:text-lg lg:text-xl mt-1">Mob :</p>
                        <p v-if="gyms.zemun.phone_2" class="md:text-lg xl:text-xl tracking-wide">{{ gyms.zemun.phone_2 }}</p>
                    </div>
                    <div class="absolute bottom-0 pb-5">
                        <div class="tooltip-clubs">
                            <icon v-if="gyms.zemun.gym" :icon="['fas', 'dumbbell']" class="mx-2 text-2xl"></icon>
                            <icon v-if="gyms.zemun.fitness" :icon="['fas', 'users']" class="mx-2 text-2xl"></icon>
                            <span v-if="gyms.zemun.gym && gyms.zemun.fitness && $i18n.locale === 'sr'" class="tooltip-text-clubs">{{ fitnessAndGymSrb }}</span>
                            <span v-if="gyms.zemun.gym && gyms.zemun.fitness && $i18n.locale === 'en'" class="tooltip-text-clubs">{{ fitnessAndGymEng }}</span>
                            <span v-if="gyms.zemun.gym && !gyms.zemun.fitness && $i18n.locale === 'sr'" class="tooltip-text-clubs">{{ gymSrb }}</span>
                            <span v-if="gyms.zemun.gym && !gyms.zemun.fitness && $i18n.locale === 'en'" class="tooltip-text-clubs">{{ gymEng }}</span>
                            <span v-if="!gyms.zemun.gym && gyms.zemun.fitness && $i18n.locale === 'sr'" class="tooltip-text-clubs">{{ fitnessSrb }}</span>
                            <span v-if="!gyms.zemun.gym && gyms.zemun.fitness && $i18n.locale === 'en'" class="tooltip-text-clubs">{{ fitnessEng }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="h-280 md:h-335 text-white bg-cyan-20 mx-1 mt-2 cursor-pointer transform
                         xl:hover:scale-110 transition duration-500 ease-in-out flex">
                <div class="m-auto flex justify-center ">
                    <div class="absolute top-0 pt-5 px-1" @click="$router.push('/vracar')">
                        <h1 class="text-1xl md:text-1xl lg:text-2xl font-bold uppercase">{{ gyms.vracar.name }}</h1>
                        <p class="font-semibold  md:text-lg lg:text-xl mt-1">{{ $t('address') }} :</p>
                        <p class="md:text-lg xl:text-xl tracking-wide">{{ gyms.vracar.address }}</p>
                        <p v-if="gyms.vracar.phone" class="font-semibold  md:text-lg lg:text-xl mt-1">Tel :</p>
                        <p v-if="gyms.vracar.phone" class="md:text-lg xl:text-xl tracking-wide">{{ gyms.vracar.phone }}</p>
                        <p v-if="gyms.vracar.phone_2" class="font-semibold  md:text-lg lg:text-xl mt-1">Mob :</p>
                        <p v-if="gyms.vracar.phone_2" class="md:text-lg xl:text-xl tracking-wide">{{ gyms.vracar.phone_2 }}</p>
                    </div>
                    <div class="absolute bottom-0 pb-5">
                        <div class="tooltip-clubs">
                            <icon v-if="gyms.vracar.gym" :icon="['fas', 'dumbbell']" class="mx-2 text-2xl"></icon>
                            <icon v-if="gyms.vracar.fitness" :icon="['fas', 'users']" class="mx-2 text-2xl"></icon>
                            <span v-if="gyms.vracar.gym && gyms.vracar.fitness && $i18n.locale === 'sr'" class="tooltip-text-clubs">{{ fitnessAndGymSrb }}</span>
                            <span v-if="gyms.vracar.gym && gyms.vracar.fitness && $i18n.locale === 'en'" class="tooltip-text-clubs">{{ fitnessAndGymEng }}</span>
                            <span v-if="gyms.vracar.gym && !gyms.vracar.fitness && $i18n.locale === 'sr'" class="tooltip-text-clubs">{{ gymSrb }}</span>
                            <span v-if="gyms.vracar.gym && !gyms.vracar.fitness && $i18n.locale === 'en'" class="tooltip-text-clubs">{{ gymEng }}</span>
                            <span v-if="!gyms.vracar.gym && gyms.vracar.fitness && $i18n.locale === 'sr'" class="tooltip-text-clubs">{{ fitnessSrb }}</span>
                            <span v-if="!gyms.vracar.gym && gyms.vracar.fitness && $i18n.locale === 'en'" class="tooltip-text-clubs">{{ fitnessEng }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="h-280 md:h-335 text-white bg-cyan-20 mx-1 mt-2 cursor-pointer transform
                         xl:hover:scale-110 transition duration-500 ease-in-out flex">
                <div class="m-auto flex justify-center ">
                    <div class="absolute top-0 pt-5 px-1" @click="$router.push('/navigator')">
                        <h1 class="text-1xl md:text-1xl lg:text-2xl font-bold uppercase">{{ gyms.navigator.name }}</h1>
                        <p class="font-semibold  md:text-lg lg:text-xl mt-1">{{ $t('address') }} :</p>
                        <p class="md:text-lg xl:text-xl tracking-wide">{{gyms. navigator.address }}</p>
                        <p v-if="gyms.navigator.phone" class="font-semibold  md:text-lg lg:text-xl mt-1">Tel :</p>
                        <p v-if="gyms.navigator.phone" class="md:text-lg xl:text-xl tracking-wide">{{ gyms.navigator.phone }}</p>
                        <p v-if="gyms.navigator.phone_2" class="font-semibold  md:text-lg lg:text-xl mt-1">Mob :</p>
                        <p v-if="gyms.navigator.phone_2" class="md:text-lg xl:text-xl tracking-wide">{{ gyms.navigator.phone_2 }}</p>
                    </div>
                    <div class="absolute bottom-0 pb-5">
                        <div class="tooltip-clubs">
                            <icon v-if="gyms.navigator.gym" :icon="['fas', 'dumbbell']" class="mx-2 text-2xl"></icon>
                            <icon v-if="gyms.navigator.fitness" :icon="['fas', 'users']" class="mx-2 text-2xl"></icon>
                            <span v-if="gyms.navigator.gym && gyms.navigator.fitness && $i18n.locale === 'sr'" class="tooltip-text-clubs">{{ fitnessAndGymSrb }}</span>
                            <span v-if="gyms.navigator.gym && gyms.navigator.fitness && $i18n.locale === 'en'" class="tooltip-text-clubs">{{ fitnessAndGymEng }}</span>
                            <span v-if="gyms.navigator.gym && !gyms.navigator.fitness && $i18n.locale === 'sr'" class="tooltip-text-clubs">{{ gymSrb }}</span>
                            <span v-if="gyms.navigator.gym && !gyms.navigator.fitness && $i18n.locale === 'en'" class="tooltip-text-clubs">{{ gymEng }}</span>
                            <span v-if="!gyms.navigator.gym && gyms.navigator.fitness && $i18n.locale === 'sr'" class="tooltip-text-clubs">{{ fitnessSrb }}</span>
                            <span v-if="!gyms.navigator.gym && gyms.navigator.fitness && $i18n.locale === 'en'" class="tooltip-text-clubs">{{ fitnessEng }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="xl:w-8/12 h-350 md:h-400 lg:h-500 bg-black-80 xl:mx-auto my-10 xl:my-20">
            <google-map :locations="locations" :centerMap="centerMap"></google-map>
        </div>
    </div>
</template>

<script>
import facebookIcon from "../../icons/FacebookIcon";
import MainMessage from "../../components/MainMessage";
import GoogleMap from "../../components/GoogleMap";
import LogoMobileGray from "../../icons/LogoMobileGray";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    name: "Clubs",
    data() {
        return {
            // resolution: null,
            // lt768: false,
            centerMap: {lat: 44.7866, lng: 20.4489},
            locations: [
                {position: { lat: 44.763566, lng: 20.472464 }, icon:""}, // banjica
                {position: { lat: 44.7926229, lng: 20.4820496 }, icon:""}, // vracar
                {position: { lat: 44.80334579, lng: 20.3831138 }, icon:""}, // blokovi
                {position: { lat: 44.8357549, lng: 20.4074158 }, icon:""}, // zemun
                {position: { lat: 44.8156726, lng: 20.482527 }, icon:""}, // pionir
                {position: { lat: 44.7823742, lng: 20.506747 }, icon:""}, // ustanicka
                {position: { lat: 44.8158837, lng: 20.434235 }, icon:""}, // usce
                {position: { lat: 44.807412, lng: 20.4194301 }, icon:""}, // navigator
            ],
            fitnessAndGymSrb: "Teretana i fitnes",
            fitnessAndGymEng: "Fitness and gym",
            gymSrb: "Teretana",
            gymEng: "Gym",
            fitnessSrb: "Fitnes",
            fitnessEng: "Fitness",

        }
    },
    components: {facebookIcon, MainMessage, GoogleMap, LogoMobileGray},
    mounted() {
        this.setResolution();
        this.fetchAll();
    },
    computed: {
        ...mapGetters("shared", {
            resolution: "getResolution"
        }),
        ...mapGetters("gyms", {
            gyms: "getAllGyms"
        })
    },
    methods: {
        ...mapMutations("shared", {
            setResolution: "setResolution"
        }),
        ...mapActions("gyms", {
            fetchAll: "fetchAll",
        }),
    }
}
</script>
