<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="16" height="16" viewBox="0 0 16 16" style="enable-background:new 0 0 100 100;" xml:space="preserve">
        <path class="st0" d="M14.85,0H1.15C0.52,0,0,0.52,0,1.15v13.69C0,15.48,0.52,16,1.15,16h7.39V9.81H6.47V7.39h2.08V5.61
            c0-2.07,1.26-3.19,3.11-3.19c0.88,0,1.64,0.07,1.86,0.1v2.16h-1.27c-1,0-1.2,0.48-1.2,1.18v1.54h2.4l-0.31,2.42h-2.09V16h3.8
            c0.64,0,1.15-0.52,1.15-1.15V1.15C16,0.52,15.48,0,14.85,0z"/>
    </svg>
</template>

<script>
    export default {
        name: "FacebookIconHome"
    }
</script>

<style scoped>
	.st0{fill:#808080;}
</style>