<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="100" height="100" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">

        <polygon class="st0" points="33.94,0 16.97,16.97 0,0 "/>
    </svg>
</template>

<script>
    export default {
        name: "TriangleIcon"
    }
</script>

<style scoped>
	.st0{fill:#1E1E1E;}
</style>