<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="40" height="40" viewBox="0 -10 120 120" style="enable-background:new 0 0 1000 1000;" xml:space="preserve">
    <g>
        <rect class="st0" width="16" height="16"/>
        <rect x="32" class="st0" width="16" height="16"/>
        <rect x="64" class="st0" width="16" height="16"/>
        <rect y="32" class="st0" width="16" height="16"/>
        <rect x="32" y="32" class="st0" width="16" height="16"/>
        <rect x="64" y="32" class="st0" width="16" height="16"/>
        <rect y="64" class="st0" width="16" height="16"/>
        <rect x="32" y="64" class="st0" width="16" height="16"/>
        <rect x="64" y="64" class="st0" width="16" height="16"/>
    </g>
    </svg>
</template>

<script>
    export default {
        name: "TrainingScheduleIcon"
    }
</script>

<style scoped>
        .st0{fill:#4D4D4D;}
</style>