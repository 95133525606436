import http from "@/config/http";
export default {
    namespaced: true,
    state: {
        coaches: [],
    },
    mutations: {
        setCoaches(state, data) {
            state.coaches = data;
        }
    },
    actions: {
        async fetchCoaches(context) {
            context.commit('loader/setLoading', true, { root: true });
            const { data } = await http.get('/trainer');
            if (data) {
                data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ) ? 1 : -1);
                context.commit("setCoaches", data);
                context.commit('loader/setLoading', false, { root: true });
            }
        }
    },
    getters: {
        getCoaches(state) {
            return state.coaches;
        }
    }
}