const zemun = [
  "galleries/zemun/1.jpg",
  "galleries/zemun/2.jpg",
  "galleries/zemun/3.jpg",
  "galleries/zemun/4.jpg",
  "galleries/zemun/5.jpg",
  "galleries/zemun/6.jpg",
  "galleries/zemun/7.jpg",
  "galleries/zemun/8.jpg",
  "galleries/zemun/9.jpg",
  "galleries/zemun/10.jpg",
];
export default zemun;
