<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="25" height="16" viewBox="0 0 25 16" style="enable-background:new 0 0 100 100;" xml:space="preserve">

    <path class="st0" d="M22.38,2.5c-0.26-0.98-1.04-1.75-2.01-2.01C18.58,0,11.43,0,11.43,0S4.27,0,2.48,0.47
        C1.52,0.73,0.73,1.52,0.47,2.5C0,4.29,0,8,0,8s0,3.73,0.47,5.5c0.26,0.98,1.04,1.75,2.01,2.01C4.29,16,11.43,16,11.43,16
        s7.15,0,8.94-0.47c0.98-0.26,1.75-1.04,2.01-2.01c0.47-1.79,0.47-5.5,0.47-5.5S22.87,4.29,22.38,2.5z M9.15,11.43V4.57L15.1,8
        L9.15,11.43z"/>
    </svg>

</template>

<script>
    export default {
        name: "YoutubeIcon"
    }
</script>

<style scoped>
	.st0{fill:#808080;}
</style>