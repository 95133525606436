const vracar = [
  "galleries/vracar/1.jpg",
  "galleries/vracar/2.jpg",
  "galleries/vracar/3.jpg",
  "galleries/vracar/4.jpg",
  "galleries/vracar/5.jpg",
  "galleries/vracar/6.jpg",
  "galleries/vracar/7.jpg",
  "galleries/vracar/8.jpg",
  "galleries/vracar/9.jpg",
  "galleries/vracar/10.jpg",
  "galleries/vracar/11.jpg",
  "galleries/vracar/12.jpg",
  "galleries/vracar/13.jpg",
  "galleries/vracar/14.jpg",
  "galleries/vracar/15.jpg",
  "galleries/vracar/16.jpg",
  "galleries/vracar/17.jpg",
  "galleries/vracar/18.jpg",
  "galleries/vracar/19.jpg",
  "galleries/vracar/20.jpg",
  "galleries/vracar/21.jpg",
  "galleries/vracar/22.jpg",
  "galleries/vracar/23.jpg",
  "galleries/vracar/24.jpg",
  "galleries/vracar/25.jpg",
  "galleries/vracar/26.jpg",
  "galleries/vracar/27.jpg",
];
export default vracar;
