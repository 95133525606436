import { render, staticRenderFns } from "./FacebookIcon.vue?vue&type=template&id=3412607c&scoped=true"
var script = {}
import style0 from "./FacebookIcon.vue?vue&type=style&index=0&id=3412607c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3412607c",
  null
  
)

export default component.exports