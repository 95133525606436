<template>
    <div class="select-none">
        <!--  MOBILE NAV START-->
        <div class="w-12/12 flex pb-2 h-full md:h-20 block lg:hidden" :class="isOpenNav ? 'bg-black' : 'bg-black-80'">
            <div class="w-11/12 mt-2 flex justify-center" @click="$router.push('/')">
                <logo class="h-12 md:h-16 w-48 md:w-full"></logo>
            </div>
            <div class="w-1/12 mr-8 md:mr-12" @click="toggleNavigation">
                <menu-icon v-if="!isOpenNav" class="h-10 md:h-16 md:w-20 w-10 mt-6 md:mt-6 md:pr-2"></menu-icon>
                <div v-if="isOpenNav" class="h-8 md:h-12 w-10 md:mt-0 md:pr-2 text-white text-4xl md:text-5xl">&times;</div>
            </div>
        </div>
        <transition name="slide-fade">
            <div
                v-if="isOpenNav"
                class="flex absolute z-10 top-16 w-screen pb-4 bg-black-90 bg-opacity-75 shadow-2xl transition duration-400 ease-in-out z-50"
                style="user-select: none;"
            >
                <ul class="mr-auto md:text-xl text-white text-left ml-8">
                    <li class="pt-3" @click="$router.push('/'); toggleNavigation();">
                        <icon icon="home" class="mr-1"></icon> {{ $t('home') }}
                    </li>
                    <li
                        class="pt-3"
                        :class="services ? 'text-orange-50 text-3xl' : ''"
                        @click="services = !services">
                        <icon icon="handshake" class="mr-1"></icon> {{ $t('services') }}
                    </li>
                    <transition name="slide-fade">
                        <ul v-if="services" class="transition duration-200 ease-in-out mb-8 ml-6 uppercase">
                            <li class="pt-2 uppercase">{{ $t('group-trainings') }} :</li>
                            <li
                                class="pt-2 text-sm md:text-base text-white ml-6"
                                @click="$router.push('/freestyle-trainings'); toggleNavigation(); services = false;">
                                <icon icon="running" class="mr-1"></icon> FREESTYLE
                            </li>
                            <li
                                class="pt-2 text-sm md:text-base text-white ml-6"
                                @click="$router.push('/les-mills-trainings'); toggleNavigation(); services = false;">
                                <icon icon="universal-access" class="mr-1"></icon> LES MILLS
                            </li>
                            <li class="pt-6">{{ $t('gym-1') }} :</li>
                            <li
                                @click="$router.push('/gym-services#personal'); toggleNavigation(); services = false;"
                                class="pt-2 text-sm md:text-base text-white ml-6">
                                <icon icon="user-friends" class="mr-1"></icon> {{ $t('personal-training') }}
                            </li>
                            <li
                                @click="$router.push('/gym-services#individual'); toggleNavigation(); services = false;"
                                class="pt-2 text-sm md:text-base text-white ml-6">
                                <icon icon="user" class="mr-1"></icon> {{ $t('individual-training') }}
                            </li>
                            <li
                                @click="$router.push('/gym-services#small'); toggleNavigation(); services = false;"
                                class="pt-2 text-sm md:text-base text-white ml-6">
                                <icon icon="users" class="mr-1"></icon> {{ $t('small-group-training') }}
                            </li>
                            <li class="pt-6 uppercase">{{ $t('other-services') }} :</li>
                            <li
                                class="pt-2 text-sm md:text-base text-white ml-6"
                                @click="$router.push('/other-services#solarium'); toggleNavigation(); services = false;">
                                <icon icon="sun" class="mr-1"></icon> {{ $t('solarium') }}
                            </li>
                            <li
                                class="pt-2 text-sm md:text-base text-white ml-6"
                                @click="$router.push('/other-services#massage'); toggleNavigation(); services = false;">
                                <icon icon="hand-holding-medical" class="mr-1"></icon> {{ $t('massage') }}
                            </li>
                            <li
                                class="pt-2 text-sm md:text-base text-white ml-6 "
                                @click="$router.push('/other-services#health-bar'); toggleNavigation(); services = false;">
                                <icon icon="heart" class="mr-1"></icon> HEALTH BAR
                            </li>
                        </ul>
                    </transition>
                    <!--  SERVICES END MOBILE NAV  -->


                    <!--  TRAINING SCHEDULE START MOBILE NAV  -->
                    <li
                        class="pt-3"
                        :class="schedule ? 'text-orange-50 text-3xl' : ''"
                        @click="schedule = !schedule">
                        <icon icon="calendar-alt" class="mr-1"></icon> {{ $t('training-schedule') }}
                    </li>
                    <transition name="slide-fade">
                        <ul v-if="schedule" class="transition duration-200 ease-in-out mb-8 ml-6">
                            <li
                                v-for="location in locations"
                                class="pt-2 text-sm md:text-base text-white ml-3 uppercase"
                                v-if="location.fitness && location.has_room"
                                @click="$router.push(`/training-schedule/${location.id}`); toggleNavigation(); schedule = false;">
                                <span>
                                     <icon icon="map-pin" class="mr-1"></icon> {{ location.name }}
                                </span>
                            </li>
                        </ul>
                    </transition>
                    <!--  TRAINING SCHEDULE END MOBILE NAV  -->


                    <li
                        class="pt-3 uppercase"
                        @click="$router.push('/coaches'); toggleNavigation();">
                        <icon icon="users" class="mr-1"></icon> {{ $t('team') }}
                    </li>

                    <li
                        class="pt-3 uppercase"
                        @click="$router.push('/online-trainings'); toggleNavigation();">
                        <icon icon="video" class="mr-1"></icon> {{ $t('online-trainings') }}
                    </li>

                    <!--  CLUBS START MOBILE NAV  -->
                    <li
                        class="pt-3"
                        :class="clubs ? 'text-orange-50 text-3xl' : ''"
                        @click="clubs = !clubs">
                        <icon icon="map-marker-alt" class="mr-1"></icon> {{ $t('clubs') }}
                    </li>
                    <transition name="slide-fade">
                        <ul v-if="clubs" class="transition duration-200 ease-in-out mb-8 ml-6">
                            <li
                                class="pt-2 text-sm md:text-base text-white ml-2"
                                @click="$router.push('/clubs'); toggleNavigation(); clubs = false;">
                                <icon icon="border-all" class="mr-1"></icon> {{ $t('all-clubs') }}
                            </li>
                            <li
                                class="pt-2 text-sm md:text-base text-white ml-3"
                                @click="$router.push('/banjica'); toggleNavigation(); clubs = false;">
                                <icon icon="map-pin" class="mr-1"></icon> BANJICA
                            </li>
                            <li
                                class="pt-2 text-sm md:text-base text-white ml-3"
                                @click="$router.push('/blokovi'); toggleNavigation(); clubs = false;">
                                <icon icon="map-pin" class="mr-1"></icon> BLOKOVI
                            </li>
                            <li
                                class="pt-2 text-sm md:text-base text-white ml-3"
                                @click="$router.push('/zemun'); toggleNavigation(); clubs = false;">
                                <icon icon="map-pin" class="mr-1"></icon> ZEMUN
                            </li>
                            <li
                                class="pt-2 text-sm md:text-base text-white ml-3"
                                @click="$router.push('/pionir'); toggleNavigation(); clubs = false;">
                                <icon icon="map-pin" class="mr-1"></icon> PIONIR
                            </li>
                            <li
                                class="pt-2 text-sm md:text-base text-white ml-3"
                                @click="$router.push('/ustanicka'); toggleNavigation(); clubs = false;">
                                <icon icon="map-pin" class="mr-1"></icon> USTANIČKA
                            </li>
                            <li
                                class="pt-2 text-sm md:text-base text-white ml-3"
                                @click="$router.push('/usce-sc'); toggleNavigation(); clubs = false;">
                                <icon icon="map-pin" class="mr-1"></icon> UŠĆE
                            </li>
                            <li
                                class="pt-2 text-sm md:text-base text-white ml-3"
                                @click="$router.push('/vracar'); toggleNavigation(); clubs = false;">
                                <icon icon="map-pin" class="mr-1"></icon> VRAČAR
                            </li>
                            <li
                                class="pt-2 text-sm md:text-base text-white ml-3"
                                @click="$router.push('/navigator'); toggleNavigation(); clubs = false;">
                                <icon icon="map-pin" class="mr-1"></icon> NAVIGATOR
                            </li>
                            <li
                                class="pt-2 text-sm md:text-base text-white ml-3"
                                @click="$router.push('/prvomajska'); toggleNavigation(); clubs = false;">
                                <icon icon="map-pin" class="mr-1"></icon> PRVOMAJSKA
                            </li>
                        </ul>
                    </transition>
                    <!--  CLUBS END MOBILE NAV  -->

                    <!-- NEWS NAV START -->
                    <li
                        class="pt-3 uppercase"
                        :class="news ? 'text-orange-50 text-3xl' : ''"
                        @click="news = !news">
                        <icon icon="book-open" class="mr-1"></icon> {{ $t('news') }}
                    </li>
                    <transition name="slide-fade">
                        <ul v-if="news" class="transition duration-200 text-white ease-in-out mb-8 ml-6">
                            <li
                                class="pt-2 text-sm md:text-base uppercase ml-3"
                                @click="$router.push('/events'); toggleNavigation(); news = false;">
                                <icon icon="fire-alt" class="mr-1"></icon> {{ $t('events') }}
                            </li>
                            <li
                                class="pt-2 text-sm md:text-base uppercase ml-3"
                                @click="$router.push('/blog'); toggleNavigation(); news = false;">
                                <icon icon="blog" class="mr-1"></icon> BLOG
                            </li>
                            <!--  PROMOTIONS START MOBILE NAV  -->
                            <li
                                v-if="activePromotionGyms && activePromotionGyms.length > 0"
                                class="pt-2 text-sm md:text-base uppercase ml-3"
                                :class="promotions ? 'text-orange-50 text-3xl' : ''"
                                @click="promotions = !promotions">
                                <icon icon="bolt" class="mr-1"></icon> {{ promotionName }}
                            </li>
                            <transition name="slide-fade">
                                <ul v-if="promotions" class="transition duration-200 ease-in-out mb-8 ml-6">
                                    <li
                                        v-for="gym in activePromotionGyms"
                                        class="pt-2 text-sm md:text-base text-white ml-3 uppercase"
                                        v-if="gym.fitness"
                                        @click="$router.push(`/promotions/${gym.id}`); toggleNavigation(); promotions = false;">
                                <span>
                                     <icon icon="map-pin" class="mr-1"></icon> {{ gym.name }}
                                </span>
                                    </li>
                                </ul>
                            </transition>
                            <!--  PROMOTIONS END MOBILE NAV  -->
                        </ul>
                    </transition>

                    <li
                        class="pt-3"
                        :class="contact ? 'text-orange-50 text-3xl' : ''"
                        @click="contact = !contact">
                        <icon icon="address-card" class="mr-1"></icon> {{ $t('contact') }}
                    </li>
                    <transition name="slide-fade">
                        <ul v-if="contact" class="transition duration-200 uppercase text-white ease-in-out mb-8 ml-6">
                            <li
                                class="pt-2 text-sm md:text-base ml-3"
                                @click="$router.push('/price-list'); toggleNavigation(); contact = false;">
                                <icon icon="money-bill-alt" class="mr-1"></icon> {{ $t('price-list') }}
                            </li>
                            <li
                                class="pt-2 text-sm md:text-base ml-3"
                                @click="$router.push('/training-selection'); toggleNavigation(); contact = false;">
                                <icon icon="search" class="mr-1"></icon> {{ $t('find-your-training') }}
                            </li>
                            <li
                                class="pt-2 text-sm md:text-base ml-3"
                                @click="$router.push('/free-training'); toggleNavigation(); contact = false;">
                                <icon icon="hand-point-right" class="mr-1"></icon> {{ $t('free-training') }}
                            </li>
                            <li
                                class="pt-2 text-sm md:text-base ml-3"
                                @click="$router.push('/become-a-team-member'); toggleNavigation(); contact = false;">
                                <icon icon="user-plus" class="mr-1"></icon> {{ $t('become-a-team-member') }}
                            </li>
                        </ul>
                    </transition>
                    <li
                        class="pt-3 uppercase pl-1"
                        @click="$router.push('/application'); toggleNavigation();">
                        <icon icon="mobile-alt" class="mr-1"></icon> {{ $t('application') }}
                    </li>
                    <li
                        v-if="!isLoggedIn"
                        @click="$router.push('/login'); toggleNavigation();"
                        class="pt-3"><icon icon="sign-in-alt" class="mr-1"></icon> {{ $t('log-in') }}
                    </li>
                    <li
                        v-if="isLoggedIn"
                        @click="logout"
                        class="pt-3 uppercase"><icon icon="sign-out-alt" class="mr-1"></icon> {{ $t('log-out') }}
                    </li>
                    <li class="pt-3 bg-black-80 bg-opacity-0">
                        <icon icon="language" class="mr-1"></icon>
                        <select
                            v-model="$i18n.locale"
                            name="langs"
                            id="langs"
                            class="bg-black-80 bg-opacity-0 text-white uppercase">
                            <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ lang }}</option>
                        </select>
                    </li>
                </ul>
            </div>
        </transition>
        <!--  END MOBILE NAV-->

        <!--  DESKTOP NAV START  -->
        <div class="w-screen h-10 bg-black-90 hidden lg:block select-none">
            <div class="w-full xl:w-9/12 bg-black-90 h-10 mx-auto">
                <ul class="mr-auto pl-2 xl:pl-0">
                    <li class="float-left mr-4 mt-1 cursor-pointer">
                        <a href="http://www.instagram.com/kocovicfitness" target="_blank">
                            <insta-icon class="mt-1"></insta-icon>
                        </a>
                    </li>
                    <li class="float-left mr-4 mt-1 cursor-pointer">
                        <a href="https://www.facebook.com/SAKocovic" target="_blank">
                            <facebook-nav-icon class="mt-1"></facebook-nav-icon>
                        </a>
                    </li>
                    <li class="float-left mr-4 mt-1 cursor-pointer">
                        <a href="https://www.youtube.com/channel/UCfYaYz3IxXuCClBhzQtmMnw" target="_blank">
                            <youtube-icon class="mt-1"></youtube-icon>
                        </a>
                    </li>
                </ul>
                <ul class="float-right flex text-btn">
                    <li class="cursor-pointer bg-black-90 mr-3">
                        <select
                            v-model="$i18n.locale"
                            name="langs"
                            class="bg-black-90 text-black-30 uppercase focus:outline-none">
                            <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ lang }}</option>
                        </select>
                    </li>
                    <li v-if="!isLoggedIn" @click="$router.push('/login')" data-test="h-login" class="bg-green-20 h-10 text-white w-full px-4 cursor-pointer uppercase">
                        {{ $t('log-in') }}
                    </li>
                    <li v-if="isLoggedIn"  @click="logout" class="bg-green-20 h-10 text-white w-full px-4 cursor-pointer uppercase">
                        {{ $t('log-out') }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="w-12/12 h-16 bg-white hidden lg:block">
            <div class="w-12/12 xl:w-9/12 flex mx-auto">
                <div class="w-2/12 h-16" @click="$router.push('/')">
                    <logo-web-nav
                        @click="$router.push('/')"
                        class="h-24 float-left mt-2 ml-2 xl:ml-0 cursor-pointer">
                    </logo-web-nav>
                </div>
                <div class="w-9/12 flex justify-center h-16 mt-1 text-btn">
                    <ul class="flex">
                        <li
                            @mouseover="clubs = true"
                            @mouseleave="clubs = false"
                            class="mr-5 my-3 cursor-pointer"
                            :class="clubs ? ' z-20 pb-10' : ''"
                        >
                            <p :class="clubs ? 'text-black-30' : ''">{{ $t('clubs') }}</p>
                            <ul v-if="clubs" class="w-56 pt-5 absolute bg-white z-10 show-slowly">
                                <li
                                    @click="$router.push('/clubs')"
                                    class="border-t border-black-20 leading-10 hover:bg-black-20 hover:text-white">
                                    {{ $t('all-clubs') }}
                                </li>
                                <li
                                    @click="$router.push('/banjica')"
                                    class="border-t border-black-20 leading-10 hover:bg-black-20 hover:text-white">
                                    BANJICA
                                </li>
                                <li
                                    @click="$router.push('/blokovi')"
                                    class="border-t border-black-20 leading-10 hover:bg-black-20 hover:text-white">
                                    BLOKOVI
                                </li>
                                <li
                                    @click="$router.push('/zemun')"
                                    class="border-t border-black-20 leading-10 hover:bg-black-20 hover:text-white">
                                    ZEMUN
                                </li>
                                <li
                                    @click="$router.push('/pionir')"
                                    class="border-t border-black-20 leading-10 hover:bg-black-20 hover:text-white">
                                    PIONIR
                                </li>
                                <li
                                    @click="$router.push('/ustanicka')"
                                    class="border-t border-black-20 leading-10 hover:bg-black-20 hover:text-white">
                                    USTANIČKA
                                </li>
                                <li
                                    @click="$router.push('/usce-sc')"
                                    class="border-t border-black-20 leading-10 hover:bg-black-20 hover:text-white">
                                    UŠĆE
                                </li>
                                <li
                                    @click="$router.push('/vracar')"
                                    class="border-t border-black-20 leading-10 hover:bg-black-20 hover:text-white">
                                    VRAČAR
                                </li>
                                <li
                                    @click="$router.push('/navigator')"
                                    class="border-b border-t border-black-20 leading-10 hover:bg-black-20 hover:text-white">
                                    NAVIGATOR
                                </li>
                                <li
                                    @click="$router.push('/prvomajska')"
                                    class="border-b border-t border-black-20 leading-10 hover:bg-black-20 hover:text-white">
                                    PRVOMAJSKA
                                </li>
                            </ul>
                        </li>
                        <!--      CLUBS NAV END-->

                        <!--   TRAINING SCHEDULE DESKTOP NAV      -->


                        <li
                            @mouseover="schedule = true;"
                            @mouseleave="schedule = false"
                            class="mr-5 my-3 cursor-pointer"
                            :class="schedule ? ' z-20 pb-10' : ''"
                        >
                            <p :class="schedule ? 'text-black-30' : ''" data-test="training-schedule">{{ $t('training-schedule') }}</p>
                            <ul v-if="schedule" class="w-56 pt-5 absolute bg-white z-10 show-slowly" data-test="u-training-schedule">
                                <li
                                    v-for="location in locations"
                                    @click="$router.push(`/training-schedule/${location.id}`)"
                                    :data-test="location.name"
                                    v-if="location.fitness && location.has_room"
                                    class="border-t border-black-20 leading-10 hover:bg-black-20 hover:text-white uppercase">
                                    <span>{{ location.name }}</span>
                                </li>
                            </ul>
                        </li>

                        <!--  END TRAINING SCHEDULE DESKTOP NAV      -->


                        <!--  SERVICES DESKTOP NAV  -->
                        <li
                            @mouseover="services = true"
                            @mouseleave="services = false;
                                        gym = false;
                                        groupTrainings = false;
                                        other = false;"
                            class="mr-5 my-3 cursor-pointer"
                            :class="services ? ' z-20 pb-10' : ''"
                        >
                            <p :class="services ? 'text-black-30' : ''">{{ $t('services') }}</p>
                            <ul v-if="services" class="w-56 pt-5 absolute bg-white z-10 show-slowly">
                                <li
                                    @click="groupTrainings = !groupTrainings"
                                    class="border-b border-t border-black-20 leading-10 uppercase"
                                    :class="groupTrainings ? 'bg-black-30 text-white' : ''">
                                    {{ $t('group-trainings') }}
                                    <ul v-if="groupTrainings" class="bg-white show-slowly">
                                        <li
                                            class="pt-2 text-black hover:bg-black-20 leading-8 hover:text-white font-normal"
                                            @click="$router.push('/freestyle-trainings')">
                                            FREESTYLE
                                        </li>
                                        <li @click="$router.push('/les-mills-trainings')"
                                            class="pt-2 text-black hover:bg-black-20 leading-8 hover:text-white font-normal">
                                            LES MILLS
                                        </li>
                                    </ul>
                                </li>
                                <li
                                    class="border-b border-black-20 leading-10 uppercase"
                                    :class="gym ? 'bg-black-30 text-white' : ''"
                                    @click="gym = !gym">
                                    {{ $t('gym-1') }}
                                    <ul v-if="gym" class="bg-white show-slowly">
                                        <li
                                            @click="$router.push('/gym-services#personal')"
                                            class="pt-2 text-black hover:bg-black-20 leading-8 hover:text-white font-normal">
                                            {{ $t('personal-training') }}
                                        </li>
                                        <li
                                            @click="$router.push('/gym-services#individual')"
                                            class="pt-2 text-black hover:bg-black-20 leading-8 hover:text-white font-normal">
                                            {{ $t('individual-training') }}
                                        </li>
                                        <li
                                            @click="$router.push('/gym-services#small')"
                                            class="pt-2 text-black hover:bg-black-20 leading-8 hover:text-white font-normal">
                                            {{ $t('small-group-training') }}
                                        </li>
                                    </ul>
                                </li>
                                <li
                                    class="leading-10 uppercase"
                                    :class="other ? 'bg-black-30 text-white' : ''"
                                    @click="other = !other">
                                    {{ $t('other-services') }}
                                    <ul v-if="other" class="bg-white show-slowly">
                                        <li
                                            class="pt-1 text-black hover:bg-black-20 leading-8 hover:text-white"
                                            @click="$router.push('/other-services#solarium')">
                                            {{ $t('solarium') }}
                                        </li>
                                        <li
                                            class="pt-1 text-black hover:bg-black-20 leading-8 hover:text-white"
                                            @click="$router.push('/other-services#massage')">
                                            {{ $t('massage') }}
                                        </li>
                                        <li
                                            class="pt-1 text-black hover:bg-black-20 leading-8 hover:text-white"
                                            @click="$router.push('/other-services#health-bar')">
                                            HEALTH BAR
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <!--  SERVICES END DESKTOP NAV  -->

                        <router-link
                            to="/coaches"
                            exact-active-class="active"
                            class="mr-5 my-3 cursor-pointer uppercase">
                            {{ $t('team') }}
                        </router-link>

                        <router-link
                            to="/online-trainings"
                            exact-active-class="active"
                            class="mr-5 my-3 cursor-pointer uppercase">
                            {{ $t('online-trainings') }}
                        </router-link>

                        <!--      CLUBS NAV START-->

                        <!-- BLOG NAV START -->

                        <li
                            @mouseover="news = true"
                            @mouseleave="news = false"
                            class="mr-5 my-3 cursor-pointer uppercase"
                            :class="news ? ' z-20 pb-10' : ''"
                        >
                            <p :class="news ? 'text-black-30' : ''">{{ $t('news') }}</p>
                            <ul v-if="news" class="w-56 pt-5 absolute bg-white z-10 show-slowly">
                                <li
                                    @click="$router.push('/events')"
                                    class="border-t border-black-20 leading-10 hover:bg-black-20 hover:text-white">
                                    {{ $t('events') }}
                                </li>
                                <li
                                    @click="$router.push('/blog')"
                                    class="border-t border-black-20 leading-10 hover:bg-black-20 hover:text-white">
                                    BLOG
                                </li>
                                <!--   PROMOTIONS DESKTOP NAV      -->


                                <li
                                    v-if="activePromotionGyms && activePromotionGyms.length > 0"
                                    @mouseover="promotions = true;"
                                    @mouseleave="promotions = false"
                                    class="border-t border-black-20 leading-10 hover:bg-black-20 hover:text-white"
                                    :class="promotions ? ' z-20 pb-10' : ''"
                                >
                                    <p :class="promotions ? 'text-white' : ''" data-test="training-schedule">{{ promotionName }}</p>
                                    <ul v-if="promotions" class="w-56 absolute bg-white z-10 show-slowly" data-test="u-promotions">
                                        <li
                                            v-for="gym in activePromotionGyms"
                                            @click="$router.push(`/promotions/${gym.id}`)"
                                            :data-test="gym.name"
                                            v-if="gym.fitness"
                                            class="border-t border-black-20 text-black-80 leading-10 hover:bg-black-20 hover:text-white uppercase">
                                            <span>{{ gym.name }}</span>
                                        </li>
                                    </ul>
                                </li>

                                <!--  END PROMOTIONS DESKTOP NAV      -->
                            </ul>
                        </li>

                        <li
                            @mouseover="contact = true"
                            @mouseleave="contact = false"
                            class="mr-5 my-3 cursor-pointer uppercase"
                            :class="contact ? ' z-20 pb-10' : ''"
                        >
                            <p :class="contact ? 'text-black-20' : ''">{{ $t('contact') }}</p>
                            <ul v-if="contact" class="w-56 pt-5 absolute bg-white z-10 show-slowly">
                                <li
                                    @click="$router.push('/price-list')"
                                    class="border-t border-black-20 leading-10 hover:bg-black-20 hover:text-white uppercase">
                                    {{ $t('price-list') }}
                                </li>
                                <li
                                    @click="$router.push('/training-selection')"
                                    class="border-t border-black-20 leading-10 hover:bg-black-20 hover:text-white uppercase">
                                {{ $t('find-your-training') }}
                                </li>
                                <li
                                    @click="$router.push('/free-training')"
                                    class="border-t border-black-20 leading-10 hover:bg-black-20 hover:text-white uppercase">
                                    {{ $t('free-trial-training') }}
                                </li>
                                <li
                                    @click="$router.push('/become-a-team-member')"
                                    class="border-t border-black-20 leading-10 hover:bg-black-20 hover:text-white uppercase">
                                    {{ $t('become-a-team-member') }}
                                </li>
                            </ul>
                        </li>
                        <router-link
                            to="/application"
                            exact-active-class="active"
                            class="mr-5 my-3 cursor-pointer uppercase">
                            {{ $t('application') }}
                        </router-link>
                    </ul>
                </div>
            </div>
        </div>
        <!-- END DESKTOP NAV-->
    </div>
</template>

<script>
import Logo from "../../icons/Logo";
import MenuIcon from "../../icons/MenuIcon";
import InstaIcon from "../../icons/InstaIcon";
import FacebookNavIcon from "../../icons/FacebookNavIcon";
import YoutubeIcon from "../../icons/YoutubeIcon";
import LogoWebNav from "../../icons/LogoWebNav";
import SearchIcon from "../../icons/SearchIcon";
import {mapActions, mapGetters, mapMutations} from 'vuex';
export default {
    name: "Header.vue",
    metaInfo: {
        // Children can override the title.
        title: 'Fitness & Gym Kočović',
        // Result: My Page Title ← My Site
        // If a child changes the title to "My Other Page Title",
        // it will become: My Other Page Title ← My Site
        titleTemplate: '%s ← Teretana Beograd',
        htmlAttrs: {
            lang: 'en',
            // amp: true
        },
        // Define meta tags here.
        meta: [
            // {"http-equiv": 'Content-Type', content: 'text/html; charset=utf-8'},
            // {name: 'viewport', content: 'width=device-width, initial-scale=1'},
            {name: 'description', content: 'Teretana Kočović Vam nudi sve na jednom mestu. Izaberite trening po Vašim potrebama i prijavite se odmah! | Teretana Beograd'},
            {
                name: 'keywords',
                content: '' +
                    'fitnes Beograd, ' +
                    'fitnes Zemun, ' +
                    'fitnes Blokovi, ' +
                    'Fitnes Novi Beograd, ' +
                    'fitnes Vračar, ' +
                    'fitnes Banjica, ' +
                    'Fitnes Pionir, ' +
                    'Fitnes Ustanička, ' +
                    'teretana Beograd, ' +
                    'teretana Zemun, ' +
                    'teretana Blokovi, ' +
                    'teretana Vračar, ' +
                    'Teretana Pionir, ' +
                    'Teretana Ušće, ' +
                    'Teretana Banjica, ' +
                    'Les Mills Beograd, ' +
                    'grupni fitnes, ' +
                    'fitnes treninzi Beograd, ' +
                    'mršavljenje, ' +
                    'zatezanje, ' +
                    'oblikovanje tela, ' +
                    'joga zemun, ' +
                    'Joga Novi Beograd, ' +
                    'Joga Beograd, ' +
                    'boks, ' +
                    'trening snage, ' +
                    'Kardio, ' +
                    'Fitnes centar Beograd, ' +
                    'Gluteus, ' +
                    'Zadnjica, ' +
                    'Gubljenje kilograma/težine, ' +
                    'Celulit, ' +
                    'kardio treninzi, ' +
                    'gym belgrade'
            },
        ],
    },
    components: {
        Logo,
        MenuIcon,
        InstaIcon,
        FacebookNavIcon,
        YoutubeIcon,
        LogoWebNav,
        SearchIcon
    },
    data() {
        return {
            services: false,
            clubs: false,
            contact: false,
            schedule: false,
            promotions: false,
            groupTrainings: false,
            news: false,
            gym: false,
            other: false,
            // locations: [],
            activePromotionGyms: [],
            langs: ['sr', 'en'],
            is_validate: null,
            position: null,
            promotionName: '',
        }
    },
    async mounted() {
        this.fetchGyms();
        this.getActivePromotion();
    },
    watch: {
        '$route.path'() {
            window.scrollTo(0,0);
        }
    },
    computed: {
        ...mapGetters("auth", {
            isLoggedIn: "getIsLoggedIn"
        }),
        ...mapGetters("shared", {
            isOpenNav: "getIsOpenNav"
        }),
        ...mapGetters("gyms", {
            locations: "getGyms"
        }),
    },
    methods: {
        ...mapMutations("shared", {
            toggleNavigation: "toggleNavigation"
        }),
        ...mapActions("auth", {
            logout: "logout"
        }),
        ...mapActions("gyms", {
            fetchGyms: "fetchAll"
        }),
        // async getClubs() {
        //     const {data} = await this.$http.get('/gym');
        //     this.locations = data;
        // },
        async getActivePromotion() {
            const {data} = await this.$http.get('/promotion/active');
            this.activePromotionGyms = data.gyms;
            this.promotionName = data.title;
        }
    },
}
</script>