<template>
  <main class="bg-black">
    <call-to-action @scroll-to-form="scrollToForm"></call-to-action>
    <lokacije @select-gym="handleSelectGym"></lokacije>
    <why-kocovic></why-kocovic>
    <questions-and-answers></questions-and-answers>
    <landing-form :form="form" ref="landingFormComponent"></landing-form>
  </main>
</template>

<script>
import WhyKocovic from "../components/landing/WhyKocovic.vue";
import QuestionsAndAnswers from "../components/landing/QuestionsAndAnswers/QuestionsAndAnswers.vue";
import CallToAction from "./../components/landing/CallToAction.vue";
import Lokacije from "../components/landing/lokacije/Lokacije.vue";
import LandingForm from "../components/landing/LandingForm.vue";

export default {
  name: "LandingPage",
  components: {
    CallToAction,
    Lokacije,
    LandingForm,
    WhyKocovic,
    QuestionsAndAnswers,
  },
  data() {
    return {
      form: {
        name: "",
        address: "",
        phone_1: null,
        email: "",
        wish_to_try: null,
        gym_location: null,
      },
      event: {},
      host: process.env.VUE_APP_API_URL,
      lang: "sr",
    };
  },
  methods: {
    handleSelectGym(gymId) {
      this.form.gym_location = gymId;
      this.scrollToForm();
    },
    scrollToForm() {
      const landingFormComponent = this.$refs.landingFormComponent.$el;
      if (landingFormComponent) {
        landingFormComponent.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>
