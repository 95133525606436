import Vue from "vue";
import VueRouter from "vue-router";
import Vuex from "vuex";

import Home from "../views/Home.vue";

Vue.use(Vuex);
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/training-schedule/:id",
    name: "TrainingSchedule",
    component: require("../views/TrainingSchedule").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/promotions/:id",
    name: "Promotions",
    component: require("../views/Promotions").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/freestyle-trainings",
    name: "Freestyle Trainings",
    component: require("../views/FreestyleTrainings").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/les-mills-trainings",
    name: "Less Mills Trainings",
    component: require("../views/LesMillsTrainings").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/clubs",
    name: "Clubs",
    component: require("../views/clubs/Clubs").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/banjica",
    name: "Banjica",
    component: require("../views/clubs/Banjica").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/blokovi",
    name: "Blokovi",
    component: require("../views/clubs/Blokovi").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/zemun",
    name: "Zemun",
    component: require("../views/clubs/Zemun").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/prvomajska",
    name: "Prvomajska",
    component: require("../views/clubs/Prvomajska").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/pionir",
    name: "Pionir",
    component: require("../views/clubs/Pionir").default,
  },
  {
    path: "/ustanicka",
    name: "Ustanicka",
    component: require("../views/clubs/Ustanicka").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/usce-sc",
    name: "Usce",
    component: require("../views/clubs/Usce").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/vracar",
    name: "Vracar",
    component: require("../views/clubs/Vracar").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/navigator",
    name: "Navigator",
    component: require("../views/clubs/Navigator").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/coaches",
    name: "Coaches",
    component: require("../views/Coaches").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/become-a-member",
    name: "Become a member",
    component: require("../views/Register").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/become-a-team-member",
    name: "BecomeTeamMember",
    component: require("../views/BecomeTeamMember").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/online-trainings",
    name: "OnlineTrainings",
    component: require("../views/OnlineTrainings").default,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/price-list",
    name: "PriceListForm",
    component: require("../views/PriceListForm").default,
  },
  {
    path: "/training-selection",
    name: "TrainingSelectionForm",
    component: require("../views/TrainingSelectionForm").default,
  },
  {
    path: "/free-training",
    name: "FreeTrainingForm",
    component: require("../views/FreeTrainingForm").default,
  },
  {
    path: "/application",
    name: "ApplicationPage",
    component: require("../views/ApplicationPage").default,
  },
  {
    path: "/gym-services",
    name: "GymServices",
    component: require("../views/GymServices").default,
  },
  {
    path: "/other-services",
    name: "OtherServices",
    component: require("../views/OtherServices").default,
  },
  {
    path: "/blog",
    name: "Blog",
    component: require("../views/Blog").default,
  },
  {
    path: "/events",
    name: "Events",
    component: require("../views/Events").default,
  },
  {
    path: "/login",
    name: "Login",
    component: require("../views/Login").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/blog/:id",
    name: "BlogReadPage",
    component: require("../views/BlogReadPage").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/events/:id",
    name: "EventReadPage",
    component: require("../views/EventReadPage").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/live-chat",
    name: "LiveChat",
    component: require("../views/LiveChat").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/landing",
    name: "LandingPage",
    component: require("../views/LandingPage").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/maintenance",
    name: "404",
    component: require("../views/404").default,
  },
  {
    path: "*",
    name: "Home",
    component: require("../views/Home").default,
  },
];

const router = new VueRouter({
  // mode: 'history',
  mode: process.env.CORDOVA_PLATFORM ? "hash" : "history",
  base: process.env.BASE_URL,
  routes,
  duplicateNavigationPolicy: "ignore",
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !localStorage.getItem("token")
  ) {
    next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
  } else next();
});

export default router;
