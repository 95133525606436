const banjica_thumbnails = [
  "galleries/banjica_thumbnails/1_thumb.jpg",
  "galleries/banjica_thumbnails/2_thumb.jpg",
  "galleries/banjica_thumbnails/3_thumb.jpg",
  "galleries/banjica_thumbnails/4_thumb.jpg",
  "galleries/banjica_thumbnails/5_thumb.jpg",
  "galleries/banjica_thumbnails/6_thumb.jpg",
  "galleries/banjica_thumbnails/7_thumb.jpg",
  "galleries/banjica_thumbnails/8_thumb.jpg",
  "galleries/banjica_thumbnails/9_thumb.jpg",
  "galleries/banjica_thumbnails/10_thumb.jpg",
  "galleries/banjica_thumbnails/11_thumb.jpg",
  "galleries/banjica_thumbnails/12_thumb.jpg",
  "galleries/banjica_thumbnails/13_thumb.jpg",
  "galleries/banjica_thumbnails/14_thumb.jpg",
  "galleries/banjica_thumbnails/15_thumb.jpg",
  "galleries/banjica_thumbnails/16_thumb.jpg",
  "galleries/banjica_thumbnails/17_thumb.jpg",
  "galleries/banjica_thumbnails/18_thumb.jpg",
];
export default banjica_thumbnails;
