<template>
    <div class="w-full pb-10">
        <div class="bg-black-90 h-24 md:h-40 lg:h-48 xl:h-56 flex sm:w-screen md:w-screen">
            <div class="flex mx-auto w-3/4 xl:h-56">
                <p class="text-black-30 m-auto font-thin text-lg md:text-3xl lg:text-4xl xl:text-4xl uppercase">
                    <span>{{ $t('online-trainings').split(" ")[0] }}</span>&nbsp;
                    <span class="font-bold">{{ $t('online-trainings').split(" ")[1] }}</span>
                </p>
            </div>
        </div>
        <div v-if="videos && videos.length > 0" class="w-12/12 xl:w-9/12 xl:mx-auto flex grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 my-10 show-slowly">
            <div v-for="video in videos" :key="video.id">
                <app-video
                    :video="video"
                    :device="device"
                    @openModal="$modal.show('description_modal'); modalData = video;"
                    class="transform xl:hover:scale-105 transition duration-200 mx-auto"
                ></app-video>
            </div>
        </div>
        <div v-if="!videos || videos.length < 1" class="w-12/12 xl:w-9/12 h-500 mx-auto flex">
            <div class="m-auto">
                <h5 class="text-black-20 uppercase text-xl">{{ $t('there-is-no-video') }}</h5>
<!--                <button class="bg-green-20 px-2 py-1 mt-4 hover:bg-green-30 uppercase" @click="$modal.show('enter_code_modal');">{{ $t('please-enter-code') }}</button>-->
            </div>
        </div>
        <modal
          name="enter_code_modal"
          :resizable="false"
          :adaptive="true">
            <div v-if="!me.terms_and_conditions" class="w-12/12 h-full bg-black-60 flex border-2 border-green-10">
                <div class="rounded-lg m-auto grid grid-cols-1 flex">
                    <button class="bg-green-20 px-2 py-1 mt-4  mb-4 hover:bg-green-30" @click="terms">
                        {{ $t('read_terms_and_conditions') }}
                    </button>
                    <div>
                        <input v-model="termsAndConditions" type="checkbox" id="terms_and_conditions">
                        <label class="ml-2 text-black-10" for="terms_and_conditions">{{ $t('accept_with_terms_and_conditions') }}</label><br>
                    </div>
                    <button class="bg-green-20 px-2 py-1 mt-4 hover:bg-green-30" @click="setTermsAndConditions(termsAndConditions)">{{ $t('submit') }}</button>
                </div>
            </div>
            <div v-if="me.terms_and_conditions" class="w-12/12 h-full bg-black-60 flex border-2 border-green-10">
                <div class="w-64 rounded-lg m-auto grid grid-cols-1">
                    <p class="text-white mb-4 text-lg">{{  $t('please-enter-code') }}</p>
                    <input type="text" v-model="userCode" placeholder="" class="bg-black-10">
                    <button class="bg-green-20 px-2 py-1 mt-4 hover:bg-green-30" @click="setCode(userCode)">{{ $t('submit') }}</button>
                </div>
            </div>
        </modal>
        <modal
          name="description_modal"
          :resizable="true"
          :adaptive="true">
            <div
                class="text-black-10 bg-gradient-to-r from-black-80 to-black-30 overflow-auto flex m-auto border-4"
                v-if="modalData"
                style="max-height: 100%; height: 100%; max-width: 100%;"
            >
                <div class="w-12/12 md:w-10/12 mt-2 m-auto px-10 md:px-0 overflow-auto pb-16 xl:pb-6">
                    <div class="p-2 font-semibold text-lg pb-4">
                        <h1 v-if="$i18n.locale === 'sr'" class="uppercase px-2 md:px-5">{{modalData.title}}</h1>
                        <h1 v-if="$i18n.locale === 'en'" class="uppercase px-2 md:px-5">{{modalData.title_eng}}</h1>
                    </div>
                    <div class="text-center">
                        <p v-if="$i18n.locale === 'sr'" class="text-justify md:px-10" v-html="modalData.description"></p>
                        <p v-if="$i18n.locale === 'en'" class="text-justify md:px-10" v-html="modalData.description_eng"></p>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    import Video from "@/components/Video";
    import DeviceDetector from "device-detector-js";
    import { mapActions, mapGetters} from "vuex";
    export default {
        name: "OnlineTrainings",
        metaInfo: {
            // Children can override the title.
            title: 'Fitness & Gym Kočović | Teretana Kočović Beograd',
            // Result: My Page Title ← My Site
            // If a child changes the title to "My Other Page Title",
            // it will become: My Other Page Title ← My Site
            titleTemplate: '%s ← Teretana Beograd',
            // htmlAttrs: {
            //     lang: 'en',
            //     amp: true
            // },
            // Define meta tags here.
            meta: [
              // {"http-equiv": 'Content-Type', content: 'text/html; charset=utf-8'},
              // {name: 'viewport', content: 'width=device-width, initial-scale=1'},
              {name: 'description', content: 'Online treninzi. Treniraj bilo gde. Teretana Kocovic Beograd! | Teretana Beograd | Online treninzi'},
              {
                  name: 'keywords',
                  content: '' +
                      'online treninzi, ' +
                      'online treninzi beograd, ' +
                      'online teretana beograd, ' +
                      'fitnes Beograd, ' +
                      'fitnes Zemun, ' +
                      'fitnes Blokovi, ' +
                      'Fitnes Novi Beograd, ' +
                      'fitnes Vračar, ' +
                      'fitnes Banjica, ' +
                      'Fitnes Pionir, ' +
                      'Fitnes Ustanička, ' +
                      'teretana Beograd, ' +
                      'teretana Zemun, ' +
                      'teretana Blokovi, ' +
                      'teretana Vračar, ' +
                      'Teretana Pionir, ' +
                      'Teretana Ušće, ' +
                      'Teretana Banjica, ' +
                      'Les Mills Beograd, ' +
                      'grupni fitnes, ' +
                      'fitnes treninzi Beograd, ' +
                      'mršavljenje, ' +
                      'zatezanje, ' +
                      'oblikovanje tela, ' +
                      'joga zemun, ' +
                      'Joga Novi Beograd, ' +
                      'Joga Beograd, ' +
                      'boks, ' +
                      'trening snage, ' +
                      'Kardio, ' +
                      'Fitnes centar Beograd, ' +
                      'Gluteus, ' +
                      'Zadnjica, ' +
                      'Gubljenje kilograma/težine, ' +
                      'Celulit, ' +
                      'kardio treninzi, ' +
                      'gym belgrade'
              },
            ],
        },
        data() {
            return {
                host: process.env.VUE_APP_API_URL,
                userCode: null,
                termsAndConditions: null,
                device: null,
                modalData: {
                    title: '',
                    title_eng: '',
                    description: '',
                    description_eng: '',
                },
            }
        },
        components: {
            'app-video': Video
        },
        mounted() {
            this.fetchVideos();
            this.checkDevice();
            if (!this.me) {
                this.fetchMe();
            }
        },
        computed: {
            ...mapGetters("auth", {
                me: "getMe"
            }),
            ...mapGetters("onlineTrainings", {
                videos: "getVideos"
            })
        },
        methods: {
            ...mapActions("auth", {
                fetchMe: "fetchMe"
            }),
            ...mapActions("onlineTrainings", {
                fetchVideos: "fetchVideos",
                setCode: "setCode",
                setTermsAndConditions: "setTermsAndConditions",
            }),
            checkDevice() {
                const deviceDetector = new DeviceDetector();
                const userAgent = window.navigator.userAgent;
                const device = deviceDetector.parse(userAgent);
                this.device = device.os.name + ' - ' + device.client.name;
            },
            terms(){
                window.open(
                './USLOVI KORISCENJA.pdf',
                '_blank' // <- This is what makes it open in a new window.
                );
            },
            // async setCode(code) {
            //     await this.$http.post('/user/group-code', {code: code})
            //         .then((resp) => {
            //             this.handle_success_response('SUC_YOUR_CODE_IS_CORRECT');
            //             this.$modal.hide('enter_code_modal');
            //             this.getVideos();
            //         })
            //         .catch((err) => {
            //             if (err.response.data.error === 'ERR_USER_ALREADY_IN_GROUP') {
            //                 this.$modal.hide('enter_code_modal');
            //             } else if (err.response.data.error === 'ERR_BAD_GROUP_CODE') {
            //                 this.$modal.show('enter_code_modal');
            //             }
            //             this.handle_error(err);
            //         });
            // },
            // async setTermsAndConditions(termsAndConditions) {
            //     await this.$http.patch('/user', {terms_and_conditions: termsAndConditions})
            //         .then((resp) => {
            //             this.fetchMe();
            //         })
            //         .catch((err) => {
            //             this.handle_error(err);
            //
            //         });
            // },
        }
    }
</script>