<template>
  <div
    class="w-12/12 h-full bg-gradient-to-b from-black-90 via-black-70 to-black-90 font-oswald"
    id="app"
    style="overflow-x: clip"
  >
<!--    <div-->
<!--      class="w-12/12 text-center h-10 md:h-12 leto-red text-white md:text-3xl flex align-middle justify-center sticky top-0 z-50"-->
<!--    >-->
<!--      <router-link to="/events/84">-->
<!--        <h1 class="content-center">-->
<!--          {{ $t("leto-kocovic") }}-->
<!--        </h1>-->
<!--      </router-link>-->
<!--    </div>-->
    <div id="nav" class="overflow-hidden" v-if="!isHeaderAndFooterHidden">
      <Header />
    </div>
    <router-view class="show-slowly" />
    <app-loader v-if="loading"></app-loader>
    <div v-if="!isHeaderAndFooterHidden">
      <Footer />
    </div>
    <cookies-banner v-if="cookiesBanner" @acceptCookies="acceptCookies" />
  </div>
</template>

<script>
import Header from "./components/shared/Header";
import Footer from "./components/shared/Footer";
import AppLoader from "@/components/AppLoader";
import CookiesBanner from "./components/CookiesBanner";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      cookiesBanner: false,
    };
  },
  components: { Header, Footer, AppLoader, CookiesBanner },
  beforeMount() {
    this.fetchCookie();
    this.handleCookiesBanner();
  },
  computed: {
    isHeaderAndFooterHidden() {
      console.log(this.$route.name, "this.$route.name");
      if (this.$route.name === "404" || this.$route.name === "LiveChat") {
        return true;
      }
      return false;
    },
    ...mapGetters("shared", {
      errorMessage: "getErrorMessage",
      modalStatus: "getModalStatus",
      modalTitle: "getModalTitle",
      successMessage: "getSuccessMessage",
    }),
    ...mapGetters("loader", {
      loading: "getLoading",
    }),
  },
  methods: {
    ...mapMutations("shared", {
      setModal: "setModal",
      setSuccessMessage: "setSuccessMessage",
      setErrorMessage: "setErrorMessage",
    }),
    ...mapActions("auth", {
      fetchCookie: "fetchCookie",
    }),
    acceptCookies() {
      this.cookiesBanner = false;
      localStorage.setItem("cookies_accepted", "true");
    },
    handleCookiesBanner() {
      const cookiesAccepted = localStorage.getItem("cookies_accepted");
      if (cookiesAccepted !== "true") {
        this.cookiesBanner = true;
      }
    },
  },
  watch: {
    successMessage(val) {
      if (val !== null) {
        this.handle_success_response(val);
        this.$nextTick(() => {
          this.setSuccessMessage(null);
        });
      }
    },
    errorMessage(val) {
      if (val !== null) {
        this.handle_error(val);
      }
      this.$nextTick(() => {
        this.setErrorMessage(null);
      });
    },
    modalStatus(val) {
      if (val !== null) {
        if (val === true) {
          this.$modal.show(this.modalTitle);
        } else if (val === false) {
          this.$modal.hide(this.modalTitle);
        }
        this.$nextTick(() => {
          this.setModal({ name: null, show: null });
        });
      }
    },
    modalTitle(val) {
      if (val !== null) {
        if (this.modalStatus === true) {
          this.$modal.show(val);
        } else if (this.modalStatus === false) {
          this.$modal.hide(val);
        }
        this.$nextTick(() => {
          this.setModal({ name: null, show: null });
        });
      }
    },
  },
};
</script>

<style>
/*@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;700&display=swap');*/
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;600;700&display=swap");
#app {
  font-family: "Oswald";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

@keyframes opacity-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.show-slowly {
  animation-name: opacity-animation;
  animation-duration: 0.5s;
}

::-webkit-scrollbar {
  width: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px lightgray;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: darkgray;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: gray;
}

.leto-red {
  background-color: rgb(217, 55, 49);
}
.content-center {
  align-content: center;
}
</style>
