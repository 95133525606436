<template>
    <div class="w-full">
        <div>
            <main-message></main-message>
        </div>
        <div class="w-7/12 md:w-4/12 lg:w-3/12 xl:w-2/12 h-full py-6 grid grid-cols-1 mx-auto flex bg-gradient-to-b from-black-70 via-black-60 to-black-20 bg-opacity-75 shadow-2xl my-16">
            <div class="m-auto w-10/12">
                <div class="mb-5 flex">
                    <login-logo class="m-auto"></login-logo>
                </div>

                <div class="grid grid-row-2 w-full mx-auto mt-3">
                    <div
                        @click="$store.state.googleConnected = true; setLoginType('google')"
                        class="mt-1 w-full h-10 rounded overflow-hidden mx-auto bg-white"
                    >
                        <g-signin-button
                            :params="googleSignInParams"
                            @success="onSignInSuccess"
                            @error="onSignInError">
                            <img src="../assets/backgrounds/gLogo.png" class="h-8 w-8 mt-1 ml-1" >
                            <span class="text-black-60 m-auto pr-8">{{ $t('continue-with-google') }}</span>
                        </g-signin-button>
                    </div>
                    <div
                        @click.prevent="setLoginType('facebook'); loginWithFacebook();"
                        class="w-full flex h-10 rounded  cursor-pointer overflow-hidden mt-3 mx-auto "
                        style="background-color: rgba(45, 120, 242, 1) ">
                        <img src="../assets/backgrounds/fblogo.png" class="h-8 w-8 mt-1 ml-1" >
                        <div class="flex flex-1 justify-center">
                            <span class="flex text-white mr-5 facebook-login">{{ $t('continue-with-facebook') }}</span>
                        </div>

                    </div>
                </div>

                <div class="w-full mx-auto grid grid-cols-9">
                    <div class="col-span-4 border-b border-black-10 pt-6"></div>
                    <div class="col-span-1 text-black-10 relative pt-3 flex justify-center"><small class="absolute">{{ $t('or') }}</small></div>
                    <div class="col-span-4 border-b border-black-10 pt-6"></div>
                </div>

                <div class="mt-5">
                    <!--                    <p class="text-white font-semibold mb-2 text-left uppercase">{{ $t('email') }}</p>-->
                    <div>
                        <input
                            v-model="form.email"
                            data-test="login-email"
                            :placeholder="$i18n.locale === 'sr' ? 'Mejl adresa' : 'Email address'"
                            class="w-full rounded-sm p-1 bg-black-10 focus:outline-none"
                            type="text">
                    </div>
                </div>
                <div class="mt-2 grid grid-cols-5">
                    <!--                    <div class="col-span-5">-->
                    <!--                        <p class="text-black-10 font-semibold mb-2 text-left uppercase">{{ $t('password') }}</p>-->
                    <!--                    </div>-->
                    <div class="col-span-4">
                        <input
                            name="pass"
                            v-model="form.password"
                            data-test="login-password"
                            :placeholder="$i18n.locale === 'sr' ? 'Lozinka' : 'Password'"
                            class="w-full rounded-l-sm p-1 bg-black-10 focus:outline-none"
                            :type="type">
                    </div>
                    <div class="col-span-1 w-full rounded-r-sm">
                        <div @click="switchType" class="bg-black-10 w-full py-1 ml-auto rounded-r-sm cursor-pointer">
                            <icon v-if="type === 'password'" :icon="['far', 'eye']" class="text-black-30"></icon>
                            <icon v-if="type === 'text'" :icon="['far', 'eye-slash']" class="text-black-30"></icon>
                        </div>
                    </div>
                </div>
                <div class="mt-5 mb-3">
                    <button @click="login" class="bg-black-80 hover:bg-black-60 text-white uppercase w-full font-semibold rounded py-1" data-test="login-btn">{{ $t('log-in') }}</button>
                    <button @click="$router.push('/become-a-member')" class="bg-black hover:bg-black-70 w-full uppercase text-black-10 py-1 font-semibold rounded mt-3" data-test="become-a-member">{{ $t('become-a-member') }}</button>
                </div>
                <div>
                    <span class="text-white text-left hover:underline cursor-pointer" @click="$modal.show('forgot_password_modal');">{{ $t('forgot-password') }}?</span>
                </div>

                <div class="mt-8">
                    <p class="text-center text-white text-xs">&copy;{{ todaysYear }} Fitness & Gym Kočović</p>
                </div>
            </div>
        </div>
        <modal
            name="forgot_password_modal"
            :resizable="false"
            :adaptive="true">
            <div class="w-12/12 h-full bg-black-60 flex border-2 border-green-10">
                <div class="w-64 rounded-lg m-auto grid grid-cols-1">
                    <p class="text-white mb-4 text-lg">{{ $t('please-enter-your-email-address') }}</p>
                    <input type="text" v-model="forgotEmail" placeholder="Email" class="bg-black-10">
                    <button class="bg-green-20 px-2 py-1 mt-4 hover:bg-green-30" @click="forgotPassword({forgotEmail: forgotEmail, lang: $i18n.locale})">{{ $t('submit') }}</button>
                </div>
            </div>
        </modal>

        <modal
            name="enter_phone_modal_google"
            :resizable="false"
            :adaptive="true">
            <div class="w-12/12 h-full bg-black-60 flex border-2 border-green-10">
                <div class="w-64 rounded-lg m-auto grid grid-cols-1">
                    <p class="text-white mb-4 text-lg">{{ $t('please-enter-your-phone') }}</p>
                    <input type="number" :class="!phoneGoogle ? 'border-2 border-red-50' : ''" v-model="phoneGoogle" class="bg-black-10 text-black">
                    <button
                        :disabled="phoneGoogle === '' || phoneGoogle === null"
                        class="bg-green-20 px-2 py-1 mt-4 hover:bg-green-30"
                        @click="createUserFromGoogle({googleData: googleData, phone: phoneGoogle, lang: $i18n.locale})">
                        {{ $t('submit') }}
                    </button>
                </div>
            </div>
        </modal>

        <modal
            name="enter_phone_modal_facebook"
            :resizable="false"
            :adaptive="true">
            <div class="w-12/12 h-full bg-black-60 flex border-2 border-green-10">
                <div class="w-64 rounded-lg m-auto grid grid-cols-1">
                    <span v-if="!email || email === ''" class="text-white text-lg text-left">{{ $t('please-enter-your-email') }}</span>
                    <input v-if="!email || email === ''" :class="email ? 'border-2 border-red-50' : ''" type="text" v-model="facebookEmail" class="bg-black-10 text-black">
                    <span class="text-white text-lg mt-3 text-left">{{ $t('please-enter-your-phone') }}</span>
                    <input type="number" :class="!phoneFacebook ? 'border-2 border-red-50' : ''" v-model="phoneFacebook" class="bg-black-10 text-black">
                    <button
                        class="bg-green-20 px-2 py-1 mt-4 hover:bg-green-30"
                        @click="createUserFromFacebook({facebookData: facebookData, email: facebookEmail, phone: phoneFacebook, lang: $i18n.locale})">
                        {{ $t('submit') }}
                    </button>
                </div>
            </div>
        </modal>

        <modal
            name="resend_confirmaiton_email"
            :resizable="false"
            width="350"
            :adaptive="true">
            <div class="w-full bg-black-50 h-full border-2 border-cyan-10 flex">
                <div class="mx-auto flex">
                    <div class="m-auto">
                        <div class="text-4xl md:text-5xl lg:text-6xl mx-auto">
                            <icon :icon="['fas', 'envelope']" class="text-cyan-10"></icon>
                        </div>
                        <div class="mx-auto h-30 w-2/3 text-center md:text-lg mt-1">
                            <p class="text-white px-2">{{ $t('resend-me-verification-email') }} {{ form.email }}</p>
                            <input type="email" v-model="form.email" class="bg-black-10 my-3 w-full mx-2 px-1">
                        </div>
                        <div class="mt-2">
                            <button class="w-20 bg-cyan-10 hover:bg-cyan-20 rounded-sm" @click="resendVerificationEmail({email: form.email, lang: $i18n.locale})">{{ $t('send') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <modal
            name="confirm_email_info_modal"
            :resizable="false"
            @closed="$router.push('/login')"
            :adaptive="true">
            <div class="w-full bg-cyan-10 h-full border-2 border-black-30">
                <div class="text-5xl md:text-6xl lg:text-7xl mx-auto">
                    <icon :icon="['fas', 'envelope']" class="text-black-60"></icon>
                </div>
                <div class="mx-auto h-20 w-2/3 text-left md:text-lg">
                    <p class="text-black-60 px-2">{{ $t('hi') }}</p>
                    <p class="text-black-60 px-2">{{ $t('thank-you-for-you-registration') }}</p>
                    <p class="text-black-60 px-2">{{ $t('please-click-the-button-below') }}</p>
                    <p class="text-black-60 px-2">{{ $t('see-you-at-training') }}</p>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import MainMessage from "../components/MainMessage";
import LoginLogo from "../icons/LoginLogo";
import {sha512} from "js-sha512";
import FacebookLoginIcon from "@/icons/FacebookLoginIcon";
import {mapActions, mapMutations, mapGetters} from "vuex";
export default {
    name: "Login",
    metaInfo: {
        title: 'Fitness & Gym Kočović | Teretana Kočović Beograd',
        titleTemplate: '%s ← Teretana Beograd',
        meta: [
            {"http-equiv": 'Content-Type', content: 'text/html; charset=utf-8'},
            {name: 'viewport', content: 'width=device-width, initial-scale=1'},
            {name: 'description', content: 'Teretana Kočović Vam nudi sve na jednom mestu. Izaberite trening po Vašim potrebama i prijavite se odmah! | Teretana Beograd | Login | Prijavi se'},
            {
                name: 'keywords',
                content: '' +
                    'login, ' +
                    'prijavi se, ' +
                    'prijavi se teretana beograd, ' +
                    'login gym belgrade, ' +
                    'fitnes Beograd, ' +
                    'fitnes Zemun, ' +
                    'fitnes Blokovi, ' +
                    'Fitnes Novi Beograd, ' +
                    'fitnes Vračar, ' +
                    'fitnes Banjica, ' +
                    'Fitnes Pionir, ' +
                    'Fitnes Ustanička, ' +
                    'teretana Beograd, ' +
                    'teretana Zemun, ' +
                    'teretana Blokovi, ' +
                    'teretana Vračar, ' +
                    'Teretana Pionir, ' +
                    'Teretana Ušće, ' +
                    'Teretana Banjica, ' +
                    'Les Mills Beograd, ' +
                    'grupni fitnes, ' +
                    'fitnes treninzi Beograd, ' +
                    'mršavljenje, ' +
                    'zatezanje, ' +
                    'oblikovanje tela, ' +
                    'joga zemun, ' +
                    'Joga Novi Beograd, ' +
                    'Joga Beograd, ' +
                    'boks, ' +
                    'trening snage, ' +
                    'Kardio, ' +
                    'Fitnes centar Beograd, ' +
                    'Gluteus, ' +
                    'Zadnjica, ' +
                    'Gubljenje kilograma/težine, ' +
                    'Celulit, ' +
                    'kardio treninzi, ' +
                    'gym belgrade'
            },
        ],
    },
    data() {
        return {
            form: {
                email: '',
                password: ''
            },
            forgotEmail: '',
            rememberMe: false,
            type: 'password',
            personalID: null,
            facebookEmail: null,
            name: null,
            phoneFacebook: null,
            phoneGoogle: null,
            phone: null,
            isConnected: false,
            picture: '',
            access_token: null,
            todaysYear: null,
            FB: {},
            model: {},
            scope: {},
            err: {
                response: {
                    data: {
                        error: ''
                    }
                }
            },
            googleSignInParams: {
                client_id: '377832598250-ts9bkfigrd274t0r1o8dp8ibq1bnetf4.apps.googleusercontent.com'
            }
        }
    },
    components: {MainMessage, LoginLogo, FacebookLoginIcon},
    created() {
        window.addEventListener('keypress', (e) => {this.doCommand(e)});
    },
    destroyed() {
        window.removeEventListener('keypress', this.doCommand(0));
    },
    mounted() {
        this.initFB();
        var date = new Date;
        this.todaysYear = date.getFullYear();
    },
    computed: {
        ...mapGetters("auth", {
            googleData: "getGoogleData",
            facebookData: "getFacebookData",
            email: "getEmail",
        })
    },
    methods: {
        doCommand(e) {
            if (e.keyCode === 13) {
                this.login();
            }
        },
        ...mapActions("auth", {
            loginUser: "login",
            initFB: "initFB",
            resendVerificationEmail: "resendVerificationEmail",
            forgotPassword: "forgotPassword",
            onSignInSuccess: "onSignInSuccess",
            onSignInError: "onSignInError",
            createUserFromGoogle: "createUserFromGoogle",
            loginWithFacebook: "loginWithFacebook",
            createUserFromFacebook: "createUserFromFacebook",
        }),
        ...mapMutations("auth", {
            setLoginType: "setLoginType",
        }),
        switchType() {
            if (this.type === 'password') {
                this.type = 'text'
            } else {
                this.type = 'password'
            }
        },
        async login() {
            this.setLoginType('default');
            var email = this.form.email;
            var password = sha512(this.form.password);
            this.loginUser({email: email, password: password});
        },

        // facebook login
        // loginWithFacebook () {
        //     window.FB.login(response => {
        //         if (response.status === "connected") {
        //             this.access_token = response.authResponse.accessToken;
        //             window.FB.api('/me?fields=first_name,last_name,email', response => {
        //                 this.first_name = response.first_name;
        //                 this.last_name = response.last_name;
        //             });
        //             this.$store.dispatch('login', {facebook: this.access_token})
        //             this.loginUser({facebook: this.access_token}).then((resp) => {
        //                     this.handle_success_response('SUC_SUCCESSFULLY_LOGGED_IN');
        //                     const { redirect = false } = this.$route.query
        //                     const path = redirect ? decodeURI(redirect) : '/'
        //                     this.$router.push({path})
        //                 })
        //                 .catch((err) => {
        //                     this.form.email = this.email;
        //                     if (err.response.data.error === 'ERR_USER_NOT_VALIDATED') {
        //                         this.$modal.show('resend_confirmaiton_email');
        //                     } else if (err.response.data.error === 'ERR_BAD_CREDENTIALS') {
        //                         this.$modal.show('enter_phone_modal_facebook');
        //                     } else {
        //                         this.handle_error(err);
        //                         window.FB.logout();
        //                         window.FB.Event.clear;
        //                     }
        //                 });
        //         }
        //     }, this.params)
        // },
        // async createUserFromFacebook(api_data) {
        //     api_data.language = this.$i18n.locale;
        //     if (!api_data.phone || !api_data.email) {
        //         this.err.response.data.error = 'ERR_ALL_FIELDS_REQUIRED';
        //         this.handle_error(this.err);
        //     } else {
        //         await this.$http.post("user/register", api_data)
        //             .then((response) => {
        //                 this.$modal.hide('enter_phone_modal_facebook');
        //                 this.$modal.show('confirm_email_info_modal');
        //                 window.FB.logout();
        //                 window.FB.Event.clear;
        //                 this.err.response.data.error = '';
        //             })
        //             .catch((err) => {
        //                 this.handle_error(err);
        //                 this.$modal.hide('enter_phone_modal_facebook');
        //                 window.FB.logout();
        //                 window.FB.Event.clear;
        //             })
        //     }
        // },
        //end facebook login
    },
}
</script>