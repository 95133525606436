<template>
  <div class="h-full w-full xl:w-10/12 pb-24 mx-auto select-none relative">
    <div class="w-full xl:w-8/12 h-24 mx-auto flex">
      <div class="absolute flex mr-auto w-24 h-24 xl:hidden">
        <training-schedule-icon
          class="flex m-auto pt-2"
        ></training-schedule-icon>
      </div>
      <div class="flex m-auto h-24">
        <p
          class="text-black-50 text-1xl lg:text-2xl xl:text-4xl xl:text-white uppercase m-auto"
        >
          {{ page === "promotions" ? promotionName : $t("training-schedule") }}
        </p>
      </div>
    </div>
    <div
      v-if="desktopView"
      class="w-11/12 md:w-9/12 lg:w-8/12 px-3 mx-auto mt-6 xl:hidden"
    >
      <button
        class="w-full py-3 bg-cyan-20 rounded-md text-white uppercase font-bold focus:outline-none"
        @click="desktopView = false"
      >
        <icon icon="mobile-alt" class="mr-1"></icon>
        {{ $t("daily-view") }}
      </button>

      <div class="text-orange-50 my-3">
        <span class="text-sm md:text-base lg:text-lg uppercase">{{
          $t("to-schedule-a-training")
        }}</span>
      </div>
    </div>
    <div v-if="desktopView" class="w-full bg-black-50 h-12 flex rounded-t-lg">
      <div
        class="text-black-10 hover:bg-green-20 hover:text-white w-64 h-full uppercase flex cursor-pointer transition duration-300 ease-in-out rounded-t-lg"
        :class="
          { active: i === activeItem } + i === activeItem
            ? 'rounded-t-lg bg-green-20'
            : ''
        "
        v-for="(room, i) in rooms"
        :key="room.id"
        @click="
          selectItem(i);
          fetchPeriodsForRoom({ roomId: room.id, page: page });
        "
      >
        <div class="m-auto">{{ room.name }}</div>
      </div>
    </div>
    <!-- Mobile View - START -->
    <div v-if="!desktopView">
      <div
        class="mt-4 w-11/12 md:w-9/12 lg:w-8/12 mx-auto flex px-3 text-black-20 font-bold xl:hidden"
      >
        <span class="uppercase text-left text-lg md:text-xl lg:text-1xl"
          >{{ $t("room") }} :</span
        >
      </div>

      <div
        class="mt-4 w-11/12 md:w-9/12 lg:w-8/12 mx-auto px-3 text-black-60 font-bold xl:hidden"
      >
        <select
          v-model="selectedRoomHelp"
          class="w-full h-12 md:h-16 bg-black-20 px-3 border-2 tex-black-40 uppercase border-black-30"
        >
          <option
            class="text-black-70"
            v-for="(room, i) in rooms"
            :key="room.id"
            :value="room.id"
          >
            {{ room.name }}
          </option>
        </select>
      </div>
      <!-- <div class="w-11/12 md:w-9/12 lg:w-8/12 px-3 mx-auto my-6 xl:hidden">
                <button class="w-full py-3 bg-cyan-20 rounded-md text-white uppercase font-bold focus:outline-none" @click="generatePdf">
                    <icon icon="download" class="mr-1"></icon>
                    {{ $t('download-schedule') }}
                </button>
            </div> -->
      <div
        v-if="!desktopView"
        class="w-11/12 md:w-9/12 lg:w-8/12 px-3 mx-auto my-6 xl:hidden"
      >
        <button
          class="w-full py-3 bg-cyan-20 rounded-md text-white uppercase font-bold focus:outline-none"
          @click="desktopView = true"
        >
          <icon icon="desktop" class="mr-1"></icon>
          {{ $t("weekly-view") }}
        </button>
      </div>

      <div
        class="w-full grid grid-cols-8 bg-black-50 h-12 hidden md:hidden lg:hidden xl:flex rounded-t-lg"
      >
        <div
          class="text-black-10 hover:bg-green-20 hover:text-white w-64 h-full uppercase flex cursor-pointer transition duration-300 ease-in-out rounded-t-lg"
          :class="
            { active: i === activeItem } + i === activeItem
              ? 'rounded-t-lg bg-green-20'
              : ''
          "
          v-for="(room, i) in rooms"
          :key="room.id"
          @click="
            selectItem(i);
            fetchPeriodsForRoom({ roomId: room.id, page: page });
          "
        >
          <div class="m-auto">{{ room.name }}</div>
        </div>
      </div>

      <div
        v-if="days && Object.keys(days).length > 0"
        class="h-12 md:h-16 mt-4 w-11/12 md:w-9/12 lg:w-8/12 mx-auto px-3 flex xl:hidden"
      >
        <div
          class="w-2/12 h-12 md:h-16 bg-cyan-10 rounded-l-md"
          @click="daySwitcher('-')"
        >
          <calendar-arrow-left-icon
            class="mx-auto pl-2 mt-1 md:mt-3"
          ></calendar-arrow-left-icon>
        </div>
        <div class="w-8/12 h-12 md:h-16 bg-cyan-20">
          <p
            v-if="$i18n.locale === 'sr'"
            class="text-white mt-3 md:mt-5 text-lg md:text-xl font-semibold uppercase"
          >
            {{ defaultDay | formatCurrentDaySrb }}
          </p>
          <p
            v-if="$i18n.locale === 'en'"
            class="text-white mt-3 md:mt-5 text-lg md:text-xl font-semibold uppercase"
          >
            {{ defaultDay | formatCurrentDayEng }}
          </p>
        </div>
        <div
          class="w-2/12 h-12 md:h-16 bg-cyan-10 rounded-r-md"
          @click="daySwitcher('+')"
        >
          <calendar-arrow-right-icon
            class="mx-auto pl-2 mt-1 md:mt-3"
          ></calendar-arrow-right-icon>
        </div>
      </div>

      <div
        v-if="days && Object.keys(days).length > 0"
        class="my-10 w-11/12 md:w-9/12 lg:w-8/12 mx-auto px-3 xl:hidden text-white"
      >
        <ul v-for="(day, i) in days" :key="i">
          <li
            v-for="(training, i) in day"
            :key="i"
            v-if="training.day === defaultDay"
            class="mt-8 text-white tooltip-schedule show-slowly"
          >
            <training-schedule-card
              @bookTrainingModal="bookTrainingModal(training)"
              @setTraining="setTraining(training)"
              @bookTraining="bookTraining(training)"
              @cancelTraining="cancelBookedTraining(training)"
              @getServerTime="getServerTime"
              :day="training.day"
              :currentTime="currentTime"
              :currentDay="currentDay"
              :name="training.training.name"
              :name_eng="training.training.name_eng"
              :booked="training.booked"
              :time="training.time.toString()"
              :trainingDate="training.training_date"
              :desktopView="desktopView"
              :page="page"
              :samsung_promotion="training.samsung_promotion"
            />
            <div v-if="training.day < currentDay && page !== 'promotions'">
              <span v-if="$i18n.locale === 'sr'" class="tooltip-text-schedule">
                {{
                  training.day < currentDay
                    ? tooltipMessage
                    : tooltipEmptyMessage || training.day > currentDay
                    ? tooltipMessage
                    : tooltipEmptyMessage
                }}
              </span>
              <span v-if="$i18n.locale === 'en'" class="tooltip-text-schedule">
                {{
                  training.day < currentDay
                    ? tooltipMessageEng
                    : tooltipEmptyMessage || training.day > currentDay
                    ? tooltipMessageEng
                    : tooltipEmptyMessage
                }}
              </span>
            </div>
            <div
              v-if="training.day > currentDay && !page.includes('promotions')"
            >
              <span v-if="$i18n.locale === 'sr'" class="tooltip-text-schedule">
                {{
                  training.day < currentDay
                    ? tooltipMessage
                    : tooltipEmptyMessage || training.day > currentDay
                    ? tooltipMessage
                    : tooltipEmptyMessage
                }}
              </span>
              <span v-if="$i18n.locale === 'en'" class="tooltip-text-schedule">
                {{
                  training.day < currentDay
                    ? tooltipMessageEng
                    : tooltipEmptyMessage || training.day > currentDay
                    ? tooltipMessageEng
                    : tooltipEmptyMessage
                }}
              </span>
            </div>
            <div
              v-if="
                Number(currentTime) > Number(training.time) &&
                training.day === currentDay
              "
            >
              <span v-if="$i18n.locale === 'sr'" class="tooltip-text-schedule">
                {{
                  Number(currentTime) > Number(training.time)
                    ? tooltipTimeMessageSrb
                    : tooltipEmptyMessage
                }}
              </span>
              <span v-if="$i18n.locale === 'en'" class="tooltip-text-schedule">
                {{
                  Number(currentTime) > Number(training.time)
                    ? tooltipTimeMessageEng
                    : tooltipEmptyMessage
                }}
              </span>
            </div>
          </li>
        </ul>
        <div v-if="days && days.length < 1" class="xl:hidden py-10 bg-cyan-20">
          {{ $t("currently-no-training") }}
        </div>
      </div>

      <div
        v-if="days && Object.keys(days).length > 0"
        class="h-12 md:h-16 mt-4 w-11/12 md:w-9/12 lg:w-8/12 mx-auto px-3 flex xl:hidden"
      >
        <div
          class="w-2/12 h-12 md:h-16 bg-cyan-10 rounded-l-md"
          @click="daySwitcher('-')"
        >
          <calendar-arrow-left-icon
            class="mx-auto pl-2 mt-1 md:mt-3"
          ></calendar-arrow-left-icon>
        </div>
        <div class="w-8/12 h-12 md:h-16 bg-cyan-20">
          <p
            v-if="$i18n.locale === 'sr'"
            class="text-white mt-3 md:mt-5 text-lg md:text-xl font-semibold uppercase"
          >
            {{ defaultDay | formatCurrentDaySrb }}
          </p>
          <p
            v-if="$i18n.locale === 'en'"
            class="text-white mt-3 md:mt-5 text-lg md:text-xl font-semibold uppercase"
          >
            {{ defaultDay | formatCurrentDayEng }}
          </p>
        </div>
        <div
          class="w-2/12 h-12 md:h-16 bg-cyan-10 rounded-r-md"
          @click="daySwitcher('+')"
        >
          <calendar-arrow-right-icon
            class="mx-auto pl-2 mt-1 md:mt-3"
          ></calendar-arrow-right-icon>
        </div>
      </div>
    </div>

    <!-- Mobile View - END -->

    <vue-html2pdf
      v-if="desktopView || width > 1279"
      :show-layout="showPdfContent"
      :float-layout="false"
      :enable-download="true"
      :paginate-elements-by-height="1200"
      :preview-modal="false"
      :filename="
        page === 'promotions' ? promotionName : $t('training-schedule')
      "
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      @hasDownloaded="clearPdf"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <!-- PDF Content Here -->
        <div
          id="export_pdf"
          v-if="hours && Object.keys(hours).length > 0"
          class="bg-black-20 grid grid-cols-1 w-full shadow xl:block"
        >
          <div
            v-if="width > 1279"
            class="grid grid-cols-8 h-12 bg-black-30 text-black-10"
          >
            <div class="flex m-auto">{{ $t("time") }}</div>
            <div
              v-if="$i18n.locale === 'sr'"
              class="flex m-auto uppercase"
              v-for="day in scheduleDaysSrb"
            >
              {{ day.name }}
            </div>
            <div
              v-if="$i18n.locale === 'en'"
              class="flex m-auto uppercase"
              v-for="day in scheduleDaysEng"
            >
              {{ day.name }}
            </div>
          </div>
          <div
            v-if="width < 1280"
            class="grid grid-cols-8 h-12 bg-black-30 text-black-10"
          >
            <div
              class="flex w-full h-full justify-center items-center text-xs font-bold"
            >
              <span>{{ $t("time") }}</span>
            </div>
            <div
              v-if="$i18n.locale === 'sr'"
              class="flex m-auto uppercase text-xs"
              v-for="day in scheduleDaysSrb"
            >
              {{ day.short_name }}
            </div>
            <div
              v-if="$i18n.locale === 'en'"
              class="flex m-auto uppercase text-xs"
              v-for="day in scheduleDaysEng"
            >
              {{ day.short_name }}
            </div>
          </div>
          <div
            v-if="hours"
            v-for="(hour, i) in hours"
            :key="i"
            class="grid grid-cols-8 py-3 odd:bg-black-30 show-slowly"
          >
            <div v-if="width > 1279" class="flex">
              <p
                v-if="i.length === 3"
                class="m-auto text-white font-semibold text-3xl"
              >
                {{ i.slice(0, 1) }}:{{ i.slice(-2, i.length) }}h
              </p>
              <p
                v-if="i.length === 4"
                class="m-auto text-white font-semibold text-3xl"
              >
                {{ i.slice(0, 2) }}:{{ i.slice(-2, i.length) }}h
              </p>
            </div>
            <div v-else class="flex">
              <p
                v-if="i.length === 3"
                class="m-auto text-white font-semibold text-sm"
              >
                {{ i.slice(0, 1) }}:{{ i.slice(-2, i.length) }}h
              </p>
              <p
                v-if="i.length === 4"
                class="m-auto text-white font-semibold text-sm"
              >
                {{ i.slice(0, 2) }}:{{ i.slice(-2, i.length) }}h
              </p>
            </div>
            <div v-for="num in 7" :key="num">
              <div v-for="(training, i) in hour" :key="i" class="w-full flex">
                <div
                  v-if="training.day === num"
                  class="w-full m-auto cursor-pointer tooltip-schedule show-slowly"
                >
                  <training-schedule-card
                    id="card"
                    :data-test="training.day"
                    @bookTraining="bookTraining(training)"
                    @bookTrainingModal="bookTrainingModal(training)"
                    @setTraining="setTraining(training)"
                    @cancelTraining="cancelBookedTraining(training)"
                    @getServerTime="getServerTime"
                    :currentTime="currentTime"
                    :currentDay="currentDay"
                    :day="training.day"
                    :trainingDate="training.training_date"
                    :time="training.time.toString()"
                    :booked="training.booked ? training.booked : false"
                    :name_eng="training.training.name_eng"
                    :name="training.training.name"
                    :desktopView="desktopView"
                    :width="width"
                    :samsung_promotion="training.samsung_promotion"
                    :page="page"
                  />
                  <div
                    v-if="training.day < currentDay && page !== 'promotions'"
                  >
                    <span
                      v-if="$i18n.locale === 'sr'"
                      class="tooltip-text-schedule"
                    >
                      {{
                        training.day < currentDay
                          ? tooltipMessage
                          : tooltipEmptyMessage || training.day > currentDay
                          ? tooltipMessage
                          : tooltipEmptyMessage
                      }}
                    </span>
                    <span
                      v-if="$i18n.locale === 'en'"
                      class="tooltip-text-schedule"
                    >
                      {{
                        training.day < currentDay
                          ? tooltipMessageEng
                          : tooltipEmptyMessage || training.day > currentDay
                          ? tooltipMessageEng
                          : tooltipEmptyMessage
                      }}
                    </span>
                  </div>

                  <div
                    v-if="training.day > currentDay && page !== 'promotions'"
                  >
                    <span
                      v-if="$i18n.locale === 'sr'"
                      class="tooltip-text-schedule"
                    >
                      {{
                        training.day < currentDay
                          ? tooltipMessage
                          : tooltipEmptyMessage || training.day > currentDay
                          ? tooltipMessage
                          : tooltipEmptyMessage
                      }}
                    </span>
                    <span
                      v-if="$i18n.locale === 'en'"
                      class="tooltip-text-schedule"
                    >
                      {{
                        training.day < currentDay
                          ? tooltipMessageEng
                          : tooltipEmptyMessage || training.day > currentDay
                          ? tooltipMessageEng
                          : tooltipEmptyMessage
                      }}
                    </span>
                  </div>

                  <div
                    v-if="
                      Number(currentTime) > Number(training.time) &&
                      training.day === currentDay &&
                      !training.booked &&
                      page !== 'promotions'
                    "
                  >
                    <span
                      v-if="$i18n.locale === 'sr'"
                      class="tooltip-text-schedule"
                    >
                      {{
                        Number(currentTime) > Number(training.time)
                          ? tooltipTimeMessageSrb
                          : tooltipEmptyMessage
                      }}
                    </span>
                    <span
                      v-if="$i18n.locale === 'en'"
                      class="tooltip-text-schedule"
                    >
                      {{
                        Number(currentTime) > Number(training.time)
                          ? tooltipTimeMessageEng
                          : tooltipEmptyMessage
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="days && days.length === 0"
            class="w-full h-500 text-2xl uppercase text-white flex"
          >
            <div class="m-auto">{{ $t("currently-no-training") }}</div>
          </div>
        </div>
      </section>
    </vue-html2pdf>

    <div v-if="hours && Object.keys(hours).length < 1" class="w-full py-10">
      <p class="text-1xl lg:text-3xl text-white uppercase">
        {{
          page === "promotions"
            ? $t("no-active-promotions")
            : $t("no-active-trainings")
        }}
      </p>
    </div>

    <div
      class="text-orange-50 mt-5 text-sm md:text-base lg:text-lg uppercase w-10/12 md:w-9/12 lg:w-8/12 mx-auto"
    >
      {{ $t("please-sign-up-for-training") }}
    </div>

    <training-info-modal
      :modal_name="'training_modal'"
      :training_data="training_data"
    >
    </training-info-modal>

    <modal
      name="book_training_modal"
      :resizable="true"
      :adaptive="true"
      @closed="$v.bookingForm.$reset"
    >
      <div
        class="text-black-70 bg-black-20 overflow-auto flex grid grid-cols-1 border-4"
        style="max-height: 100%; height: 100%; max-width: 100%"
      >
        <div class="w-full mx-auto my-auto">
          <form class="uppercase w-12/12">
            <div class="mx-auto w-2/4">
              <p class="text-left">{{ $t("first-name") }}</p>
              <input
                type="text"
                class="w-full outline-none py-1 rounded-sm"
                :class="
                  $v.bookingForm.firstName.$error
                    ? 'border-2 border-red-50'
                    : ''
                "
                v-model="bookingForm.firstName"
              />
            </div>
            <div class="mt-3 mx-auto w-2/4">
              <p class="text-left">{{ $t("last-name") }}</p>
              <input
                type="text"
                class="w-full outline-none py-1 rounded-sm"
                :class="
                  $v.bookingForm.lastName.$error ? 'border-2 border-red-50' : ''
                "
                v-model="bookingForm.lastName"
              />
            </div>
            <div class="mt-3 w-2/4 mx-auto relative">
              <p class="text-left">{{ $t("phone") }}</p>
              <span
                v-if="
                  ($v.bookingForm.phone.$error &&
                    !$v.bookingForm.phone.minLength) ||
                  ($v.bookingForm.phone.$error &&
                    !$v.bookingForm.phone.maxLength)
                "
                class="italic text-red-50 ml-2 text-sm absolute right-0 top-0"
              >
                *{{
                  !$v.bookingForm.phone.minLength
                    ? $t("minimum-eight-digits")
                    : $t("maximum-fifteen-digits")
                }}
              </span>
              <input
                type="number"
                class="w-full outline-none py-1 rounded-sm"
                :class="
                  $v.bookingForm.phone.$error ? 'border-2 border-red-50' : ''
                "
                v-model="bookingForm.phone"
                id="phone"
              />
            </div>
            <div class="mt-5">
              <button
                @click.prevent="bookTraining(period)"
                class="mx-auto uppercase bg-cyan-10 hover:bg-cyan-20 w-2/4 text-base md:text-xl xl:text-sm text-black rounded-sm py-2 z-50"
              >
                {{ $t("confirm-application") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import TrainingScheduleCard from "../components/TrainingScheduleCard";
import TrainingScheduleIcon from "../icons/TrainingScheduleIcon";
import CalendarArrowLeftIcon from "../icons/CalendarArrowLeftIcon";
import CalendarArrowRightIcon from "../icons/CalendarArrowRightIcon";
import TrainingInfoModal from "../components/TrainingInfoModal";
import { mapMutations, mapGetters, mapActions } from "vuex";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "Schedule",
  metaInfo: {
    title: "Fitness & Gym Kočović | Teretana Kočović Beograd",
    titleTemplate: "%s ← Fitness & Gym Kočović",
  },
  props: {
    page: {
      type: String,
      required: true,
    },
  },
  components: {
    TrainingScheduleCard,
    TrainingScheduleIcon,
    CalendarArrowLeftIcon,
    CalendarArrowRightIcon,
    TrainingInfoModal,
    VueHtml2pdf,
  },
  data() {
    return {
      showPdfContent: false,
      host: process.env.VUE_APP_API_URL,
      helpCurrentDay: 1,
      day: null,
      dayName: "Ponedeljak",
      period: [],
      route: "",
      phoneValid_state: false,
      activeItem: 0,
      selectedRoomHelp: "",
      promotionName: "",
      tooltipMessage: "Možete se prijaviti samo za današnji dan",
      tooltipMessageEng: "You can only sign up for todays training",
      tooltipEmptyMessage: "",
      tooltipTimeMessageSrb: "Ne možete se prijaviti za trening koji je prošao",
      tooltipTimeMessageEng: "You can't apply for a training that has passed ",
      scheduleDaysSrb: [
        {
          name: "Ponedeljak",
          short_name: "Pon.",
          value: 1,
        },
        {
          name: "Utorak",
          short_name: "Uto.",
          value: 2,
        },
        {
          name: "Sreda",
          short_name: "Sre.",
          value: 3,
        },
        {
          name: "Četvrtak",
          short_name: "Čet.",
          value: 4,
        },
        {
          name: "Petak",
          short_name: "Pet.",
          value: 5,
        },
        {
          name: "Subota",
          short_name: "Sub.",
          value: 6,
        },
        {
          name: "Nedelja",
          short_name: "Ned.",
          value: 7,
        },
      ],
      scheduleDaysEng: [
        {
          name: "Monday",
          short_name: "Mon.",
          value: 1,
        },
        {
          name: "Tuesday",
          short_name: "Tue.",
          value: 2,
        },
        {
          name: "Wednesday",
          short_name: "Wed.",
          value: 3,
        },
        {
          name: "Thursday",
          short_name: "Thu.",
          value: 4,
        },
        {
          name: "Friday",
          short_name: "Fri.",
          value: 5,
        },
        {
          name: "Saturday",
          short_name: "Sat.",
          value: 6,
        },
        {
          name: "Sunday",
          short_name: "Sun.",
          value: 7,
        },
      ],
      bookingForm: {
        firstName: "",
        lastName: "",
        phone: "",
      },
      training_data: {
        title: "",
        desc: "",
        calories: "",
        duration: "",
        intensity: "",
        photo_url: "",
      },
      desktopView: false,
      width: null,
    };
  },
  validationGroup: ["bookingForm"],
  validations: {
    bookingForm: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      phone: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(15),
      },
    },
  },
  mounted() {
    // var date = new Date();
    // this.helpCurrentDay = date.getDay();
    // this.day = date.getDate();
    // if (this.helpCurrentDay === 0) {
    //     this.helpCurrentDay = 7;
    // }
    console.log(this.page, "PAGE");
    // this.helpCurrentDay = this.defaultDay;
    this.getServerTime();
    this.fetchRooms(this.$route.params.id);
    this.selectedRoomHelp = this.selectedRoom;
    this.route = this.$route.name.toLowerCase();
    this.getPromotion();
    this.fetchPeriodsForRoom({
      page: this.page,
      roomId: this.selectedRoom,
    });
    this.width = window.innerWidth;
    window.onresize = () => {
      this.width = window.innerWidth;
    };
  },
  computed: {
    currentDayHelper() {
      return this.defaultDay;
    },
    ...mapGetters("auth", {
      isLoggedIn: "getIsLoggedIn",
    }),
    ...mapGetters("schedule", {
      hours: "getHours",
      days: "getDays",
      defaultDay: "getDefaultDay",
    }),
    ...mapGetters("gyms", {
      rooms: "getRooms",
      selectedRoom: "getSelectedRoom",
    }),
    ...mapGetters("shared", {
      currentDay: "getCurrentDay",
      currentTime: "getCurrentTime",
    }),
  },
  methods: {
    ...mapActions("schedule", {
      daySwitcher: "daySwitcher",
    }),
    generatePdf() {
      this.showPdfContent = true;
      this.setLoading(true);
      const pdf_content = document.getElementById("export_pdf");
      pdf_content.classList.remove("hidden");
      pdf_content.classList.add("absolute");
      pdf_content.classList.add("top-0");
      pdf_content.classList.add("left-0");
      pdf_content.classList.add("w-full");

      const cards = document.getElementsByClassName("trainingCard");
      cards.forEach((card, i) => {
        card.classList.add("w-56");
        card.classList.add("scale-75");
        card.classList.add("-translate-x-5");
      });

      const names = document.getElementsByClassName("name");
      names.forEach((name, i) => {
        name.classList.add("absolute");
        name.classList.add("left-0");
        name.classList.add("w-full");
        name.classList.add("tracking-widest");
      });

      this.$refs.html2Pdf.generatePdf();
    },
    clearPdf() {
      this.showPdfContent = false;
      const pdf_content = document.getElementById("export_pdf");
      const cards = document.getElementsByClassName("trainingCard");
      const names = document.getElementsByClassName("name");

      this.$nextTick(() => {
        pdf_content.classList.add("hidden");
        pdf_content.classList.remove("absolute");
        pdf_content.classList.remove("top-0");
        pdf_content.classList.remove("left-0");
        pdf_content.classList.remove("w-full");

        cards.forEach((card, i) => {
          card.classList.remove("w-56");
          card.classList.remove("scale-75");
          card.classList.remove("ml-0");
          card.classList.remove("-translate-x-5");
        });

        names.forEach((name, i) => {
          name.classList.remove("absolute");
          name.classList.remove("left-0");
          name.classList.remove("w-full");
          name.classList.remove("tracking-widest");
        });
      });
      this.setLoading(false);
    },
    ...mapMutations("shared", {
      setErrorMessaage: "setErrorMessage",
    }),
    ...mapMutations("loader", {
      setLoading: "setLoading",
    }),
    ...mapMutations("gyms", {
      setSelectedRoom: "setSelectedRoom",
    }),
    ...mapActions("schedule", {
      book: "book",
      fetchPeriodsForRoom: "fetchPeriodsForRoom",
      cancelTraining: "cancelTraining",
    }),
    ...mapActions("gyms", {
      fetchRooms: "fetchRooms",
    }),
    ...mapActions("shared", {
      getServerTime: "getServerTime",
    }),
    async getPromotion() {
      const { data } = await this.$http.get("/promotion/active");
      this.promotionName = data.title;
    },
    async bookTraining(period) {
      this.getServerTime();
      if (!this.isLoggedIn) {
        this.$v.bookingForm.$touch();
        if (this.$v.bookingForm.$invalid) {
          var err = {
            response: {
              data: {
                error: "ERR_ALL_FIELDS_REQUIRED",
              },
            },
          };
          this.setErrorMessaage(err);
        } else {
          this.book({
            bookingForm: this.bookingForm,
            period: period,
            page: this.page,
            currentTime: this.currentTime,
          });
          console.log("usao");
          window.dataLayer.push({ event: "PrijavaFitnes" });
        }
      } else {
        this.book({
          bookingForm: this.bookingForm,
          period: period,
          page: this.page,
          currentTime: this.currentTime,
        });
        window.dataLayer.push({ event: "PrijavaFitnes" });
      }
    },
    // this method will cancel training for users with account
    async cancelBookedTraining(period) {
      this.cancelTraining({
        page: this.page,
        period: period,
      });
    },
    bookTrainingModal(period) {
      this.period = period;
      this.$modal.show("book_training_modal");
    },
    setTraining(data) {
      this.training_data.id = data.id;
      this.training_data.title = data.training.name;
      this.training_data.title_eng = data.training.name_eng;
      this.training_data.desc = data.training.description;
      this.training_data.desc_eng = data.training.description_eng;
      this.training_data.intensity = data.training.intensity;
      this.training_data.duration = data.training.duration;
      this.training_data.calories = data.training.calories;
      this.training_data.photo_url = data.training.photo_url;
      this.training_data.empty_stars = 5 - data.training.intensity;
      this.day = data.day;
      this.$modal.show("training_modal");
    },
    selectItem(i) {
      this.activeItem = i;
    },
    // validatePhone(elementValue){
    //     var phonePattern = /^\d{8,15}$/;
    //     return phonePattern.test(elementValue);
    // },
  },
  watch: {
    selectedRoom(val) {
      this.fetchPeriodsForRoom({
        roomId: val,
        page: this.page,
      });
    },
    selectedRoomHelp(val) {
      this.fetchPeriodsForRoom({
        roomId: val,
        page: this.page,
      });
    },
    "$route.params.id"(val) {
      this.activeItem = 0;
      this.fetchRooms(val);
      this.setSelectedRoom(this.rooms[0].id);
      this.fetchPeriodsForRoom({
        page: this.page,
        roomId: this.rooms[0].id,
      });
    },
    "bookingForm.firstName"(val) {
      if (val.includes(" ")) {
        this.bookingForm.firstName = this.bookingForm.firstName.trim();
      }
    },
  },
};
</script>

<style scoped>
.test {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  width: 300%;
  transform: scale(32%);
}
</style>
