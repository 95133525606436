const ustanicka = [
        'galleries/ustanicka/1.jpg',
        'galleries/ustanicka/2.jpg',
        'galleries/ustanicka/3.jpg',
        'galleries/ustanicka/4.jpg',
        'galleries/ustanicka/5.jpg',
        'galleries/ustanicka/6.jpg',
        'galleries/ustanicka/7.jpg',
        'galleries/ustanicka/8.jpg',
        'galleries/ustanicka/9.jpg',
        'galleries/ustanicka/10.jpg',
        'galleries/ustanicka/11.jpg',
        'galleries/ustanicka/12.jpg',
        'galleries/ustanicka/13.jpg',
        'galleries/ustanicka/14.jpg',
        'galleries/ustanicka/15.jpg',
        'galleries/ustanicka/16.jpg',
        'galleries/ustanicka/17.jpg',
        'galleries/ustanicka/18.jpg',
        'galleries/ustanicka/19.jpg',
        'galleries/ustanicka/20.jpg',
        'galleries/ustanicka/21.jpg',
        'galleries/ustanicka/22.jpg',
        'galleries/ustanicka/23.jpg',
        'galleries/ustanicka/24.jpg',
        'galleries/ustanicka/25.jpg',
        'galleries/ustanicka/26.jpg',
        'galleries/ustanicka/27.jpg',
        'galleries/ustanicka/28.jpg',
        'galleries/ustanicka/29.jpg',
        'galleries/ustanicka/30.jpg',
    ]
export default ustanicka;