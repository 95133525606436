<template>
     <modal
            :name="modal_name"
            :resizable="false"
            :adaptive="true"
            @closed="$router.push('/')"
            :minHeight="320"
            :shiftY="0.4"
        >
             <div
                class="text-black-10 bg-gradient-to-b from-black-80 via-black-50 to-black-80 overflow-auto flex m-auto border-4 border-green-20 flex"
                style="max-height: 100%; height: 100%; max-width: 100%;"
            >
                 <div class="m-auto text-lg md:text-xl lg:text-1xl px-10 text-center">{{ $t('success-sent-form-message') }}</div>
            </div>
      </modal>
</template>

<script>
export default {
    name: "SuccessfullySentFormModal",
    props: {
        modal_name: {
            type: String,
            required: true
        }
    },
}
</script>